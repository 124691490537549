import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLocations } from '../../../selectors/Location';
import { destroyLocation, fetchLocations } from '../../../actions/Location';
import { Location } from '../../../types/Location';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const LocationDataTable = () => {

  const locations = useSelector(getLocations);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [location, setActiveLocation] = useState<Location | null>();

  useEffect(() => {
    dispatch(fetchLocations());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.LOCATION_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.LOCATION_UPDATE, row.id));
  };

  const onDelete = (row: Location) => {
    setActiveLocation(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (location?.id) {
      dispatch(destroyLocation(location?.id));
    }
    setActiveLocation(null);
  };

  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'city', label: 'City', mobileFriendly: true },
      { key: 'country', label: 'Country', mobileFriendly: true },
      { key: 'address', label: 'Address', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return <View>
        <>
          <DataTable title="LOCATIONS" ctaTitle="Create Location" onCreate={onCreate} data={locations?.data || []} config={config} />
          <Modal show={toggle} title="Delete Location" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this location?
            </Modal>
        </>
    </View>;
};

export default LocationDataTable;
