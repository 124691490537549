import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  RoleClient,
  RoleClientActionTypes,
  RoleClientDataModel, FETCH_ROLE_CLIENT_FAILURE,
  FETCH_ROLE_CLIENT_REQUEST,
  FETCH_ROLE_CLIENT_RESPONSE,
  FETCH_ROLE_CLIENTS_FAILURE,
  FETCH_ROLE_CLIENTS_REQUEST,
  FETCH_ROLE_CLIENTS_RESPONSE,
  POST_ROLE_CLIENT_FAILURE,
  POST_ROLE_CLIENT_REQUEST,
  POST_ROLE_CLIENT_RESPONSE,
} from '../types/RoleClient';

import { RootState } from '../store/configureStore';

import { deleteRoleClient, getRoleClients, postRoleClient } from '../api/RoleClient';



export function fetchRoleClientsRequest(): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENTS_REQUEST,
    payload: null,
  };
}

export function fetchRoleClientsResponse(
  roleClients: RoleClientDataModel,
): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENTS_RESPONSE,
    payload: roleClients,
  };
}

export function fetchRoleClientsFailure(): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENTS_FAILURE,
    payload: null,
  };
}

export function fetchRoleClientRequest(): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENT_REQUEST,
    payload: null,
  };
}

export function fetchRoleClientResponse(
  user: RoleClient,
): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENT_RESPONSE,
    payload: user,
  };
}

export function fetchRoleClientFailure(): RoleClientActionTypes {
  return {
    type: FETCH_ROLE_CLIENT_FAILURE,
    payload: null,
  };
}

export function postRoleClientRequest(): RoleClientActionTypes {
  return {
    type: POST_ROLE_CLIENT_REQUEST,
    payload: null,
  };
}

export function postRoleClientResponse(): RoleClientActionTypes {
  return {
    type: POST_ROLE_CLIENT_RESPONSE,
    payload: null,
  };
}

export function postRoleClientFailure(error: string, validationErrors: any): RoleClientActionTypes {
  return {
    type: POST_ROLE_CLIENT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchRoleClients = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRoleClientsRequest());
    const asyncResp: any = await getRoleClients();
    if (asyncResp?.success) {
      await dispatch(fetchRoleClientsResponse(asyncResp));
    } else {
      await dispatch(fetchRoleClientsFailure());
    }
  };

export const destroyRoleClient = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteRoleClient(id);
    if (asyncResp?.success) {
      await dispatch(fetchRoleClients());
    }
  };


export const createRoleClient = (
  roleId: string, clientId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postRoleClientRequest());
    const asyncResp: any = await postRoleClient(roleId, clientId);
    if (asyncResp?.success) {
      await dispatch(postRoleClientResponse());
      await dispatch(fetchRoleClients());
    } else {
      await dispatch(postRoleClientFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
