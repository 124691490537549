import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_RESPONSE,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_RESPONSE,
  FETCH_ACCOUNT_FAILURE,
  POST_ACCOUNT_FAILURE,
  POST_ACCOUNT_REQUEST,
  POST_ACCOUNT_RESPONSE,
  AccountActionTypes, AccountDataModel, Account,
} from '../types/Account';

import { RootState } from '../store/configureStore';

import { getAccount, getAccounts, postAccount, putAccount, deleteAccount } from '../api/Account';

export function fetchAccountsRequest(): AccountActionTypes {
  return {
    type: FETCH_ACCOUNTS_REQUEST,
    payload: null,
  };
}

export function fetchAccountsResponse(
  users: AccountDataModel,
): AccountActionTypes {
  return {
    type: FETCH_ACCOUNTS_RESPONSE,
    payload: users,
  };
}

export function fetchAccountsFailure(): AccountActionTypes {
  return {
    type: FETCH_ACCOUNTS_FAILURE,
    payload: null,
  };
}

export function fetchAccountRequest(): AccountActionTypes {
  return {
    type: FETCH_ACCOUNT_REQUEST,
    payload: null,
  };
}

export function fetchAccountResponse(
  user: Account,
): AccountActionTypes {
  return {
    type: FETCH_ACCOUNT_RESPONSE,
    payload: user,
  };
}

export function fetchAccountFailure(): AccountActionTypes {
  return {
    type: FETCH_ACCOUNT_FAILURE,
    payload: null,
  };
}


export function postAccountRequest(): AccountActionTypes {
  return {
    type: POST_ACCOUNT_REQUEST,
    payload: null,
  };
}

export function postAccountResponse(): AccountActionTypes {
  return {
    type: POST_ACCOUNT_RESPONSE,
    payload: null,
  };
}

export function postAccountFailure(error: string, validationErrors: any): AccountActionTypes {
  return {
    type: POST_ACCOUNT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveAccount = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAccountRequest());
    const asyncResp: any = await getAccount(id);
    if (asyncResp?.success) {
      await dispatch(fetchAccountResponse(asyncResp.data));
    } else {
      await dispatch(fetchAccountFailure());
    }
  };

export const fetchAccounts = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAccountsRequest());
    const asyncResp: any = await getAccounts();
    if (asyncResp?.success) {
      await dispatch(fetchAccountsResponse(asyncResp));
    } else {
      await dispatch(fetchAccountsFailure());
    }
  };

export const destroyAccount = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteAccount(id);
    if (asyncResp?.success) {
      await dispatch(fetchAccounts());
    }
  };


export const createAccount = (
  title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAccountRequest());
    const asyncResp: any = await postAccount(title, description, active);
    if (asyncResp?.success) {
      await dispatch(postAccountResponse());
      await dispatch(fetchAccounts());
    } else {
      await dispatch(postAccountFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateAccount = (
  id: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAccountRequest());
    const asyncResp: any = await putAccount(id, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postAccountResponse());
      await dispatch(fetchAccounts());
    } else {
      await dispatch(postAccountFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
