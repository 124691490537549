import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createGroup, fetchActiveGroup, updateGroup } from '../../../actions/Group';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveGroup,
  isPostingGroup,
  postingGroupError,
  postingGroupFailed,
  postingGroupValidationErrors,
  isFetchingGroups,
} from '../../../selectors/Group';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const GroupForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeGroup = useSelector(getActiveGroup);
  const isPosting = useSelector(isPostingGroup);
  const isFetching = useSelector(isFetchingGroups);
  const postingFailed = useSelector(postingGroupFailed);
  const postingError = useSelector(postingGroupError);
  const postingValidationErrors = useSelector(postingGroupValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.GROUP_UPDATE);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveGroup(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeGroup && editMode) {
      setTitle(activeGroup?.title);
      setDescription(activeGroup?.description);
      // @ts-ignore
      setActive(activeGroup?.active === 1 || activeGroup?.active === true);
    }
  }, [activeGroup]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.GROUP);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createGroup(title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateGroup(activeId, title, description, active));
    }
  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Group</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <Input v={v} err={err}
             name="title"
             id="title"
             label="Title"
             required
             value={title}
             onChange={setTitle}
             disabled={isPosting || isFetching}
             onBlur={setTitle}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             required
             name="description"
             id="description"
             label="Description"
             value={description}
             disabled={isPosting || isFetching}
             onChange={setDescription}
             onBlur={setDescription}
             placeholder="Insert a description"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default GroupForm;
