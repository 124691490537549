import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST,
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE,
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE,
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
  POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
  POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
  POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
  ActivitySlotInstructorActionTypes, ActivitySlotInstructorDataModel, ActivitySlotInstructor,
} from '../types/ActivitySlotInstructor';

import { RootState } from '../store/configureStore';

import { getActivitySlotInstructor, getActivitySlotInstructors, postActivitySlotInstructor,  deleteActivitySlotInstructor } from '../api/ActivitySlotInstructor';


export function fetchActivitySlotInstructorsRequest(): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST,
    payload: null,
  };
}

export function fetchActivitySlotInstructorsResponse(
  activitySlotInstructors: ActivitySlotInstructorDataModel,
): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE,
    payload: activitySlotInstructors,
  };
}

export function fetchActivitySlotInstructorsFailure(): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE,
    payload: null,
  };
}

export function fetchActivitySlotInstructorRequest(): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
    payload: null,
  };
}

export function fetchActivitySlotInstructorResponse(
  activitySlotInstructor: ActivitySlotInstructor,
): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
    payload: activitySlotInstructor,
  };
}

export function fetchActivitySlotInstructorFailure(): ActivitySlotInstructorActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
    payload: null,
  };
}


export function postActivitySlotInstructorRequest(): ActivitySlotInstructorActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
    payload: null,
  };
}

export function postActivitySlotInstructorResponse(): ActivitySlotInstructorActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
    payload: null,
  };
}

export function postActivitySlotInstructorFailure(error: string, validationErrors: any): ActivitySlotInstructorActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveActivitySlotInstructor = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitySlotInstructorRequest());
    const asyncResp: any = await getActivitySlotInstructor(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotInstructorResponse(asyncResp.data));
    } else {
      await dispatch(fetchActivitySlotInstructorFailure());
    }
  };

export const fetchActivitySlotInstructors = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitySlotInstructorsRequest());
    const asyncResp: any = await getActivitySlotInstructors();
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotInstructorsResponse(asyncResp));
    } else {
      await dispatch(fetchActivitySlotInstructorsFailure());
    }
  };

export const destroyActivitySlotInstructor = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteActivitySlotInstructor(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotInstructors());
    }
  };


export const createActivitySlotInstructor = (
  activitySlotId: string,
  instructorId: string,

):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivitySlotInstructorRequest());
    const asyncResp: any = await postActivitySlotInstructor(activitySlotId, instructorId);
    if (asyncResp?.success) {
      await dispatch(postActivitySlotInstructorResponse());
      await dispatch(fetchActivitySlotInstructors());
    } else {
      await dispatch(postActivitySlotInstructorFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };



