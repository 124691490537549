import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createInstructor, fetchActiveInstructor, updateInstructor } from '../../../actions/Instructor';
import { getUsers } from '../../../selectors/User';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import CheckBox from '../../../components/CheckBox';
import { fetchUsers } from '../../../actions/User';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import {
  getActiveInstructor,
  isPostingInstructor,
  postingInstructorError,
  postingInstructorFailed,
  postingInstructorValidationErrors,
  isFetchingInstructors,
} from '../../../selectors/Instructor';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const InstructorForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const activeInstructor = useSelector(getActiveInstructor);
  const isPosting = useSelector(isPostingInstructor);
  const isFetching = useSelector(isFetchingInstructors);
  const postingFailed = useSelector(postingInstructorFailed);
  const postingError = useSelector(postingInstructorError);
  const postingValidationErrors = useSelector(postingInstructorValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.INSTRUCTOR_UPDATE);

  const formConfig = [
    { field: 'userId', validators: [isRequired] },
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'phone', validators: [isRequired] },
    { field: 'address', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
    { field: 'postcode', validators: [] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [userId, setUserId] = useState('');
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveInstructor(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeInstructor && editMode) {
      setUserId(activeInstructor?.userId);
      setGroupId(activeInstructor?.groupId);
      setClientId(activeInstructor?.clientId);
      setTitle(activeInstructor?.title);
      setEmail(activeInstructor?.email);
      setFirstName(activeInstructor?.firstName);
      setLastName(activeInstructor?.lastName);
      setPhone(activeInstructor?.phone);
      setAddress(activeInstructor?.address);
      setCity(activeInstructor?.city);
      setPostcode(activeInstructor?.postcode);
      setCountry(activeInstructor?.country);
      // @ts-ignore
      setActive(activeInstructor?.active === 1 || activeInstructor?.active === true);
    }
  }, [activeInstructor]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.INSTRUCTOR);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createInstructor(userId, groupId, clientId, title, email, firstName, lastName, phone, address, city, postcode, country, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateInstructor(activeId, userId, groupId, clientId, title, email, firstName, lastName, phone, address, city, postcode, country, active));
    }
  };

  const itemsUsers = users?.data.map(r => ({ label: r.email, value: r.id })) || [];
  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onUserSelect = (obj: any) => {
    setUserId(obj.value);
  };
  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };
  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Instructor</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="userId"
                placeholder="Please select a User"
                required label="User"
                items={itemsUsers}
                value={userId}
                disabled={isPosting || isFetching}
                onSelect={onUserSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="groupId"
                placeholder="Please select a Group"
                required label="Group"
                items={itemsGroups}
                value={groupId}
                disabled={isPosting || isFetching}
                onSelect={onGroupSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="clientId"
                placeholder="Please select a Client"
                required label="Client"
                items={itemsClients}
                value={clientId}
                disabled={isPosting || isFetching || disabledClient}
                onSelect={onClientSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="title"
             id="title"
             label="Title"
             required
             value={title}
             onChange={setTitle}
             disabled={isPosting || isFetching}
             onBlur={setTitle}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             name="email"
             id="email"
             label="Email"
             required
             value={email}
             onChange={setEmail}
             disabled={isPosting || isFetching}
             onBlur={setEmail}
             placeholder="Insert an email"
      />
      <Input v={v} err={err}
             required
             name="firstName"
             id="firstName"
             label="First Name"
             value={firstName}
             disabled={isPosting || isFetching}
             onChange={setFirstName}
             onBlur={setFirstName}
             placeholder="Insert first name"
      />
      <Input v={v} err={err}
             name="lastName"
             id="lastName"
             label="Last Name"
             required
             value={lastName}
             disabled={isPosting || isFetching}
             onChange={setLastName}
             onBlur={setLastName}
             placeholder="Insert last name"
      />
      <Input v={v} err={err}
             name="phone"
             id="phone"
             label="Phone"
             required
             value={phone}
             disabled={isPosting || isFetching}
             onChange={setPhone}
             onBlur={setPhone}
             placeholder="Insert a phone number"
      />
      <Input v={v} err={err}
             name="address"
             id="address"
             label="Address"
             required
             value={address}
             disabled={isPosting || isFetching}
             onChange={setAddress}
             onBlur={setAddress}
             placeholder="Insert an address"
      />
      <Input v={v} err={err}
             name="city"
             id="city"
             label="City"
             required
             value={city}
             disabled={isPosting || isFetching}
             onChange={setCity}
             onBlur={setCity}
             placeholder="Insert a City"
      />
      <Input v={v} err={err}
             name="postcode"
             id="postcode"
             label="Postcode"
             value={postcode}
             disabled={isPosting || isFetching}
             onChange={setPostcode}
             onBlur={setPostcode}
             placeholder="Insert a Postcode"
      />
      <Input v={v} err={err}
             name="country"
             id="country"
             label="Country"
             required
             disabled={isPosting || isFetching}
             value={country}
             onChange={setCountry}
             onBlur={setCountry}
             placeholder="Insert a Country"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default InstructorForm;
