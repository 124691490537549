import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createClient, fetchActiveClient, updateClient } from '../../../actions/Client';
import { getGroups } from '../../../selectors/Group';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';

import {
  getActiveClient,
  isPostingClient,
  postingClientError,
  postingClientFailed,
  postingClientValidationErrors,
  isFetchingClients,
} from '../../../selectors/Client';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const ClientForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const activeClient = useSelector(getActiveClient);
  const isPosting = useSelector(isPostingClient);
  const isFetching = useSelector(isFetchingClients);
  const postingFailed = useSelector(postingClientFailed);
  const postingError = useSelector(postingClientError);
  const postingValidationErrors = useSelector(postingClientValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CLIENT_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [groupId, setGroupId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    if (editMode) {
      dispatch(fetchActiveClient(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeClient && editMode) {
      setGroupId(activeClient?.groupId);
      setTitle(activeClient?.title);
      setDescription(activeClient?.description);
      // @ts-ignore
      setActive(activeClient?.active === 1 || activeClient?.active === true);

    }
  }, [activeClient]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CLIENT);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createClient(groupId, title, description, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateClient(activeId, groupId, title, description, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Client</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="groupId"
                placeholder="Please select a Group"
                required label="Group"
                items={itemsGroups}
                value={groupId}
                disabled={isPosting || isFetching}
                onSelect={onGroupSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="title"
             id="title"
             label="Title"
             required
             value={title}
             onChange={setTitle}
             disabled={isPosting || isFetching}
             onBlur={setTitle}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             required
             name="description"
             id="description"
             label="Description"
             value={description}
             disabled={isPosting || isFetching}
             onChange={setDescription}
             onBlur={setDescription}
             placeholder="Insert a description"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default ClientForm;



