import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Checkout.scss';
import View from '../../components/View';
import {
  getCartItems, getOrders,
  isPostingOrder,
  postingOrderFailed,
} from '../../selectors/Order';
import { useHistory } from 'react-router-dom';
import con from '../../helpers/constants';
import Button from '../../components/Button';
import CheckoutItem from '../../components/CheckoutItem';
import { Alert } from 'react-bootstrap';

import {
  isPostingCustomer,
  postingCustomerFailed,
} from '../../selectors/Customer';

import { createOrders } from '../../actions/Order';
import { fetchUserCustomers, fetchUsers } from '../../actions/User';
import { formatUrl, getUserToken } from '../../helpers/functions';
// @ts-ignore
import CreditCardInput from 'react-credit-card-input';
import c from '../../helpers/constants';
import CheckoutCustomerForm from './CheckoutCustomerForm';
import { Customer } from '../../types/Customer';
import { CartItem } from '../../types/Order';

const Checkout = () => {

  const history = useHistory();
  const cartItems = useSelector(getCartItems);
  const orders = useSelector(getOrders);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [hasSubmittedOrder, setHasSubmittedOrder] = useState(false);
  const postingFailed = useSelector(postingCustomerFailed);
  const postingFailedOrder = useSelector(postingOrderFailed);
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingCustomer);
  const isPostingMyOrder = useSelector(isPostingOrder);
  const [triggerValidationOrder, setTriggerValidationOrder] = useState(false);
  const [total, setTotal] = useState(0);
  const [allowTotal, setAllowTotal] = useState(0);
  const [myList, setMyList] = useState<CartItem[]>(cartItems);
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cardCVC, setCardCVC] = useState('');

  const onCustomerSelect = (customer: Customer, cartItem: CartItem) => {
    const items = [...myList];
    const itemIndex = items.findIndex(cartI => cartI.localId === cartItem.localId);
    if (itemIndex >= 0) {
      items[itemIndex] = { ...items[itemIndex], customerId: customer.id };
    } else {
      items.push({ ...cartItem, customerId: customer.id });
    }
    setTotal(total + cartItem.activitySlot.activityPricePerPerson);

    setMyList(items);
    if (allowTotal < cartItems.length) {
      setAllowTotal(allowTotal + 1);
    }
  };

  let subTotal = 0;
  let totalVat = 0;
  for (let i = 0; i < cartItems.length; i++){
    subTotal = subTotal + cartItems[i].activitySlot.activityPricePerPerson;
    totalVat = totalVat + (cartItems[i].activitySlot.activityVat / 100 * cartItems[i].activitySlot.activityPricePerPerson);
  }

  let grandTotal = subTotal + totalVat;


  const completeOrders = () => {
    if (cartItems.length == myList.length) {
      // @ts-ignore
      dispatch(createOrders(myList));
      setHasSubmittedOrder(true);
    } else {
      setTriggerValidationOrder(true);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchUserCustomers(getUserToken()));
  }, [dispatch]);


  useEffect(() => {
    if (!isPostingMyOrder && !postingFailedOrder && hasSubmittedOrder && orders && orders?.data?.length > 0) {
      setHasSubmittedOrder(false);
      history.push(formatUrl(c.APP_ROUTES.CHECKOUT_SUCCESS, orders.data[0].bookingReference));
    }
  }, [isPostingMyOrder]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(con.APP_ROUTES.CHECKOUT);
    }
  }, [isPosting]);


  const CardNumberChange = (obj: any) => {
    setCardNumber(obj.value);
  };
  const CardExpiry = (obj: any) => {
    setCardExpiry(obj.value);
  };
  const CardCVC = (obj: any) => {
    setCardCVC(obj.value);
  };

  return <View isAdmin={false} style="dark">
        <div className="row px-4 mb-5">
            <div className="col-md-9 align-self-end">
                <h2 className="title-white-primary my-2">CART CHECKOUT</h2>
            </div>
            <div className="col-md-3 align-self-center">
                <CheckoutCustomerForm/>
            </div>
        </div>
        <div className="row px-4">
            {triggerValidationOrder && (
                <Alert className="mt-3" variant="danger">
                    {'Please Select a Customer in every order'}
                </Alert>
            )}
            {cartItems?.map((cartItem, i) => <div key={i} className="col">

                <CheckoutItem onCustomerSelect={(customer) => onCustomerSelect(customer, cartItem)} item={cartItem}/>

            </div>)}

            <div className="row-5">
              <h6 className="title-white-primary">Subtotal: {subTotal}</h6>
              <h6 className="title-white-primary">VAT: {totalVat}</h6>
                <h3 className="title-white-primary mb-4">Total: {grandTotal} AED</h3>
              <div className="row align-items-center">
                <div className="col-sm-1">
                    <h4 className="title-white-primary ">Payment </h4>
                </div>
                    <div className="credit_card_container">
                    <CreditCardInput className="credit_card_input"
                                     cardNumberInputProps={{ value: cardNumber, onChange: CardNumberChange }}
                                     cardExpiryInputProps={{ value: cardExpiry, onChange: CardExpiry }}
                                     cardCVCInputProps={{ value: cardCVC, onChange: CardCVC }}
                                     inputClassName="credit_card_input"
                                     fieldClassName="credit_card_field"

                    />
                    </div>
              </div>
            </div>
            <div className="col mb-5 align-self-end">
                <div className="col">
                    <Button title="COMPLETE TRANSACTION"
                            onClick={completeOrders}/>
                </div>
            </div>
        </div>
    </View>;
};

export default Checkout;
