import View from '../../../components/View';
import React from 'react';


const UserPendingVerification = () => {


  return <View isAdmin={false}>
      <div className="container">
          <div className="row">
              <div className="col-12 p-5">
                <h6 style={{ color:'white' }}>Registration complete! Please wait until an administrator verifies your account.</h6>
              </div>
          </div>
      </div>
</View>;

};

export default UserPendingVerification;
