import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { requestResetPassword } from '../../../actions/User';
import Modal from '../../../components/Modal';


import {
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
  isFetchingUsers,
} from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';


const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);


  const formConfig = [
    { field: 'email', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [email, setEmail] = useState('');

  useEffect(() => {
  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER_LOGIN);
    }
  }, [isPosting]);

  
  const onModal = () => {
    setToggle(true);
  };

  const onDismiss = () => {
    setToggle(false);
  };

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      // TODO use returnUrl to redirect to where the user wanted to go in the first place after making sure they are indeed logged in.
      dispatch(requestResetPassword(email));
      setToggle(false);
    }
  };



  return <View isAdmin={false}>
        <h4>Forgot your password?</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <Input v={v} err={err}
                   name="email"
                   id="email"
                   label="Email"
                   required
                   value={email}
                   onChange={setEmail}
                   disabled={isPosting || isFetching}
                   onBlur={setEmail}
                   placeholder="Insert your email here"
            />
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={onModal}
                            title={'RESET'}/>
                </div>
            </div>
        </form>
      <Modal show={toggle} title="Almost there!" cta="Continue to login" onCta={onSubmit} onHide={onDismiss}>
          Upon clicking CONTINUE you will receive an email containing instructions to reset your password.
      </Modal>
    </View>;
};

export default UserForm;



