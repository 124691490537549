import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_RESPONSE,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_RESPONSE,
  FETCH_LOCATION_FAILURE,
  POST_LOCATION_FAILURE,
  POST_LOCATION_REQUEST,
  POST_LOCATION_RESPONSE,
  LocationActionTypes, LocationDataModel, Location,
} from '../types/Location';

import { RootState } from '../store/configureStore';

import {
  getLocation,
  getLocations,
  postLocation,
  putLocation,
  deleteLocation,
  getLocationsByShortcodes,
} from '../api/Location';

export function fetchLocationsRequest(): LocationActionTypes {
  return {
    type: FETCH_LOCATIONS_REQUEST,
    payload: null,
  };
}

export function fetchLocationsResponse(
  locations: LocationDataModel,
): LocationActionTypes {
  return {
    type: FETCH_LOCATIONS_RESPONSE,
    payload: locations,
  };
}

export function fetchLocationsFailure(): LocationActionTypes {
  return {
    type: FETCH_LOCATIONS_FAILURE,
    payload: null,
  };
}

export function fetchLocationRequest(): LocationActionTypes {
  return {
    type: FETCH_LOCATION_REQUEST,
    payload: null,
  };
}

export function fetchLocationResponse(
  location: Location,
): LocationActionTypes {
  return {
    type: FETCH_LOCATION_RESPONSE,
    payload: location,
  };
}

export function fetchLocationFailure(): LocationActionTypes {
  return {
    type: FETCH_LOCATION_FAILURE,
    payload: null,
  };
}


export function postLocationRequest(): LocationActionTypes {
  return {
    type: POST_LOCATION_REQUEST,
    payload: null,
  };
}

export function postLocationResponse(): LocationActionTypes {
  return {
    type: POST_LOCATION_RESPONSE,
    payload: null,
  };
}

export function postLocationFailure(error: string, validationErrors: any): LocationActionTypes {
  return {
    type: POST_LOCATION_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveLocation = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLocationRequest());
    const asyncResp: any = await getLocation(id);
    if (asyncResp?.success) {
      await dispatch(fetchLocationResponse(asyncResp.data));
    } else {
      await dispatch(fetchLocationFailure());
    }
  };

export const fetchLocations = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLocationsRequest());
    const asyncResp: any = await getLocations();
    if (asyncResp?.success) {
      await dispatch(fetchLocationsResponse(asyncResp));
    } else {
      await dispatch(fetchLocationsFailure());
    }
  };

export const fetchLocationsByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLocationsRequest());
    const asyncResp: any = await getLocationsByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchLocationsResponse(asyncResp));
    } else {
      await dispatch(fetchLocationsFailure());
    }
  };

export const destroyLocation = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteLocation(id);
    if (asyncResp?.success) {
      await dispatch(fetchLocations());
    }
  };

export const createLocation = (
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  address: string,
  city: string,
  postcode: string,
  country: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLocationRequest());
    const asyncResp: any = await postLocation(groupId, clientId, title, description, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postLocationResponse());
      await dispatch(fetchLocations());
    } else {
      await dispatch(postLocationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateLocation = (
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLocationRequest());
    const asyncResp: any = await putLocation(id, groupId, clientId, title, description, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postLocationResponse());
      await dispatch(fetchLocations());
    } else {
      await dispatch(postLocationFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
