import { ApiDataModel, FormError } from './Common';

export const FETCH_ACTIVITIES_REQUEST = 'FETCH_ACTIVITIES_REQUEST';
export const FETCH_ACTIVITIES_RESPONSE = 'FETCH_ACTIVITIES_RESPONSE';
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';

export const FETCH_ACTIVITY_REQUEST = 'FETCH_ACTIVITY_REQUEST';
export const FETCH_ACTIVITY_RESPONSE = 'FETCH_ACTIVITY_RESPONSE';
export const FETCH_ACTIVITY_FAILURE = 'FETCH_ACTIVITY_FAILURE';

export const POST_ACTIVITY_REQUEST = 'POST_ACTIVITY_REQUEST';
export const POST_ACTIVITY_RESPONSE = 'POST_ACTIVITY_RESPONSE';
export const POST_ACTIVITY_FAILURE = 'POST_ACTIVITY_FAILURE';

export interface Activity {
  id: string;
  groupId: string;
  clientId: string;
  locationId: string;
  title: string;
  description: string;
  pricePerPerson: number;
  currency: string;
  vat: number;
  color: string;
  timeZone: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface ActivityDataModel extends Omit<ApiDataModel, 'data'> {
  data: Activity[];
}

export interface ActivityState {
  activities: ActivityDataModel | null,
  activeActivity: Activity | null,
  isFetchingActivities: boolean;
  fetchingActivitiesFailed: boolean;
  postingActivityFailed: boolean;
  isPostingActivity: boolean;
  postingActivityValidationErrors: FormError[] | null;
  postingActivityError: string | null;
}


interface FetchActivitiesRequestAction {
  type: typeof FETCH_ACTIVITIES_REQUEST;
  payload: null;
}

interface FetchActivitiesResponseAction {
  type: typeof FETCH_ACTIVITIES_RESPONSE;
  payload: ActivityDataModel;
}

interface FetchActivitiesFailureAction {
  type: typeof FETCH_ACTIVITIES_FAILURE;
  payload: null;
}

interface FetchActivityRequestAction {
  type: typeof FETCH_ACTIVITY_REQUEST;
  payload: null;
}

interface FetchActivityResponseAction {
  type: typeof FETCH_ACTIVITY_RESPONSE;
  payload: Activity;
}

interface FetchActivityFailureAction {
  type: typeof FETCH_ACTIVITY_FAILURE;
  payload: null;
}

interface PostActivityRequestAction {
  type: typeof POST_ACTIVITY_REQUEST;
  payload: null;
}

interface PostActivityResponseAction {
  type: typeof POST_ACTIVITY_RESPONSE;
  payload: null;
}

interface PostActivityFailureAction {
  type: typeof POST_ACTIVITY_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ActivityActionTypes =
    | FetchActivitiesRequestAction
    | FetchActivitiesResponseAction
    | FetchActivitiesFailureAction
    | PostActivityRequestAction
    | PostActivityResponseAction
    | PostActivityFailureAction
    | FetchActivityRequestAction
    | FetchActivityResponseAction
    | FetchActivityFailureAction;
