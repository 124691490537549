import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_RESPONSE,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_RESPONSE,
  FETCH_CUSTOMER_FAILURE,
  POST_CUSTOMER_FAILURE,
  POST_CUSTOMER_REQUEST,
  POST_CUSTOMER_RESPONSE,
  CustomerActionTypes, CustomerDataModel, Customer,
} from '../types/Customer';

import { RootState } from '../store/configureStore';

import { getCustomer, getCustomers, postCustomer, putCustomer, deleteCustomer } from '../api/Customer';

export function fetchCustomersRequest(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMERS_REQUEST,
    payload: null,
  };
}

export function fetchCustomersResponse(
  customers: CustomerDataModel,
): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMERS_RESPONSE,
    payload: customers,
  };
}

export function fetchCustomersFailure(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMERS_FAILURE,
    payload: null,
  };
}

export function fetchCustomerRequest(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_REQUEST,
    payload: null,
  };
}

export function fetchCustomerResponse(
  customer: Customer,
): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_RESPONSE,
    payload: customer,
  };
}

export function fetchCustomerFailure(): CustomerActionTypes {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    payload: null,
  };
}


export function postCustomerRequest(): CustomerActionTypes {
  return {
    type: POST_CUSTOMER_REQUEST,
    payload: null,
  };
}

export function postCustomerResponse(): CustomerActionTypes {
  return {
    type: POST_CUSTOMER_RESPONSE,
    payload: null,
  };
}

export function postCustomerFailure(error: string, validationErrors: any): CustomerActionTypes {
  return {
    type: POST_CUSTOMER_FAILURE,
    payload: {
      error,
      validationErrors,
    },

  };
}

export const fetchActiveCustomer = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCustomerRequest());
    const asyncResp: any = await getCustomer(id);
    if (asyncResp?.success) {
      await dispatch(fetchCustomerResponse(asyncResp.data));
    } else {
      await dispatch(fetchCustomerFailure());
    }
  };

export const fetchCustomers = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCustomersRequest());
    const asyncResp: any = await getCustomers();
    if (asyncResp?.success) {
      await dispatch(fetchCustomersResponse(asyncResp));
    } else {
      await dispatch(fetchCustomersFailure());
    }
  };


export const destroyCustomer = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteCustomer(id);
    if (asyncResp?.success) {
      await dispatch(fetchCustomers());
    }
  };


export const createCustomer = (
  userId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean,
  shallFetchCustomer: boolean = false,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCustomerRequest());
    const asyncResp: any = await postCustomer(userId, title, email, firstName, lastName, phone, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postCustomerResponse());
      if (shallFetchCustomer) {
        await dispatch(fetchCustomers());
      }
    } else {
      await dispatch(postCustomerFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateCustomer = (
  id: string,
  userId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postCustomerRequest());
    const asyncResp: any = await putCustomer(id, userId, title, email, firstName, lastName, phone, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postCustomerResponse());
      await dispatch(fetchCustomers());
    } else {
      await dispatch(postCustomerFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
