import { ApiDataModel, FormError } from './Common';

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_RESPONSE = 'FETCH_CUSTOMERS_RESPONSE';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';

export const FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST';
export const FETCH_CUSTOMER_RESPONSE = 'FETCH_CUSTOMER_RESPONSE';
export const FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE';

export const POST_CUSTOMER_REQUEST = 'POST_CUSTOMER_REQUEST';
export const POST_CUSTOMER_RESPONSE = 'POST_CUSTOMER_RESPONSE';
export const POST_CUSTOMER_FAILURE = 'POST_CUSTOMER_FAILURE';

export interface Customer {
  id: string;
  userId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean
  created: Date;
  updated: Date;
}

export interface CustomerDataModel extends Omit<ApiDataModel, 'data'> {
  data: Customer[];
}

export interface CustomerState {
  customers: CustomerDataModel | null,
  activeCustomer: Customer | null,
  isFetchingCustomers: boolean;
  fetchingCustomersFailed: boolean;
  postingCustomerFailed?: boolean;
  isPostingCustomer: boolean;
  postingCustomerValidationErrors: FormError[] | null;
  postingCustomerError: string | null;
}

interface FetchCustomersRequestAction {
  type: typeof FETCH_CUSTOMERS_REQUEST;
  payload: null;
}

interface FetchCustomersResponseAction {
  type: typeof FETCH_CUSTOMERS_RESPONSE;
  payload: CustomerDataModel;
}

interface FetchCustomersFailureAction {
  type: typeof FETCH_CUSTOMERS_FAILURE;
  payload: null;
}

interface FetchCustomerRequestAction {
  type: typeof FETCH_CUSTOMER_REQUEST;
  payload: null;
}

interface FetchCustomerResponseAction {
  type: typeof FETCH_CUSTOMER_RESPONSE;
  payload: Customer;
}

interface FetchCustomerFailureAction {
  type: typeof FETCH_CUSTOMER_FAILURE;
  payload: null;
}

interface PostCustomerRequestAction {
  type: typeof POST_CUSTOMER_REQUEST;
  payload: null;
}

interface PostCustomerResponseAction {
  type: typeof POST_CUSTOMER_RESPONSE;
  payload: null;
}

interface PostCustomerFailureAction {
  type: typeof POST_CUSTOMER_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type CustomerActionTypes =
    | FetchCustomersRequestAction
    | FetchCustomersResponseAction
    | FetchCustomersFailureAction
    | PostCustomerRequestAction
    | PostCustomerResponseAction
    | PostCustomerFailureAction
    | FetchCustomerRequestAction
    | FetchCustomerResponseAction
    | FetchCustomerFailureAction;
