import React from 'react';
import People from '../../assets/svgs/people.svg';
import './PeopleCounter.scss';

type Props = {
  value?: number;
  onIncrease?: any;
  onDecrease?: any;
};

const PeopleCounter = (props: Props) => {
  const { value = 1, onDecrease, onIncrease } = props;

  return (

        <div className="people-counter">
            <div className="people-counter__img">
                <img src={People} alt="people_icon"/>
            </div>
            <div className="people-counter__count">
                <span className="people-counter__count__control" onClick={onDecrease}>-</span>
                <span>{value}</span>
                <span className="people-counter__count__control" onClick={onIncrease}>+</span>
            </div>
        </div>
  );
};

export default PeopleCounter;
