import { RootState } from '../store/configureStore';

export const getCustomers = (state: RootState) => state?.customer?.customers;

export const getActiveCustomer = (state: RootState) => state?.customer?.activeCustomer;

export const isFetchingCustomers = (state: RootState) =>
  state?.customer?.isFetchingCustomers;

export const isPostingCustomer = (state: RootState) => state?.customer?.isPostingCustomer;
export const postingCustomerFailed = (state: RootState) => state?.customer?.postingCustomerFailed;
export const postingCustomerError = (state: RootState) => state?.customer?.postingCustomerError;
export const postingCustomerValidationErrors = (state: RootState) => state?.customer?.postingCustomerValidationErrors;


export const fetchingCustomersFailed = (state: RootState) =>
  state?.customer?.fetchingCustomersFailed;
