import axios from 'axios';

import c from '../helpers/constants';

export function getActivityAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_ASSET}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivityAssets() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_ASSET,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteActivityAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_ASSET}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postActivityAsset(
  activityId: string,
  assetId: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_ASSET,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        activityId,
        assetId,

      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}




