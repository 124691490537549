import { ApiDataModel, FormError } from './Common';

export const FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST = 'FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST';
export const FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE = 'FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE';
export const FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE = 'FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE';

export const FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST = 'FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST';
export const FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE = 'FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE';
export const FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE = 'FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE';

export const POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST = 'POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST';
export const POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE = 'POST_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE';
export const POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE = 'POST_ACTIVITY_SLOT_INSTRUCTORS_FAILURE';

export interface ActivitySlotInstructor {
  id: string;
  activitySlotId: string;
  instructorId: string;

}

export interface ActivitySlotInstructorDataModel extends Omit<ApiDataModel, 'data'> {
  data: ActivitySlotInstructor[];
}

export interface ActivitySlotInstructorState {
  activitySlotInstructors: ActivitySlotInstructorDataModel | null,
  activeActivitySlotInstructor: ActivitySlotInstructor | null,
  isFetchingActivitySlotInstructors: boolean;
  fetchingActivitySlotInstructorsFailed: boolean;
  postingActivitySlotInstructorFailed: boolean;
  isPostingActivitySlotInstructor: boolean;
  postingActivitySlotInstructorValidationErrors: FormError[] | null;
  postingActivitySlotInstructorError: string | null;
}

interface FetchActivitySlotInstructorsRequestAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST;
  payload: null;
}

interface FetchActivitySlotInstructorsResponseAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE;
  payload: ActivitySlotInstructorDataModel;
}

interface FetchActivitySlotInstructorsFailureAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE;
  payload: null;
}

interface FetchActivitySlotInstructorRequestAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST;
  payload: null;
}

interface FetchActivitySlotInstructorResponseAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE;
  payload: ActivitySlotInstructor;
}

interface FetchActivitySlotInstructorFailureAction {
  type: typeof FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE;
  payload: null;
}

interface PostActivitySlotInstructorRequestAction {
  type: typeof POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST;
  payload: null;
}

interface PostActivitySlotInstructorResponseAction {
  type: typeof POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE;
  payload: null;
}

interface PostActivitySlotInstructorFailureAction {
  type: typeof POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ActivitySlotInstructorActionTypes =
    | FetchActivitySlotInstructorsRequestAction
    | FetchActivitySlotInstructorsResponseAction
    | FetchActivitySlotInstructorsFailureAction
    | PostActivitySlotInstructorRequestAction
    | PostActivitySlotInstructorResponseAction
    | PostActivitySlotInstructorFailureAction
    | FetchActivitySlotInstructorRequestAction
    | FetchActivitySlotInstructorResponseAction
    | FetchActivitySlotInstructorFailureAction;
