import { RootState } from '../store/configureStore';

export const getAccountUsers = (state: RootState) => state?.accountUser?.accountUsers;

export const isFetchingAccountUsers = (state: RootState) =>
  state?.accountUser?.isFetchingAccountUsers;

export const isPostingAccountUser = (state: RootState) =>
  state?.accountUser?.isPostingAccountUser;
export const postingAccountUserFailed = (state: RootState) =>
  state?.accountUser?.postingAccountUserFailed;
export const postingAccountUserError = (state: RootState) =>
  state?.accountUser?.postingAccountUserError;
export const postingAccountUserValidationErrors = (state: RootState) =>
  state?.accountUser?.postingAccountUserValidationErrors;

export const fetchingAccountUsersFailed = (state: RootState) =>
  state?.accountUser?.fetchingAccountUsersFailed;