import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createUser, fetchActiveUser, updateUser } from '../../../actions/User';
import { getRoles } from '../../../selectors/Role';
import CheckBox from '../../../components/CheckBox';
import { fetchRoles } from '../../../actions/Role';

import {
  getActiveUser,
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
  isFetchingUsers,
} from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const activeUser = useSelector(getActiveUser);
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.USER_UPDATE);

  const formConfig = [
    { field: 'roleId', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'password', validators: [isRequired] },
    { field: 'username', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [roleId, setRoleId] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [active, setActive] = useState(true);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    dispatch(fetchRoles());
    if (editMode) {
      dispatch(fetchActiveUser(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeUser && editMode) {
      setRoleId(activeUser?.roleId);
      setEmail(activeUser?.email);
      setPassword(activeUser?.password);
      setUsername(activeUser?.username);
      setFirstName(activeUser?.firstName);
      setLastName(activeUser?.lastName);
      // @ts-ignore
      setActive(activeUser?.active === 1 || activeUser?.active === true);
      // @ts-ignore
      setVerified(activeUser?.verified === 1 || activeUser?.verified === true);
    }
  }, [activeUser]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createUser(roleId, email, password, username, firstName, lastName, active, verified));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateUser(activeId, roleId, email, password, username, firstName, lastName, active, verified));
    }
  };

  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} User</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="roleId"
                placeholder="Please select a Role"
                required label="Role"
                items={itemsRoles}
                value={roleId}
                disabled={isPosting || isFetching}
                onSelect={onRoleSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="email"
             id="email"
             label="Email"
             required
             value={email}
             onChange={setEmail}
             disabled={isPosting || isFetching}
             onBlur={setEmail}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             required
             name="password"
             id="password"
             type="password"
             label="Password"
             value={password}
             disabled={isPosting || isFetching}
             onChange={setPassword}
             onBlur={setPassword}
             placeholder="Insert a password"
      />
      <Input v={v} err={err}
             name="username"
             id="username"
             label="Username"
             required
             value={username}
             onChange={setUsername}
             disabled={isPosting || isFetching}
             onBlur={setUsername}
             placeholder="Insert a username"
      />
      <Input v={v} err={err}
             name="firstName"
             id="firstName"
             label="First Name"
             required
             value={firstName}
             onChange={setFirstName}
             disabled={isPosting || isFetching}
             onBlur={setFirstName}
             placeholder="Insert a first name"
      />
      <Input v={v} err={err}
             name="lastName"
             id="lastName"
             label="Last Name"
             required
             value={lastName}
             onChange={setLastName}
             disabled={isPosting || isFetching}
             onBlur={setLastName}
             placeholder="Insert a last name"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <CheckBox label="Is Verified?" checked={verified} onChange={setVerified}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default UserForm;



