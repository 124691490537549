import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getLocationAssets } from '../../../selectors/LocationAsset';
import { destroyLocationAsset, fetchLocationAssets } from '../../../actions/LocationAsset';
import { LocationAsset } from '../../../types/LocationAsset';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const LocationAssetDataTable = () => {

  const locationAssets = useSelector(getLocationAssets);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [locationAsset, setActiveLocationAsset] = useState<LocationAsset | null>();


  useEffect(() => {
    dispatch(fetchLocationAssets());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.LOCATION_ASSET_CREATE);
  };



  const onDelete = (row: LocationAsset) => {
    setActiveLocationAsset(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (locationAsset?.id) {
      dispatch(destroyLocationAsset(locationAsset?.id));
    }
    setActiveLocationAsset(null);
  };


  const config = {
    columns: [
      { key: 'locationTitle', label: 'Location ', mobileFriendly: true },
      { key: 'assetTitle', label: 'Asset', mobileFriendly: true },

    ],
    actions: [

      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };


  return (
        <View>
            <DataTable title="LOCATION/ASSET" ctaTitle="Link Location/Asset" onCreate={onCreate} data={locationAssets?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Location/Asset" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Location/Asset link?
            </Modal>

        </View>
  );
};

export default LocationAssetDataTable;
