import { ApiDataModel, FormError } from './Common';

export const FETCH_CLIENTS_REQUEST = 'FETCH_CLIENTS_REQUEST';
export const FETCH_CLIENTS_RESPONSE = 'FETCH_CLIENTS_RESPONSE';
export const FETCH_CLIENTS_FAILURE = 'FETCH_CLIENTS_FAILURE';

export const FETCH_CLIENT_REQUEST = 'FETCH_CLIENT_REQUEST';
export const FETCH_CLIENT_RESPONSE = 'FETCH_CLIENT_RESPONSE';
export const FETCH_CLIENT_FAILURE = 'FETCH_CLIENT_FAILURE';

export const POST_CLIENT_REQUEST = 'POST_CLIENT_REQUEST';
export const POST_CLIENT_RESPONSE = 'POST_CLIENT_RESPONSE';
export const POST_CLIENT_FAILURE = 'POST_CLIENT_FAILURE';

export interface Client {
  id: string;
  groupId: string;
  groupShortcode: string;
  title: string;
  description: string;
  shortcode: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface ClientDataModel extends Omit<ApiDataModel, 'data'> {
  data: Client[];
}

export interface ClientState {
  clients: ClientDataModel | null,
  activeClient: Client | null,
  isFetchingClients: boolean;
  fetchingClientsFailed: boolean;
  postingClientFailed: boolean;
  isPostingClient: boolean;
  postingClientValidationErrors: FormError[] | null;
  postingClientError: string | null;
}

interface FetchClientsRequestAction {
  type: typeof FETCH_CLIENTS_REQUEST;
  payload: null;
}

interface FetchClientsResponseAction {
  type: typeof FETCH_CLIENTS_RESPONSE;
  payload: ClientDataModel;
}

interface FetchClientsFailureAction {
  type: typeof FETCH_CLIENTS_FAILURE;
  payload: null;
}

interface FetchClientRequestAction {
  type: typeof FETCH_CLIENT_REQUEST;
  payload: null;
}

interface FetchClientResponseAction {
  type: typeof FETCH_CLIENT_RESPONSE;
  payload: Client;
}

interface FetchClientFailureAction {
  type: typeof FETCH_CLIENT_FAILURE;
  payload: null;
}

interface PostClientRequestAction {
  type: typeof POST_CLIENT_REQUEST;
  payload: null;
}

interface PostClientResponseAction {
  type: typeof POST_CLIENT_RESPONSE;
  payload: null;
}

interface PostClientFailureAction {
  type: typeof POST_CLIENT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ClientActionTypes =
    | FetchClientsRequestAction
    | FetchClientsResponseAction
    | FetchClientsFailureAction
    | PostClientRequestAction
    | PostClientResponseAction
    | PostClientFailureAction
    | FetchClientRequestAction
    | FetchClientResponseAction
    | FetchClientFailureAction;
