import { ApiDataModel, FormError } from './Common';

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_RESPONSE = 'FETCH_ROLES_RESPONSE';
export const FETCH_ROLES_FAILURE = 'FETCH_ROLES_FAILURE';

export const FETCH_ROLE_REQUEST = 'FETCH_ROLE_REQUEST';
export const FETCH_ROLE_RESPONSE = 'FETCH_ROLE_RESPONSE';
export const FETCH_ROLE_FAILURE = 'FETCH_ROLE_FAILURE';

export const POST_ROLE_REQUEST = 'POST_ROLE_REQUEST';
export const POST_ROLE_RESPONSE = 'POST_ROLE_RESPONSE';
export const POST_ROLE_FAILURE = 'POST_ROLE_FAILURE';

export interface Role {
  id: string;
  title: string;
  description: string;
  accessLevel: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface RoleDataModel extends Omit<ApiDataModel, 'data'> {
  data: Role[];
}

export interface RoleState {
  roles: RoleDataModel | null,
  activeRole: Role | null,
  isFetchingRoles: boolean;
  fetchingRolesFailed: boolean;
  postingRoleFailed: boolean;
  isPostingRole: boolean;
  postingRoleValidationErrors: FormError[] | null;
  postingRoleError: string | null;
}

interface FetchRolesRequestAction {
  type: typeof FETCH_ROLES_REQUEST;
  payload: null;
}

interface FetchRolesResponseAction {
  type: typeof FETCH_ROLES_RESPONSE;
  payload: RoleDataModel;
}

interface FetchRolesFailureAction {
  type: typeof FETCH_ROLES_FAILURE;
  payload: null;
}

interface PostRoleRequestAction {
  type: typeof POST_ROLE_REQUEST;
  payload: null;
}

interface FetchRoleRequestAction {
  type: typeof FETCH_ROLE_REQUEST;
  payload: null;
}

interface FetchRoleResponseAction {
  type: typeof FETCH_ROLE_RESPONSE;
  payload: Role;
}

interface FetchRoleFailureAction {
  type: typeof FETCH_ROLE_FAILURE;
  payload: null;
}

interface PostRoleResponseAction {
  type: typeof POST_ROLE_RESPONSE;
  payload: null;
}

interface PostRoleFailureAction {
  type: typeof POST_ROLE_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type RoleActionTypes =
    | FetchRolesRequestAction
    | FetchRolesResponseAction
    | FetchRolesFailureAction
    | FetchRoleRequestAction
    | FetchRoleResponseAction
    | FetchRoleFailureAction
    | PostRoleRequestAction
    | PostRoleResponseAction
    | PostRoleFailureAction;
