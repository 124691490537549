import {
  FETCH_ACCOUNT_GROUP_FAILURE,
  FETCH_ACCOUNT_GROUP_REQUEST,
  FETCH_ACCOUNT_GROUP_RESPONSE,
  FETCH_ACCOUNT_GROUPS_FAILURE,
  FETCH_ACCOUNT_GROUPS_REQUEST,
  FETCH_ACCOUNT_GROUPS_RESPONSE,
  POST_ACCOUNT_GROUP_FAILURE,
  POST_ACCOUNT_GROUP_RESPONSE,
  AccountGroupActionTypes,
  AccountGroupState,
  POST_ACCOUNT_GROUP_REQUEST,
} from '../types/AccountGroup';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AccountGroupState = {
  accountGroups: null,
  activeAccountGroup: null,
  isFetchingAccountGroups: false,
  fetchingAccountGroupsFailed: false,
  isPostingAccountGroup: false,
  postingAccountGroupFailed: false,
  postingAccountGroupError: null,
  postingAccountGroupValidationErrors: null,

};

const accountGroupsReducer = (
  state = initialState,
  action: AccountGroupActionTypes,
): AccountGroupState => {
  switch (action.type) {
    case FETCH_ACCOUNT_GROUPS_REQUEST:
      return {
        ...state,
        accountGroups: null,
        isFetchingAccountGroups: true,
        fetchingAccountGroupsFailed: false,
      };
    case FETCH_ACCOUNT_GROUPS_RESPONSE:
      return {
        ...state,
        accountGroups: action.payload || [],
        isFetchingAccountGroups: false,
        fetchingAccountGroupsFailed: false,
      };
    case FETCH_ACCOUNT_GROUPS_FAILURE:
      return {
        ...state,
        accountGroups: null,
        isFetchingAccountGroups: false,
        fetchingAccountGroupsFailed: true,
      };
    case FETCH_ACCOUNT_GROUP_REQUEST:
      return {
        ...state,
        accountGroups: null,
        isFetchingAccountGroups: true,
        fetchingAccountGroupsFailed: false,
      };
    case FETCH_ACCOUNT_GROUP_RESPONSE:
      return {
        ...state,
        activeAccountGroup: action.payload,
        isFetchingAccountGroups: false,
        fetchingAccountGroupsFailed: false,
      };
    case FETCH_ACCOUNT_GROUP_FAILURE:
      return {
        ...state,
        accountGroups: null,
        isFetchingAccountGroups: false,
        fetchingAccountGroupsFailed: true,
      };
    case POST_ACCOUNT_GROUP_REQUEST:
      return {
        ...state,
        isPostingAccountGroup: true,
        postingAccountGroupError: null,
        postingAccountGroupValidationErrors: null,
        postingAccountGroupFailed: false,
      };
    case POST_ACCOUNT_GROUP_RESPONSE:
      return {
        ...state,
        isPostingAccountGroup: false,
        postingAccountGroupError: null,
        postingAccountGroupValidationErrors: null,
        postingAccountGroupFailed: false,
      };
    case POST_ACCOUNT_GROUP_FAILURE:
      return {
        ...state,
        isPostingAccountGroup: false,
        postingAccountGroupError: action.payload.error || 'An error occurred',
        postingAccountGroupValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAccountGroupFailed: true,
      };

    default:
      return state;
  }
};

export default accountGroupsReducer;

