import { ApiDataModel } from './Common';

export const FETCH_COUNTRY_REQUEST = 'FETCH_COUNTRY_REQUEST';
export const FETCH_COUNTRY_RESPONSE = 'FETCH_COUNTRY_RESPONSE';
export const FETCH_COUNTRY_FAILURE = 'FETCH_COUNTRY_FAILURE';



export interface Country {
  id: string;
  country: string;

}

export interface CountryDataModel extends Omit<ApiDataModel, 'data'> {
  data: Country[];
}

export interface CountryState {
  country: CountryDataModel | null,
  isFetchingCountry: boolean;
  fetchingCountryFailed: boolean;
}

interface FetchCountryRequestAction {
  type: typeof FETCH_COUNTRY_REQUEST;
  payload: null;
}

interface FetchCountryResponseAction {
  type: typeof FETCH_COUNTRY_RESPONSE;
  payload: CountryDataModel;
}

interface FetchCountryFailureAction {
  type: typeof FETCH_COUNTRY_FAILURE;
  payload: null;
}



export type CountryActionTypes =
    | FetchCountryRequestAction
    | FetchCountryResponseAction
    | FetchCountryFailureAction;