import React from 'react';

import { CartItem as CartItemType } from '../../types/Order';
import './CartItem.scss';
import PeopleCounter from '../PeopleCounter';
import format from 'date-fns/format';
import { getTimezoneOffset } from 'date-fns-tz';

type Props = {
  item: CartItemType;
  onIncrease?: () => void;
  onDecrease?: () => void;
  onDelete?: () => void;
  count?: number;
};

const CartItem = (props: Props) => {
  const {
    item,
    onDecrease = () => {},
    onIncrease = () => {},
    count = 1,
  } = props;

  const timeZoneOffset = getTimezoneOffset(item.activitySlot.activityTimeZone);
  const timeZone = (Math.floor(timeZoneOffset / 3600000));
  const test = timeZone.toString();
  let GMT = '';
  if (timeZone >= 0) {
    GMT = `GMT +${test}`;
  } else {
    GMT = `GMT ${test}`;
  }

  const calculatedVat = (item.activitySlot.activityVat / 100 * item.activitySlot.activityPricePerPerson);

  return (
    <div className='cart row'>
      <div className="col-md-4">
        <h4 className='white-oswald pb-2 cart__left__title'>{item.activitySlot.activityTitle}</h4>
        <p className='offwhite-roboto cart__left__date'>{format(new Date(item.selectedDate), 'yyyy-MM-dd').toString()}</p>
        <p className='offwhite-roboto cart__left__time'>{item.activitySlot.startTime} - {item.activitySlot.endTime} | {GMT}</p>
        {item.activitySlot.instructorTitle && <p className='offwhite-roboto cart__left__teacher'>Instructor: {item.activitySlot.instructorTitle}</p>}
      </div>
        <div className="col-md-4 justify-content-center align-items-center d-flex align-self-center">
                <PeopleCounter value={count} onIncrease={onIncrease} onDecrease={onDecrease} />
        </div>
      <div className="col-md-4 justify-content-center align-items-center d-flex align-self-center">
        <div className="middle-right">
          <div className="cart__middle__price">
            <p>{(item.activitySlot.activityPricePerPerson * count)} {item.activitySlot.activityCurrency.replace(/ *\([^)]*\) */g, '')} (+{(calculatedVat * count)} {item.activitySlot.activityCurrency.replace(/ *\([^)]*\) */g, '')} VAT)</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
