import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './ActivitySlotForm.scss';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createActivitySlot, fetchActiveActivitySlot, updateActivitySlot } from '../../../actions/ActivitySlot';
import { getActivities } from '../../../selectors/Activity';
import CheckBox from '../../../components/CheckBox';
import { fetchActivities } from '../../../actions/Activity';

import {
  getActiveActivitySlot,
  isPostingActivitySlot,
  postingActivitySlotError,
  postingActivitySlotFailed,
  postingActivitySlotValidationErrors,
  isFetchingActivitySlots,
} from '../../../selectors/ActivitySlot';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import DatePicker from '../../../components/DatePicker';


const ActivitySlotForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activities = useSelector(getActivities);
  const activeActivitySlot = useSelector(getActiveActivitySlot);
  const isPosting = useSelector(isPostingActivitySlot);
  const isFetching = useSelector(isFetchingActivitySlots);
  const postingFailed = useSelector(postingActivitySlotFailed);
  const postingError = useSelector(postingActivitySlotError);
  const postingValidationErrors = useSelector(postingActivitySlotValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ACTIVITY_SLOT_UPDATE);

  const formConfig = [
    { field: 'activityId', validators: [isRequired] },
    { field: 'cutOffTime', validators: [isRequired] },
    { field: 'capacity', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [activityId, setActivityId] = useState('');
  const [validFrom, setValidFrom] = useState('');
  const [validUntil, setValidUntil] = useState('');
  const [cutOffTime, setCutOffTime] = useState<number>(0);
  const [dayOfTheWeek, setDayOfTheWeek] = useState<number>(0);
  const [daysOfTheWeek, setDaysOfTheWeek] = useState([]);
  const [startTime, setStartTime] = useState<string/* | number | null*/>('');
  const [endTime, setEndTime] = useState<string/* | number | null*/>('');
  const [capacity, setCapacity] = useState<number>();
  const [active, setActive] = useState(true);
  const [monday, setMonday] = useState(false);
  const [tuesday, setTuesday] = useState(false);
  const [wednesday, setWednesday] = useState(false);
  const [thursday, setThursday] = useState(false);
  const [friday, setFriday] = useState(false);
  const [saturday, setSaturday] = useState(false);
  const [sunday, setSunday] = useState(false);

  useEffect(() => {
    dispatch(fetchActivities());
    if (editMode) {
      dispatch(fetchActiveActivitySlot(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeActivitySlot && editMode) {
      setActivityId(activeActivitySlot?.activityId);
      setValidFrom(activeActivitySlot?.validFrom);
      setValidUntil(activeActivitySlot?.validUntil);
      setCutOffTime(activeActivitySlot?.cutOffTime);
      setDayOfTheWeek(activeActivitySlot?.dayOfTheWeek);
      setStartTime(activeActivitySlot?.startTime);
      setEndTime(activeActivitySlot?.endTime);
      setCapacity(activeActivitySlot?.capacity);
      setActive(activeActivitySlot?.active);
      // @ts-ignore
      setActive(activeActivitySlot?.active === 1 || activeActivitySlot?.active === true);
    }
  }, [activeActivitySlot]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACTIVITY_SLOT);
    }
  }, [isPosting]);


  useEffect(() => {
    if (endTime < startTime) {
      setStartTime(endTime);
    }
  }, [endTime]);

  useEffect(() => {
    if (validUntil < validFrom) {
      setValidFrom(validUntil);
    }
  }, [validUntil]);

  useEffect(() => {
    setDaysOfTheWeek([]);
    if (sunday) {
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 0]);
    } if (monday) {
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 1]);
    } if (tuesday){
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 2]);
    } if (wednesday){
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 3]);
    } if (thursday){
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 4]);
    } if (friday){
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 5]);
    } if (saturday){
      // @ts-ignore
      setDaysOfTheWeek(current => [...current, 6]);
    }
  }, [sunday, monday, tuesday, wednesday, thursday, friday, saturday]);


  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createActivitySlot(activityId, validFrom, validUntil, cutOffTime || 0, daysOfTheWeek, startTime, endTime, capacity || 0, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateActivitySlot(activeId, activityId, validFrom, validUntil, cutOffTime || 0, dayOfTheWeek, startTime, endTime, capacity || 0, active));
    }
  };

  const itemsActivities = activities?.data.map(r => ({ label: r.title, value: r.id })) || [];
  var items = [];
  for (var i = 0; i <= 60; i += 5) {
    items.push(i);
  }
  const itemsCutoff = items?.map( r => ({ label: r.toString(), value: r })) || [];

  var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const daysList = days?.map( (r, index) => ({ label: r, value: index })) || [];

  //TODO fix update function, issue seems to be coming from setDayOfTheWeek

  const onActivitySelect = (obj: any) => {
    setActivityId(obj.value);
  };

  const onFromSelect = (obj: any) => {
    setValidFrom(obj);
  };

  const onUntilSelect = (obj: any) => {
    setValidUntil(obj);
  };

  const onStartTimeSelect = (obj: any) => {
    setStartTime(obj);
  };

  const onEndTimeSelect = (obj: any) => {
    setEndTime(obj);
  };

  const onCutOffSelect = (obj: any) => {
    setCutOffTime(obj.value);
  };

  const onDaySelect = (obj : any) => {
    setDayOfTheWeek(obj.value);
    console.log(dayOfTheWeek);
  };

  // @ts-ignore
  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} ActivitySlot</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="activityId"
                placeholder="Please select an Activity"
                required label="Activity"
                items={itemsActivities}
                value={activityId}
                disabled={isPosting || isFetching}
                onSelect={onActivitySelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="cutOffTime"
                placeholder="Please specify the cutoff time (min)"
                required label="Cut-off Time (minutes)"
                items={itemsCutoff}
                value={cutOffTime}
                disabled={isPosting || isFetching}
                onSelect={onCutOffSelect}
                type="default"
      />
      <DatePicker /*v={v} err={err}*/
                  title={'Valid From'}
                  id="validFrom"
                  value={validFrom}
                  onChange={onFromSelect}
      />
      <DatePicker /*v={v} err={err}*/
                  title={'Valid Until'}
                  id="validUntil"
                  value={validUntil}
                  onChange={onUntilSelect}
      />
      {!editMode && <div className="checkbox__row">
        <CheckBox label="Monday" checked={monday} onChange={setMonday}/>
        <CheckBox label="Tuesday" checked={tuesday} onChange={setTuesday}/>
        <CheckBox label="Wednesday" checked={wednesday} onChange={setWednesday}/>
        <CheckBox label="Thursday" checked={thursday} onChange={setThursday}/>
        <CheckBox label="Friday" checked={friday} onChange={setFriday}/>
        <CheckBox label="Saturday" checked={saturday} onChange={setSaturday}/>
        <CheckBox label="Sunday" checked={sunday} onChange={setSunday}/>
      </div>}
      {editMode && <DropDown v={v} err={err}
                id="dayOfTheWeek"
                placeholder="Please select a day"
                required label="Day of the week"
                items={daysList}
                value={dayOfTheWeek}
                disabled={isPosting || isFetching}
                onSelect={onDaySelect}
                type="default"
      />}
      <DatePicker /*v={v} err={err}*/ title={'Start Time'} showTimeSelect={true} showTimeSelectOnly={true} id="startTime" value={startTime} onChange={onStartTimeSelect}/>
      <DatePicker /*v={v} err={err}*/ title={'End Time'} showTimeSelect={true} showTimeSelectOnly={true} id="endTime" value={endTime} onChange={onEndTimeSelect}/>
      <Input v={v} err={err}
             name="capacity"
             id="capacity"
             label="Capacity"
             required
             value={capacity}
             onChange={setCapacity}
             disabled={isPosting || isFetching}
             onBlur={setCapacity}
             placeholder="Specify capacity"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default ActivitySlotForm;




