import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  RoleAccount,
  RoleAccountActionTypes,
  RoleAccountDataModel, FETCH_ROLE_ACCOUNT_FAILURE,
  FETCH_ROLE_ACCOUNT_REQUEST,
  FETCH_ROLE_ACCOUNT_RESPONSE,
  FETCH_ROLE_ACCOUNTS_FAILURE,
  FETCH_ROLE_ACCOUNTS_REQUEST,
  FETCH_ROLE_ACCOUNTS_RESPONSE,
  POST_ROLE_ACCOUNT_FAILURE,
  POST_ROLE_ACCOUNT_REQUEST,
  POST_ROLE_ACCOUNT_RESPONSE,
} from '../types/RoleAccount';

import { RootState } from '../store/configureStore';

import { deleteRoleAccount, getRoleAccounts, postRoleAccount } from '../api/RoleAccount';



export function fetchRoleAccountsRequest(): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNTS_REQUEST,
    payload: null,
  };
}

export function fetchRoleAccountsResponse(
  roleAccounts: RoleAccountDataModel,
): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNTS_RESPONSE,
    payload: roleAccounts,
  };
}

export function fetchRoleAccountsFailure(): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNTS_FAILURE,
    payload: null,
  };
}

export function fetchRoleAccountRequest(): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNT_REQUEST,
    payload: null,
  };
}

export function fetchRoleAccountResponse(
  user: RoleAccount,
): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNT_RESPONSE,
    payload: user,
  };
}

export function fetchRoleAccountFailure(): RoleAccountActionTypes {
  return {
    type: FETCH_ROLE_ACCOUNT_FAILURE,
    payload: null,
  };
}

export function postRoleAccountRequest(): RoleAccountActionTypes {
  return {
    type: POST_ROLE_ACCOUNT_REQUEST,
    payload: null,
  };
}

export function postRoleAccountResponse(): RoleAccountActionTypes {
  return {
    type: POST_ROLE_ACCOUNT_RESPONSE,
    payload: null,
  };
}

export function postRoleAccountFailure(error: string, validationErrors: any): RoleAccountActionTypes {
  return {
    type: POST_ROLE_ACCOUNT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchRoleAccounts = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRoleAccountsRequest());
    const asyncResp: any = await getRoleAccounts();
    if (asyncResp?.success) {
      await dispatch(fetchRoleAccountsResponse(asyncResp));
    } else {
      await dispatch(fetchRoleAccountsFailure());
    }
  };

export const destroyRoleAccount = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteRoleAccount(id);
    if (asyncResp?.success) {
      await dispatch(fetchRoleAccounts());
    }
  };


export const createRoleAccount = (
  roleId: string, accountId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postRoleAccountRequest());
    const asyncResp: any = await postRoleAccount(roleId, accountId);
    if (asyncResp?.success) {
      await dispatch(postRoleAccountResponse());
      await dispatch(fetchRoleAccounts());
    } else {
      await dispatch(postRoleAccountFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
