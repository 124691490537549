import {
  FETCH_ROLE_CLIENT_FAILURE,
  FETCH_ROLE_CLIENT_REQUEST,
  FETCH_ROLE_CLIENT_RESPONSE,
  FETCH_ROLE_CLIENTS_FAILURE,
  FETCH_ROLE_CLIENTS_REQUEST,
  FETCH_ROLE_CLIENTS_RESPONSE,
  POST_ROLE_CLIENT_FAILURE,
  POST_ROLE_CLIENT_RESPONSE,
  RoleClientActionTypes,
  RoleClientState,
  POST_ROLE_CLIENT_REQUEST,
} from '../types/RoleClient';
import { parseValidationErrors } from '../helpers/functions';

const initialState: RoleClientState = {
  roleClients: null,
  activeRoleClient: null,
  isFetchingRoleClients: false,
  fetchingRoleClientsFailed: false,
  isPostingRoleClient: false,
  postingRoleClientFailed: false,
  postingRoleClientError: null,
  postingRoleClientValidationErrors: null,

};

const roleClientsReducer = (
  state = initialState,
  action: RoleClientActionTypes,
): RoleClientState => {
  switch (action.type) {
    case FETCH_ROLE_CLIENTS_REQUEST:
      return {
        ...state,
        roleClients: null,
        isFetchingRoleClients: true,
        fetchingRoleClientsFailed: false,
      };
    case FETCH_ROLE_CLIENTS_RESPONSE:
      return {
        ...state,
        roleClients: action.payload || [],
        isFetchingRoleClients: false,
        fetchingRoleClientsFailed: false,
      };
    case FETCH_ROLE_CLIENTS_FAILURE:
      return {
        ...state,
        roleClients: null,
        isFetchingRoleClients: false,
        fetchingRoleClientsFailed: true,
      };
    case FETCH_ROLE_CLIENT_REQUEST:
      return {
        ...state,
        roleClients: null,
        isFetchingRoleClients: true,
        fetchingRoleClientsFailed: false,
      };
    case FETCH_ROLE_CLIENT_RESPONSE:
      return {
        ...state,
        activeRoleClient: action.payload,
        isFetchingRoleClients: false,
        fetchingRoleClientsFailed: false,
      };
    case FETCH_ROLE_CLIENT_FAILURE:
      return {
        ...state,
        roleClients: null,
        isFetchingRoleClients: false,
        fetchingRoleClientsFailed: true,
      };
    case POST_ROLE_CLIENT_REQUEST:
      return {
        ...state,
        isPostingRoleClient: true,
        postingRoleClientError: null,
        postingRoleClientValidationErrors: null,
        postingRoleClientFailed: false,
      };
    case POST_ROLE_CLIENT_RESPONSE:
      return {
        ...state,
        isPostingRoleClient: false,
        postingRoleClientError: null,
        postingRoleClientValidationErrors: null,
        postingRoleClientFailed: false,
      };
    case POST_ROLE_CLIENT_FAILURE:
      return {
        ...state,
        isPostingRoleClient: false,
        postingRoleClientError: action.payload.error || 'An error occurred',
        postingRoleClientValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingRoleClientFailed: true,
      };

    default:
      return state;
  }
};

export default roleClientsReducer;

