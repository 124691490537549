import {
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_RESPONSE,
  FETCH_LOCATIONS_FAILURE,
  FETCH_LOCATIONS_REQUEST,
  FETCH_LOCATIONS_RESPONSE,
  POST_LOCATION_FAILURE,
  POST_LOCATION_RESPONSE,
  LocationActionTypes,
  LocationState,
  POST_LOCATION_REQUEST,
} from '../types/Location';
import { parseValidationErrors } from '../helpers/functions';

const initialState: LocationState = {
  locations: null,
  activeLocation: null,
  isFetchingLocations: false,
  fetchingLocationsFailed: false,
  isPostingLocation: false,
  postingLocationFailed: false,
  postingLocationError: null,
  postingLocationValidationErrors: null,
};

const locationsReducer = (
  state = initialState,
  action: LocationActionTypes,
): LocationState => {
  switch (action.type) {
    case FETCH_LOCATIONS_REQUEST:
      return {
        ...state,
        locations: null,
        isFetchingLocations: true,
        fetchingLocationsFailed: false,
      };
    case FETCH_LOCATIONS_RESPONSE:
      return {
        ...state,
        locations: action.payload || [],
        isFetchingLocations: false,
        fetchingLocationsFailed: false,
      };
    case FETCH_LOCATIONS_FAILURE:
      return {
        ...state,
        locations: null,
        isFetchingLocations: false,
        fetchingLocationsFailed: true,
      };
    case FETCH_LOCATION_REQUEST:
      return {
        ...state,
        locations: null,
        isFetchingLocations: true,
        fetchingLocationsFailed: false,
      };
    case FETCH_LOCATION_RESPONSE:
      return {
        ...state,
        activeLocation: action.payload,
        isFetchingLocations: false,
        fetchingLocationsFailed: false,
      };
    case FETCH_LOCATION_FAILURE:
      return {
        ...state,
        locations: null,
        isFetchingLocations: false,
        fetchingLocationsFailed: true,
      };
    case POST_LOCATION_REQUEST:
      return {
        ...state,
        isPostingLocation: true,
        postingLocationError: null,
        postingLocationValidationErrors: null,
        postingLocationFailed: false,
      };
    case POST_LOCATION_RESPONSE:
      return {
        ...state,
        isPostingLocation: false,
        postingLocationError: null,
        postingLocationValidationErrors: null,
        postingLocationFailed: false,
      };
    case POST_LOCATION_FAILURE:
      return {
        ...state,
        isPostingLocation: false,
        postingLocationError: action.payload.error || 'An error occurred',
        postingLocationValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingLocationFailed: true,
      };
    default:
      return state;
  }
};

export default locationsReducer;
