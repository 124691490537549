import { ApiDataModel, FormError } from './Common';

export const FETCH_ACCOUNT_GROUPS_REQUEST = 'FETCH_ACCOUNT_GROUPS_REQUEST';
export const FETCH_ACCOUNT_GROUPS_RESPONSE = 'FETCH_ACCOUNT_GROUPS_RESPONSE';
export const FETCH_ACCOUNT_GROUPS_FAILURE = 'FETCH_ACCOUNT_GROUPS_FAILURE';

export const FETCH_ACCOUNT_GROUP_REQUEST = 'FETCH_ACCOUNT_GROUP_REQUEST';
export const FETCH_ACCOUNT_GROUP_RESPONSE = 'FETCH_ACCOUNT_GROUP_RESPONSE';
export const FETCH_ACCOUNT_GROUP_FAILURE = 'FETCH_ACCOUNT_GROUP_FAILURE';

export const POST_ACCOUNT_GROUP_REQUEST = 'POST_ACCOUNT_GROUP_REQUEST';
export const POST_ACCOUNT_GROUP_RESPONSE = 'POST_ACCOUNT_GROUP_RESPONSE';
export const POST_ACCOUNT_GROUP_FAILURE = 'POST_ACCOUNT_GROUP_FAILURE';

export interface AccountGroup {
  id: string;
  groupdId: string;
  accountId: string;
}

export interface AccountGroupDataModel extends Omit<ApiDataModel, 'data'> {
  data: AccountGroup[];
}

export interface AccountGroupState {
  accountGroups: AccountGroupDataModel | null,
  activeAccountGroup: AccountGroup | null,
  isFetchingAccountGroups: boolean;
  fetchingAccountGroupsFailed: boolean;
  postingAccountGroupFailed: boolean;
  isPostingAccountGroup: boolean;
  postingAccountGroupValidationErrors: FormError[] | null;
  postingAccountGroupError: string | null;
}

interface FetchAccountGroupsRequestAction {
  type: typeof FETCH_ACCOUNT_GROUPS_REQUEST;
  payload: null;
}

interface FetchAccountGroupsResponseAction {
  type: typeof FETCH_ACCOUNT_GROUPS_RESPONSE;
  payload: AccountGroupDataModel;
}

interface FetchAccountGroupsFailureAction {
  type: typeof FETCH_ACCOUNT_GROUPS_FAILURE;
  payload: null;
}

interface FetchAccountGroupRequestAction {
  type: typeof FETCH_ACCOUNT_GROUP_REQUEST;
  payload: null;
}

interface FetchAccountGroupResponseAction {
  type: typeof FETCH_ACCOUNT_GROUP_RESPONSE;
  payload: AccountGroup;
}

interface FetchAccountGroupFailureAction {
  type: typeof FETCH_ACCOUNT_GROUP_FAILURE;
  payload: null;
}

interface PostAccountGroupRequestAction {
  type: typeof POST_ACCOUNT_GROUP_REQUEST;
  payload: null;
}

interface PostAccountGroupResponseAction {
  type: typeof POST_ACCOUNT_GROUP_RESPONSE;
  payload: null;
}

interface PostAccountGroupFailureAction {
  type: typeof POST_ACCOUNT_GROUP_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AccountGroupActionTypes =
    | FetchAccountGroupsRequestAction
    | FetchAccountGroupsResponseAction
    | FetchAccountGroupsFailureAction
    | PostAccountGroupRequestAction
    | PostAccountGroupResponseAction
    | PostAccountGroupFailureAction
    | FetchAccountGroupRequestAction
    | FetchAccountGroupResponseAction
    | FetchAccountGroupFailureAction;
