import {
  FETCH_ACTIVITY_SLOT_FAILURE,
  FETCH_ACTIVITY_SLOT_REQUEST,
  FETCH_ACTIVITY_SLOT_RESPONSE,
  FETCH_ACTIVITY_SLOTS_FAILURE,
  FETCH_ACTIVITY_SLOTS_REQUEST,
  FETCH_ACTIVITY_SLOTS_RESPONSE,
  POST_ACTIVITY_SLOT_FAILURE,
  POST_ACTIVITY_SLOT_RESPONSE,
  ActivitySlotActionTypes,
  ActivitySlotState,
  POST_ACTIVITY_SLOT_REQUEST,
} from '../types/ActivitySlot';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ActivitySlotState = {
  activitySlots: null,
  activeActivitySlot: null,
  isFetchingActivitySlots: false,
  fetchingActivitySlotsFailed: false,
  isPostingActivitySlot: false,
  postingActivitySlotFailed: false,
  postingActivitySlotError: null,
  postingActivitySlotValidationErrors: null,

};

const activitySlotsReducer = (
  state = initialState,
  action: ActivitySlotActionTypes,
): ActivitySlotState => {
  switch (action.type) {
    case FETCH_ACTIVITY_SLOTS_REQUEST:
      return {
        ...state,
        activitySlots: null,
        isFetchingActivitySlots: true,
        fetchingActivitySlotsFailed: false,
      };
    case FETCH_ACTIVITY_SLOTS_RESPONSE:
      return {
        ...state,
        activitySlots: action.payload || [],
        isFetchingActivitySlots: false,
        fetchingActivitySlotsFailed: false,
      };
    case FETCH_ACTIVITY_SLOTS_FAILURE:
      return {
        ...state,
        activitySlots: null,
        isFetchingActivitySlots: false,
        fetchingActivitySlotsFailed: true,
      };
    case FETCH_ACTIVITY_SLOT_REQUEST:
      return {
        ...state,
        activitySlots: null,
        isFetchingActivitySlots: true,
        fetchingActivitySlotsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_RESPONSE:
      return {
        ...state,
        activeActivitySlot: action.payload,
        isFetchingActivitySlots: false,
        fetchingActivitySlotsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_FAILURE:
      return {
        ...state,
        activitySlots: null,
        isFetchingActivitySlots: false,
        fetchingActivitySlotsFailed: true,
      };
    case POST_ACTIVITY_SLOT_REQUEST:
      return {
        ...state,
        isPostingActivitySlot: true,
        postingActivitySlotError: null,
        postingActivitySlotValidationErrors: null,
        postingActivitySlotFailed: false,
      };
    case POST_ACTIVITY_SLOT_RESPONSE:
      return {
        ...state,
        isPostingActivitySlot: false,
        postingActivitySlotError: null,
        postingActivitySlotValidationErrors: null,
        postingActivitySlotFailed: false,
      };
    case POST_ACTIVITY_SLOT_FAILURE:
      return {
        ...state,
        isPostingActivitySlot: false,
        postingActivitySlotError: action.payload.error || 'An error occurred',
        postingActivitySlotValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingActivitySlotFailed: true,
      };

    default:
      return state;
  }
};

export default activitySlotsReducer;
