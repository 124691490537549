import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
// @ts-ignore
import SearchIcon from '../../assets/svgs/ems-search-icon.svg';
// @ts-ignore
import ProfileIcon from '../../assets/svgs/ems-profile-icon.svg';
// @ts-ignore
import CartIcon from '../../assets/svgs/ems-cart-icon.svg';
import { Gear } from 'react-bootstrap-icons';
import './Header.scss';
import { getToken, getUserToken } from '../../helpers/functions';
import { useDispatch } from 'react-redux';
import c from '../../helpers/constants';
import { useSelector } from 'react-redux';
import { getCartItems } from '../../selectors/Order';
import { setCart } from '../../actions/Order';
import { getActiveConfiguration } from '../../selectors/Configuration';
import Logo from '../../assets/images/logo.png';
import { getLoggedInRole } from '../../selectors/Auth';


const Header = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const configuration = useSelector(getActiveConfiguration);
  const user = useSelector(getLoggedInRole);


  const matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH_SHORTCODE);
  // @ts-ignore
  const groupShortcode = matchUpdate?.params?.groupShortcode;
  // @ts-ignore
  const clientShortcode = matchUpdate?.params?.clientShortcode;
  let isGroupExact = false;
  let isClientExact = false;

  isGroupExact = !!(groupShortcode && groupShortcode.length == 12);

  isClientExact = !!(clientShortcode && clientShortcode.length == 12);


  useEffect(() => {
    const local = localStorage.getItem('cartItems') || '';
    if (localStorage.getItem('cartItems')){
      const items = JSON.parse(local);
      dispatch(setCart(items));
    }
  }, [dispatch]);


  const toProfile = () => {
    history.push(c.APP_ROUTES.PROFILE);
  };

  const toSearch = () => {
    if (!getUserToken() && (groupShortcode && isGroupExact) && (clientShortcode && isClientExact)) {
      history.push(`/${groupShortcode}/${clientShortcode}`);
    } else {
      history.push(c.APP_ROUTES.SEARCH);
    }
  };

  const toAdmin = () => {
    // @ts-ignore
    if (user?.accessLevel >= 2000){
      history.push(c.APP_ROUTES.ROLE);
    } else { // @ts-ignore
      if (user?.accessLevel < 1999 && user?.accessLevel > 999){
        history.push(c.APP_ROUTES.USER);
      } else { // @ts-ignore
        if (user?.accessLevel < 999) {
          history.push(c.APP_ROUTES.CUSTOMER);
        }
      }
    }
  };

  const toLogin = () => {
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  const toCart = () => {
    if (!getUserToken() && (groupShortcode && isGroupExact) && (clientShortcode && isClientExact)) {
      history.push(`/${groupShortcode}/${clientShortcode}/cart`);
    } else {
      history.push(c.APP_ROUTES.CART);
    }
  };
  

  const cartItems = useSelector(getCartItems);

  return (
        <div className='header px-sm-5'>
            <div onClick={toSearch}>
                {configuration?.primaryLogo && <img className="header__logo" src={`${c.API_ENDPOINTS.ASSET_FILE}/${configuration?.primaryLogo}`} alt="Primary Logo"
                                                    onError={(e) => (e.currentTarget.onerror = null, e.currentTarget.src = Logo)}
                />}
                {!configuration?.primaryLogo && <img className="header__logo" src={ Logo } alt="Primary Logo" />}
            </div>
            <div className="header__icons">
                <div className="header__icons__search px-sm-5">
                    <img src={SearchIcon} alt="search-icon" onClick={toSearch} />
                </div>
                <div className="header__icons__profile">
                    <img src={ProfileIcon} alt="profile-icon" onClick={getToken() ? toProfile : toLogin} />
                </div>
                <div className="header__icons__cart px-sm-5" >
                    <div onClick={toCart}>
                    {cartItems?.length > 0 && <div className="cart-counter">
                        {cartItems?.length}
                    </div>}
                    <img src={CartIcon} alt="cart-icon"  />
                    </div>
                </div>
                <div className="header__icons__dots" style={getToken() ? { display:'flex' } : { display:'none' }  }>
                    <div onClick={toAdmin}>
                        {/* @ts-ignore */}
                        <Gear />
                    </div>
                </div>
            </div>
        </div>
  );
};

export default Header;

