import { ApiDataModel, FormError } from './Common';

export const FETCH_LOCATION_ASSETS_REQUEST = 'FETCH_LOCATION_ASSETS_REQUEST';
export const FETCH_LOCATION_ASSETS_RESPONSE = 'FETCH_LOCATION_ASSETS_RESPONSE';
export const FETCH_LOCATION_ASSETS_FAILURE = 'FETCH_LOCATION_ASSETS_FAILURE';

export const FETCH_LOCATION_ASSET_REQUEST = 'FETCH_LOCATION_ASSET_REQUEST';
export const FETCH_LOCATION_ASSET_RESPONSE = 'FETCH_LOCATION_ASSET_RESPONSE';
export const FETCH_LOCATION_ASSET_FAILURE = 'FETCH_LOCATION_ASSET_FAILURE';

export const POST_LOCATION_ASSET_REQUEST = 'POST_LOCATION_ASSET_REQUEST';
export const POST_LOCATION_ASSET_RESPONSE = 'POST_LOCATION_ASSETS_RESPONSE';
export const POST_LOCATION_ASSET_FAILURE = 'POST_LOCATION_ASSETS_FAILURE';

export interface LocationAsset {
  id: string;
  locationId: string;
  instructorId: string;

}

export interface LocationAssetDataModel extends Omit<ApiDataModel, 'data'> {
  data: LocationAsset[];
}

export interface LocationAssetState {
  locationAssets: LocationAssetDataModel | null,
  activeLocationAsset: LocationAsset | null,
  isFetchingLocationAssets: boolean;
  fetchingLocationAssetsFailed: boolean;
  postingLocationAssetFailed: boolean;
  isPostingLocationAsset: boolean;
  postingLocationAssetValidationErrors: FormError[] | null;
  postingLocationAssetError: string | null;
}

interface FetchLocationAssetsRequestAction {
  type: typeof FETCH_LOCATION_ASSETS_REQUEST;
  payload: null;
}

interface FetchLocationAssetsResponseAction {
  type: typeof FETCH_LOCATION_ASSETS_RESPONSE;
  payload: LocationAssetDataModel;
}

interface FetchLocationAssetsFailureAction {
  type: typeof FETCH_LOCATION_ASSETS_FAILURE;
  payload: null;
}

interface FetchLocationAssetRequestAction {
  type: typeof FETCH_LOCATION_ASSET_REQUEST;
  payload: null;
}

interface FetchLocationAssetResponseAction {
  type: typeof FETCH_LOCATION_ASSET_RESPONSE;
  payload: LocationAsset;
}

interface FetchLocationAssetFailureAction {
  type: typeof FETCH_LOCATION_ASSET_FAILURE;
  payload: null;
}

interface PostLocationAssetRequestAction {
  type: typeof POST_LOCATION_ASSET_REQUEST;
  payload: null;
}

interface PostLocationAssetResponseAction {
  type: typeof POST_LOCATION_ASSET_RESPONSE;
  payload: null;
}

interface PostLocationAssetFailureAction {
  type: typeof POST_LOCATION_ASSET_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type LocationAssetActionTypes =
    | FetchLocationAssetsRequestAction
    | FetchLocationAssetsResponseAction
    | FetchLocationAssetsFailureAction
    | PostLocationAssetRequestAction
    | PostLocationAssetResponseAction
    | PostLocationAssetFailureAction
    | FetchLocationAssetRequestAction
    | FetchLocationAssetResponseAction
    | FetchLocationAssetFailureAction;
