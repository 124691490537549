import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  AccountClient,
  AccountClientActionTypes,
  AccountClientDataModel, FETCH_ACCOUNT_CLIENT_FAILURE,
  FETCH_ACCOUNT_CLIENT_REQUEST,
  FETCH_ACCOUNT_CLIENT_RESPONSE,
  FETCH_ACCOUNT_CLIENTS_FAILURE,
  FETCH_ACCOUNT_CLIENTS_REQUEST,
  FETCH_ACCOUNT_CLIENTS_RESPONSE,
  POST_ACCOUNT_CLIENT_FAILURE,
  POST_ACCOUNT_CLIENT_REQUEST,
  POST_ACCOUNT_CLIENT_RESPONSE,
} from '../types/AccountClient';

import { RootState } from '../store/configureStore';

import { deleteAccountClient, getAccountClients, postAccountClient } from '../api/AccountClient';



export function fetchAccountClientsRequest(): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENTS_REQUEST,
    payload: null,
  };
}

export function fetchAccountClientsResponse(
  accountClients: AccountClientDataModel,
): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENTS_RESPONSE,
    payload: accountClients,
  };
}

export function fetchAccountClientsFailure(): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENTS_FAILURE,
    payload: null,
  };
}

export function fetchAccountClientRequest(): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENT_REQUEST,
    payload: null,
  };
}

export function fetchAccountClientResponse(
  user: AccountClient,
): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENT_RESPONSE,
    payload: user,
  };
}

export function fetchAccountClientFailure(): AccountClientActionTypes {
  return {
    type: FETCH_ACCOUNT_CLIENT_FAILURE,
    payload: null,
  };
}

export function postAccountClientRequest(): AccountClientActionTypes {
  return {
    type: POST_ACCOUNT_CLIENT_REQUEST,
    payload: null,
  };
}

export function postAccountClientResponse(): AccountClientActionTypes {
  return {
    type: POST_ACCOUNT_CLIENT_RESPONSE,
    payload: null,
  };
}

export function postAccountClientFailure(error: string, validationErrors: any): AccountClientActionTypes {
  return {
    type: POST_ACCOUNT_CLIENT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchAccountClients = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAccountClientsRequest());
    const asyncResp: any = await getAccountClients();
    if (asyncResp?.success) {
      await dispatch(fetchAccountClientsResponse(asyncResp));
    } else {
      await dispatch(fetchAccountClientsFailure());
    }
  };

export const destroyAccountClient = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteAccountClient(id);
    if (asyncResp?.success) {
      await dispatch(fetchAccountClients());
    }
  };


export const createAccountClient = (
  accountId: string, clientId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAccountClientRequest());
    const asyncResp: any = await postAccountClient(accountId, clientId);
    if (asyncResp?.success) {
      await dispatch(postAccountClientResponse());
      await dispatch(fetchAccountClients());
    } else {
      await dispatch(postAccountClientFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
