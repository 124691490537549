import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../selectors/User';
import { destroyUser, fetchUsers } from '../../../actions/User';
import { User } from '../../../types/User';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const UserDataTable = () => {

  const users = useSelector(getUsers);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [user, setActiveUser] = useState<User | null>();


  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.USER_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.USER_UPDATE, row.id));
  };

  const onDelete = (row: User) => {
    setActiveUser(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (user?.id) {
      dispatch(destroyUser(user?.id));
    }
    setActiveUser(null);
  };


  const config = {
    columns: [
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },
      { key: 'email', label: 'Email', mobileFriendly: true },
      { key: 'username', label: 'Username', mobileFriendly: true },
      { key: 'firstName', label: 'First Name', mobileFriendly: true },
      { key: 'lastName', label: 'Last Name', mobileFriendly: true },
      { key: 'active', label: 'Active', mobileFriendly: true },
      { key: 'verified', label: 'Verified', mobileFriendly: true },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
      <View>
        <DataTable title="USERS" ctaTitle="Create User" onCreate={onCreate} data={users?.data || []} config={config} />
        <Modal show={toggle} title="Delete User" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this user?
        </Modal>

      </View>
  );
};

export default UserDataTable;
