import { ApiDataModel, FormError } from './Common';

export const FETCH_ROLE_GROUPS_REQUEST = 'FETCH_ROLE_GROUPS_REQUEST';
export const FETCH_ROLE_GROUPS_RESPONSE = 'FETCH_ROLE_GROUPS_RESPONSE';
export const FETCH_ROLE_GROUPS_FAILURE = 'FETCH_ROLE_GROUPS_FAILURE';

export const FETCH_ROLE_GROUP_REQUEST = 'FETCH_ROLE_GROUP_REQUEST';
export const FETCH_ROLE_GROUP_RESPONSE = 'FETCH_ROLE_GROUP_RESPONSE';
export const FETCH_ROLE_GROUP_FAILURE = 'FETCH_ROLE_GROUP_FAILURE';

export const POST_ROLE_GROUP_REQUEST = 'POST_ROLE_GROUP_REQUEST';
export const POST_ROLE_GROUP_RESPONSE = 'POST_ROLE_GROUP_RESPONSE';
export const POST_ROLE_GROUP_FAILURE = 'POST_ROLE_GROUP_FAILURE';

export interface RoleGroup {
  id: string;
  groupdId: string;
  roleId: string;
}

export interface RoleGroupDataModel extends Omit<ApiDataModel, 'data'> {
  data: RoleGroup[];
}

export interface RoleGroupState {
  roleGroups: RoleGroupDataModel | null,
  activeRoleGroup: RoleGroup | null,
  isFetchingRoleGroups: boolean;
  fetchingRoleGroupsFailed: boolean;
  postingRoleGroupFailed: boolean;
  isPostingRoleGroup: boolean;
  postingRoleGroupValidationErrors: FormError[] | null;
  postingRoleGroupError: string | null;
}

interface FetchRoleGroupsRequestAction {
  type: typeof FETCH_ROLE_GROUPS_REQUEST;
  payload: null;
}

interface FetchRoleGroupsResponseAction {
  type: typeof FETCH_ROLE_GROUPS_RESPONSE;
  payload: RoleGroupDataModel;
}

interface FetchRoleGroupsFailureAction {
  type: typeof FETCH_ROLE_GROUPS_FAILURE;
  payload: null;
}

interface FetchRoleGroupRequestAction {
  type: typeof FETCH_ROLE_GROUP_REQUEST;
  payload: null;
}

interface FetchRoleGroupResponseAction {
  type: typeof FETCH_ROLE_GROUP_RESPONSE;
  payload: RoleGroup;
}

interface FetchRoleGroupFailureAction {
  type: typeof FETCH_ROLE_GROUP_FAILURE;
  payload: null;
}

interface PostRoleGroupRequestAction {
  type: typeof POST_ROLE_GROUP_REQUEST;
  payload: null;
}

interface PostRoleGroupResponseAction {
  type: typeof POST_ROLE_GROUP_RESPONSE;
  payload: null;
}

interface PostRoleGroupFailureAction {
  type: typeof POST_ROLE_GROUP_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type RoleGroupActionTypes =
    | FetchRoleGroupsRequestAction
    | FetchRoleGroupsResponseAction
    | FetchRoleGroupsFailureAction
    | PostRoleGroupRequestAction
    | PostRoleGroupResponseAction
    | PostRoleGroupFailureAction
    | FetchRoleGroupRequestAction
    | FetchRoleGroupResponseAction
    | FetchRoleGroupFailureAction;
