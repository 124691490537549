import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

//import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createUser } from '../../../actions/User';
import { isPostingUser, postingUserError, postingUserFailed, postingUserValidationErrors, isFetchingUsers } from '../../../selectors/User';

import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { Alert } from 'react-bootstrap';


const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);


  const formConfig = [
    { field: 'email', validators: [isRequired] },
    { field: 'password', validators: [isRequired] },
    { field: 'confirmPassword', validators: [isRequired] },
    { field: 'username', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [username, setUsername] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.USER_PENDING_VERIFICATION);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createUser('', email, password, username, firstName, lastName, false, false));
    }
  };



  return <div className="row">
                <div className="col-12 p-3">
                    <h4 className="pb-4">Register</h4>
                    {postingError && (
                        <Alert className="mt-3" variant="danger">
                            {postingError}
                        </Alert>
                    )}
                    <form>
                        <Input v={v} err={err}
                               name="email"
                               id="email"
                               label="Email"
                               required
                               value={email}
                               onChange={setEmail}
                               disabled={isPosting || isFetching}
                               onBlur={setEmail}
                               placeholder="Insert an email"
                        />
                        <Input v={v} err={err}
                               required
                               name="password"
                               id="password"
                               type="password"
                               label="Password"
                               value={password}
                               disabled={isPosting || isFetching}
                               onChange={setPassword}
                               onBlur={setPassword}
                               placeholder="Insert a password"
                        />
                        <Input v={v} err={err}
                               name="confirmPassword"
                               id="confirmPassword"
                               label="Confirm Password"
                               required
                               type="password"
                               value={confirmPassword}
                               onChange={setConfirmPassword}
                               disabled={isPosting || isFetching}
                               onBlur={setConfirmPassword}
                               placeholder=""
                        />
                        <Input v={v} err={err}
                               required
                               name="username"
                               id="username"
                               label="Username"
                               value={username}
                               disabled={isPosting || isFetching}
                               onChange={setUsername}
                               onBlur={setUsername}
                               placeholder="Insert a username"
                        />
                        <Input v={v} err={err}
                               required
                               name="firstName"
                               id="firstName"
                               label="First Name"
                               value={firstName}
                               disabled={isPosting || isFetching}
                               onChange={setFirstName}
                               onBlur={setFirstName}
                               placeholder="Insert a first name"
                        />
                        <Input v={v} err={err}
                               required
                               name="lastName"
                               id="lastName"
                               label="Last Name"
                               value={lastName}
                               disabled={isPosting || isFetching}
                               onChange={setLastName}
                               onBlur={setLastName}
                               placeholder="Insert a last name"
                        />
                        <div className="row pt-4">
                            <div className="col-md-4">
                                <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                        onClick={() => onSubmit()}
                                        title={'CREATE'}/>
                            </div>
                        </div>
                    </form>
                </div>
            </div>;
};

export default UserForm;





