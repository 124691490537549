import {
  FETCH_COUNTRY_FAILURE,
  FETCH_COUNTRY_REQUEST,
  FETCH_COUNTRY_RESPONSE,

  CountryActionTypes,
  CountryState,

} from '../types/Country';


const initialState: CountryState = {
  country: null,
  isFetchingCountry: false,
  fetchingCountryFailed: false,
};

const countryReducer = (
  state = initialState,
  action: CountryActionTypes,
): CountryState => {
  switch (action.type) {
    case FETCH_COUNTRY_REQUEST:
      return {
        ...state,
        country: null,
        isFetchingCountry: true,
        fetchingCountryFailed: false,
      };
    case FETCH_COUNTRY_RESPONSE:
      return {
        ...state,
        country: action.payload || [],
        isFetchingCountry: false,
        fetchingCountryFailed: false,
      };
    case FETCH_COUNTRY_FAILURE:
      return {
        ...state,
        country: null,
        isFetchingCountry: false,
        fetchingCountryFailed: true,
      };

    default:
      return state;
  }
};

export default countryReducer;