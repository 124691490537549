import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropDownSearchableMultiselect.scss';


type Item = {
  value: string | number;
  label?: string;
};

type Props = {
  items: Item[],
  name?: string;
  id?: string,
  placeholder?: string,
  value: string | number | null,
  label?: string,
  required?: boolean,
  v?: any;
  err?: (id: string) => string[];
  onSelect: (item: Item) => void,
  errors?: string[];
  disabled?: boolean;
  validate?: (field: string, value: string | number | null | undefined | boolean) => void;
  type: 'default' | 'red',
};

const DropDown = (props: Props) => {

  const {
    items, v, onSelect = () => {
    }, value,
    disabled = false,
    type, placeholder = 'Please select', label, err = () => [], required, id = '',
  } = props;

  const selected = items.find(i => i.value === value);
  const [touched, setTouched] = useState(false);
  const [searchvalue, setSearch] = useState('');
  const [myDropdown, updateDropdown] = useState(items);
  const [isSending, setIsSending] = useState(false);

  const onInnerSelect = (item: Item) => {
    setTouched(true);
    if (v && typeof v === 'function') {
      v(id, item?.value);
    }
    onSelect(item);
    updateDropdown(prev_dropdown => prev_dropdown.filter(dropdown_item => dropdown_item?.label !== item?.label ));
  };



  const onChangeSearch = (search: React.SetStateAction<string>) => {
    setSearch(search);
  };

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);
  // Todo every time we click on the dropdown is sending is firing
  useEffect(() => {
    updateDropdown(items);
  }, [isSending]);

  const errors = err(id);

  const getNewCustomers = () =>{
    // Todo
    setIsSending(!isSending);
  };


  return (
      <div>
      <div className='dropdown-currencies' id={type}>
        {label && <label className={`form-label ${errors.length > 0 && 'error-lbl'}`}>{label}{required && '*'}</label>}


        <Dropdown  onClick={getNewCustomers}  id={id} >

          <Dropdown.Toggle   disabled={disabled} variant="success" id="drop-t" >
            {  selected?.label ||  selected?.value || placeholder }


          </Dropdown.Toggle >


          <Dropdown.Menu className='dropdown-menu-currencies'  >
            <Dropdown.Item as={'p'} className = 'dropdown-item-currencies'  onClick={(e) => e.stopPropagation()} >
              <input className = 'search-item'  placeholder="Search..." value={searchvalue} onChange={(e)=>onChangeSearch(e.target.value)}  type="text" name="search"/>

              {/* {myPills.map( button => button != '' ? <button className="btn btn-light" key={button} onClick={ ()=> onDelete(button) } >{button}<img  src={Close} alt="close" /></button> : '')} */}
            </Dropdown.Item >
            <Dropdown.Divider />
            {myDropdown.filter(item => item.label?.includes(searchvalue)).map(item => <Dropdown.Item  key={item.value}
                                                                                                 onClick={() => onInnerSelect(item)}>{item.label || item.value}</Dropdown.Item>)}
          </Dropdown.Menu>

        </Dropdown>
        {errors.map((e: string, i: number) => (<div className="error-lbl" key={i}>{e}</div>))}
      </div>

      </div>
  );
};

export default DropDown;
