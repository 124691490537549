import {
  FETCH_CURRENCY_FAILURE,
  FETCH_CURRENCY_REQUEST,
  FETCH_CURRENCY_RESPONSE,

  CurrencyActionTypes,
  CurrencyState,

} from '../types/Currency';


const initialState: CurrencyState = {
  currency: null,
  isFetchingCurrency: false,
  fetchingCurrencyFailed: false,
};

const currencyReducer = (
  state = initialState,
  action: CurrencyActionTypes,
): CurrencyState => {
  switch (action.type) {
    case FETCH_CURRENCY_REQUEST:
      return {
        ...state,
        currency: null,
        isFetchingCurrency: true,
        fetchingCurrencyFailed: false,
      };
    case FETCH_CURRENCY_RESPONSE:
      return {
        ...state,
        currency: action.payload || [],
        isFetchingCurrency: false,
        fetchingCurrencyFailed: false,
      };
    case FETCH_CURRENCY_FAILURE:
      return {
        ...state,
        currency: null,
        isFetchingCurrency: false,
        fetchingCurrencyFailed: true,
      };

    default:
      return state;
  }
};

export default currencyReducer;