import React  from 'react';
// import { useHistory, useRouteMatch } from 'react-router-dom';

import './Footer.scss';
// import c from '../../helpers/constants';


const Footer = () => {

  // const history = useHistory();
  //
  //
  // const toAdmin = () => {
  //   history.push(c.APP_ROUTES.ROLE);
  // };

  return (
        <div className='footer px-sm-5'>
            <div className="row justify-content-between">
                <div className="col">
                    © 2022 EMS. All rights reserved
                </div>
                <div className="footer_right col-auto">
                    <p>
                    PRIVACY POLICY
                    </p>
                    <p className="terms">
                    TERMS & CONDITIONS
                    </p>
                </div>
            </div>
        </div>
  );
};

export default Footer;

