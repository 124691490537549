const API_BASE_URL = 'https://dev-api.sked.world/api';
//const API_BASE_URL = 'http://localhost:8000/api';


const API_ENDPOINTS = {
  ROLE: `${API_BASE_URL}/role`,
  ROLE_GROUP: `${API_BASE_URL}/role-group`,
  ROLE_CLIENT: `${API_BASE_URL}/role-client`,
  CLIENT: `${API_BASE_URL}/client`,
  INSTRUCTOR: `${API_BASE_URL}/instructor`,
  GROUP: `${API_BASE_URL}/group`,
  USER: `${API_BASE_URL}/user`,
  LOCATION: `${API_BASE_URL}/location`,
  LOCATION_SHORTCODE: `${API_BASE_URL}/guest/location`,
  LOCATION_ASSET: `${API_BASE_URL}/location-asset`,
  CUSTOMER: `${API_BASE_URL}/customer`,
  ACTIVITY: `${API_BASE_URL}/activity`,
  ACTIVITY_SHORTCODE: `${API_BASE_URL}/guest/activity`,
  ACTIVITY_SLOT: `${API_BASE_URL}/activity-slot`,
  ACTIVITY_SLOT_SHORTCODE: `${API_BASE_URL}/guest/activity-slot`,
  ASSET: `${API_BASE_URL}/asset`,
  ORDER: `${API_BASE_URL}/order`,
  CHECKOUT: `${API_BASE_URL}/order/checkout`,
  ACTIVITY_SLOT_INSTRUCTOR: `${API_BASE_URL}/activity-slot-instructor`,
  ACTIVITY_ASSET: `${API_BASE_URL}/activity-asset`,
  ACCOUNT: `${API_BASE_URL}/account`,
  ACCOUNT_CLIENT: `${API_BASE_URL}/account-client`,
  ACCOUNT_GROUP: `${API_BASE_URL}/account-group`,
  ROLE_ACCOUNT: `${API_BASE_URL}/role-account`,
  ACCOUNT_USER: `${API_BASE_URL}/account-user`,
  TIMEZONES: `${API_BASE_URL}/timezone`,
  CURRENCY: `${API_BASE_URL}/currency`,
  COUNTRY: `${API_BASE_URL}/country`,
  CITY: `${API_BASE_URL}/city`,
  CONFIGURATION: `${API_BASE_URL}/configuration`,
  CONFIGURATION_SHORTCODE: `${API_BASE_URL}/guest/configuration`,
  ASSET_FILE: `${API_BASE_URL}/asset/file`,
};

const APP_ROUTES = {
  // USER AREA
  CART: '/cart',
  SEARCH: '/',
  CHECKOUT: '/checkout',
  CHECKOUT_SUCCESS: '/checkout/success/:bookingReference',
  CHECKOUT_FAILURE: '/checkout/failure',
  PROFILE: '/profile',
  PROFILE_CUSTOMERS: '/profile/customers',
  USER_LOGIN: '/user/login',
  USER_REGISTER: '/user/register',
  USER_PENDING_VERIFICATION: '/verification',
  FORGOT_PASSWORD: '/user/resetPassword',
  RESET_PASSWORD: '/user/resetPassword/:id',
  // ADMIN AREA
  ROLE: '/admin/role',
  ROLE_CREATE: '/admin/role/create',
  ROLE_UPDATE: '/admin/role/update/:id',
  ROLE_GROUP: '/admin/role-group',
  ROLE_GROUP_CREATE: '/admin/role-group/create',
  ROLE_GROUP_UPDATE: '/admin/role-group/update/:id',
  ROLE_CLIENT: '/admin/role-client',
  ROLE_CLIENT_CREATE: '/admin/role-client/create',
  ROLE_CLIENT_UPDATE: '/admin/role-client/update/:id',
  GROUP: '/admin/group',
  GROUP_CREATE: '/admin/group/create',
  GROUP_UPDATE: '/admin/group/update/:id',
  USER: '/admin/user',
  USER_CREATE: '/admin/user/create',
  USER_UPDATE: '/admin/user/update/:id',
  LOCATION: '/admin/location',
  LOCATION_CREATE: '/admin/location/create',
  LOCATION_UPDATE: '/admin/location/update/:id',
  LOCATION_ASSET: '/admin/location-asset',
  LOCATION_ASSET_CREATE: '/admin/location-asset/create',
  CLIENT: '/admin/client',
  CLIENT_CREATE: '/admin/client/create',
  CLIENT_UPDATE: '/admin/client/update/:id',
  INSTRUCTOR: '/admin/instructor',
  INSTRUCTOR_CREATE: '/admin/instructor/create',
  INSTRUCTOR_UPDATE: '/admin/instructor/update/:id',
  CUSTOMER: '/admin/customer',
  CUSTOMER_CREATE: '/admin/customer/create',
  CUSTOMER_UPDATE: '/admin/customer/update/:id',
  ACTIVITY: '/admin/activity',
  ACTIVITY_CREATE: '/admin/activity/create',
  ACTIVITY_UPDATE: '/admin/activity/update/:id',
  ACTIVITY_SLOT: '/admin/activity-slot',
  ACTIVITY_SLOT_CREATE: '/admin/activity-slot/create',
  ACTIVITY_SLOT_UPDATE: '/admin/activity-slot/update/:id',
  ASSET: '/admin/asset',
  ASSET_CREATE: '/admin/asset/create',
  ORDER: '/admin/order',
  ACTIVITY_SLOT_INSTRUCTOR: '/admin/activity-slot-instructor',
  ACTIVITY_SLOT_INSTRUCTOR_CREATE: '/admin/activity-slot-instructor/create',
  ACTIVITY_SLOT_INSTRUCTOR_UPDATE: '/admin/activity-slot-instructor/update/:id',
  ACTIVITY_ASSET: '/admin/activity-asset',
  ACTIVITY_ASSET_CREATE: '/admin/activity-asset/create',
  ACCOUNT: '/admin/account',
  ACCOUNT_CREATE: '/admin/account/create',
  ACCOUNT_UPDATE: '/admin/account/update/:id',
  ACCOUNT_CLIENT: '/admin/account-client',
  ACCOUNT_CLIENT_CREATE: '/admin/account-client/create',
  ACCOUNT_CLIENT_UPDATE: '/admin/account-client/update/:id',
  ACCOUNT_GROUP: '/admin/account-group',
  ACCOUNT_GROUP_CREATE: '/admin/account-group/create',
  ACCOUNT_GROUP_UPDATE: '/admin/account-group/update/:id',
  ROLE_ACCOUNT: '/admin/role-account',
  ROLE_ACCOUNT_CREATE: '/admin/role-account/create',
  ROLE_ACCOUNT_UPDATE: '/admin/role-account/update/:id',
  ACCOUNT_USER: '/admin/account-user',
  ACCOUNT_USER_CREATE: '/admin/account-user/create',
  ACCOUNT_USER_UPDATE: '/admin/account-user/update/:id',
  CONFIGURATION: '/admin/configuration',
  CONFIGURATION_CREATE: '/admin/configuration/create',
  CONFIGURATION_UPDATE: '/admin/configuration/update/:id',



  LONG_SHORTCODE: '/:groupShortcode/:clientShortcode/:other/:id',
  SEARCH_SHORTCODE: '/:groupShortcode/:clientShortcode/',
  CART_SHORTCODE: '/:groupShortcode/:clientShortcode/cart',

};

export default {
  API_ENDPOINTS,
  APP_ROUTES, APP_BASE_URL : 'https://app.sked.world',

};
