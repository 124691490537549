import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createActivityAsset } from '../../../actions/ActivityAsset';
import { fetchActivities } from '../../../actions/Activity';

import {

  isPostingActivityAsset,
  postingActivityAssetError,
  postingActivityAssetFailed,
  postingActivityAssetValidationErrors,
  isFetchingActivityAssets,
} from '../../../selectors/ActivityAsset';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { getAssets } from '../../../selectors/Asset';
import { getActivities } from '../../../selectors/Activity';
import { fetchAssets } from '../../../actions/Asset';



const ActivityAssetForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activities = useSelector(getActivities);
  const assets = useSelector(getAssets);
  const isPosting = useSelector(isPostingActivityAsset);
  const isFetching = useSelector(isFetchingActivityAssets);
  const postingFailed = useSelector(postingActivityAssetFailed);
  const postingError = useSelector(postingActivityAssetError);
  const postingValidationErrors = useSelector(postingActivityAssetValidationErrors);



  const formConfig = [
    { field: 'activityId', validators: [isRequired] },
    { field: 'assetId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [activityId, setActivityId] = useState('');
  const [assetId, setAssetId] = useState('');


  useEffect(() => {
    dispatch(fetchActivities());
    dispatch(fetchAssets());

  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACTIVITY_ASSET);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createActivityAsset(activityId, assetId ));
    }
  };



  const itemsActivities = activities?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsAssets = assets?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onActivitySelect = (obj: any) => {
    setActivityId(obj.value);
  };
  const onAssetSelect = (obj: any) => {
    setAssetId(obj.value);
  };

  return <View>
        <h4>{'Create'} ActivityAsset</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="activityId"
                      placeholder="Please select an Activity "
                      required label="Activity "
                      items={itemsActivities}
                      value={activityId}
                      disabled={isPosting || isFetching}
                      onSelect={onActivitySelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="assetId"
                      placeholder="Please select an Asset"
                      required label="Asset"
                      items={itemsAssets}
                      value={assetId}
                      disabled={isPosting || isFetching}
                      onSelect={onAssetSelect}
                      type="default"
            />


            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={ 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default ActivityAssetForm;




