import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import TelephoneInput from '../../../components/TelephoneInput';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createCustomer, fetchActiveCustomer, updateCustomer } from '../../../actions/Customer';
import { getUsers } from '../../../selectors/User';
import CheckBox from '../../../components/CheckBox';
import DropDownSearchable from '../../../components/DropdownSearchable/DropDownSearchable';
import { fetchUsers } from '../../../actions/User';



import {
  getActiveCustomer,
  isPostingCustomer,
  postingCustomerError,
  postingCustomerFailed,
  postingCustomerValidationErrors,
  isFetchingCustomers,
} from '../../../selectors/Customer';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import titlesJson from '../../../assets/json/titles.json';
import { getCountry } from '../../../selectors/Country';
import { fetchCountry } from '../../../actions/Country';
import { fetchCity } from '../../../actions/City';
import { getCity } from '../../../selectors/City';

const CustomerForm = () => {
  const history = useHistory();
  const countries = useSelector(getCountry);
  const cities = useSelector(getCity);
  const dispatch = useDispatch();
  const users = useSelector(getUsers);
  const activeCustomer = useSelector(getActiveCustomer);
  const isPosting = useSelector(isPostingCustomer);
  const isFetching = useSelector(isFetchingCustomers);
  const postingFailed = useSelector(postingCustomerFailed);
  const postingError = useSelector(postingCustomerError);
  const postingValidationErrors = useSelector(postingCustomerValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CUSTOMER_UPDATE);

  const formConfig = [
    { field: 'userId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'phone', validators: [isRequired] },
    { field: 'address', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
    { field: 'postcode', validators: [] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [userId, setUserId] = useState('');
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [active, setActive] = useState(true);
  const [itemsCities, setItemCities] = useState([{ label: 'hey', value: 'hey' }] );
  const [isCountry, setIsCountry] = useState(true);



  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchCountry());

    if (editMode) {
      dispatch(fetchActiveCustomer(activeId));
    }

  }, [dispatch]);

  useEffect(() => {
    if (!isCountry) {
      setItemCities(cities?.data.map(r => ({ label: r.city, value: r.id })) || []) ;
    }
  }, [dispatch]);



  useEffect(() => {
    if (activeCustomer && editMode) {
      setUserId(activeCustomer?.userId);
      setTitle(activeCustomer?.title);
      setEmail(activeCustomer?.email);
      setFirstName(activeCustomer?.firstName);
      setLastName(activeCustomer?.lastName);
      setPhone(activeCustomer?.phone);
      setAddress(activeCustomer?.address);
      setCity(activeCustomer?.city);
      setPostcode(activeCustomer?.postcode);
      setCountry(activeCustomer?.country);
      // @ts-ignore
      setActive(activeCustomer?.active === 1 || activeCustomer?.active === true);
      setIsCountry(false);
    }
  }, [activeCustomer]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CUSTOMER);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createCustomer(userId, title, email, firstName, lastName, phone, address, city, postcode, country, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateCustomer(activeId, userId, title, email, firstName, lastName, phone, address, city, postcode, country, active));
    }
  };


  const itemsTitles = titlesJson?.data.map(r => ({ label: r.label, value: r.value })) || [];
  const itemsUsers = users?.data.map(r => ({ label: r.email, value: r.id })) || [];
  const itemsCountries = countries?.data.map(r => ({ label: r.country, value: r.id })) || [];


  const onUserSelect = (obj: any) => {
    setUserId(obj.value);
  };
  const onTitleSelect = (obj: any) => {
    setTitle(obj.value);
  };

  const onCountrySelect = (obj: any) => {
    dispatch(fetchCity(obj.value));
    setCountry(obj.value);
    setIsCountry(false);
  };







  const onCitySelect = (obj: any) => {
    setCity(obj.value);
  };

  console.log(cities);
  console.log('hey');
  console.log(itemsCities);


  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Customer</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="userId"
                placeholder="Please select a User"
                required label="User"
                items={itemsUsers}
                value={userId}
                disabled={isPosting || isFetching}
                onSelect={onUserSelect}
                type="default"
      />

      <DropDown v={v} err={err}
                id="title"
                placeholder="Please select a title"
                label="Title"
                items={itemsTitles}
                value={title}
                disabled={isPosting || isFetching}
                onSelect={onTitleSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="email"
             id="email"
             label="Email"
             required
             value={email}
             onChange={setEmail}
             disabled={isPosting || isFetching}
             onBlur={setEmail}
             placeholder="Insert an email"
      />
      <Input v={v} err={err}
             required
             name="firstName"
             id="firstName"
             label="First Name"
             value={firstName}
             disabled={isPosting || isFetching}
             onChange={setFirstName}
             onBlur={setFirstName}
             placeholder="Insert first name"
      />
      <Input v={v} err={err}
             name="lastName"
             id="lastName"
             label="Last Name"
             required
             value={lastName}
             disabled={isPosting || isFetching}
             onChange={setLastName}
             onBlur={setLastName}
             placeholder="Insert last name"
      />

      <TelephoneInput v={v} err={err}
             name="phone"
             id="phone"
             label="Phone"
             required
             value={phone}
             disabled={isPosting || isFetching}
             onChange={setPhone}
             onBlur={setPhone}
             placeholder="Insert a phone number"
      />



      <Input v={v} err={err}
             name="address"
             id="address"
             label="Address"
             required
             value={address}
             disabled={isPosting || isFetching}
             onChange={setAddress}
             onBlur={setAddress}
             placeholder="Insert an address"
      />
  
      <DropDownSearchable v={v} err={err}
                          id="country"
                          placeholder="Please select a country"
                          required label="Country"
                          items={itemsCountries}
                          value={country}
                          disabled={isPosting || isFetching}
                          onSelect={onCountrySelect}
                          type="default"
      />

      <DropDownSearchable v={v} err={err}
                          id="city"
                          placeholder="Please select a city"
                          required label="City"
                          items={itemsCities}
                          value={city}
                          disabled={isPosting || isFetching ||  isCountry }
                          onSelect={onCitySelect}
                          type="default"
      />

      <Input v={v} err={err}
             name="postcode"
             id="postcode"
             label="Postcode"
             value={postcode}
             disabled={isPosting || isFetching}
             onChange={setPostcode}
             onBlur={setPostcode}
             placeholder="Insert a Postcode"
      />
      
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default CustomerForm;
