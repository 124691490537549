import {
  FETCH_ORDER_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_RESPONSE,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_RESPONSE,
  POST_ORDER_FAILURE,
  POST_ORDER_RESPONSE,
  SET_CART,
  OrderActionTypes,
  OrderState,
  POST_ORDER_REQUEST,
} from '../types/Order';
import { parseValidationErrors } from '../helpers/functions';

const initialState: OrderState = {
  cartItems: [],
  orders: null,
  activeOrder: null,
  isFetchingOrders: false,
  fetchingOrdersFailed: false,
  isPostingOrder: false,
  postingOrderFailed: false,
  postingOrderError: null,
  postingOrderValidationErrors: null,
};

const orderReducer = (
  state = initialState,
  action: OrderActionTypes,
): OrderState => {
  switch (action.type) {
    case FETCH_ORDERS_REQUEST:
      return {
        ...state,
        orders: null,
        isFetchingOrders: true,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDERS_RESPONSE:
      return {
        ...state,
        orders: action.payload || null,
        isFetchingOrders: false,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDERS_FAILURE:
      return {
        ...state,
        orders: null,
        isFetchingOrders: false,
        fetchingOrdersFailed: true,
      };
    case FETCH_ORDER_REQUEST:
      return {
        ...state,
        orders: null,
        isFetchingOrders: true,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDER_RESPONSE:
      return {
        ...state,
        activeOrder: action.payload,
        isFetchingOrders: false,
        fetchingOrdersFailed: false,
      };
    case FETCH_ORDER_FAILURE:
      return {
        ...state,
        orders: null,
        isFetchingOrders: false,
        fetchingOrdersFailed: true,
      };
    case POST_ORDER_REQUEST:
      return {
        ...state,
        isPostingOrder: true,
        postingOrderError: null,
        postingOrderValidationErrors: null,
        postingOrderFailed: false,
      };
    case POST_ORDER_RESPONSE:
      return {
        ...state,
        orders: { data:action.payload },
        isPostingOrder: false,
        postingOrderError: null,
        postingOrderValidationErrors: null,
        postingOrderFailed: false,
      };
    case POST_ORDER_FAILURE:
      return {
        ...state,
        isPostingOrder: false,
        postingOrderError: action.payload.error || 'An error occurred',
        postingOrderValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingOrderFailed: true,
      };
    case SET_CART:
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };
    default:
      return state;
  }
};

export default orderReducer;
