import axios from 'axios';

import c from '../helpers/constants';

export function getLocations() {
  return (
    axios({
      url: c.API_ENDPOINTS.LOCATION,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getLocationsByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION_SHORTCODE}/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getLocation(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteLocation(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postLocation(
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.LOCATION,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        address,
        city,
        postcode,
        country,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putLocation(
  id: string,
  groupId: string,
  clientId: string,
  title: string,
  description: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        title,
        description,
        address,
        city,
        postcode,
        country,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

