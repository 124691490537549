import {
  FETCH_TIMEZONES_FAILURE,
  FETCH_TIMEZONES_REQUEST,
  FETCH_TIMEZONES_RESPONSE,
  
  TimezoneActionTypes,
  TimezoneState,
 
} from '../types/Timezones';


const initialState: TimezoneState = {
  timezones: null,
  isFetchingTimezones: false,
  fetchingTimezonesFailed: false,
};

const timezonesReducer = (
  state = initialState,
  action: TimezoneActionTypes,
): TimezoneState => {
  switch (action.type) {
    case FETCH_TIMEZONES_REQUEST:
      return {
        ...state,
        timezones: null,
        isFetchingTimezones: true,
        fetchingTimezonesFailed: false,
      };
    case FETCH_TIMEZONES_RESPONSE:
      return {
        ...state,
        timezones: action.payload || [],
        isFetchingTimezones: false,
        fetchingTimezonesFailed: false,
      };
    case FETCH_TIMEZONES_FAILURE:
      return {
        ...state,
        timezones: null,
        isFetchingTimezones: false,
        fetchingTimezonesFailed: true,
      };
   
    default:
      return state;
  }
};

export default timezonesReducer;