import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAccountClient   } from '../../../actions/AccountClient';
import { getAccounts } from '../../../selectors/Account';
import { getClients } from '../../../selectors/Client';
import { fetchAccounts } from '../../../actions/Account';
import { fetchClients } from '../../../actions/Client';


import {

  isPostingAccountClient,
  postingAccountClientError,
  postingAccountClientFailed,
  postingAccountClientValidationErrors,
  isFetchingAccountClients,
} from '../../../selectors/AccountClient';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const AccountClientForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const clients = useSelector(getClients);
  const isPosting = useSelector(isPostingAccountClient);
  const isFetching = useSelector(isFetchingAccountClients);
  const postingFailed = useSelector(postingAccountClientFailed);
  const postingError = useSelector(postingAccountClientError);
  const postingValidationErrors = useSelector(postingAccountClientValidationErrors);



  const formConfig = [
    { field: 'accountId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [accountId, setAccountId] = useState('');
  const [clientId, setClientId] = useState('');

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchClients());

  }, [dispatch]);





  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACCOUNT_CLIENT);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAccountClient(accountId, clientId ));
    }
  };



  const itemsAccounts = accounts?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsClients = clients?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };
  const onAccountSelect = (obj: any) => {
    setAccountId(obj.value);
  };

  return <View>
        <h4>Create AccountClient</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="accountId"
                      placeholder="Please select an Account"
                      required label="Account"
                      items={itemsAccounts}
                      value={accountId}
                      disabled={isPosting || isFetching}
                      onSelect={onAccountSelect}
                      type="default"
            />
          <DropDown v={v} err={err}
                    id="clientId"
                    placeholder="Please select a Client"
                    required label="Client"
                    items={itemsClients}
                    value={clientId}
                    disabled={isPosting || isFetching}
                    onSelect={onClientSelect}
                    type="default"
          />


            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default AccountClientForm;



