import { createStore, applyMiddleware, combineReducers, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import clientsReducer from '../reducers/Client';
import roleReducer from '../reducers/Role';
import instructorsReducer from '../reducers/Instructor';
import groupReducer from '../reducers/Group';
import userReducer from '../reducers/User';
import authReducer from '../reducers/Auth';
import locationReducer from '../reducers/Location';
import customerReducer from '../reducers/Customer';
import activityReducer from '../reducers/Activity';
import activitySlotsReducer from '../reducers/ActivitySlot';
import orderReducer from '../reducers/Order';
import assetReducer from '../reducers/Asset';
import accountReducer from '../reducers/Account';
import activitySlotInstructorsReducer from '../reducers/ActivitySlotInstructor';
import roleGroupReducer from '../reducers/RoleGroup';
import roleClientReducer from '../reducers/RoleClient';
import activityAssetReducer from '../reducers/ActivityAsset';
import locationAssetReducer from '../reducers/LocationAsset';
import accountClientsReducer from '../reducers/AccountClient';
import accountGroupsReducer from '../reducers/AccountGroup';
import accountUsersReducer from '../reducers/AccountUser';
import roleAccountsReducer from '../reducers/RoleAccount';
import timezonesReducer from '../reducers/Timezones';
import currencyReducer from '../reducers/Currency';
import countryReducer from '../reducers/Country';
import cityReducer from '../reducers/City';
import configurationReducer from '../reducers/Configuration';

const rootReducer = combineReducers({
  role: roleReducer,
  client: clientsReducer,
  instructor: instructorsReducer,
  group: groupReducer,
  user: userReducer,
  auth: authReducer,
  location: locationReducer,
  customer: customerReducer,
  activity: activityReducer,
  activitySlot: activitySlotsReducer,
  asset: assetReducer,
  order: orderReducer,
  account: accountReducer,
  accountClient: accountClientsReducer,
  accountGroup: accountGroupsReducer,
  accountUser: accountUsersReducer,
  roleAccount: roleAccountsReducer,
  activitySlotInstructor: activitySlotInstructorsReducer,
  roleGroup: roleGroupReducer,
  roleClient: roleClientReducer,
  activityAsset: activityAssetReducer,
  locationAsset: locationAssetReducer,
  timezone: timezonesReducer,
  currency: currencyReducer,
  country: countryReducer,
  city: cityReducer,
  configuration: configurationReducer,

});

export type RootState = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;

export default store;
