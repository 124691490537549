import axios from 'axios';

import c from '../helpers/constants';

export function getAccountUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACCOUNT_USER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getAccountUsers() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACCOUNT_USER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteAccountUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACCOUNT_USER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postAccountUser(accountId: string, userId: string ) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACCOUNT_USER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        accountId,
        userId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

