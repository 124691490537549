import {
  FETCH_CITY_FAILURE,
  FETCH_CITY_REQUEST,
  FETCH_CITY_RESPONSE,

  CityActionTypes,
  CityState,

} from '../types/City';


const initialState: CityState = {
  city: null,
  isFetchingCity: false,
  fetchingCityFailed: false,
};

const cityReducer = (
  state = initialState,
  action: CityActionTypes,
): CityState => {
  switch (action.type) {
    case FETCH_CITY_REQUEST:
      return {
        ...state,
        city: null,
        isFetchingCity: true,
        fetchingCityFailed: false,
      };
    case FETCH_CITY_RESPONSE:
      return {
        ...state,
        city: action.payload || [],
        isFetchingCity: false,
        fetchingCityFailed: false,
      };
    case FETCH_CITY_FAILURE:
      return {
        ...state,
        city: null,
        isFetchingCity: false,
        fetchingCityFailed: true,
      };

    default:
      return state;
  }
};

export default cityReducer;