import { RootState } from '../store/configureStore';

export const getOrders = (state: RootState) => state?.order?.orders;

export const getCartItems = (state: RootState) => state?.order?.cartItems;

export const getActiveOrder = (state: RootState) => state?.order?.activeOrder;

export const isFetchingOrders = (state: RootState) => state?.order?.isFetchingOrders;

export const isPostingOrder = (state: RootState) => state?.order?.isPostingOrder;
export const postingOrderFailed = (state: RootState) => state?.order?.postingOrderFailed;
export const postingOrderError = (state: RootState) => state?.order?.postingOrderError;
export const postingOrderValidationErrors = (state: RootState) => state?.order?.postingOrderValidationErrors;


export const fetchingOrdersFailed = (state: RootState) => state?.order?.fetchingOrdersFailed;
