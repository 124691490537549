import axios from 'axios';

import c from '../helpers/constants';

export function getActivity(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivities() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivitiesByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SHORTCODE}/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteActivity(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postActivity(
  groupId: string,
  clientId: string,
  locationId: string,
  title: string,
  description: string,
  pricePerPerson: number,
  currency: string,
  vat: number,
  color: string,
  timeZone: string,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        locationId,
        title,
        description,
        pricePerPerson,
        currency,
        vat,
        color,
        timeZone,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function putActivity(
  id: string,
  groupId: string,
  clientId: string,
  locationId: string,
  title: string,
  description: string,
  pricePerPerson: number,
  currency: string,
  vat: number,
  color: string,
  timeZone: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        clientId,
        locationId,
        title,
        description,
        pricePerPerson,
        currency,
        vat,
        color,
        timeZone,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

