import Header from '../Header';
import Button from '../Button';
import Footer from '../Footer';
import React from 'react';
import './View.scss';
import { useHistory, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import c from '../../helpers/constants';
import { logoutUser } from '../../actions/Auth';
import { getLoggedInRole } from '../../selectors/Auth';


type Props = {
  isAdmin?: boolean;
  isUser?: boolean;
  hasMenu?: boolean;
  children: React.ReactNode;
  style?: 'dark' | 'default';
};



const View = (props: Props) => {
  const { isAdmin = true, children, isUser = false, hasMenu = true, style = 'default' } = props;
  const history = useHistory();
  const user = useSelector(getLoggedInRole);
  const dispatch = useDispatch();

  const onLogout = () => {
    dispatch(logoutUser());
    history.push(c.APP_ROUTES.USER_LOGIN);
  };

  console.log(user?.accessLevel);

  const renderAdminMenu = () => {
    // @ts-ignore
    if (user?.accessLevel >= 2000) {
      return (
              <ul className="nav nav-pills flex-column mb-auto">
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/role'>Roles</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/role-group'>Role Groups</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/role-account'>Role Accounts</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/role-client'>Role Clients</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/group'>Groups</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/client'>Clients</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/user'>Users</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/location'>Locations</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/location-asset'>Locations Assets</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/instructor'>Instructors</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/account'>Accounts</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/account-client'>Account Clients</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/account-user'>Account Users</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/account-group'>Account Groups</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/customer'>Customers</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/activity'>Activities</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/activity-asset'>Activities Assets</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/activity-slot'>Activity Slots</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/activity-slot-instructor'>Activity Slots
                          Instructors</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/asset'>Assets</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/configuration'>Configurations</NavLink>
                  </li>
                  <li className="nav-item ms-3">
                      <NavLink className="nav-link" to='/admin/order'>Order</NavLink>
                  </li>
                  <Button title={'Logout'} onClick={onLogout}/>
              </ul>);
    }
  };

  const renderClientMenu = () => {
    // @ts-ignore
    if (user?.accessLevel > 999 && user?.accessLevel < 1999) {
      return (<ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/user'>Users</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/location'>Locations</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/location-asset'>Locations Assets</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/instructor'>Instructors</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/account'>Accounts</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/account-client'>Account Clients</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/account-user'>Account Users</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/customer'>Customers</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/activity'>Activities</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/activity-asset'>Activities Assets</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/activity-slot'>Activity Slots</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/activity-slot-instructor'>Activity Slots Instructors</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/asset'>Assets</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/configuration'>Configurations</NavLink>
          </li>
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/admin/order'>Order</NavLink>
          </li>
          <Button title={'Logout'} onClick={onLogout}/>
      </ul>);
    }
  };

  const renderAccountMenu = () => {
    // @ts-ignore
    if (user?.accessLevel < 999) {
      return (<ul className="nav nav-pills flex-column mb-auto">
              <li className="nav-item ms-3">
                  <NavLink className="nav-link" to='/profile/customers'>My Customers</NavLink>
              </li>
              <Button title={'Logout'} onClick={onLogout}/>
          </ul>);
    }
  };

  const renderUserMenu = () => {
    return (<ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item ms-3">
              <NavLink className="nav-link" to='/profile'>Profile Info</NavLink>
          </li>
        <br></br>
{/*          <li className="nav-item me-3">
              <NavLink className="nav-link" to='/INEEDTOCHANGE'>Transactions</NavLink>
          </li>*/}
        <li className="nav-item ms-3">
            <NavLink className="nav-link" to='/profile/customers'>My Customers</NavLink>
        </li>
        <br/>
              <Button title={'Logout'} onClick={onLogout}/>
      </ul>);
  };

  return (<>
            <header>
                <Header/>
            </header>
            <main>
                <div className={`container-fluid view ${style === 'dark' && 'view-dark'}`}>
                    <div className="row">
                        {hasMenu && (isAdmin || isUser) && <div className="col-md-2">
                            <div className="side-menu">
                                {isAdmin && renderAdminMenu()}
                                {isAdmin && renderClientMenu()}
                                {isAdmin && renderAccountMenu()}
                                {/* TODO decide whether this is an admin or a user */}
                                {!isAdmin && isUser && renderUserMenu()}
                            </div>
                        </div>}
                        <div className={(isAdmin || isUser) ? 'col-md-10' : 'col-md-12'}>
                            {children}
                        </div>
                    </div>
                </div>
            </main>
          <footer>
              <Footer/>
          </footer>
        </>
  );
};

export default View;
