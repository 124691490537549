import { ApiDataModel, FormError } from './Common';

export const FETCH_ROLE_ACCOUNTS_REQUEST = 'FETCH_ROLE_ACCOUNTS_REQUEST';
export const FETCH_ROLE_ACCOUNTS_RESPONSE = 'FETCH_ROLE_ACCOUNTS_RESPONSE';
export const FETCH_ROLE_ACCOUNTS_FAILURE = 'FETCH_ROLE_ACCOUNTS_FAILURE';

export const FETCH_ROLE_ACCOUNT_REQUEST = 'FETCH_ROLE_ACCOUNT_REQUEST';
export const FETCH_ROLE_ACCOUNT_RESPONSE = 'FETCH_ROLE_ACCOUNT_RESPONSE';
export const FETCH_ROLE_ACCOUNT_FAILURE = 'FETCH_ROLE_ACCOUNT_FAILURE';

export const POST_ROLE_ACCOUNT_REQUEST = 'POST_ROLE_ACCOUNT_REQUEST';
export const POST_ROLE_ACCOUNT_RESPONSE = 'POST_ROLE_ACCOUNT_RESPONSE';
export const POST_ROLE_ACCOUNT_FAILURE = 'POST_ROLE_ACCOUNT_FAILURE';

export interface RoleAccount {
  id: string;
  accountdId: string;
  roleId: string;
}

export interface RoleAccountDataModel extends Omit<ApiDataModel, 'data'> {
  data: RoleAccount[];
}

export interface RoleAccountState {
  roleAccounts: RoleAccountDataModel | null,
  activeRoleAccount: RoleAccount | null,
  isFetchingRoleAccounts: boolean;
  fetchingRoleAccountsFailed: boolean;
  postingRoleAccountFailed: boolean;
  isPostingRoleAccount: boolean;
  postingRoleAccountValidationErrors: FormError[] | null;
  postingRoleAccountError: string | null;
}

interface FetchRoleAccountsRequestAction {
  type: typeof FETCH_ROLE_ACCOUNTS_REQUEST;
  payload: null;
}

interface FetchRoleAccountsResponseAction {
  type: typeof FETCH_ROLE_ACCOUNTS_RESPONSE;
  payload: RoleAccountDataModel;
}

interface FetchRoleAccountsFailureAction {
  type: typeof FETCH_ROLE_ACCOUNTS_FAILURE;
  payload: null;
}

interface FetchRoleAccountRequestAction {
  type: typeof FETCH_ROLE_ACCOUNT_REQUEST;
  payload: null;
}

interface FetchRoleAccountResponseAction {
  type: typeof FETCH_ROLE_ACCOUNT_RESPONSE;
  payload: RoleAccount;
}

interface FetchRoleAccountFailureAction {
  type: typeof FETCH_ROLE_ACCOUNT_FAILURE;
  payload: null;
}

interface PostRoleAccountRequestAction {
  type: typeof POST_ROLE_ACCOUNT_REQUEST;
  payload: null;
}

interface PostRoleAccountResponseAction {
  type: typeof POST_ROLE_ACCOUNT_RESPONSE;
  payload: null;
}

interface PostRoleAccountFailureAction {
  type: typeof POST_ROLE_ACCOUNT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type RoleAccountActionTypes =
    | FetchRoleAccountsRequestAction
    | FetchRoleAccountsResponseAction
    | FetchRoleAccountsFailureAction
    | PostRoleAccountRequestAction
    | PostRoleAccountResponseAction
    | PostRoleAccountFailureAction
    | FetchRoleAccountRequestAction
    | FetchRoleAccountResponseAction
    | FetchRoleAccountFailureAction;
