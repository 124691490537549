import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import './search.scss';

import View from '../../components/View';
import Modal from '../../components/Modal';
import DropDown from '../../components/DropDown';
import Calendar from '../../components/Calendar';
import { getActivitySlots } from '../../selectors/ActivitySlot';
import { fetchActivitySlots, fetchActivitySlotsByShortcodes } from '../../actions/ActivitySlot';
import { ActivitySlot } from '../../types/ActivitySlot';
import { format } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';
import { addToCart } from '../../actions/Order';
import { CartItem } from '../../types/Order';
import PeopleCounter from '../../components/PeopleCounter';
import { getLocations } from '../../selectors/Location';
import { getActivities } from '../../selectors/Activity';
import { fetchLocations, fetchLocationsByShortcodes } from '../../actions/Location';
import { fetchActivities, fetchActivitiesByShortcodes } from '../../actions/Activity';

const Search = () => {
  const activitySlots = useSelector(getActivitySlots);

  const locations = useSelector(getLocations);
  const activities = useSelector(getActivities);

  const dispatch = useDispatch();

  // let matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH);
  const matchUpdateShortcode = useRouteMatch(c.APP_ROUTES.SEARCH_SHORTCODE);
  //@ts-ignore
  const groupShortcode = matchUpdateShortcode?.params?.groupShortcode;
  //@ts-ignore
  const clientShortcode = matchUpdateShortcode?.params?.clientShortcode;
  // if (groupShortcode && clientShortcode) {
  //   matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH_SHORTCODE);
  // } else {
  //   matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH);
  // }




  useEffect(() => {
    if (!groupShortcode && !clientShortcode){
      dispatch(fetchActivitySlots());
      dispatch(fetchLocations());
      dispatch(fetchActivities());
    } else if (groupShortcode && clientShortcode) {
      dispatch(fetchActivitySlotsByShortcodes(groupShortcode, clientShortcode));
      dispatch(fetchLocationsByShortcodes(groupShortcode, clientShortcode));
      dispatch(fetchActivitiesByShortcodes(groupShortcode, clientShortcode));
    }

  }, [dispatch]);
  const itemsActivities = activities?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const ActivitiesLocationId = activities?.data.map(r => (  r.locationId )) || [];
  let itemsLocation = locations?.data.filter(data => ActivitiesLocationId.includes(data.id)).map(r => ({ label: r.title, value: r.id })) || [];
  itemsLocation.unshift({ label:'All Locations', value: 'all' });


  const [slots, setSlots] = useState(activitySlots?.data);
  const [toggle, setToggle] = useState(false);
  const [count, setCount] = useState<number>(1);
  const [activeSlot, setActiveSlot] = useState<ActivitySlot>();
  const [activeDate, setActiveDate] = useState<Date>();
  const [locationId, setLocationId] = useState('');
  const [activityId, setActivityId] = useState('');
  const [DisabledActivities, setDisabledActivities] = useState(true);
  const [itemsActivitiesState, setItemsActivitiesState] = useState(itemsActivities);

  // @ts-ignore
  const timeZoneOffset = getTimezoneOffset(activeSlot?.activityTimeZone);
  const timeZone = (Math.floor(timeZoneOffset / 3600000));
  const test = timeZone.toString();
  let GMT = '';
  if (timeZone >= 0) {
    GMT = `GMT +${test}`;
  } else {
    GMT = `GMT ${test}`;
  }

  // @ts-ignore
  const calculatedVat = (activeSlot?.activityVat / 100 * activeSlot?.activityPricePerPerson);





  useEffect(() => {
    setSlots(activitySlots?.data);
  }, [activitySlots?.data]);

  const onShowModal = (d: Date, s: ActivitySlot) => {
    setActiveDate(d);
    setActiveSlot(s);
    setToggle(true);
  };

  const onAddToCart = () => {
    if (activeSlot && activeDate) {
      for (let i = 0; i < count; i += 1) {
        const cartItem: CartItem = {
          activitySlot: activeSlot,
          selectedDate: activeDate,
        };
        dispatch(addToCart(cartItem));
      }
    }
    setCount(1);
    setToggle(false);
  };

  const onDismiss = () => {
    setToggle(false);
  };

  const onLocationSelect = (obj: any) => {
    if (obj.value == 'all') {
      setLocationId('all');
      setActivityId('');
      setItemsActivitiesState(itemsActivities || []);
      setDisabledActivities(false);
      setSlots(activitySlots?.data);


    } else {
      setLocationId(obj.value);
      setActivityId('');
      setItemsActivitiesState(activities?.data.filter(data => data.locationId == obj.value ).map(r => ({ label: r.title, value: r.id })) || []);
      setDisabledActivities(false);
      setSlots(activitySlots?.data.filter(data=>data.locationId == obj.value ));
    }


  };
  const onActivitySelect = (obj: any) => {
    setActivityId(obj.value);
    setSlots(activitySlots?.data.filter(data=>data.activityId == obj.value ));

  };


  return <View isAdmin={false}>
    <div className="row">
      <div className="col-md-3 align-self-end">
        <h2 className="title-white-primary my-3 mx-3">FIND YOUR ACTIVITIES</h2>
      </div>
      <div className="col-md-9 align-self-end">
          <div className="row justify-content-end">
            <div className="col-md-3">
              <DropDown label="Locations" items={itemsLocation} value={locationId} onSelect={onLocationSelect} type="red" />
            </div>
            <div className="col-md-3">
              <DropDown label="Activities" items={itemsActivitiesState} disabled={DisabledActivities} value={activityId} onSelect={onActivitySelect} type="red" />
            </div>
          </div>
      </div>
    </div>
    <div className="row">
      <Calendar activitySlots={slots?.sort((a, b) => a.startTime < b.endTime ? -1 : 1) || []} onSlotClick={onShowModal} />
      <Modal show={toggle} title={activeSlot?.activityTitle} cta="Add To Cart" onCta={onAddToCart} onHide={onDismiss}>
        <div className="row">
          <div className="search_modal col">
            <div className="search_modal_subtext mb-2">{activeDate && format(activeDate, 'd MMM yyyy')} | {activeSlot?.startTime} - {activeSlot?.endTime} | {GMT}</div>
            <div className="search_modal_subtext">{activeSlot?.capacity} spots available</div>
            <div className="search_modal_subtext">{activeSlot?.locationTitle}</div>
            <div className="search_modal_subtext fw-bold">{activeSlot?.activityCurrency} {activeSlot?.activityPricePerPerson} {activeSlot?.activityVat ? `+ ${calculatedVat} VAT` : ''}</div>
            <div className="my-3">{activeSlot?.activityDescription}</div>
            <PeopleCounter value={count} onDecrease={() => setCount(count - 1)} onIncrease={() => setCount(count + 1)}  />
          </div>
          {activeSlot?.assetId && <div className="col">
            <img className="activity-image" src={`${c.API_ENDPOINTS.ASSET_FILE}/${activeSlot?.assetId}`}></img>
          </div>}
        </div>
      </Modal>
    </div>
  </View>;
};

export default Search;
