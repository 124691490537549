import {
  FETCH_CUSTOMER_FAILURE,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_RESPONSE,
  FETCH_CUSTOMERS_FAILURE,
  FETCH_CUSTOMERS_REQUEST,
  FETCH_CUSTOMERS_RESPONSE,
  POST_CUSTOMER_FAILURE,
  POST_CUSTOMER_RESPONSE,
  CustomerActionTypes,
  CustomerState,
  POST_CUSTOMER_REQUEST,
} from '../types/Customer';
import { parseValidationErrors } from '../helpers/functions';

const initialState: CustomerState = {
  customers: null,
  activeCustomer: null,
  isFetchingCustomers: false,
  fetchingCustomersFailed: false,
  isPostingCustomer: false,
  postingCustomerFailed: false,
  postingCustomerError: null,
  postingCustomerValidationErrors: null,
};

const customersReducer = (
  state = initialState,
  action: CustomerActionTypes,
): CustomerState => {
  switch (action.type) {
    case FETCH_CUSTOMERS_REQUEST:
      return {
        ...state,
        customers: null,
        isFetchingCustomers: true,
        fetchingCustomersFailed: false,
      };
    case FETCH_CUSTOMERS_RESPONSE:
      return {
        ...state,
        customers: action.payload || [],
        isFetchingCustomers: false,
        fetchingCustomersFailed: false,
      };
    case FETCH_CUSTOMERS_FAILURE:
      return {
        ...state,
        customers: null,
        isFetchingCustomers: false,
        fetchingCustomersFailed: true,
      };
    case FETCH_CUSTOMER_REQUEST:
      return {
        ...state,
        customers: null,
        isFetchingCustomers: true,
        fetchingCustomersFailed: false,
      };
    case FETCH_CUSTOMER_RESPONSE:
      return {
        ...state,
        activeCustomer: action.payload,
        isFetchingCustomers: false,
        fetchingCustomersFailed: false,
      };
    case FETCH_CUSTOMER_FAILURE:
      return {
        ...state,
        customers: null,
        isFetchingCustomers: false,
        fetchingCustomersFailed: true,
      };
    case POST_CUSTOMER_REQUEST:
      return {
        ...state,
        isPostingCustomer: true,
        postingCustomerError: null,
        postingCustomerValidationErrors: null,
        postingCustomerFailed: false,
      };
    case POST_CUSTOMER_RESPONSE:
      return {
        ...state,
        isPostingCustomer: false,
        postingCustomerError: null,
        postingCustomerValidationErrors: null,
        postingCustomerFailed: false,
      };
    case POST_CUSTOMER_FAILURE:
      return {
        ...state,
        isPostingCustomer: false,
        postingCustomerError: action.payload.error || 'An error occurred',
        postingCustomerValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingCustomerFailed: true,
      };
    default:
      return state;
  }
};

export default customersReducer;
