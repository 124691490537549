import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createRole, fetchActiveRole, updateRole } from '../../../actions/Role';
import CheckBox from '../../../components/CheckBox';
import {
  getActiveRole,
  isPostingRole,
  postingRoleError,
  postingRoleFailed,
  postingRoleValidationErrors,
  isFetchingRoles,
} from '../../../selectors/Role';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const RoleForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activeRole = useSelector(getActiveRole);
  const isPosting = useSelector(isPostingRole);
  const isFetching = useSelector(isFetchingRoles);
  const postingFailed = useSelector(postingRoleFailed);
  const postingError = useSelector(postingRoleError);
  const postingValidationErrors = useSelector(postingRoleValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ROLE_UPDATE);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
    { field: 'accessLevel', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [accessLevel, setAccessLevel] = useState('');
  const [active, setActive] = useState(true);

  useEffect(() => {
    if (editMode) {
      dispatch(fetchActiveRole(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeRole && editMode) {
      setTitle(activeRole?.title);
      setDescription(activeRole?.description);
      setAccessLevel(activeRole?.accessLevel);
      // @ts-ignore
      setActive(activeRole?.active === 1 || activeRole?.active === true);
    }
  }, [activeRole]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ROLE);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createRole(title, description, accessLevel, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateRole(activeId, title, description, accessLevel, active));
    }
  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Role</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <Input v={v} err={err}
             name="title"
             id="title"
             label="Title"
             required
             value={title}
             onChange={setTitle}
             disabled={isPosting || isFetching}
             onBlur={setTitle}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             required
             name="description"
             id="description"
             label="Description"
             value={description}
             disabled={isPosting || isFetching}
             onChange={setDescription}
             onBlur={setDescription}
             placeholder="Insert a description"
      />
      <Input v={v} err={err}
             name="accessLevel"
             id="accessLevel"
             label="Access Level"
             required
             value={accessLevel}
             onChange={setAccessLevel}
             disabled={isPosting || isFetching}
             onBlur={setAccessLevel}
             placeholder="Specify access level"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default RoleForm;
