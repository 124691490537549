import { RootState } from '../store/configureStore';

export const getRoleClients = (state: RootState) => state?.roleClient?.roleClients;

export const isFetchingRoleClients = (state: RootState) =>
  state?.roleClient?.isFetchingRoleClients;

export const isPostingRoleClient = (state: RootState) =>
  state?.roleClient?.isPostingRoleClient;
export const postingRoleClientFailed = (state: RootState) =>
  state?.roleClient?.postingRoleClientFailed;
export const postingRoleClientError = (state: RootState) =>
  state?.roleClient?.postingRoleClientError;
export const postingRoleClientValidationErrors = (state: RootState) =>
  state?.roleClient?.postingRoleClientValidationErrors;

export const fetchingRoleClientsFailed = (state: RootState) =>
  state?.roleClient?.fetchingRoleClientsFailed;
