import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../../selectors/Order';
import { destroyOrder, fetchOrders } from '../../../actions/Order';
import { Order } from '../../../types/Order';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const OrderDataTable = () => {

  const orders = useSelector(getOrders);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [order, setActiveOrder] = useState<Order | null>();


  useEffect(() => {
    dispatch(fetchOrders());
  }, [dispatch]);

  /*    const history = useHistory();

  const onCreate = () => {
    history.push(c.APP_ROUTES.ORDER_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ORDER_UPDATE, row.id));
  };*/

  const onDismiss = () => {
    setToggle(false);
  };

  const onDelete = (row: Order) => {
    setActiveOrder(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (order?.id) {
      dispatch(destroyOrder(order?.id));
    }
    setActiveOrder(null);
  };


  const config = {
    columns: [
      { key: 'bookingReference', label: 'Booking Reference', mobileFriendly: true },
      { key: 'customerEmail', label: 'Customer', mobileFriendly: true },
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'locationTitle', label: 'Location', mobileFriendly: false },
      { key: 'activityTitle', label: 'Activity', mobileFriendly: false },
      { key: 'activitySlotId', label: 'Activity Slot', mobileFriendly: false },
      { key: 'userEmail', label: 'User', mobileFriendly:false },
      { key: 'subtotal', label: 'Subtotal', mobileFriendly: false },
      { key: 'vat', label: 'VAT', mobileFriendly: false },
      { key: 'total', label: 'Total', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
    hideButton: true,
  };

  return <View>
    <DataTable title="ORDERS" data={orders?.data || []} config={config} />
    <Modal show={toggle} title="Delete Order" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
            Are you sure you want to delete this order?
        </Modal>

    </View>;
};

export default OrderDataTable;
