import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActivitySlots } from '../../../selectors/ActivitySlot';
import { destroyActivitySlot, fetchActivitySlots } from '../../../actions/ActivitySlot';
import { ActivitySlot } from '../../../types/ActivitySlot';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ActivitySlotDataTable = () => {

  const activitySlots = useSelector(getActivitySlots);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [activitySlot, setActiveActivitySlot] = useState<ActivitySlot | null>();
  console.log(activitySlots?.data);
  
  const newForm = activitySlots?.data.map(obj => {
    if (obj.dayOfTheWeek === 0){
      return { ...obj, dayOfTheWeek: 'Sunday' };
    }
    if (obj.dayOfTheWeek === 1){
      return { ...obj, dayOfTheWeek: 'Monday' };
    }
    if (obj.dayOfTheWeek === 2){
      return { ...obj, dayOfTheWeek: 'Tuesday' };
    }
    if (obj.dayOfTheWeek === 3){
      return { ...obj, dayOfTheWeek: 'Wednesday' };
    }
    if (obj.dayOfTheWeek === 4){
      return { ...obj, dayOfTheWeek: 'Thursday' };
    }
    if (obj.dayOfTheWeek === 5){
      return { ...obj, dayOfTheWeek: 'Friday' };
    }
    if (obj.dayOfTheWeek === 6){
      return { ...obj, dayOfTheWeek: 'Saturday' };
    }
    return obj;
  });

  useEffect(() => {
    dispatch(fetchActivitySlots());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACTIVITY_SLOT_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ACTIVITY_SLOT_UPDATE, row.id));
  };

  const onDelete = (row: ActivitySlot) => {
    setActiveActivitySlot(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (activitySlot?.id) {
      dispatch(destroyActivitySlot(activitySlot?.id));
    }
    setActiveActivitySlot(null);
  };


  const config = {
    columns: [
      { key: 'activityTitle', label: 'Activity', mobileFriendly: true },
      { key: 'validFrom', label: 'Valid From', mobileFriendly: true },
      { key: 'validUntil', label: 'Valid Until', mobileFriendly: false },
      { key: 'cutOffTime', label: 'Cut-off Time', mobileFriendly: false },
      { key: 'dayOfTheWeek', label: 'Day Of The Week', mobileFriendly: false },
      { key: 'startTime', label: 'Start Time', mobileFriendly: false },
      { key: 'endTime', label: 'End Time', mobileFriendly: false },
      { key: 'capacity', label: 'Capacity', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
      <View>
        <DataTable title="ACTIVITY SLOTS" ctaTitle="Create Activity Slot" onCreate={onCreate} data={newForm || []} config={config} />
        <Modal show={toggle} title="Delete Activity Slot" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this activity slot?
        </Modal>

      </View>
  );
};

export default ActivitySlotDataTable;
