import { ApiDataModel } from './Common';

export const FETCH_TIMEZONES_REQUEST = 'FETCH_TIMEZONES_REQUEST';
export const FETCH_TIMEZONES_RESPONSE = 'FETCH_TIMEZONES_RESPONSE';
export const FETCH_TIMEZONES_FAILURE = 'FETCH_TIMEZONES_FAILURE';



export interface Timezone {
  id: string;
  timezone: string;

}

export interface TimezoneDataModel extends Omit<ApiDataModel, 'data'> {
  data: Timezone[];
}

export interface TimezoneState {
  timezones: TimezoneDataModel | null,
  isFetchingTimezones: boolean;
  fetchingTimezonesFailed: boolean;
}

interface FetchTimezonesRequestAction {
  type: typeof FETCH_TIMEZONES_REQUEST;
  payload: null;
}

interface FetchTimezonesResponseAction {
  type: typeof FETCH_TIMEZONES_RESPONSE;
  payload: TimezoneDataModel;
}

interface FetchTimezonesFailureAction {
  type: typeof FETCH_TIMEZONES_FAILURE;
  payload: null;
}



export type TimezoneActionTypes =
    | FetchTimezonesRequestAction
    | FetchTimezonesResponseAction
    | FetchTimezonesFailureAction;
  