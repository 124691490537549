import React, { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveConfiguration } from '../../selectors/Configuration';
import {
  fetchConfigurationByLogin,
  fetchConfigurationByShortcode,
  fetchDefaultConfiguration,
} from '../../actions/Configuration';
import { useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import { getLoggedInRole } from '../../selectors/Auth';
import { getUserToken } from '../../helpers/functions';
//import { fetchLoggedInUser } from '../../actions/Auth';


const Themes = () => {
  const dispatch = useDispatch();
  const configuration = useSelector(getActiveConfiguration);
  let matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH_SHORTCODE);
  // @ts-ignore
  const groupShortcode = matchUpdate?.params?.groupShortcode;
  // @ts-ignore
  const clientShortcode = matchUpdate?.params?.clientShortcode;
  const loggedInRole = useSelector(getLoggedInRole);
  let isGroupExact = false;
  let isClientExact = false;
  const activeConfiguration = useSelector(getActiveConfiguration);


  isGroupExact = !!(groupShortcode && groupShortcode.length == 12);

  isClientExact = !!(clientShortcode && clientShortcode.length == 12);


  useEffect(() => {
    // @ts-ignore
    if (getUserToken() && loggedInRole?.id && (!groupShortcode || !isGroupExact) && (!clientShortcode || !isClientExact)){
      // @ts-ignore
      dispatch(fetchConfigurationByLogin(loggedInRole?.id));
    }
    if (!getUserToken() && groupShortcode && clientShortcode && isGroupExact && isClientExact){
      dispatch(fetchConfigurationByShortcode(groupShortcode, clientShortcode));
    }
    if (getUserToken() && groupShortcode && clientShortcode && isGroupExact && isClientExact){
      dispatch(fetchConfigurationByShortcode(groupShortcode, clientShortcode));
    } 
    if (!getUserToken() && (!groupShortcode || !isGroupExact) && (!clientShortcode || !isClientExact)){
      dispatch(fetchDefaultConfiguration());
    }
  }, [dispatch, loggedInRole?.id]);

  useEffect(() => {
    // @ts-ignore
    if (configuration !== null) {
      // @ts-ignore
      document.documentElement.style.setProperty('--backgroundColor', configuration?.backgroundColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--foregroundColor', configuration?.foregroundColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--primaryActionColor', configuration?.primaryActionColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--secondaryActionColor', configuration?.secondaryActionColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--successColor', configuration?.successColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--dropdownColor', configuration?.dropdownColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--warningColor', configuration?.warningColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--primaryTextColor', configuration?.primaryTextColor);
      // @ts-ignore
      document.documentElement.style.setProperty('--secondaryTextColor', configuration?.secondaryTextColor);
      if (configuration?.favicon) {
        // @ts-ignore
        document.getElementById('favicon').setAttribute('href', `${c.API_ENDPOINTS.ASSET_FILE}/${configuration?.favicon}`);
      }
    }
  });
  // }, [dispatch, loggedInRole?.id]);


  return ( <div>
    {!activeConfiguration && (getUserToken() || (groupShortcode && clientShortcode)) &&
        <div className="loading">
          <div className="row">
            <div className="spinner-grow" role="status">
            </div>
            <span className="sr-only">Loading, please wait.</span>
          </div>
        </div>
    }
  </div>);


};

export default Themes;
