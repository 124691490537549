import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createLocation, fetchActiveLocation, updateLocation } from '../../../actions/Location';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import {
  getActiveLocation,
  isPostingLocation,
  postingLocationError,
  postingLocationFailed,
  postingLocationValidationErrors,
  isFetchingLocations,
} from '../../../selectors/Location';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';

const LocationForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const activeLocation = useSelector(getActiveLocation);
  const isPosting = useSelector(isPostingLocation);
  const isFetching = useSelector(isFetchingLocations);
  const postingFailed = useSelector(postingLocationFailed);
  const postingError = useSelector(postingLocationError);
  const postingValidationErrors = useSelector(postingLocationValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.LOCATION_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
    { field: 'address', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
    { field: 'postcode', validators: [] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveLocation(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeLocation && editMode) {
      setGroupId(activeLocation?.groupId);
      setClientId(activeLocation?.clientId);
      setTitle(activeLocation?.title);
      setDescription(activeLocation?.description);
      setAddress(activeLocation?.address);
      setCity(activeLocation?.city);
      setPostcode(activeLocation?.postcode);
      setCountry(activeLocation?.country);
      // @ts-ignore
      setActive(activeLocation?.active === 1 || activeLocation?.active === true);
    }
  }, [activeLocation]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.LOCATION);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createLocation(groupId, clientId, title, description, address, city, postcode, country, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateLocation(activeId, groupId, clientId, title, description, address, city, postcode, country, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
  };

  return <View>
        <h4>{editMode ? 'Edit' : 'Create'} Location</h4>
      {postingError && (
          <Alert className="mt-3" variant="danger">
              {postingError}
          </Alert>
      )}
        <form>
            <DropDown v={v} err={err}
                      id="groupId"
                      placeholder="Please select a Group"
                      required label="Group"
                      items={itemsGroups}
                      value={groupId}
                      disabled={isPosting || isFetching}
                      onSelect={onGroupSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="clientId"
                      placeholder="Please select a Client"
                      required
                      label="Client"
                      items={itemsClients}
                      disabled={isPosting || isFetching || disabledClient}
                      value={clientId}
                      onSelect={onClientSelect}
                      type="default"
            />
            <Input v={v} err={err}
                   name="title"
                   id="title"
                   label="Title"
                   required
                   value={title}
                   onChange={setTitle}
                   disabled={isPosting || isFetching}
                   onBlur={setTitle}
                   placeholder="Insert a title"
            />
            <Input v={v} err={err}
                   required
                   name="description"
                   id="description"
                   label="Description"
                   value={description}
                   disabled={isPosting || isFetching}
                   onChange={setDescription}
                   onBlur={setDescription}
                   placeholder="Insert a description"
            />
            <Input v={v} err={err}
                   name="address"
                   id="address"
                   label="Address"
                   required
                   value={address}
                   disabled={isPosting || isFetching}
                   onChange={setAddress}
                   onBlur={setAddress}
                   placeholder="Insert an address"
            />
            <Input v={v} err={err}
                   name="city"
                   id="city"
                   label="City"
                   required
                   value={city}
                   disabled={isPosting || isFetching}
                   onChange={setCity}
                   onBlur={setCity}
                   placeholder="Insert a City"
            />
            <Input v={v} err={err}
                   name="postcode"
                   id="postcode"
                   label="Postcode"
                   value={postcode}
                   disabled={isPosting || isFetching}
                   onChange={setPostcode}
                   onBlur={setPostcode}
                   placeholder="Insert a Postcode"
            />
            <Input v={v} err={err}
                   name="country"
                   id="country"
                   label="Country"
                   required
                   disabled={isPosting || isFetching}
                   value={country}
                   onChange={setCountry}
                   onBlur={setCountry}
                   placeholder="Insert a Country"
            />
            <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => editMode ? onSave() : onSubmit()}
                            title={editMode ? 'SAVE' : 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default LocationForm;
