import axios from 'axios';

import c from '../helpers/constants';

export function getOrder(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getOrders() {
  return (
    axios({
      url: c.API_ENDPOINTS.ORDER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteOrder(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postOrder(
  bookingReference: string,
  customerId: string,
  groupId: string,
  clientId: string,
  locationId: string,
  activityId: string,
  activitySlotId: string,
  userId: string,
  subtotal: number,
  vat: number,
  total: number) {
  return (
    axios({
      url: c.API_ENDPOINTS.ORDER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        bookingReference,
        customerId,
        groupId,
        clientId,
        locationId,
        activityId,
        activitySlotId,
        userId,
        subtotal,
        vat,
        total,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postOrders(
  myList: [],
) {
  return (
    axios({
      url: c.API_ENDPOINTS.CHECKOUT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data:{ orders: myList },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function putOrder(
  id: string,
  bookingReference: string,
  customerId: string,
  groupId: string,
  clientId: string,
  locationId: string,
  activityId: string,
  activitySlotId: string,
  userId: string,
  subtotal: number,
  vat: number,
  total: number) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        bookingReference,
        customerId,
        groupId,
        clientId,
        locationId,
        activityId,
        activitySlotId,
        userId,
        subtotal,
        vat,
        total,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getOrderByReference(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ORDER}/reference/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

