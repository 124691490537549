import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import axios from 'axios';

import { FormError } from '../types/Common';
import c from './constants';

const lPrefix = 'EMS_';

export const objectToQueryString = (obj: any) => _.reduce(obj, (result: string, value: string, key: string) => {
  return (!_.isNull(value) && !_.isUndefined(value)) ? (result += key + '=' + value + '&') : result;
}, '').slice(0, -1);

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const localStorageSetItem = (key: string, value: string) => {
  window.localStorage.setItem(`${lPrefix}${key}`, value);
};

export const localStorageGetItem = (key: string) => {
  return window.localStorage.getItem(`${lPrefix}${key}`);
};

export const getToken =  () => {
  return localStorageGetItem('token') || '';
};

export const setToken = (token: string) => {
  localStorageSetItem('token', token);
};

export const getUserToken =  () => {
  return localStorageGetItem('activeUser') || '';
};

export const getBookingReference = () => {
  return localStorageGetItem('bookingReference' || '');
};

export const setUserToken = (user: string) => {
  localStorageSetItem('activeUser', user);
};


// @ts-ignore
export function formatUrl(path, ...vars) {
  const matches = path.match(/(:[a-z])\w+/g);
  let newString = path;
  // @ts-ignore
  matches.forEach((v, i) => {
    if (vars[i]) {
      newString = newString.replace(v, vars[i]);
      newString = newString.replace('?', '');
    } else {
      newString = newString.replace(`/${v}`, '');
      newString = newString.replace('?', '');
    }
  });
  return newString;
}


export function parseValidationErrors(rawErrors: any) {
  if (!rawErrors) {
    return [];
  }
  const errors: FormError[] = [];
  Object.keys(rawErrors).forEach(k => {
    errors.push({
      field: k,
      errors: rawErrors[k],
    });
  });
  return errors;
}

axios.interceptors.request.use((config) => {
  /** In dev, intercepts request and logs it into console for dev */
  // console.info('✉️ ', config);
  const token = getToken();
  // console.log(token);
  // @ts-ignore
  config.headers.Authorization =  `Bearer ${token}`;
  return config;
}, (error) => {
  console.error('✉️ ', error);
  return Promise.reject(error);
});

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error?.response?.status === 401) { //AKA Unauthorised
    setToken('');
    setUserToken('');
    window.location.href = `${c.APP_ROUTES.USER_LOGIN}?returnUrl=${location.pathname}`;
    localStorage.setItem('returnUrl', location.pathname);
    console.error('You are not authorised for this resource. Please login');
  }
  return Promise.reject(error);
});

