import React from 'react';
// @ts-ignore
import ArrowRight from '../../assets/svgs/arrow-circle-right.svg';
import './ActivityTile.scss';

type Props = {
  title: string;
  time: string;
  instructor?: string;
  onClick?: () => void;
  capacity: number;
  booked: number;
  color: string;
};

const ActivityTile = (props: Props) => {
  const { title, time, instructor, onClick, color } = props;

  return (
    <div className='tile' style={{ backgroundColor: color }} onClick={onClick}>
      <div className="tile__title pb-3">
        <h4>{title}</h4>
      </div>
      <div className="tile__time">
        <p>{time}</p>
      </div>
       <div className="tile__teacher">
        <p>{instructor ? `Instructor: ${instructor}` : '\u00A0' }</p>
      </div>
      <div className="tile__cta pt-3">
        <div className="tile__cta__text">
          <p>Learn More</p>
        </div>
        <div className="tile__cta__icon ">
          <img src={ArrowRight} alt='' />
        </div>
      </div>
    </div>
  );
};

export default ActivityTile;
