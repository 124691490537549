import React, { useState } from 'react';
import DatePickerModule from 'react-datepicker';
import './DatePicker.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';

// import { Calendar } from 'react-bootstrap-icons';

type Props = {
  title: string;
  id: string;
  value: string | null | undefined;
  onChange?: (e: any) => void;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  timeIntervals?: number;
  timeCaption?: string;
  dateFormat?: string;
  v?: any;
  err?: (id: string) => string[];
  errors?: string[];
  validate?: (field: string, value: string | number | null | undefined | boolean) => void;
  label?: string,

};

const DatePicker = (props:Props) => {
  const [startDate] = useState<number>(0);
  const {
    title = '',
    id = '',
    value = null,
    showTimeSelect = false,
    showTimeSelectOnly = false,
    timeIntervals = 15,
    timeCaption = 'Time',
    dateFormat = 'HH:mm',
    err = () => [],
    onChange = () => {
    },
  } = props;


  const onInnerChange = (e: any) => {
    if (!showTimeSelect && !showTimeSelectOnly){
      let date = format(e, 'yyyy-MM-dd');
      onChange(date);
    } else {
      let date = format(e, 'HH:mm');
      onChange(date);
    }
  };


  const errors = err(id);

  return (
    <div className="ems-date-picker">
      <label className="ems-date-picker label">{title}</label>
        {/*@ts-ignore*/}
      <DatePickerModule autoComplete={'off'} showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        timeIntervals={timeIntervals}
                        timeCaption={timeCaption}
                        dateFormat={dateFormat}
                        selected={startDate}
                        id={id}
                        value={value}
                        onChange={onInnerChange} />
      {errors.map((e: string, i: number) => (<div className="error-lbl" key={i}>{e}</div>))}
    </div>
  );
};

export default DatePicker;
