import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountUsers } from '../../../selectors/AccountUser';
import { destroyAccountUser, fetchAccountUsers } from '../../../actions/AccountUser';
import { AccountUser } from '../../../types/AccountUser';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const AccountUserDataTable = () => {

  const accountUsers = useSelector(getAccountUsers);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [accountUser, setActiveAccountUser] = useState<AccountUser | null>();


  useEffect(() => {
    dispatch(fetchAccountUsers());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACCOUNT_USER_CREATE);
  };



  const onDelete = (row: AccountUser) => {
    setActiveAccountUser(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (accountUser?.id) {
      dispatch(destroyAccountUser(accountUser?.id));
    }
    setActiveAccountUser(null);
  };


  const config = {
    columns: [
      { key: 'accountTitle', label: 'Account', mobileFriendly: true },
      { key: 'userEmail', label: 'User', mobileFriendly: true },
      
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ACCOUNT/USER" ctaTitle="Link Account/User" onCreate={onCreate} data={accountUsers?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Account/User" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this account/user link?
            </Modal>

        </View>
  );
};

export default AccountUserDataTable;
