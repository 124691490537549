import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ACTIVITY_SLOTS_REQUEST,
  FETCH_ACTIVITY_SLOTS_RESPONSE,
  FETCH_ACTIVITY_SLOTS_FAILURE,
  FETCH_ACTIVITY_SLOT_REQUEST,
  FETCH_ACTIVITY_SLOT_RESPONSE,
  FETCH_ACTIVITY_SLOT_FAILURE,
  POST_ACTIVITY_SLOT_FAILURE,
  POST_ACTIVITY_SLOT_REQUEST,
  POST_ACTIVITY_SLOT_RESPONSE,
  ActivitySlotActionTypes, ActivitySlotDataModel, ActivitySlot,
} from '../types/ActivitySlot';

import { RootState } from '../store/configureStore';

import {
  getActivitySlot,
  getActivitySlots,
  postActivitySlot,
  putActivitySlot,
  deleteActivitySlot,
  getActivitySlotsByShortcodes,
} from '../api/ActivitySlot';


export function fetchActivitySlotsRequest(): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOTS_REQUEST,
    payload: null,
  };
}

export function fetchActivitySlotsResponse(
  activitySlots: ActivitySlotDataModel,
): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOTS_RESPONSE,
    payload: activitySlots,
  };
}

export function fetchActivitySlotsFailure(): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOTS_FAILURE,
    payload: null,
  };
}

export function fetchActivitySlotRequest(): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_REQUEST,
    payload: null,
  };
}

export function fetchActivitySlotResponse(
  activitySlot: ActivitySlot,
): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_RESPONSE,
    payload: activitySlot,
  };
}

export function fetchActivitySlotFailure(): ActivitySlotActionTypes {
  return {
    type: FETCH_ACTIVITY_SLOT_FAILURE,
    payload: null,
  };
}


export function postActivitySlotRequest(): ActivitySlotActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_REQUEST,
    payload: null,
  };
}

export function postActivitySlotResponse(): ActivitySlotActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_RESPONSE,
    payload: null,
  };
}

export function postActivitySlotFailure(error: string, validationErrors: any): ActivitySlotActionTypes {
  return {
    type: POST_ACTIVITY_SLOT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveActivitySlot = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitySlotRequest());
    const asyncResp: any = await getActivitySlot(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotResponse(asyncResp.data));
    } else {
      await dispatch(fetchActivitySlotFailure());
    }
  };

export const fetchActivitySlots = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitySlotsRequest());
    const asyncResp: any = await getActivitySlots();
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotsResponse(asyncResp));
    } else {
      await dispatch(fetchActivitySlotsFailure());
    }
  };

export const fetchActivitySlotsByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitySlotsRequest());
    const asyncResp: any = await getActivitySlotsByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlotsResponse(asyncResp));
    } else {
      await dispatch(fetchActivitySlotsFailure());
    }
  };


export const destroyActivitySlot = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteActivitySlot(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivitySlots());
    }
  };


export const createActivitySlot = (
  activityId: string,
  validFrom: string,
  validUntil: string,
  cutOffTime: number,
  dayOfTheWeek: number[],
  startTime: string,
  endTime: string,
  capacity: number,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivitySlotRequest());
    const asyncResp: any = await postActivitySlot(activityId, validFrom, validUntil, cutOffTime, dayOfTheWeek, startTime, endTime, capacity, active);
    if (asyncResp?.success) {
      await dispatch(postActivitySlotResponse());
      await dispatch(fetchActivitySlots());
    } else {
      await dispatch(postActivitySlotFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateActivitySlot = (
  id: string,
  activityId: string,
  validFrom: string,
  validUntil: string,
  cutOffTime: number,
  dayOfTheWeek: number,
  startTime: string,
  endTime: string,
  capacity: number,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivitySlotRequest());
    const asyncResp: any = await putActivitySlot(id, activityId, validFrom, validUntil, cutOffTime, dayOfTheWeek, startTime, endTime, capacity, active);
    if (asyncResp?.success) {
      await dispatch(postActivitySlotResponse());
      await dispatch(fetchActivitySlots());
    } else {
      await dispatch(postActivitySlotFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

