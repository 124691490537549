import axios from 'axios';

import c from '../helpers/constants';

export function getActivitySlot(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivitySlots() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_SLOT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivitySlotsByShortcodes(groupShortcode: string, clientShortcode: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT_SHORTCODE}/${groupShortcode}/${clientShortcode}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteActivitySlot(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postActivitySlot(
  activityId: string,
  validFrom: string,
  validUntil: string,
  cutOffTime: number,
  dayOfTheWeek: number[],
  startTime: string,
  endTime: string,
  capacity: number,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_SLOT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        activityId,
        validFrom,
        validUntil,
        cutOffTime,
        dayOfTheWeek,
        startTime,
        endTime,
        capacity,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putActivitySlot(
  id: string,
  activityId: string,
  validFrom: string,
  validUntil: string,
  cutOffTime: number,
  dayOfTheWeek: number,
  startTime: string,
  endTime: string,
  capacity: number,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        activityId,
        validFrom,
        validUntil,
        cutOffTime,
        dayOfTheWeek,
        startTime,
        endTime,
        capacity,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


