import React, { useEffect, useState } from 'react';
import View from '../../components/View';
import { useDispatch, useSelector } from 'react-redux';
import { getOrders } from '../../selectors/Order';
import { fetchOrderByReference } from '../../actions/Order';
import QRCode from 'react-qr-code';
import { formatUrl } from '../../helpers/functions';

// import DataTable from '../../components/DataTable';
import { useHistory, useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import './Checkout.scss';
import Button from '../../components/Button';
import con from '../../helpers/constants';
// import Button from "../../components/Button";


const OrderDataTable = () => {

  const orders = useSelector(getOrders);
  const history = useHistory();
  const matchUpdate = useRouteMatch(c.APP_ROUTES.CHECKOUT_SUCCESS);
  // @ts-ignore
  const [bookingReference] = useState(matchUpdate?.params?.bookingReference);
  const [url, setUrl] = useState('');
  const dispatch = useDispatch();


  const toActivities = ()=> {
    history.push(con.APP_ROUTES.SEARCH);
  };


  useEffect(() => {
    // @ts-ignore
    dispatch(fetchOrderByReference(bookingReference));
  }, [dispatch, bookingReference]);

 


  useEffect(() => {
    const qr = formatUrl(c.APP_ROUTES.CHECKOUT_SUCCESS, bookingReference);
    setUrl(c.APP_BASE_URL + qr);
  });

  const newForm = orders?.data.map(obj => {
    if (obj.activitySlotDay === 0){
      return { ...obj, activitySlotDay: 'Sunday' };
    }
    if (obj.activitySlotDay === 1){
      return { ...obj, activitySlotDay: 'Monday' };
    }
    if (obj.activitySlotDay === 2){
      return { ...obj, activitySlotDay: 'Tuesday' };
    }
    if (obj.activitySlotDay === 3){
      return { ...obj, activitySlotDay: 'Wednesday' };
    }
    if (obj.activitySlotDay === 4){
      return { ...obj, activitySlotDay: 'Thursday' };
    }
    if (obj.activitySlotDay === 5){
      return { ...obj, activitySlotDay: 'Friday' };
    }
    if (obj.activitySlotDay === 6){
      return { ...obj, activitySlotDay: 'Saturday' };
    }
    return obj;
  });

  let subTotal = 0;
  let totalVat = 0;
  let grandTotal = 0;
  //@ts-ignore
  for (let i = 0; i < newForm?.length; i++){
    // @ts-ignore
    grandTotal = grandTotal + newForm[i].total;
  }
  console.log(subTotal, totalVat, grandTotal);


  // const config = {
  //   columns: [
  //     { key: 'bookingReference', label: 'Booking Reference', mobileFriendly: true },
  //     { key: 'customerEmail', label: 'Customer', mobileFriendly: true },
  //     { key: 'locationTitle', label: 'Location', mobileFriendly: false },
  //     { key: 'activityTitle', label: 'Activity', mobileFriendly: false },
  //     { key: 'activitySlotDay', label: 'Day', mobileFriendly: false },
  //     { key: 'activitySlotStartTime', label: 'Start Time', mobileFriendly: false },
  //     { key: 'activitySlotEndTime', label: 'End Time', mobileFriendly: false },
  //     { key: 'subtotal', label: 'Subtotal', mobileFriendly: false },
  //     { key: 'vat', label: 'VAT', mobileFriendly: false },
  //     { key: 'total', label: 'Total', mobileFriendly: false },
  //   ],
  //   hideControls: true,
  //   hideOverheadControls: true,
  //   hideButton: true,
  //   actions: [],
  // };

  return <View isAdmin={false} style="dark">
      <div className="container-fluid">
        <div className="row mb-5">
            <div className="col align-self-start">
                <div className="row">
                    <h3 className="title-white-primary my-2">BOOKING CONFIRMATION</h3>
                    <h5 className="card_body">Thank you, your order has gone through!
                        We will send an email to further confirm your order.<span className="checkout_emoji"> 🎉</span></h5>
                </div>
            </div>
            <div className="col">
                <div className="row float-end">
                <h3 className="title-white-primary my-2">Booking Ref: {bookingReference}</h3>
                </div>
            </div>
        </div>
          <div className="row">
              <h3 className="title-white-primary my-2">Summary:</h3>
          </div>
      </div>
        {/* @ts-ignore */}
        {/*<DataTable title="ORDERS" data={orders?.data || []} config={config}/>*/}
      <div className="checkout_container">
      <div className="row container">
          {newForm?.map((order, i) => <div key={i} className="card_summary">
              <div className="card_left">
                  <p className="card_header">{order.customerFirstName} {order.customerLastName}</p>
                  <p className="card_body>">{order.customerEmail}</p>
                  <p className="card_body">{order.customerCountry}</p>
                  <p className="card_body">{order.customerCity}</p></div>
              <div className="card_middle">
                  <p className="card_header">{order.activityTitle}</p>
                  <p className="card_body>">{order.activitySlotDay}</p>
                  <p className="card_body">{order.activitySlotStartTime}-{order.activitySlotEndTime}</p>
                  {order.instructorFirstName && <p className="card_body">Instructor: {order.instructorFirstName} {order.instructorLastName}</p>}
              </div>
              <div className="card_right">{order.total} {order.activityCurrency?.replace(/ *\([^)]*\) */g, '')}</div>
          </div>)}
      </div>
          <div className="col-md-2">
              <QRCode
                  size={160}
                  style={{ height: '100%', maxWidth: '100%', width: '100%', padding: '10px' }}
                  value={url}
                  viewBox={'0 0 256 256'}>
              </QRCode>
          </div>
      </div>
      <div className="container-fluid">
          <div className="row final">
      <h3 className="title-white-primary my-4">Amount Spent: {grandTotal} AED</h3>
              <Button title="GO BACK TO ACTIVITIES" onClick={toActivities}/>
              </div>
      </div>

    </View>;
};

export default OrderDataTable;
