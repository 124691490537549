import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleAccounts } from '../../../selectors/RoleAccount';
import { destroyRoleAccount, fetchRoleAccounts } from '../../../actions/RoleAccount';
import { RoleAccount } from '../../../types/RoleAccount';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const RoleAccountDataTable = () => {

  const roleAccounts = useSelector(getRoleAccounts);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [roleAccount, setActiveRoleAccount] = useState<RoleAccount | null>();


  useEffect(() => {
    dispatch(fetchRoleAccounts());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_ACCOUNT_CREATE);
  };



  const onDelete = (row: RoleAccount) => {
    setActiveRoleAccount(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (roleAccount?.id) {
      dispatch(destroyRoleAccount(roleAccount?.id));
    }
    setActiveRoleAccount(null);
  };


  const config = {
    columns: [
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },
      { key: 'accountTitle', label: 'Account', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ROLE/ACCOUNT" ctaTitle="Link Role/Account" onCreate={onCreate} data={roleAccounts?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Role/Account" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Role/Account link?
            </Modal>

        </View>
  );
};

export default RoleAccountDataTable;
