import {
  FETCH_ROLE_ACCOUNT_FAILURE,
  FETCH_ROLE_ACCOUNT_REQUEST,
  FETCH_ROLE_ACCOUNT_RESPONSE,
  FETCH_ROLE_ACCOUNTS_FAILURE,
  FETCH_ROLE_ACCOUNTS_REQUEST,
  FETCH_ROLE_ACCOUNTS_RESPONSE,
  POST_ROLE_ACCOUNT_FAILURE,
  POST_ROLE_ACCOUNT_RESPONSE,
  RoleAccountActionTypes,
  RoleAccountState,
  POST_ROLE_ACCOUNT_REQUEST,
} from '../types/RoleAccount';
import { parseValidationErrors } from '../helpers/functions';

const initialState: RoleAccountState = {
  roleAccounts: null,
  activeRoleAccount: null,
  isFetchingRoleAccounts: false,
  fetchingRoleAccountsFailed: false,
  isPostingRoleAccount: false,
  postingRoleAccountFailed: false,
  postingRoleAccountError: null,
  postingRoleAccountValidationErrors: null,

};

const roleAccountsReducer = (
  state = initialState,
  action: RoleAccountActionTypes,
): RoleAccountState => {
  switch (action.type) {
    case FETCH_ROLE_ACCOUNTS_REQUEST:
      return {
        ...state,
        roleAccounts: null,
        isFetchingRoleAccounts: true,
        fetchingRoleAccountsFailed: false,
      };
    case FETCH_ROLE_ACCOUNTS_RESPONSE:
      return {
        ...state,
        roleAccounts: action.payload || [],
        isFetchingRoleAccounts: false,
        fetchingRoleAccountsFailed: false,
      };
    case FETCH_ROLE_ACCOUNTS_FAILURE:
      return {
        ...state,
        roleAccounts: null,
        isFetchingRoleAccounts: false,
        fetchingRoleAccountsFailed: true,
      };
    case FETCH_ROLE_ACCOUNT_REQUEST:
      return {
        ...state,
        roleAccounts: null,
        isFetchingRoleAccounts: true,
        fetchingRoleAccountsFailed: false,
      };
    case FETCH_ROLE_ACCOUNT_RESPONSE:
      return {
        ...state,
        activeRoleAccount: action.payload,
        isFetchingRoleAccounts: false,
        fetchingRoleAccountsFailed: false,
      };
    case FETCH_ROLE_ACCOUNT_FAILURE:
      return {
        ...state,
        roleAccounts: null,
        isFetchingRoleAccounts: false,
        fetchingRoleAccountsFailed: true,
      };
    case POST_ROLE_ACCOUNT_REQUEST:
      return {
        ...state,
        isPostingRoleAccount: true,
        postingRoleAccountError: null,
        postingRoleAccountValidationErrors: null,
        postingRoleAccountFailed: false,
      };
    case POST_ROLE_ACCOUNT_RESPONSE:
      return {
        ...state,
        isPostingRoleAccount: false,
        postingRoleAccountError: null,
        postingRoleAccountValidationErrors: null,
        postingRoleAccountFailed: false,
      };
    case POST_ROLE_ACCOUNT_FAILURE:
      return {
        ...state,
        isPostingRoleAccount: false,
        postingRoleAccountError: action.payload.error || 'An error occurred',
        postingRoleAccountValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingRoleAccountFailed: true,
      };

    default:
      return state;
  }
};

export default roleAccountsReducer;

