import {
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
  FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE,
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST,
  FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE,
  POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE,
  POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE,
  ActivitySlotInstructorActionTypes,
  ActivitySlotInstructorState,
  POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST,
} from '../types/ActivitySlotInstructor';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ActivitySlotInstructorState = {
  activitySlotInstructors: null,
  activeActivitySlotInstructor: null,
  isFetchingActivitySlotInstructors: false,
  fetchingActivitySlotInstructorsFailed: false,
  isPostingActivitySlotInstructor: false,
  postingActivitySlotInstructorFailed: false,
  postingActivitySlotInstructorError: null,
  postingActivitySlotInstructorValidationErrors: null,

};

const activitySlotInstructorsReducer = (
  state = initialState,
  action: ActivitySlotInstructorActionTypes,
): ActivitySlotInstructorState => {
  switch (action.type) {
    case FETCH_ACTIVITY_SLOT_INSTRUCTORS_REQUEST:
      return {
        ...state,
        activitySlotInstructors: null,
        isFetchingActivitySlotInstructors: true,
        fetchingActivitySlotInstructorsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_INSTRUCTORS_RESPONSE:
      return {
        ...state,
        activitySlotInstructors: action.payload || [],
        isFetchingActivitySlotInstructors: false,
        fetchingActivitySlotInstructorsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_INSTRUCTORS_FAILURE:
      return {
        ...state,
        activitySlotInstructors: null,
        isFetchingActivitySlotInstructors: false,
        fetchingActivitySlotInstructorsFailed: true,
      };
    case FETCH_ACTIVITY_SLOT_INSTRUCTOR_REQUEST:
      return {
        ...state,
        activitySlotInstructors: null,
        isFetchingActivitySlotInstructors: true,
        fetchingActivitySlotInstructorsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE:
      return {
        ...state,
        activeActivitySlotInstructor: action.payload,
        isFetchingActivitySlotInstructors: false,
        fetchingActivitySlotInstructorsFailed: false,
      };
    case FETCH_ACTIVITY_SLOT_INSTRUCTOR_FAILURE:
      return {
        ...state,
        activitySlotInstructors: null,
        isFetchingActivitySlotInstructors: false,
        fetchingActivitySlotInstructorsFailed: true,
      };
    case POST_ACTIVITY_SLOT_INSTRUCTOR_REQUEST:
      return {
        ...state,
        isPostingActivitySlotInstructor: true,
        postingActivitySlotInstructorError: null,
        postingActivitySlotInstructorValidationErrors: null,
        postingActivitySlotInstructorFailed: false,
      };
    case POST_ACTIVITY_SLOT_INSTRUCTOR_RESPONSE:
      return {
        ...state,
        isPostingActivitySlotInstructor: false,
        postingActivitySlotInstructorError: null,
        postingActivitySlotInstructorValidationErrors: null,
        postingActivitySlotInstructorFailed: false,
      };
    case POST_ACTIVITY_SLOT_INSTRUCTOR_FAILURE:
      return {
        ...state,
        isPostingActivitySlotInstructor: false,
        postingActivitySlotInstructorError: action.payload.error || 'An error occurred',
        postingActivitySlotInstructorValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingActivitySlotInstructorFailed: true,
      };

    default:
      return state;
  }
};

export default activitySlotInstructorsReducer;
