import {
  FETCH_GROUP_FAILURE,
  FETCH_GROUP_REQUEST,
  FETCH_GROUP_RESPONSE,
  FETCH_GROUPS_FAILURE,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_RESPONSE,
  POST_GROUP_FAILURE,
  POST_GROUP_RESPONSE,
  GroupActionTypes,
  GroupState,
  POST_GROUP_REQUEST,
} from '../types/Group';
import { parseValidationErrors } from '../helpers/functions';

const initialState: GroupState = {
  groups: null,
  activeGroup: null,
  isFetchingGroups: false,
  fetchingGroupsFailed: false,
  isPostingGroup: false,
  postingGroupFailed: false,
  postingGroupError: null,
  postingGroupValidationErrors: null,
};

const groupsReducer = (
  state = initialState,
  action: GroupActionTypes,
): GroupState => {
  switch (action.type) {
    case FETCH_GROUPS_REQUEST:
      return {
        ...state,
        groups: null,
        isFetchingGroups: true,
        fetchingGroupsFailed: false,
      };
    case FETCH_GROUPS_RESPONSE:
      return {
        ...state,
        groups: action.payload || [],
        isFetchingGroups: false,
        fetchingGroupsFailed: false,
      };
    case FETCH_GROUPS_FAILURE:
      return {
        ...state,
        groups: null,
        isFetchingGroups: false,
        fetchingGroupsFailed: true,
      };
    case FETCH_GROUP_REQUEST:
      return {
        ...state,
        groups: null,
        isFetchingGroups: true,
        fetchingGroupsFailed: false,
      };
    case FETCH_GROUP_RESPONSE:
      return {
        ...state,
        activeGroup: action.payload,
        isFetchingGroups: false,
        fetchingGroupsFailed: false,
      };
    case FETCH_GROUP_FAILURE:
      return {
        ...state,
        groups: null,
        isFetchingGroups: false,
        fetchingGroupsFailed: true,
      };
    case POST_GROUP_REQUEST:
      return {
        ...state,
        isPostingGroup: true,
        postingGroupError: null,
        postingGroupValidationErrors: null,
        postingGroupFailed: false,
      };
    case POST_GROUP_RESPONSE:
      return {
        ...state,
        isPostingGroup: false,
        postingGroupError: null,
        postingGroupValidationErrors: null,
        postingGroupFailed: false,
      };
    case POST_GROUP_FAILURE:
      return {
        ...state,
        isPostingGroup: false,
        postingGroupError: action.payload.error || 'An error occurred',
        postingGroupValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingGroupFailed: true,
      };
    default:
      return state;
  }
};

export default groupsReducer;
