import { RootState } from '../store/configureStore';

export const getAccountClients = (state: RootState) => state?.accountClient?.accountClients;

export const isFetchingAccountClients = (state: RootState) =>
  state?.accountClient?.isFetchingAccountClients;

export const isPostingAccountClient = (state: RootState) => 
  state?.accountClient?.isPostingAccountClient;
export const postingAccountClientFailed = (state: RootState) => 
  state?.accountClient?.postingAccountClientFailed;
export const postingAccountClientError = (state: RootState) => 
  state?.accountClient?.postingAccountClientError;
export const postingAccountClientValidationErrors = (state: RootState) => 
  state?.accountClient?.postingAccountClientValidationErrors;

export const fetchingAccountClientsFailed = (state: RootState) =>
  state?.accountClient?.fetchingAccountClientsFailed;
