import { ApiDataModel } from './Common';

export const FETCH_CITY_REQUEST = 'FETCH_CITY_REQUEST';
export const FETCH_CITY_RESPONSE = 'FETCH_CITY_RESPONSE';
export const FETCH_CITY_FAILURE = 'FETCH_CITY_FAILURE';



export interface City {
  id: string;
  city: string;

}

export interface CityDataModel extends Omit<ApiDataModel, 'data'> {
  data: City[];
}

export interface CityState {
  city: CityDataModel | null,
  isFetchingCity: boolean;
  fetchingCityFailed: boolean;
}

interface FetchCityRequestAction {
  type: typeof FETCH_CITY_REQUEST;
  payload: null;
}

interface FetchCityResponseAction {
  type: typeof FETCH_CITY_RESPONSE;
  payload: CityDataModel;
}

interface FetchCityFailureAction {
  type: typeof FETCH_CITY_FAILURE;
  payload: null;
}



export type CityActionTypes =
    | FetchCityRequestAction
    | FetchCityResponseAction
    | FetchCityFailureAction;