import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ACTIVITY_ASSETS_REQUEST,
  FETCH_ACTIVITY_ASSETS_RESPONSE,
  FETCH_ACTIVITY_ASSETS_FAILURE,
  FETCH_ACTIVITY_ASSET_REQUEST,
  FETCH_ACTIVITY_ASSET_RESPONSE,
  FETCH_ACTIVITY_ASSET_FAILURE,
  POST_ACTIVITY_ASSET_FAILURE,
  POST_ACTIVITY_ASSET_REQUEST,
  POST_ACTIVITY_ASSET_RESPONSE,
  ActivityAssetActionTypes, ActivityAssetDataModel, ActivityAsset,
} from '../types/ActivityAsset';

import { RootState } from '../store/configureStore';

import { getActivityAsset, getActivityAssets, postActivityAsset,  deleteActivityAsset } from '../api/ActivityAsset';


export function fetchActivityAssetsRequest(): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSETS_REQUEST,
    payload: null,
  };
}

export function fetchActivityAssetsResponse(
  activityAssets: ActivityAssetDataModel,
): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSETS_RESPONSE,
    payload: activityAssets,
  };
}

export function fetchActivityAssetsFailure(): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSETS_FAILURE,
    payload: null,
  };
}

export function fetchActivityAssetRequest(): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSET_REQUEST,
    payload: null,
  };
}

export function fetchActivityAssetResponse(
  activityAsset: ActivityAsset,
): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSET_RESPONSE,
    payload: activityAsset,
  };
}

export function fetchActivityAssetFailure(): ActivityAssetActionTypes {
  return {
    type: FETCH_ACTIVITY_ASSET_FAILURE,
    payload: null,
  };
}


export function postActivityAssetRequest(): ActivityAssetActionTypes {
  return {
    type: POST_ACTIVITY_ASSET_REQUEST,
    payload: null,
  };
}

export function postActivityAssetResponse(): ActivityAssetActionTypes {
  return {
    type: POST_ACTIVITY_ASSET_RESPONSE,
    payload: null,
  };
}

export function postActivityAssetFailure(error: string, validationErrors: any): ActivityAssetActionTypes {
  return {
    type: POST_ACTIVITY_ASSET_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveActivityAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivityAssetRequest());
    const asyncResp: any = await getActivityAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivityAssetResponse(asyncResp.data));
    } else {
      await dispatch(fetchActivityAssetFailure());
    }
  };

export const fetchActivityAssets = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivityAssetsRequest());
    const asyncResp: any = await getActivityAssets();
    if (asyncResp?.success) {
      await dispatch(fetchActivityAssetsResponse(asyncResp));
    } else {
      await dispatch(fetchActivityAssetsFailure());
    }
  };

export const destroyActivityAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteActivityAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivityAssets());
    }
  };


export const createActivityAsset = (
  activityId: string,
  InstructorId: string,

):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivityAssetRequest());
    const asyncResp: any = await postActivityAsset(activityId, InstructorId);
    if (asyncResp?.success) {
      await dispatch(postActivityAssetResponse());
      await dispatch(fetchActivityAssets());
    } else {
      await dispatch(postActivityAssetFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };



