import React from 'react';
import './Checkout.scss';
import View from '../../components/View';


const CheckoutFailure = () => {
  return (
      <View isAdmin={false}>
      <div className="box">

            <h1>Checkout failed.</h1>
          </div>

      </View>
  );
};

export default CheckoutFailure;
