import axios from 'axios';

import c from '../helpers/constants';


export function getUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getUsers() {
  return (
    axios({
      url: c.API_ENDPOINTS.USER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getUserCustomers(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}/customers`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteUser(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postUser(roleId: string, email: string, password: string, username: string, firstName: string, lastName: string, active: boolean, verified: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.USER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        email,
        password,
        username,
        firstName,
        lastName,
        active,
        verified,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postUserLogin(email: string, password: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/login`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        email,
        password,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postResetPassword(email: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/resetPassword`,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        email,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putResetPassword(id: string, password: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/resetPassword/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        password,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putUser(
  id: string,
  roleId: string,
  email: string,
  password: string,
  username: string,
  firstName: string,
  lastName: string,
  active: boolean,
  verified: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.USER}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        email,
        password,
        username,
        firstName,
        lastName,
        active,
        verified,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


