import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_INSTRUCTORS_REQUEST,
  FETCH_INSTRUCTORS_RESPONSE,
  FETCH_INSTRUCTORS_FAILURE,
  FETCH_INSTRUCTOR_REQUEST,
  FETCH_INSTRUCTOR_RESPONSE,
  FETCH_INSTRUCTOR_FAILURE,
  POST_INSTRUCTOR_FAILURE,
  POST_INSTRUCTOR_REQUEST,
  POST_INSTRUCTOR_RESPONSE,
  InstructorActionTypes, InstructorDataModel, Instructor,
} from '../types/Instructor';

import { RootState } from '../store/configureStore';

import { getInstructor, getInstructors, postInstructor, putInstructor, deleteInstructor } from '../api/Instructor';

export function fetchInstructorsRequest(): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTORS_REQUEST,
    payload: null,
  };
}

export function fetchInstructorsResponse(
  instructors: InstructorDataModel,
): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTORS_RESPONSE,
    payload: instructors,
  };
}

export function fetchInstructorsFailure(): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTORS_FAILURE,
    payload: null,
  };
}

export function fetchInstructorRequest(): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTOR_REQUEST,
    payload: null,
  };
}

export function fetchInstructorResponse(
  instructor: Instructor,
): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTOR_RESPONSE,
    payload: instructor,
  };
}

export function fetchInstructorFailure(): InstructorActionTypes {
  return {
    type: FETCH_INSTRUCTOR_FAILURE,
    payload: null,
  };
}


export function postInstructorRequest(): InstructorActionTypes {
  return {
    type: POST_INSTRUCTOR_REQUEST,
    payload: null,
  };
}

export function postInstructorResponse(): InstructorActionTypes {
  return {
    type: POST_INSTRUCTOR_RESPONSE,
    payload: null,
  };
}

export function postInstructorFailure(error: string, validationErrors: any): InstructorActionTypes {
  return {
    type: POST_INSTRUCTOR_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveInstructor = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchInstructorRequest());
    const asyncResp: any = await getInstructor(id);
    if (asyncResp?.success) {
      await dispatch(fetchInstructorResponse(asyncResp.data));
    } else {
      await dispatch(fetchInstructorFailure());
    }
  };

export const fetchInstructors = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchInstructorsRequest());
    const asyncResp: any = await getInstructors();
    if (asyncResp?.success) {
      await dispatch(fetchInstructorsResponse(asyncResp));
    } else {
      await dispatch(fetchInstructorsFailure());
    }
  };

export const destroyInstructor = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteInstructor(id);
    if (asyncResp?.success) {
      await dispatch(fetchInstructors());
    }
  };


export const createInstructor = (
  userId: string, groupId: string, clientId: string, title: string, email: string, firstName: string, lastName: string, phone: string, address: string, city: string, postcode: string, country: string, active: boolean, // TODO Edit the fields to have the fields of the Instructor entity
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postInstructorRequest());
    const asyncResp: any = await postInstructor(userId, groupId, clientId, title, email, firstName, lastName, phone, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postInstructorResponse());
      await dispatch(fetchInstructors());
    } else {
      await dispatch(postInstructorFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateInstructor = (
  id: string,
  userId: string,
  groupId: string,
  clientId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postInstructorRequest());
    const asyncResp: any = await putInstructor(id, userId, groupId, clientId, title, email, firstName, lastName, phone, address, city, postcode, country, active);
    if (asyncResp?.success) {
      await dispatch(postInstructorResponse());
      await dispatch(fetchInstructors());
    } else {
      await dispatch(postInstructorFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

