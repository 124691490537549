import {
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_RESPONSE,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_RESPONSE,
  POST_ACCOUNT_FAILURE,
  POST_ACCOUNT_RESPONSE,
  AccountActionTypes,
  AccountState,
  POST_ACCOUNT_REQUEST,
} from '../types/Account';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AccountState = {
  accounts: null,
  activeAccount: null,
  isFetchingAccounts: false,
  fetchingAccountsFailed: false,
  isPostingAccount: false,
  postingAccountFailed: false,
  postingAccountError: null,
  postingAccountValidationErrors: null,
};

const accountsReducer = (
  state = initialState,
  action: AccountActionTypes,
): AccountState => {
  switch (action.type) {
    case FETCH_ACCOUNTS_REQUEST:
      return {
        ...state,
        accounts: null,
        isFetchingAccounts: true,
        fetchingAccountsFailed: false,
      };
    case FETCH_ACCOUNTS_RESPONSE:
      return {
        ...state,
        accounts: action.payload || [],
        isFetchingAccounts: false,
        fetchingAccountsFailed: false,
      };
    case FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        accounts: null,
        isFetchingAccounts: false,
        fetchingAccountsFailed: true,
      };
    case FETCH_ACCOUNT_REQUEST:
      return {
        ...state,
        accounts: null,
        isFetchingAccounts: true,
        fetchingAccountsFailed: false,
      };
    case FETCH_ACCOUNT_RESPONSE:
      return {
        ...state,
        activeAccount: action.payload,
        isFetchingAccounts: false,
        fetchingAccountsFailed: false,
      };
    case FETCH_ACCOUNT_FAILURE:
      return {
        ...state,
        accounts: null,
        isFetchingAccounts: false,
        fetchingAccountsFailed: true,
      };
    case POST_ACCOUNT_REQUEST:
      return {
        ...state,
        isPostingAccount: true,
        postingAccountError: null,
        postingAccountValidationErrors: null,
        postingAccountFailed: false,
      };
    case POST_ACCOUNT_RESPONSE:
      return {
        ...state,
        isPostingAccount: false,
        postingAccountError: null,
        postingAccountValidationErrors: null,
        postingAccountFailed: false,
      };
    case POST_ACCOUNT_FAILURE:
      return {
        ...state,
        isPostingAccount: false,
        postingAccountError: action.payload.error || 'An error occurred',
        postingAccountValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAccountFailed: true,
      };
    default:
      return state;
  }
};

export default accountsReducer;
