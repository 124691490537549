import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_COUNTRY_REQUEST,
  FETCH_COUNTRY_RESPONSE,
  FETCH_COUNTRY_FAILURE,

  CountryActionTypes, CountryDataModel,
} from '../types/Country';

import { RootState } from '../store/configureStore';

import { getCountry } from '../api/Country';

export function fetchCountryRequest(): CountryActionTypes {
  return {
    type: FETCH_COUNTRY_REQUEST,
    payload: null,
  };
}

export function fetchCountryResponse(
  country: CountryDataModel,
): CountryActionTypes {
  return {
    type: FETCH_COUNTRY_RESPONSE,
    payload: country,
  };
}

export function fetchCountryFailure(): CountryActionTypes {
  return {
    type: FETCH_COUNTRY_FAILURE,
    payload: null,
  };
}


export const fetchCountry = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCountryRequest());
    const asyncResp: any = await getCountry();
    if (asyncResp?.success) {
      await dispatch(fetchCountryResponse(asyncResp));
    } else {
      await dispatch(fetchCountryFailure());
    }
  };