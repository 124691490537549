import {
  FETCH_LOCATION_ASSET_FAILURE,
  FETCH_LOCATION_ASSET_REQUEST,
  FETCH_LOCATION_ASSET_RESPONSE,
  FETCH_LOCATION_ASSETS_FAILURE,
  FETCH_LOCATION_ASSETS_REQUEST,
  FETCH_LOCATION_ASSETS_RESPONSE,
  POST_LOCATION_ASSET_FAILURE,
  POST_LOCATION_ASSET_RESPONSE,
  LocationAssetActionTypes,
  LocationAssetState,
  POST_LOCATION_ASSET_REQUEST,
} from '../types/LocationAsset';
import { parseValidationErrors } from '../helpers/functions';

const initialState: LocationAssetState = {
  locationAssets: null,
  activeLocationAsset: null,
  isFetchingLocationAssets: false,
  fetchingLocationAssetsFailed: false,
  isPostingLocationAsset: false,
  postingLocationAssetFailed: false,
  postingLocationAssetError: null,
  postingLocationAssetValidationErrors: null,

};

const locationAssetsReducer = (
  state = initialState,
  action: LocationAssetActionTypes,
): LocationAssetState => {
  switch (action.type) {
    case FETCH_LOCATION_ASSETS_REQUEST:
      return {
        ...state,
        locationAssets: null,
        isFetchingLocationAssets: true,
        fetchingLocationAssetsFailed: false,
      };
    case FETCH_LOCATION_ASSETS_RESPONSE:
      return {
        ...state,
        locationAssets: action.payload || [],
        isFetchingLocationAssets: false,
        fetchingLocationAssetsFailed: false,
      };
    case FETCH_LOCATION_ASSETS_FAILURE:
      return {
        ...state,
        locationAssets: null,
        isFetchingLocationAssets: false,
        fetchingLocationAssetsFailed: true,
      };
    case FETCH_LOCATION_ASSET_REQUEST:
      return {
        ...state,
        locationAssets: null,
        isFetchingLocationAssets: true,
        fetchingLocationAssetsFailed: false,
      };
    case FETCH_LOCATION_ASSET_RESPONSE:
      return {
        ...state,
        activeLocationAsset: action.payload,
        isFetchingLocationAssets: false,
        fetchingLocationAssetsFailed: false,
      };
    case FETCH_LOCATION_ASSET_FAILURE:
      return {
        ...state,
        locationAssets: null,
        isFetchingLocationAssets: false,
        fetchingLocationAssetsFailed: true,
      };
    case POST_LOCATION_ASSET_REQUEST:
      return {
        ...state,
        isPostingLocationAsset: true,
        postingLocationAssetError: null,
        postingLocationAssetValidationErrors: null,
        postingLocationAssetFailed: false,
      };
    case POST_LOCATION_ASSET_RESPONSE:
      return {
        ...state,
        isPostingLocationAsset: false,
        postingLocationAssetError: null,
        postingLocationAssetValidationErrors: null,
        postingLocationAssetFailed: false,
      };
    case POST_LOCATION_ASSET_FAILURE:
      return {
        ...state,
        isPostingLocationAsset: false,
        postingLocationAssetError: action.payload.error || 'An error occurred',
        postingLocationAssetValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingLocationAssetFailed: true,
      };

    default:
      return state;
  }
};

export default locationAssetsReducer;
