import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import CheckBox from '../../../components/CheckBox';
import Color from '../../../components/Color';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createConfiguration, fetchActiveConfiguration,  updateConfiguration } from '../../../actions/Configuration';
import {
  getActiveConfiguration,
  isPostingConfiguration,
  postingConfigurationError,
  postingConfigurationFailed,
  postingConfigurationValidationErrors,
  isFetchingConfigurations,
} from '../../../selectors/Configuration';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import Button from '../../../components/Button';
const ConfigurationForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const activeConfiguration = useSelector(getActiveConfiguration);
  const isPosting = useSelector(isPostingConfiguration);
  const isFetching = useSelector(isFetchingConfigurations);
  const postingFailed = useSelector(postingConfigurationFailed);
  const postingError = useSelector(postingConfigurationError);
  const postingValidationErrors = useSelector(postingConfigurationValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.CONFIGURATION_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'backgroundColor', validators: [isRequired]  },
    { field: 'foregroundColor', validators: [isRequired]  },
    { field: 'primaryActionColor', validators: [isRequired] },
    { field: 'secondaryActionColor', validators: [isRequired] },
    { field: 'successColor', validators: [isRequired] },
    { field: 'warningColor', validators: [isRequired] },
    { field: 'dropdownColor', validators: [isRequired]  },
    { field: 'primaryTextColor', validators: [isRequired] },
    { field: 'secondaryTextColor', validators: [isRequired] },
    { field: 'primaryLogo', validators: [isRequired]  },
    { field: 'secondaryLogo', validators: [isRequired]  },
    { field: 'favicon', validators: [isRequired]  },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [backgroundColor, setBackgroundColor] = useState('');
  const [foregroundColor, setForegroundColor] = useState('');
  const [primaryActionColor, setPrimaryActionColor] = useState('');
  const [secondaryActionColor, setSecondaryActionColor] = useState('');
  const [successColor, setSuccessColor] = useState('');
  const [warningColor, setWarningColor] = useState('');
  const [dropdownColor, setDropdownColor] = useState('');
  const [primaryTextColor, setPrimaryTextColor] = useState('');
  const [secondaryTextColor, setSecondaryTextColor] = useState('');
  const [primaryLogo, setPrimaryLogo] = useState('');
  const [secondaryLogo, setSecondaryLogo] = useState('');
  const [favicon, setFavicon] = useState('');
  const [allowAnonymousAccess, setAllowAnonymousAccess] = useState(false);
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);


  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    if (editMode) {
      dispatch(fetchActiveConfiguration(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeConfiguration && editMode) {
      setGroupId(activeConfiguration?.groupId);
      setClientId(activeConfiguration?.clientId);
      setBackgroundColor(activeConfiguration?.backgroundColor);
      setForegroundColor(activeConfiguration?.foregroundColor);
      setPrimaryActionColor(activeConfiguration?.primaryActionColor);
      setSecondaryActionColor(activeConfiguration?.secondaryActionColor);
      setSuccessColor(activeConfiguration?.successColor);
      setWarningColor(activeConfiguration?.warningColor);
      setDropdownColor(activeConfiguration?.dropdownColor);
      setPrimaryTextColor(activeConfiguration?.primaryTextColor);
      setSecondaryTextColor(activeConfiguration?.secondaryTextColor);
      setPrimaryLogo(activeConfiguration?.primaryLogo);
      setSecondaryLogo(activeConfiguration?.secondaryLogo);
      setFavicon(activeConfiguration?.favicon);
      // @ts-ignore
      setAllowAnonymousAccess(activeConfiguration?.allowAnonymousAccess === 1 || activeConfiguration?.allowAnonymousAccess === true);
      // @ts-ignore
      setActive(activeConfiguration?.active === 1 || activeConfiguration?.active === true);
    }
  }, [activeConfiguration]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.CONFIGURATION);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createConfiguration(groupId, clientId, backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon, allowAnonymousAccess, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateConfiguration( activeId, groupId, clientId, backgroundColor,
        foregroundColor,
        primaryActionColor,
        secondaryActionColor,
        successColor,
        warningColor,
        dropdownColor,
        primaryTextColor,
        secondaryTextColor,
        primaryLogo,
        secondaryLogo,
        favicon, allowAnonymousAccess, active));
    }
  };

  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);

  };



  return <View>
        <h4>{editMode ? 'Edit' : 'Create'} Configuration</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>

            <DropDown v={v} err={err}
                      id="groupId"
                      placeholder="Please select a Group"
                      required label="Group"
                      items={itemsGroups}
                      value={groupId}
                      disabled={isPosting || isFetching}
                      onSelect={onGroupSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="clientId"
                      placeholder="Please select a Client"
                      required label="Client"
                      items={itemsClients}
                      value={clientId}
                      disabled={isPosting || isFetching || disabledClient}
                      onSelect={onClientSelect}
                      type="default"
            />



            <Color v={v} err={err}
                   name="backgroundColor"
                   id="backgroundColor"
                   label="BackgroundColor"
                   required
                   value={backgroundColor}
                   onChange={setBackgroundColor}
                   disabled={isPosting || isFetching}
                   onBlur={setBackgroundColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="foregroundColor"
                   id="foregroundColor"
                   label="Foreground Color"
                   required
                   value={foregroundColor}
                   onChange={setForegroundColor}
                   disabled={isPosting || isFetching}
                   onBlur={setForegroundColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="primaryActionColor"
                   id="primaryActionColor"
                   label="Primary Action Color"
                   required
                   value={primaryActionColor}
                   onChange={setPrimaryActionColor}
                   disabled={isPosting || isFetching}
                   onBlur={setPrimaryActionColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="secondaryActionColor"
                   id="secondaryActionColor"
                   label="Secondary Action Color"
                   required
                   value={secondaryActionColor}
                   onChange={setSecondaryActionColor}
                   disabled={isPosting || isFetching}
                   onBlur={setSecondaryActionColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="successColor"
                   id="successColor"
                   label="Success Color"
                   required
                   value={successColor}
                   onChange={setSuccessColor}
                   disabled={isPosting || isFetching}
                   onBlur={setSuccessColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="warningColor"
                   id="warningColor"
                   label="Warning Color"
                   required
                   value={warningColor}
                   onChange={setWarningColor}
                   disabled={isPosting || isFetching}
                   onBlur={setWarningColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="dropdownColor"
                   id="dropdownColor"
                   label="Dropdown Color"
                   required
                   value={dropdownColor}
                   onChange={setDropdownColor}
                   disabled={isPosting || isFetching}
                   onBlur={setDropdownColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="primaryTextColor"
                   id="primaryTextColor"
                   label="Primary Text Color"
                   required
                   value={primaryTextColor}
                   onChange={setPrimaryTextColor}
                   disabled={isPosting || isFetching}
                   onBlur={setPrimaryTextColor}
                   placeholder="Pick a color"
            />

            <Color v={v} err={err}
                   name="secondaryTextColor"
                   id="secondaryTextColor"
                   label="Secondary Text Color"
                   required
                   value={secondaryTextColor}
                   onChange={setSecondaryTextColor}
                   disabled={isPosting || isFetching}
                   onBlur={setSecondaryTextColor}
                   placeholder="Pick a color"
            />

            <Input v={v} err={err}
                   name="primaryLogo"
                   id="primaryLogo"
                   label="Primary Logo"
                   required
                   value={primaryLogo}
                   onChange={setPrimaryLogo}
                   disabled={isPosting || isFetching}
                   onBlur={setPrimaryLogo}
                   placeholder="Insert a title"
            />

            <Input v={v} err={err}
                   name="secondaryLogo"
                   id="secondaryLogo"
                   label="Secondary Logo"
                   required
                   value={secondaryLogo}
                   onChange={setSecondaryLogo}
                   disabled={isPosting || isFetching}
                   onBlur={setSecondaryLogo}
                   placeholder="Insert a title"
            />

            <Input v={v} err={err}
                   name="favicon"
                   id="favicon"
                   label="Favicon"
                   required
                   value={favicon}
                   onChange={setFavicon}
                   disabled={isPosting || isFetching}
                   onBlur={setFavicon}
                   placeholder="Insert a title"
            />

            <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => editMode ? onSave() : onSubmit()}
                            title={editMode ? 'SAVE' : 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default ConfigurationForm;