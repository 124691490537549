import { useEffect, useState } from 'react';
import { FormError } from '../types/Common';

export type Value = string | number | null | undefined | boolean;

export type Validator = (value: Value) => boolean | string;

type Field = {
  field: string;
  validators: Validator[];
  value?: Value;
  isValid?: boolean;
  touched?: boolean;
  errors?: string[];
};

export const useValidation = (config: Field[], serverValidationErrors: FormError[] | null) => {

  const [formTouched, setFormTouched] = useState<boolean>(false);
  const [fields, setFields] = useState<Field[]>(config);

  useEffect(() => {
    if (serverValidationErrors && serverValidationErrors?.length > 0) {
      serverValidationErrors.forEach(e => {
        const localFields = [...fields];
        const index = localFields.findIndex((fie: Field) => fie.field === e.field);
        localFields[index].errors = e.errors;
        setFields(localFields);
      });
    }
  }, [serverValidationErrors]);

  const validate = (field: string, value: Value) => {

    if (!fields || fields?.length === 0) {
      return;
    }

    if (!formTouched) {
      setFormTouched(true);
    }

    const localErrors: string[] = [];

    const localFields = [...fields];

    const index = localFields.findIndex((fie: Field) => fie.field === field);
    const f = localFields[index];
    localFields[index].value = value;
    f?.validators.forEach(validator => {
      const test = validator(value);
      if (typeof test === 'string') {
        localErrors.push(test);
      }
    });

    localFields[index].errors = localErrors;
    setFields(localFields);
  };

  const err = (field: string) => {
    const f = fields.find(fie => fie.field === field);
    return f?.errors || [];
  };

  const v = (field: string, value: Value) => {
    validate(field, value);
  };

  const isFormValid = () => {
    let isValid = true;
    fields.forEach(f => {
      if (f.errors && f.errors?.length > 0) {
        isValid = false;
      }
    });
    return isValid && formTouched;
  };

  const triggerValidation = () => {
    fields.forEach(f => {
      validate(f.field, f.value);
    });
    return isFormValid();
  };

  return [v, triggerValidation, isFormValid, err] as const;
};
