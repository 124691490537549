import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActivityAssets } from '../../../selectors/ActivityAsset';
import { destroyActivityAsset, fetchActivityAssets } from '../../../actions/ActivityAsset';
import { ActivityAsset } from '../../../types/ActivityAsset';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';



const ActivityAssetDataTable = () => {

  const activityAssets = useSelector(getActivityAssets);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [activityAsset, setActiveActivityAsset] = useState<ActivityAsset | null>();


  useEffect(() => {
    dispatch(fetchActivityAssets());

  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACTIVITY_ASSET_CREATE);
  };



  const onDelete = (row: ActivityAsset) => {
    setActiveActivityAsset(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (activityAsset?.id) {
      dispatch(destroyActivityAsset(activityAsset?.id));
    }
    setActiveActivityAsset(null);
  };


  const config = {
    columns: [
      { key: 'activityTitle', label: 'Activity ', mobileFriendly: true },
      { key: 'assetPath', label: 'Asset', mobileFriendly: true },

    ],
    actions: [

      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ACTIVITY/ASSETS" ctaTitle="Link Activity/Asset" onCreate={onCreate} data={activityAssets?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Activity/Asset" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Activity/Asset link?
            </Modal>

        </View>
  );
};

export default ActivityAssetDataTable;
