import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/Button';
import { Alert } from 'react-bootstrap';
import DropDown from '../../components/DropDown';
import Input from '../../components/Input';
import DropDownSearchable from '../../components/DropdownSearchable/DropDownSearchable';
import CheckBox from '../../components/CheckBox';
import Modal from '../../components/Modal';
import './Checkout.scss';

import {
  isFetchingCustomers,
  isPostingCustomer,
  postingCustomerError,
  postingCustomerFailed,
  postingCustomerValidationErrors,
} from '../../selectors/Customer';
import { isRequired } from '../../helpers/validators';
import { useValidation } from '../../hooks/ValidationHook';
import countriesjson from '../../assets/json/countries_output.json';
import citiesjson from '../../assets/json/countries_cities2.json';
import { createCustomer } from '../../actions/Customer';
import { fetchUserCustomers, fetchUsers } from '../../actions/User';
import { getUserToken } from '../../helpers/functions';
// @ts-ignore
import titlesJson from '../../assets/json/titles.json';

const Checkout = () => {
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [isCountry, setIsCountry] = useState(true);
  const [itemsCities, setItemCities] = useState([{ 'label': 'Select a country', 'value': 'select a country' }]);
  const postingError = useSelector(postingCustomerError);
  const postingFailed = useSelector(postingCustomerFailed);
  const postingValidationErrors = useSelector(postingCustomerValidationErrors);
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingCustomer);
  const isFetching = useSelector(isFetchingCustomers);

  const [userId, setUserId] = useState(getUserToken());
  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [active, setActive] = useState(true);

  const formConfig = [
    { field: 'title', validators: [isRequired] },
    { field: 'email', validators: [isRequired] },
    { field: 'firstName', validators: [isRequired] },
    { field: 'lastName', validators: [isRequired] },
    { field: 'phone', validators: [isRequired] },
    { field: 'address', validators: [isRequired] },
    { field: 'city', validators: [isRequired] },
    { field: 'country', validators: [isRequired] },
    { field: 'postcode', validators: [] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);


  const showModal = () => {
    setToggle(true);
  };

  const dismissModal = () => {
    setToggle(false);
  };


  const onTitleSelect = (obj: any) => {
    setTitle(obj.value);
  };

  const onCountrySelect = (obj: any) => {
    setItemCities(citiesjson[obj.value as keyof typeof citiesjson]);
    setCountry(obj.value);
    setIsCountry(false);
  };
  const onCitySelect = (obj: any) => {
    setCity(obj.value);
  };

  const resetForm = () => {
    setUserId('');
    setTitle('');
    setEmail('');
    setFirstName('');
    setLastName('');
    setPhone('');
    setAddress('');
    setCity('');
    setPostcode('');
    setCountry('');
    setActive(true);
  };

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchUserCustomers(getUserToken()));
  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      dispatch(fetchUserCustomers(getUserToken()));
      setHasSubmitted(false);
      resetForm();
      dismissModal();
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      dispatch(createCustomer(userId, title, email, firstName, lastName, phone, address, city, postcode, country, active, false));
      setHasSubmitted(true);
    }
  };

  const itemsTitles = titlesJson?.data.map(r => ({ label: r.label, value: r.value })) || [];
  const itemsCountries = countriesjson?.data.map(r => ({ label: r.label, value: r.value })) || [];
  const itemsCitiesUse = itemsCities?.map(r => ({ label: r.label, value: r.value })) || [];

  return (<>
      <Button title="ADD A CUSTOMER" onClick={showModal} />
      <Modal show={toggle} onHide={dismissModal}>
            <h4>{'Create'} Customer</h4>
            {postingError && (
                <Alert className="mt-3" variant="danger">
                    {postingError}
                </Alert>
            )}
            <form>
                <DropDown v={v} err={err}
                          id="title"
                          placeholder="Please select a title"
                          label="Title"
                          items={itemsTitles}
                          value={title}
                          disabled={isPosting || isFetching}
                          onSelect={onTitleSelect}
                          type="default"
                />
                <Input v={v} err={err}
                       name="email"
                       id="email"
                       label="Email"
                       required
                       value={email}
                       onChange={setEmail}
                       disabled={isPosting || isFetching}
                       onBlur={setEmail}
                       placeholder="Insert an email"
                />
                <Input v={v} err={err}
                       required
                       name="firstName"
                       id="firstName"
                       label="First Name"
                       value={firstName}
                       disabled={isPosting || isFetching}
                       onChange={setFirstName}
                       onBlur={setFirstName}
                       placeholder="Insert first name"
                />
                <Input v={v} err={err}
                       name="lastName"
                       id="lastName"
                       label="Last Name"
                       required
                       value={lastName}
                       disabled={isPosting || isFetching}
                       onChange={setLastName}
                       onBlur={setLastName}
                       placeholder="Insert last name"
                />
                <Input v={v} err={err}
                       name="phone"
                       id="phone"
                       label="Phone"
                       required
                       value={phone}
                       disabled={isPosting || isFetching}
                       onChange={setPhone}
                       onBlur={setPhone}
                       placeholder="Insert a phone number"
                />
                <Input v={v} err={err}
                       name="address"
                       id="address"
                       label="Address"
                       required
                       value={address}
                       disabled={isPosting || isFetching}
                       onChange={setAddress}
                       onBlur={setAddress}
                       placeholder="Insert an address"
                />

                <DropDownSearchable v={v} err={err}
                                    id="country"
                                    placeholder="Please select a country"
                                    required label="Country"
                                    items={itemsCountries}
                                    value={country}
                                    disabled={isPosting || isFetching}
                                    onSelect={onCountrySelect}
                                    type="default"
                />

                <DropDownSearchable v={v} err={err}
                                    id="city"
                                    placeholder="Please select a city"
                                    required label="City"
                                    items={itemsCitiesUse}
                                    value={city}
                                    disabled={isPosting || isFetching || isCountry}
                                    onSelect={onCitySelect}
                                    type="default"
                />

                <Input v={v} err={err}
                       name="postcode"
                       id="postcode"
                       label="Postcode"
                       value={postcode}
                       disabled={isPosting || isFetching}
                       onChange={setPostcode}
                       onBlur={setPostcode}
                       placeholder="Insert a Postcode"
                />

                <CheckBox label="Is Active?" checked={true} onChange={setActive} />
                <div className="row">
                    <div className="col-md-4">
                        <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                onClick={() => onSubmit()}
                                title={'CREATE'} />
                    </div>
                </div>
            </form>
        </Modal>
      </>);
};

export default Checkout;
