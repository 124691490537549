import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAccountUser   } from '../../../actions/AccountUser';
import { getAccounts } from '../../../selectors/Account';
import { getUsers } from '../../../selectors/User';
import { fetchAccounts } from '../../../actions/Account';
import { fetchUsers } from '../../../actions/User';


import {

  isPostingAccountUser,
  postingAccountUserError,
  postingAccountUserFailed,
  postingAccountUserValidationErrors,
  isFetchingAccountUsers,
} from '../../../selectors/AccountUser';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const AccountUserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const users = useSelector(getUsers);
  const isPosting = useSelector(isPostingAccountUser);
  const isFetching = useSelector(isFetchingAccountUsers);
  const postingFailed = useSelector(postingAccountUserFailed);
  const postingError = useSelector(postingAccountUserError);
  const postingValidationErrors = useSelector(postingAccountUserValidationErrors);



  const formConfig = [
    { field: 'accountId', validators: [isRequired] },
    { field: 'userId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [accountId, setAccountId] = useState('');
  const [userId, setUserId] = useState('');

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchUsers());

  }, [dispatch]);





  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACCOUNT_USER);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAccountUser(accountId, userId ));
    }
  };



  const itemsAccounts = accounts?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsUsers = users?.data.map(r => ({ label: r.email, value: r.id })) || [];

  const onUserSelect = (obj: any) => {
    setUserId(obj.value);
  };
  const onAccountSelect = (obj: any) => {
    setAccountId(obj.value);
  };

  return <View>
        <h4>Create AccountUser</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="accountId"
                      placeholder="Please select an Account"
                      required label="Account"
                      items={itemsAccounts}
                      value={accountId}
                      disabled={isPosting || isFetching}
                      onSelect={onAccountSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="userId"
                      placeholder="Please select a User"
                      required label="User"
                      items={itemsUsers}
                      value={userId}
                      disabled={isPosting || isFetching}
                      onSelect={onUserSelect}
                      type="default"
            />


            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default AccountUserForm;



