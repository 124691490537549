import { ApiDataModel, FormError } from './Common';

export const FETCH_ACCOUNT_CLIENTS_REQUEST = 'FETCH_ACCOUNT_CLIENTS_REQUEST';
export const FETCH_ACCOUNT_CLIENTS_RESPONSE = 'FETCH_ACCOUNT_CLIENTS_RESPONSE';
export const FETCH_ACCOUNT_CLIENTS_FAILURE = 'FETCH_ACCOUNT_CLIENTS_FAILURE';

export const FETCH_ACCOUNT_CLIENT_REQUEST = 'FETCH_ACCOUNT_CLIENT_REQUEST';
export const FETCH_ACCOUNT_CLIENT_RESPONSE = 'FETCH_ACCOUNT_CLIENT_RESPONSE';
export const FETCH_ACCOUNT_CLIENT_FAILURE = 'FETCH_ACCOUNT_CLIENT_FAILURE';

export const POST_ACCOUNT_CLIENT_REQUEST = 'POST_ACCOUNT_CLIENT_REQUEST';
export const POST_ACCOUNT_CLIENT_RESPONSE = 'POST_ACCOUNT_CLIENT_RESPONSE';
export const POST_ACCOUNT_CLIENT_FAILURE = 'POST_ACCOUNT_CLIENT_FAILURE';

export interface AccountClient {
  id: string;
  clientdId: string;
  accountId: string;
}

export interface AccountClientDataModel extends Omit<ApiDataModel, 'data'> {
  data: AccountClient[];
}

export interface AccountClientState {
  accountClients: AccountClientDataModel | null,
  activeAccountClient: AccountClient | null,
  isFetchingAccountClients: boolean;
  fetchingAccountClientsFailed: boolean;
  postingAccountClientFailed: boolean;
  isPostingAccountClient: boolean;
  postingAccountClientValidationErrors: FormError[] | null;
  postingAccountClientError: string | null;
}

interface FetchAccountClientsRequestAction {
  type: typeof FETCH_ACCOUNT_CLIENTS_REQUEST;
  payload: null;
}

interface FetchAccountClientsResponseAction {
  type: typeof FETCH_ACCOUNT_CLIENTS_RESPONSE;
  payload: AccountClientDataModel;
}

interface FetchAccountClientsFailureAction {
  type: typeof FETCH_ACCOUNT_CLIENTS_FAILURE;
  payload: null;
}

interface FetchAccountClientRequestAction {
  type: typeof FETCH_ACCOUNT_CLIENT_REQUEST;
  payload: null;
}

interface FetchAccountClientResponseAction {
  type: typeof FETCH_ACCOUNT_CLIENT_RESPONSE;
  payload: AccountClient;
}

interface FetchAccountClientFailureAction {
  type: typeof FETCH_ACCOUNT_CLIENT_FAILURE;
  payload: null;
}

interface PostAccountClientRequestAction {
  type: typeof POST_ACCOUNT_CLIENT_REQUEST;
  payload: null;
}

interface PostAccountClientResponseAction {
  type: typeof POST_ACCOUNT_CLIENT_RESPONSE;
  payload: null;
}

interface PostAccountClientFailureAction {
  type: typeof POST_ACCOUNT_CLIENT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AccountClientActionTypes =
    | FetchAccountClientsRequestAction
    | FetchAccountClientsResponseAction
    | FetchAccountClientsFailureAction
    | PostAccountClientRequestAction
    | PostAccountClientResponseAction
    | PostAccountClientFailureAction
    | FetchAccountClientRequestAction
    | FetchAccountClientResponseAction
    | FetchAccountClientFailureAction;
