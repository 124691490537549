import { isNumber as lodashIsNumber } from 'lodash';

export const validationMessages = {
  REQUIRED_MSG: 'This field is required.',
  EMAIL_MSG: 'This field needs to be a valid email address.',
  NUMERIC_MSG: 'This fields needs to be a number',
};

export const isNotEmpty = (val: string | number | null | undefined | boolean) => val !== null && val !== undefined && val !== '';

export const isEmail = (val: string | number | null | undefined | boolean) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(`${val}`);

export const isRequired = (val: string | number | null | undefined | boolean) => isNotEmpty(val) ? true : validationMessages.REQUIRED_MSG;

export const isValidEmail = (val:  string | number | null | undefined | boolean) => isEmail(val) ? true : validationMessages.EMAIL_MSG;

export const isNumber = (val:  string | number | null | undefined | boolean) => lodashIsNumber(val) ? true : validationMessages.NUMERIC_MSG;
