import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createLocationAsset } from '../../../actions/LocationAsset';
import { fetchLocations } from '../../../actions/Location';

import {

  isPostingLocationAsset,
  postingLocationAssetError,
  postingLocationAssetFailed,
  postingLocationAssetValidationErrors,
  isFetchingLocationAssets,
} from '../../../selectors/LocationAsset';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { getAssets } from '../../../selectors/Asset';
import { getLocations } from '../../../selectors/Location';
import { fetchAssets } from '../../../actions/Asset';



const LocationAssetForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const locations = useSelector(getLocations);
  const assets = useSelector(getAssets);
  const isPosting = useSelector(isPostingLocationAsset);
  const isFetching = useSelector(isFetchingLocationAssets);
  const postingFailed = useSelector(postingLocationAssetFailed);
  const postingError = useSelector(postingLocationAssetError);
  const postingValidationErrors = useSelector(postingLocationAssetValidationErrors);



  const formConfig = [
    { field: 'locationId', validators: [isRequired] },
    { field: 'assetId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [locationId, setLocationId] = useState('');
  const [assetId, setAssetId] = useState('');


  useEffect(() => {
    dispatch(fetchLocations());
    dispatch(fetchAssets());

  }, [dispatch]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.LOCATION_ASSET);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createLocationAsset(locationId, assetId ));
    }
  };



  const itemsLocations = locations?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsAssets = assets?.data.map(r => ({ label: r.title, value: r.id })) || [];
  console.log(assets?.data);


  const onLocationSelect = (obj: any) => {
    setLocationId(obj.value);
  };
  const onAssetSelect = (obj: any) => {
    setAssetId(obj.value);
    console.log(obj.value);
  };

  return <View>
        <h4>{'Create'} LocationAsset</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="locationId"
                      placeholder="Please select an Location "
                      required label="Location "
                      items={itemsLocations}
                      value={locationId}
                      disabled={isPosting || isFetching}
                      onSelect={onLocationSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="assetId"
                      placeholder="Please select an Asset"
                      required label="Asset"
                      items={itemsAssets}
                      value={assetId}
                      disabled={isPosting || isFetching}
                      onSelect={onAssetSelect}
                      type="default"
            />


            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={ 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default LocationAssetForm;




