import { ApiDataModel, FormError } from './Common';
import { ActivitySlot } from './ActivitySlot';

export const FETCH_ORDERS_REQUEST = 'FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_RESPONSE = 'FETCH_ORDERS_RESPONSE';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';

export const FETCH_ORDER_REQUEST = 'FETCH_ORDER_REQUEST';
export const FETCH_ORDER_RESPONSE = 'FETCH_ORDER_RESPONSE';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';

export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export const POST_ORDER_RESPONSE = 'POST_ORDER_RESPONSE';
export const POST_ORDER_FAILURE = 'POST_ORDER_FAILURE';

export const SET_CART = 'SET_CART';

// check the data I gave you are correct
// make a request to pay to Adyen
// if payment is successful issue a new booking reference
// write the orders in the database
// return the booking reference

export interface Order {
  id: string; // ticket 2, ticket 3
  bookingReference: string, // BYA234
  customerId: string, // John Ropas
  customerEmail: string,
  customerFirstName: string,
  customerLastName: string,
  customerCity: string,
  customerCountry: string,
  instructorFirstName: string,
  instructorLastName: string,
  groupId: string,
  clientId: string,
  locationId: string,
  activityId: string,
  activityTitle: string,
  activityCurrency: string,
  activitySlotId: string,
  activitySlotDay: number,
  activitySlotStartTime: string;
  activitySlotEndTime: string,
  userId: string,
  subtotal: number,
  vat: number,
  total: number
  created: Date;
  updated: Date;
}

export interface CCDetails {
  cardHolder: string
  cardNumber: string;
  cardCVV: string;
  cardExpiryDate: string;
}

export interface CartItem {
  localId?: string,
  customerId?: string;
  activitySlot: ActivitySlot;
  selectedDate: Date;
}

export interface OrderDataModel extends Omit<ApiDataModel, 'data'> {
  data: Order[];
}

export interface OrderState {
  cartItems: CartItem[],
  orders: OrderDataModel | null,
  activeOrder: Order | null,
  isFetchingOrders: boolean;
  fetchingOrdersFailed: boolean;
  postingOrderFailed: boolean;
  isPostingOrder: boolean;
  postingOrderValidationErrors: FormError[] | null;
  postingOrderError: string | null;
}


interface FetchOrdersRequestAction {
  type: typeof FETCH_ORDERS_REQUEST;
  payload: null;
}

interface FetchOrdersResponseAction {
  type: typeof FETCH_ORDERS_RESPONSE;
  payload: OrderDataModel;
}

interface FetchOrdersFailureAction {
  type: typeof FETCH_ORDERS_FAILURE;
  payload: null;
}

interface FetchOrderRequestAction {
  type: typeof FETCH_ORDER_REQUEST;
  payload: null;
}

interface FetchOrderResponseAction {
  type: typeof FETCH_ORDER_RESPONSE;
  payload: Order;
}

interface FetchOrderFailureAction {
  type: typeof FETCH_ORDER_FAILURE;
  payload: null;
}

interface PostOrderRequestAction {
  type: typeof POST_ORDER_REQUEST;
  payload: null;
}

interface PostOrderResponseAction {
  type: typeof POST_ORDER_RESPONSE;
  payload: Order[];
}

interface PostOrderFailureAction {
  type: typeof POST_ORDER_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

interface SetCartAction {
  type: typeof SET_CART;
  payload: {
    cartItems: CartItem[]
  };
}

export type OrderActionTypes =
    | FetchOrdersRequestAction
    | FetchOrdersResponseAction
    | FetchOrdersFailureAction
    | PostOrderRequestAction
    | PostOrderResponseAction
    | PostOrderFailureAction
    | FetchOrderRequestAction
    | FetchOrderResponseAction
    | FetchOrderFailureAction
    | SetCartAction;
