import { ApiDataModel, FormError } from './Common';

export const FETCH_LOCATIONS_REQUEST = 'FETCH_LOCATIONS_REQUEST';
export const FETCH_LOCATIONS_RESPONSE = 'FETCH_LOCATIONS_RESPONSE';
export const FETCH_LOCATIONS_FAILURE = 'FETCH_LOCATIONS_FAILURE';

export const FETCH_LOCATION_REQUEST = 'FETCH_LOCATION_REQUEST';
export const FETCH_LOCATION_RESPONSE = 'FETCH_LOCATION_RESPONSE';
export const FETCH_LOCATION_FAILURE = 'FETCH_LOCATION_FAILURE';

export const POST_LOCATION_REQUEST = 'POST_LOCATION_REQUEST';
export const POST_LOCATION_RESPONSE = 'POST_LOCATION_RESPONSE';
export const POST_LOCATION_FAILURE = 'POST_LOCATION_FAILURE';

export interface Location {
  id: string;
  groupId: string;
  clientId: string;
  title: string;
  description: string;
  address: string;
  city: string;
  postcode: string;
  country: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface LocationDataModel extends Omit<ApiDataModel, 'data'> {
  data: Location[];
}

export interface LocationState {
  locations: LocationDataModel | null,
  activeLocation: Location | null,
  isFetchingLocations: boolean;
  fetchingLocationsFailed: boolean;
  postingLocationFailed?: boolean;
  isPostingLocation: boolean;
  postingLocationValidationErrors: FormError[] | null;
  postingLocationError: string | null;
}

interface FetchLocationsRequestAction {
  type: typeof FETCH_LOCATIONS_REQUEST;
  payload: null;
}

interface FetchLocationsResponseAction {
  type: typeof FETCH_LOCATIONS_RESPONSE;
  payload: LocationDataModel;
}

interface FetchLocationsFailureAction {
  type: typeof FETCH_LOCATIONS_FAILURE;
  payload: null;
}

interface FetchLocationRequestAction {
  type: typeof FETCH_LOCATION_REQUEST;
  payload: null;
}

interface FetchLocationResponseAction {
  type: typeof FETCH_LOCATION_RESPONSE;
  payload: Location;
}

interface FetchLocationFailureAction {
  type: typeof FETCH_LOCATION_FAILURE;
  payload: null;
}

interface PostLocationRequestAction {
  type: typeof POST_LOCATION_REQUEST;
  payload: null;
}

interface PostLocationResponseAction {
  type: typeof POST_LOCATION_RESPONSE;
  payload: null;
}

interface PostLocationFailureAction {
  type: typeof POST_LOCATION_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type LocationActionTypes =
    | FetchLocationsRequestAction
    | FetchLocationsResponseAction
    | FetchLocationsFailureAction
    | PostLocationRequestAction
    | PostLocationResponseAction
    | PostLocationFailureAction
    | FetchLocationRequestAction
    | FetchLocationResponseAction
    | FetchLocationFailureAction;
