import React, { useEffect, useState } from 'react';
import './Input.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Validator } from '../../hooks/ValidationHook';

type Props = {
  value?: string | number | null;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  type?: 'number' | 'text' | 'password';
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any,
  validators?: Validator[]
  errors?: string[];
};

const TelephoneInput = (props: Props) => {
  const {
    onBlur = () => { },
    onChange = () => { },
    disabled = false,
    required = false,
    placeholder = '',
    value = '',
    name = '',
    id = '',
    label = '',
    err = () => [],
    bgColor = 'grey',
    type = 'text',
    v = () => {},
  } = props;

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);
  const formatPhoneNumber = (some_value: string)=>{

    return some_value;

  };

  const innerOnChange = (e: any) => {
    setTouched(true);
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);

    onChange(formattedPhoneNumber);
    if (v && typeof v === 'function') {
      v(id, formattedPhoneNumber);
    }
  };
  const innerOnBlur = (e: any) => {
    setTouched(true);
    onBlur(e.target.value);
    if (v && typeof v === 'function') {
      v(id, e.target.value);
    }
  };

  const errors = err(id);

  return (
    <div className="ems-input my-3">
      {label && <label htmlFor={id} className={`form-label ${errors.length > 0 && 'error-lbl'}`}>{label}{required && '*'}</label>}
      <input disabled={disabled} type={type} required={required} className={`form-control ${bgColor} ${errors.length > 0 && 'error'}`} value={value || ''} name={name} id={id}
        onBlur={innerOnBlur}
        onChange={innerOnChange}
        placeholder={placeholder}
      />
      {errors.map((e: string, i: number) => (<div className="error-lbl" key={i}>{e}</div>))}
    </div>
  );
};

export default TelephoneInput;

