import React from 'react';
import { Switch, Route } from 'react-router-dom';
import c from '../../helpers/constants';
import RoleDataTable from '../../views/admin/role/RoleDataTable';
import RoleForm from '../../views/admin/role/RoleForm';
import ClientDataTable from '../../views/admin/client/ClientDataTable';
import ClientForm from '../../views/admin/client/ClientForm';
import InstructorDataTable from '../../views/admin/instructor/InstructorDataTable';
import InstructorForm from '../../views/admin/instructor/InstructorForm';
import GroupDataTable from '../../views/admin/group/GroupDataTable';
import GroupForm from '../../views/admin/group/GroupForm';
import UserDataTable from '../../views/admin/user/UserDataTable';
import UserForm from '../../views/admin/user/UserForm';
import UserLogin from '../../views/admin/user/UserLogin';
import UserRegister from '../../views/admin/user/UserRegister';
import UserPendingVerification from '../../views/admin/user/UserPendingVerification';
import RequestResetPassword from '../../views/admin/user/RequestResetPassword';
import ResetPassword from '../../views/admin/user/ResetPassword';
import LocationDataTable from '../../views/admin/location/LocationDataTable';
import LocationForm from '../../views/admin/location/LocationForm';
import CustomerDataTable from '../../views/admin/customer/CustomerDataTable';
import CustomerForm from '../../views/admin/customer/CustomerForm';
import ActivitySlotDataTable from '../../views/admin/activitySlot/ActivitySlotDataTable';
import ActivitySlotForm from '../../views/admin/activitySlot/ActivitySlotForm';
import AssetDataTable from '../../views/admin/asset/AssetDataTable';
import AssetForm from '../../views/admin/asset/AssetForm';
import OrderDataTable from '../../views/admin/order/OrderDataTable';
import AccountDataTable from '../../views/admin/account/AccountDataTable';
import AccountForm from '../../views/admin/account/AccountForm';
import AccountClientDataTable from '../../views/admin/accountClient/AccountClientDataTable';
import AccountClientForm from '../../views/admin/accountClient/AccountClientForm';
import ActivityDataTable from '../../views/admin/activity/ActivityDataTable';
import ActivityForm from '../../views/admin/activity/ActivityForm';
import Cart from '../../views/cart';
import Checkout from '../../views/checkout';
import CheckoutSuccess from '../../views/checkout/CheckoutSuccess';
import CheckoutFailure from '../../views/checkout/CheckoutFailure';
import Search from '../../views/search';
import Profile from '../../views/profile/Profile';
import UserCustomers from '../../views/profile/UserCustomers';
import AccountUserDataTable from '../../views/admin/accountUser/AccountUserDataTable';
import AccountUserForm from '../../views/admin/accountUser/AccountUserForm';
import AccountGroupDataTable from '../../views/admin/accountGroup/AccountGroupDataTable';
import AccountGroupForm from '../../views/admin/accountGroup/AccountGroupForm';
import RoleGroupDataTable from '../../views/admin/roleGroup/RoleGroupDataTable';
import RoleGroupForm from '../../views/admin/roleGroup/RoleGroupForm';
import RoleAccountDataTable from '../../views/admin/roleAccount/RoleAccountDataTable';
import RoleAccountForm from '../../views/admin/roleAccount/RoleAccountForm';
import RoleClientDataTable from '../../views/admin/roleClient/RoleClientDataTable';
import RoleClientForm from '../../views/admin/roleClient/RoleClientForm';
import ActivitySlotInstructorDataTable from '../../views/admin/activitySlotInstructor/ActivitySlotInstructorDataTable';
import ActivitySlotInstructorForm from '../../views/admin/activitySlotInstructor/ActivitySlotInstructorForm';
import ActivityAssetDataTable from '../../views/admin/activityAsset/ActivityAssetDataTable';
import ActivityAssetForm from '../../views/admin/activityAsset/ActivityAssetForm';
import LocationAssetDataTable from '../../views/admin/locationAsset/LocationAssetDataTable';
import LocationAssetForm from '../../views/admin/locationAsset/LocationAssetForm';
import ConfigurationDataTable from '../../views/admin/configuration/ConfigurationDataTable';
import ConfigurationForm from '../../views/admin/configuration/ConfigurationForm';

const Router = () => {
  return (
        <Switch>
            <Route path={c.APP_ROUTES.CHECKOUT} exact component={Checkout}/>
            <Route path={c.APP_ROUTES.CHECKOUT_SUCCESS} exact component={CheckoutSuccess}/>
            <Route path={c.APP_ROUTES.CHECKOUT_FAILURE} exact component={CheckoutFailure}/>
            <Route path={c.APP_ROUTES.CLIENT} exact component={ClientDataTable}/>
            <Route path={c.APP_ROUTES.CLIENT_CREATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.CLIENT_UPDATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.ROLE} exact component={RoleDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_CREATE} exact component={RoleForm}/>
            <Route path={c.APP_ROUTES.ROLE_UPDATE} exact component={RoleForm}/>
            <Route path={c.APP_ROUTES.ROLE_GROUP} exact component={RoleGroupDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_GROUP_CREATE} exact component={RoleGroupForm}/>
            <Route path={c.APP_ROUTES.ROLE_ACCOUNT} exact component={RoleAccountDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_ACCOUNT_CREATE} exact component={RoleAccountForm}/>
            <Route path={c.APP_ROUTES.ROLE_CLIENT} exact component={RoleClientDataTable}/>
            <Route path={c.APP_ROUTES.ROLE_CLIENT_CREATE} exact component={RoleClientForm}/>
            <Route path={c.APP_ROUTES.CLIENT} exact component={ClientDataTable}/>
            <Route path={c.APP_ROUTES.CLIENT_CREATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.CLIENT_UPDATE} exact component={ClientForm}/>
            <Route path={c.APP_ROUTES.INSTRUCTOR} exact component={InstructorDataTable}/>
            <Route path={c.APP_ROUTES.INSTRUCTOR_CREATE} exact component={InstructorForm}/>
            <Route path={c.APP_ROUTES.INSTRUCTOR_UPDATE} exact component={InstructorForm}/>
            <Route path={c.APP_ROUTES.GROUP} exact component={GroupDataTable}/>
            <Route path={c.APP_ROUTES.GROUP_CREATE} exact component={GroupForm}/>
            <Route path={c.APP_ROUTES.GROUP_UPDATE} exact component={GroupForm}/>
            <Route path={c.APP_ROUTES.USER} exact component={UserDataTable}/>
            <Route path={c.APP_ROUTES.USER_CREATE} exact component={UserForm}/>
            <Route path={c.APP_ROUTES.USER_UPDATE} exact component={UserForm}/>
            <Route path={c.APP_ROUTES.USER_LOGIN} exact component={UserLogin}/>
            <Route path={c.APP_ROUTES.USER_REGISTER} exact component={UserRegister}/>
            <Route path={c.APP_ROUTES.USER_PENDING_VERIFICATION} exact component={UserPendingVerification}/>
            <Route path={c.APP_ROUTES.FORGOT_PASSWORD} exact component={RequestResetPassword}/>
            <Route path={c.APP_ROUTES.RESET_PASSWORD} exact component={ResetPassword}/>
            <Route path={c.APP_ROUTES.PROFILE} exact component={Profile}/>
            <Route path={c.APP_ROUTES.PROFILE_CUSTOMERS} exact component={UserCustomers}/>
            <Route path={c.APP_ROUTES.LOCATION} exact component={LocationDataTable}/>
            <Route path={c.APP_ROUTES.LOCATION_CREATE} exact component={LocationForm}/>
            <Route path={c.APP_ROUTES.LOCATION_UPDATE} exact component={LocationForm}/>
            <Route path={c.APP_ROUTES.LOCATION_ASSET} exact component={LocationAssetDataTable}/>
            <Route path={c.APP_ROUTES.LOCATION_ASSET_CREATE} exact component={LocationAssetForm}/>
            <Route path={c.APP_ROUTES.CUSTOMER} exact component={CustomerDataTable}/>
            <Route path={c.APP_ROUTES.CUSTOMER_CREATE} exact component={CustomerForm}/>
            <Route path={c.APP_ROUTES.CUSTOMER_UPDATE} exact component={CustomerForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY} exact component={ActivityDataTable}/>
            <Route path={c.APP_ROUTES.ACTIVITY_CREATE} exact component={ActivityForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY_UPDATE} exact component={ActivityForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY_SLOT} exact component={ActivitySlotDataTable}/>
            <Route path={c.APP_ROUTES.ACTIVITY_SLOT_CREATE} exact component={ActivitySlotForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY_SLOT_UPDATE} exact component={ActivitySlotForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY_SLOT_INSTRUCTOR} exact component={ActivitySlotInstructorDataTable}/>
            <Route path={c.APP_ROUTES.ACTIVITY_SLOT_INSTRUCTOR_CREATE} exact component={ActivitySlotInstructorForm}/>
            <Route path={c.APP_ROUTES.ACTIVITY_ASSET} exact component={ActivityAssetDataTable}/>
            <Route path={c.APP_ROUTES.ACTIVITY_ASSET_CREATE} exact component={ActivityAssetForm}/>
            <Route path={c.APP_ROUTES.ACCOUNT} exact component={AccountDataTable}/>
            <Route path={c.APP_ROUTES.ACCOUNT_CREATE} exact component={AccountForm}/>
            <Route path={c.APP_ROUTES.ACCOUNT_UPDATE} exact component={AccountForm}/>
            <Route path={c.APP_ROUTES.ACCOUNT_CLIENT} exact component={AccountClientDataTable}/>
            <Route path={c.APP_ROUTES.ACCOUNT_CLIENT_CREATE} exact component={AccountClientForm}/>
            <Route path={c.APP_ROUTES.ACCOUNT_USER} exact component={AccountUserDataTable}/>
            <Route path={c.APP_ROUTES.ACCOUNT_USER_CREATE} exact component={AccountUserForm}/>
            <Route path={c.APP_ROUTES.ACCOUNT_GROUP} exact component={AccountGroupDataTable}/>
            <Route path={c.APP_ROUTES.ACCOUNT_GROUP_CREATE} exact component={AccountGroupForm}/>
            <Route path={c.APP_ROUTES.ASSET} exact component={AssetDataTable}/>
            <Route path={c.APP_ROUTES.ASSET_CREATE} exact component={AssetForm}/>
            <Route path={c.APP_ROUTES.CONFIGURATION} exact component={ConfigurationDataTable}/>
            <Route path={c.APP_ROUTES.CONFIGURATION_UPDATE} exact component={ConfigurationForm}/>
            <Route path={c.APP_ROUTES.CONFIGURATION_CREATE} exact component={ConfigurationForm}/>
            <Route path={c.APP_ROUTES.ORDER} exact component={OrderDataTable}/>
            <Route path={c.APP_ROUTES.CART_SHORTCODE} exact component={Cart}/>
            <Route path={c.APP_ROUTES.CART} exact component={Cart}/>
            <Route path={c.APP_ROUTES.SEARCH_SHORTCODE} exact component={Search}/>
            <Route path={c.APP_ROUTES.SEARCH} exact component={Search}/>



        </Switch>
  );
};

export default Router;
