import { RootState } from '../store/configureStore';

export const getActivitySlots = (state: RootState) => state?.activitySlot?.activitySlots;

export const getActiveActivitySlot = (state: RootState) => state?.activitySlot?.activeActivitySlot;

export const isFetchingActivitySlots = (state: RootState) => state?.activitySlot?.isFetchingActivitySlots;

export const isPostingActivitySlot = (state: RootState) => state?.activitySlot?.isPostingActivitySlot;
export const postingActivitySlotFailed = (state: RootState) => state?.activitySlot?.postingActivitySlotFailed;
export const postingActivitySlotError = (state: RootState) => state?.activitySlot?.postingActivitySlotError;
export const postingActivitySlotValidationErrors = (state: RootState) => state?.activitySlot?.postingActivitySlotValidationErrors;


export const fetchingActivitySlotsFailed = (state: RootState) => state?.activitySlot?.fetchingActivitySlotsFailed;
