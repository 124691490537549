import {
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_FAILURE,
  FETCH_ASSETS_RESPONSE,
  AssetState,
  AssetActionTypes,
  FETCH_ASSET_RESPONSE,
  FETCH_ASSET_FAILURE,
  FETCH_ASSET_REQUEST,
} from '../types/Asset';

const initialState: AssetState = {
  assets: null,
  activeAsset: null,
  isFetchingAssets: false,
  fetchingAssetsFailed: false,
  isPostingAsset: false,
  postingAssetFailed: false,
  postingAssetError: null,
  postingAssetValidationErrors: null,
};

const assetsReducer = (
  state = initialState,
  action: AssetActionTypes,
): AssetState => {
  switch (action.type) {
    case FETCH_ASSETS_REQUEST:
      return {
        ...state,
        assets: null,
        isFetchingAssets: true,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSETS_RESPONSE:
      return {
        ...state,
        assets: action.payload || [],
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSETS_FAILURE:
      return {
        ...state,
        assets: null,
        isFetchingAssets: false,
        fetchingAssetsFailed: true,
      };
    case FETCH_ASSET_REQUEST:
      return {
        ...state,
        assets: null,
        isFetchingAssets: true,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSET_RESPONSE:
      return {
        ...state,
        activeAsset: action.payload,
        isFetchingAssets: false,
        fetchingAssetsFailed: false,
      };
    case FETCH_ASSET_FAILURE:
      return {
        ...state,
        assets: null,
        isFetchingAssets: false,
        fetchingAssetsFailed: true,
      };
    default:
      return state;
  }
};

export default assetsReducer;
