import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_LOCATION_ASSETS_REQUEST,
  FETCH_LOCATION_ASSETS_RESPONSE,
  FETCH_LOCATION_ASSETS_FAILURE,
  FETCH_LOCATION_ASSET_REQUEST,
  FETCH_LOCATION_ASSET_RESPONSE,
  FETCH_LOCATION_ASSET_FAILURE,
  POST_LOCATION_ASSET_FAILURE,
  POST_LOCATION_ASSET_REQUEST,
  POST_LOCATION_ASSET_RESPONSE,
  LocationAssetActionTypes, LocationAssetDataModel, LocationAsset,
} from '../types/LocationAsset';

import { RootState } from '../store/configureStore';

import { getLocationAsset, getLocationAssets, postLocationAsset,  deleteLocationAsset } from '../api/LocationAsset';


export function fetchLocationAssetsRequest(): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSETS_REQUEST,
    payload: null,
  };
}

export function fetchLocationAssetsResponse(
  locationAssets: LocationAssetDataModel,
): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSETS_RESPONSE,
    payload: locationAssets,
  };
}

export function fetchLocationAssetsFailure(): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSETS_FAILURE,
    payload: null,
  };
}

export function fetchLocationAssetRequest(): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSET_REQUEST,
    payload: null,
  };
}

export function fetchLocationAssetResponse(
  locationAsset: LocationAsset,
): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSET_RESPONSE,
    payload: locationAsset,
  };
}

export function fetchLocationAssetFailure(): LocationAssetActionTypes {
  return {
    type: FETCH_LOCATION_ASSET_FAILURE,
    payload: null,
  };
}


export function postLocationAssetRequest(): LocationAssetActionTypes {
  return {
    type: POST_LOCATION_ASSET_REQUEST,
    payload: null,
  };
}

export function postLocationAssetResponse(): LocationAssetActionTypes {
  return {
    type: POST_LOCATION_ASSET_RESPONSE,
    payload: null,
  };
}

export function postLocationAssetFailure(error: string, validationErrors: any): LocationAssetActionTypes {
  return {
    type: POST_LOCATION_ASSET_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveLocationAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLocationAssetRequest());
    const asyncResp: any = await getLocationAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchLocationAssetResponse(asyncResp.data));
    } else {
      await dispatch(fetchLocationAssetFailure());
    }
  };

export const fetchLocationAssets = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchLocationAssetsRequest());
    const asyncResp: any = await getLocationAssets();
    if (asyncResp?.success) {
      await dispatch(fetchLocationAssetsResponse(asyncResp));
    } else {
      await dispatch(fetchLocationAssetsFailure());
    }
  };

export const destroyLocationAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteLocationAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchLocationAssets());
    }
  };


export const createLocationAsset = (
  locationId: string,
  assetId: string,

):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postLocationAssetRequest());
    const asyncResp: any = await postLocationAsset(locationId, assetId);
    if (asyncResp?.success) {
      await dispatch(postLocationAssetResponse());
      await dispatch(fetchLocationAssets());
    } else {
      await dispatch(postLocationAssetFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };



