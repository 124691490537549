import {
  FETCH_CONFIGURATION_FAILURE,
  FETCH_CONFIGURATION_REQUEST,
  FETCH_CONFIGURATION_RESPONSE,
  FETCH_CONFIGURATIONS_FAILURE,
  FETCH_CONFIGURATIONS_REQUEST,
  FETCH_CONFIGURATIONS_RESPONSE,
  POST_CONFIGURATION_FAILURE,
  POST_CONFIGURATION_RESPONSE,
  ConfigurationActionTypes,
  ConfigurationState,
  POST_CONFIGURATION_REQUEST,
} from '../types/Configuration';
import { parseValidationErrors } from '../helpers/functions';


const initialState: ConfigurationState = {
  configurations: null,
  activeConfiguration: null,
  isFetchingConfigurations: false,
  fetchingConfigurationsFailed: false,
  isPostingConfiguration: false,
  postingConfigurationFailed: false,
  postingConfigurationError: null,
  postingConfigurationValidationErrors: null,

};

const configurationsReducer = (
  state = initialState,
  action: ConfigurationActionTypes,
): ConfigurationState => {
  switch (action.type) {
    case FETCH_CONFIGURATIONS_REQUEST:
      return {
        ...state,
        configurations: null,
        isFetchingConfigurations: true,
        fetchingConfigurationsFailed: false,
      };
    case FETCH_CONFIGURATIONS_RESPONSE:
      return {
        ...state,
        configurations: action.payload || [],
        isFetchingConfigurations: false,
        fetchingConfigurationsFailed: false,
      };
    case FETCH_CONFIGURATIONS_FAILURE:
      return {
        ...state,
        configurations: null,
        isFetchingConfigurations: false,
        fetchingConfigurationsFailed: true,
      };
    case FETCH_CONFIGURATION_REQUEST:
      return {
        ...state,
        configurations: null,
        isFetchingConfigurations: true,
        fetchingConfigurationsFailed: false,
      };
    case FETCH_CONFIGURATION_RESPONSE:
      return {
        ...state,
        activeConfiguration: action.payload,
        isFetchingConfigurations: false,
        fetchingConfigurationsFailed: false,
      };
    case FETCH_CONFIGURATION_FAILURE:
      return {
        ...state,
        configurations: null,
        isFetchingConfigurations: false,
        fetchingConfigurationsFailed: true,
      };
    case POST_CONFIGURATION_REQUEST:
      return {
        ...state,
        isPostingConfiguration: true,
        postingConfigurationError: null,
        postingConfigurationValidationErrors: null,
        postingConfigurationFailed: false,
      };
    case POST_CONFIGURATION_RESPONSE:
      return {
        ...state,
        isPostingConfiguration: false,
        postingConfigurationError: null,
        postingConfigurationValidationErrors: null,
        postingConfigurationFailed: false,
      };
    case POST_CONFIGURATION_FAILURE:
      return {
        ...state,
        isPostingConfiguration: false,
        postingConfigurationError: action.payload.error || 'An error occurred',
        postingConfigurationValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingConfigurationFailed: true,
      };
    default:
      return state;
  }
};

export default configurationsReducer;