import { ApiDataModel, FormError } from './Common';

export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_RESPONSE = 'FETCH_GROUPS_RESPONSE';
export const FETCH_GROUPS_FAILURE = 'FETCH_GROUPS_FAILURE';

export const FETCH_GROUP_REQUEST = 'FETCH_GROUP_REQUEST';
export const FETCH_GROUP_RESPONSE = 'FETCH_GROUP_RESPONSE';
export const FETCH_GROUP_FAILURE = 'FETCH_GROUP_FAILURE';

export const POST_GROUP_REQUEST = 'POST_GROUP_REQUEST';
export const POST_GROUP_RESPONSE = 'POST_GROUP_RESPONSE';
export const POST_GROUP_FAILURE = 'POST_GROUP_FAILURE';

export interface Group {
  id: string;
  title: string;
  description: string;
  shortcode: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface GroupDataModel extends Omit<ApiDataModel, 'data'> {
  data: Group[];
}

export interface GroupState {
  groups: GroupDataModel | null,
  activeGroup: Group | null,
  isFetchingGroups: boolean;
  fetchingGroupsFailed: boolean;
  postingGroupFailed: boolean;
  isPostingGroup: boolean;
  postingGroupValidationErrors: FormError[] | null;
  postingGroupError: string | null;
}

interface FetchGroupsRequestAction {
  type: typeof FETCH_GROUPS_REQUEST;
  payload: null;
}

interface FetchGroupsResponseAction {
  type: typeof FETCH_GROUPS_RESPONSE;
  payload: GroupDataModel;
}

interface FetchGroupsFailureAction {
  type: typeof FETCH_GROUPS_FAILURE;
  payload: null;
}

interface FetchGroupRequestAction {
  type: typeof FETCH_GROUP_REQUEST;
  payload: null;
}

interface FetchGroupResponseAction {
  type: typeof FETCH_GROUP_RESPONSE;
  payload: Group;
}

interface FetchGroupFailureAction {
  type: typeof FETCH_GROUP_FAILURE;
  payload: null;
}

interface PostGroupRequestAction {
  type: typeof POST_GROUP_REQUEST;
  payload: null;
}

interface PostGroupResponseAction {
  type: typeof POST_GROUP_RESPONSE;
  payload: null;
}

interface PostGroupFailureAction {
  type: typeof POST_GROUP_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type GroupActionTypes =
    | FetchGroupsRequestAction
    | FetchGroupsResponseAction
    | FetchGroupsFailureAction
    | PostGroupRequestAction
    | PostGroupResponseAction
    | PostGroupFailureAction
    | FetchGroupRequestAction
    | FetchGroupResponseAction
    | FetchGroupFailureAction;
