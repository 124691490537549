import { ApiDataModel, FormError } from './Common';

export const FETCH_ACCOUNT_USERS_REQUEST = 'FETCH_ACCOUNT_USERS_REQUEST';
export const FETCH_ACCOUNT_USERS_RESPONSE = 'FETCH_ACCOUNT_USERS_RESPONSE';
export const FETCH_ACCOUNT_USERS_FAILURE = 'FETCH_ACCOUNT_USERS_FAILURE';

export const FETCH_ACCOUNT_USER_REQUEST = 'FETCH_ACCOUNT_USER_REQUEST';
export const FETCH_ACCOUNT_USER_RESPONSE = 'FETCH_ACCOUNT_USER_RESPONSE';
export const FETCH_ACCOUNT_USER_FAILURE = 'FETCH_ACCOUNT_USER_FAILURE';

export const POST_ACCOUNT_USER_REQUEST = 'POST_ACCOUNT_USER_REQUEST';
export const POST_ACCOUNT_USER_RESPONSE = 'POST_ACCOUNT_USER_RESPONSE';
export const POST_ACCOUNT_USER_FAILURE = 'POST_ACCOUNT_USER_FAILURE';

export interface AccountUser {
  id: string;
  userId: string;
  accountId: string;
}

export interface AccountUserDataModel extends Omit<ApiDataModel, 'data'> {
  data: AccountUser[];
}

export interface AccountUserState {
  accountUsers: AccountUserDataModel | null,
  activeAccountUser: AccountUser | null,
  isFetchingAccountUsers: boolean;
  fetchingAccountUsersFailed: boolean;
  postingAccountUserFailed: boolean;
  isPostingAccountUser: boolean;
  postingAccountUserValidationErrors: FormError[] | null;
  postingAccountUserError: string | null;
}

interface FetchAccountUsersRequestAction {
  type: typeof FETCH_ACCOUNT_USERS_REQUEST;
  payload: null;
}

interface FetchAccountUsersResponseAction {
  type: typeof FETCH_ACCOUNT_USERS_RESPONSE;
  payload: AccountUserDataModel;
}

interface FetchAccountUsersFailureAction {
  type: typeof FETCH_ACCOUNT_USERS_FAILURE;
  payload: null;
}

interface FetchAccountUserRequestAction {
  type: typeof FETCH_ACCOUNT_USER_REQUEST;
  payload: null;
}

interface FetchAccountUserResponseAction {
  type: typeof FETCH_ACCOUNT_USER_RESPONSE;
  payload: AccountUser;
}

interface FetchAccountUserFailureAction {
  type: typeof FETCH_ACCOUNT_USER_FAILURE;
  payload: null;
}

interface PostAccountUserRequestAction {
  type: typeof POST_ACCOUNT_USER_REQUEST;
  payload: null;
}

interface PostAccountUserResponseAction {
  type: typeof POST_ACCOUNT_USER_RESPONSE;
  payload: null;
}

interface PostAccountUserFailureAction {
  type: typeof POST_ACCOUNT_USER_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AccountUserActionTypes =
    | FetchAccountUsersRequestAction
    | FetchAccountUsersResponseAction
    | FetchAccountUsersFailureAction
    | PostAccountUserRequestAction
    | PostAccountUserResponseAction
    | PostAccountUserFailureAction
    | FetchAccountUserRequestAction
    | FetchAccountUserResponseAction
    | FetchAccountUserFailureAction;
