import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_TIMEZONES_REQUEST,
  FETCH_TIMEZONES_RESPONSE,
  FETCH_TIMEZONES_FAILURE,

  TimezoneActionTypes, TimezoneDataModel,
} from '../types/Timezones';

import { RootState } from '../store/configureStore';

import { getTimezones } from '../api/Timezones';

export function fetchTimezonesRequest(): TimezoneActionTypes {
  return {
    type: FETCH_TIMEZONES_REQUEST,
    payload: null,
  };
}

export function fetchTimezonesResponse(
  timezones: TimezoneDataModel,
): TimezoneActionTypes {
  return {
    type: FETCH_TIMEZONES_RESPONSE,
    payload: timezones,
  };
}

export function fetchTimezonesFailure(): TimezoneActionTypes {
  return {
    type: FETCH_TIMEZONES_FAILURE,
    payload: null,
  };
}


export const fetchTimezones = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchTimezonesRequest());
    const asyncResp: any = await getTimezones();
    if (asyncResp?.success) {
      await dispatch(fetchTimezonesResponse(asyncResp));
    } else {
      await dispatch(fetchTimezonesFailure());
    }
  };


