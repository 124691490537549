import {
  FETCH_ACCOUNT_CLIENT_FAILURE,
  FETCH_ACCOUNT_CLIENT_REQUEST,
  FETCH_ACCOUNT_CLIENT_RESPONSE,
  FETCH_ACCOUNT_CLIENTS_FAILURE,
  FETCH_ACCOUNT_CLIENTS_REQUEST,
  FETCH_ACCOUNT_CLIENTS_RESPONSE,
  POST_ACCOUNT_CLIENT_FAILURE,
  POST_ACCOUNT_CLIENT_RESPONSE,
  AccountClientActionTypes,
  AccountClientState,
  POST_ACCOUNT_CLIENT_REQUEST,
} from '../types/AccountClient';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AccountClientState = {
  accountClients: null,
  activeAccountClient: null,
  isFetchingAccountClients: false,
  fetchingAccountClientsFailed: false,
  isPostingAccountClient: false,
  postingAccountClientFailed: false,
  postingAccountClientError: null,
  postingAccountClientValidationErrors: null,

};

const accountClientsReducer = (
  state = initialState,
  action: AccountClientActionTypes,
): AccountClientState => {
  switch (action.type) {
    case FETCH_ACCOUNT_CLIENTS_REQUEST:
      return {
        ...state,
        accountClients: null,
        isFetchingAccountClients: true,
        fetchingAccountClientsFailed: false,
      };
    case FETCH_ACCOUNT_CLIENTS_RESPONSE:
      return {
        ...state,
        accountClients: action.payload || [],
        isFetchingAccountClients: false,
        fetchingAccountClientsFailed: false,
      };
    case FETCH_ACCOUNT_CLIENTS_FAILURE:
      return {
        ...state,
        accountClients: null,
        isFetchingAccountClients: false,
        fetchingAccountClientsFailed: true,
      };
    case FETCH_ACCOUNT_CLIENT_REQUEST:
      return {
        ...state,
        accountClients: null,
        isFetchingAccountClients: true,
        fetchingAccountClientsFailed: false,
      };
    case FETCH_ACCOUNT_CLIENT_RESPONSE:
      return {
        ...state,
        activeAccountClient: action.payload,
        isFetchingAccountClients: false,
        fetchingAccountClientsFailed: false,
      };
    case FETCH_ACCOUNT_CLIENT_FAILURE:
      return {
        ...state,
        accountClients: null,
        isFetchingAccountClients: false,
        fetchingAccountClientsFailed: true,
      };
    case POST_ACCOUNT_CLIENT_REQUEST:
      return {
        ...state,
        isPostingAccountClient: true,
        postingAccountClientError: null,
        postingAccountClientValidationErrors: null,
        postingAccountClientFailed: false,
      };
    case POST_ACCOUNT_CLIENT_RESPONSE:
      return {
        ...state,
        isPostingAccountClient: false,
        postingAccountClientError: null,
        postingAccountClientValidationErrors: null,
        postingAccountClientFailed: false,
      };
    case POST_ACCOUNT_CLIENT_FAILURE:
      return {
        ...state,
        isPostingAccountClient: false,
        postingAccountClientError: action.payload.error || 'An error occurred',
        postingAccountClientValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAccountClientFailed: true,
      };

    default:
      return state;
  }
};

export default accountClientsReducer;

