import React, { useEffect, useState } from 'react';
import './Color.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Validator } from '../../hooks/ValidationHook';


type Props = {
  value?: string | number | null  ;
  onBlur?: (e: any) => void;
  onChange?: (e: any) => void;
  placeholder?: string;
  name?: string;
  id?: string;
  label?: string;
  required?: boolean;
  type?: 'number' | 'text' | 'password' | 'color';
  bgColor?: 'grey' | 'white';
  style?: 'rounded' | 'normal';
  disabled?: boolean;
  v?: any;
  err?: (id: string) => string[];
  validate?: any,
  validators?: Validator[]
  errors?: string[];
};


const Color = (props: Props) => {
  const {
    onBlur = () => { },
    onChange = () => { },
    disabled = false,
    required = false,
    placeholder = '',
    value = '',
    name = '',
    id = '',
    label = '',
    err = () => [],
    bgColor = 'grey',
    type = 'color',
    v = () => {},
  } = props;

  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);

  const innerOnChange = (e: any) => {
    setTouched(true);
    onChange(e.target.value);
    if (v && typeof v === 'function') {
      v(id, e.target.value);
    }
  };
  const innerOnBlur = (e: any) => {
    setTouched(true);
    onBlur(e.target.value);
    if (v && typeof v === 'function') {
      v(id, e.target.value);
    }
  };

  const errors = err(id);



  return (
        <div className="ems-input-color my-3">

            {label && <label htmlFor={id} className={`form-label-color ${errors.length > 0 && 'error-lbl-color'}`}>{label}{required && '*'}</label>}

                <input disabled={disabled} type={type} required={required} className={`form-control-color ${bgColor} ${errors.length > 0 && 'error'}`} value={value || ''} name={name} id={id}
                       onBlur={innerOnBlur}
                       onChange={innerOnChange}
                       placeholder={placeholder}
                />

            {errors.map((e: string, i: number) => (<div className="form-label-color error-lbl-color" key={i}>{e}</div>))}


        </div>
  );
};

export default Color;