import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_RESPONSE,
  FETCH_USERS_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_RESPONSE,
  FETCH_USER_FAILURE,
  POST_USER_FAILURE,
  POST_USER_REQUEST,
  POST_USER_RESPONSE,
  UserActionTypes, UserDataModel, User,
} from '../types/User';

import { RootState } from '../store/configureStore';

import { getUser, getUsers, postUser, putUser, deleteUser, postUserLogin, postResetPassword, putResetPassword } from '../api/User';
import { setToken, setUserToken } from '../helpers/functions';
import { getUserCustomers } from '../api/User';
import { fetchCustomersFailure, fetchCustomersRequest, fetchCustomersResponse } from './Customer';


export function fetchUsersRequest(): UserActionTypes {
  return {
    type: FETCH_USERS_REQUEST,
    payload: null,
  };
}

export function fetchUsersResponse(
  users: UserDataModel,
): UserActionTypes {
  return {
    type: FETCH_USERS_RESPONSE,
    payload: users,
  };
}

export function fetchUsersFailure(): UserActionTypes {
  return {
    type: FETCH_USERS_FAILURE,
    payload: null,
  };
}

export function fetchUserRequest(): UserActionTypes {
  return {
    type: FETCH_USER_REQUEST,
    payload: null,
  };
}

export function fetchUserResponse(
  user: User,
): UserActionTypes {
  return {
    type: FETCH_USER_RESPONSE,
    payload: user,
  };
}

export function fetchUserFailure(): UserActionTypes {
  return {
    type: FETCH_USER_FAILURE,
    payload: null,
  };
}


export function postUserRequest(): UserActionTypes {
  return {
    type: POST_USER_REQUEST,
    payload: null,
  };
}

export function postUserResponse(): UserActionTypes {
  return {
    type: POST_USER_RESPONSE,
    payload: null,
  };
}

export function postUserFailure(error: string, validationErrors: any): UserActionTypes {
  return {
    type: POST_USER_FAILURE,
    payload: {
      error,
      validationErrors,
    },

  };
}

export const fetchActiveUser = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchUserRequest());
    const asyncResp: any = await getUser(id);
    if (asyncResp?.success) {
      await dispatch(fetchUserResponse(asyncResp.data));
    } else {
      await dispatch(fetchUserFailure());
    }
  };


export const fetchUserCustomers = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCustomersRequest());
    const asyncResp: any = await getUserCustomers(id);
    if (asyncResp?.success) {
      await dispatch(fetchCustomersResponse(asyncResp));
    } else {
      await dispatch(fetchCustomersFailure());
    }
  };


export const fetchUsers = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchUsersRequest());
    const asyncResp: any = await getUsers();
    if (asyncResp?.success) {
      await dispatch(fetchUsersResponse(asyncResp));
    } else {
      await dispatch(fetchUsersFailure());
    }
  };

export const destroyUser = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteUser(id);
    if (asyncResp?.success) {
      await dispatch(fetchUsers());
    }
  };



export const createUser = (
  roleId: string, email: string, password: string, username: string, firstName: string, lastName: string, active: boolean, verified: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await postUser(roleId, email, password, username, firstName, lastName, active, verified);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateUser = (
  id: string,
  roleId: string,
  email: string,
  password: string,
  username: string,
  firstName: string,
  lastName: string,
  verified: boolean,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await putUser(id, roleId, email, password, username, firstName, lastName, verified, active);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const loginUser = (
  email: string, password: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await postUserLogin(email, password);
    if (asyncResp?.success) {
      setToken(asyncResp?.token);
      setUserToken(asyncResp?.user.id);
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const requestResetPassword = (
  email: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await postResetPassword(email);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const resetPassword = (
  id: string,
  password: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postUserRequest());
    const asyncResp: any = await putResetPassword(id, password);
    if (asyncResp?.success) {
      await dispatch(postUserResponse());
      await dispatch(fetchUsers());
    } else {
      await dispatch(postUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };


