import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  AccountUser,
  AccountUserActionTypes,
  AccountUserDataModel, FETCH_ACCOUNT_USER_FAILURE,
  FETCH_ACCOUNT_USER_REQUEST,
  FETCH_ACCOUNT_USER_RESPONSE,
  FETCH_ACCOUNT_USERS_FAILURE,
  FETCH_ACCOUNT_USERS_REQUEST,
  FETCH_ACCOUNT_USERS_RESPONSE,
  POST_ACCOUNT_USER_FAILURE,
  POST_ACCOUNT_USER_REQUEST,
  POST_ACCOUNT_USER_RESPONSE,
} from '../types/AccountUser';

import { RootState } from '../store/configureStore';

import { deleteAccountUser, getAccountUsers, postAccountUser } from '../api/AccountUser';



export function fetchAccountUsersRequest(): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USERS_REQUEST,
    payload: null,
  };
}

export function fetchAccountUsersResponse(
  accountUsers: AccountUserDataModel,
): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USERS_RESPONSE,
    payload: accountUsers,
  };
}

export function fetchAccountUsersFailure(): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USERS_FAILURE,
    payload: null,
  };
}

export function fetchAccountUserRequest(): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USER_REQUEST,
    payload: null,
  };
}

export function fetchAccountUserResponse(
  user: AccountUser,
): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USER_RESPONSE,
    payload: user,
  };
}

export function fetchAccountUserFailure(): AccountUserActionTypes {
  return {
    type: FETCH_ACCOUNT_USER_FAILURE,
    payload: null,
  };
}

export function postAccountUserRequest(): AccountUserActionTypes {
  return {
    type: POST_ACCOUNT_USER_REQUEST,
    payload: null,
  };
}

export function postAccountUserResponse(): AccountUserActionTypes {
  return {
    type: POST_ACCOUNT_USER_RESPONSE,
    payload: null,
  };
}

export function postAccountUserFailure(error: string, validationErrors: any): AccountUserActionTypes {
  return {
    type: POST_ACCOUNT_USER_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchAccountUsers = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAccountUsersRequest());
    const asyncResp: any = await getAccountUsers();
    if (asyncResp?.success) {
      await dispatch(fetchAccountUsersResponse(asyncResp));
    } else {
      await dispatch(fetchAccountUsersFailure());
    }
  };

export const destroyAccountUser = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteAccountUser(id);
    if (asyncResp?.success) {
      await dispatch(fetchAccountUsers());
    }
  };


export const createAccountUser = (
  accountId: string, userId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAccountUserRequest());
    const asyncResp: any = await postAccountUser(accountId, userId);
    if (asyncResp?.success) {
      await dispatch(postAccountUserResponse());
      await dispatch(fetchAccountUsers());
    } else {
      await dispatch(postAccountUserFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
