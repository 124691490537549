import { RootState } from '../store/configureStore';

export const getActivities = (state: RootState) => state?.activity?.activities;

export const getActiveActivity = (state: RootState) => state?.activity?.activeActivity;

export const isFetchingActivities = (state: RootState) => state?.activity?.isFetchingActivities;

export const isPostingActivity = (state: RootState) => state?.activity?.isPostingActivity;
export const postingActivityFailed = (state: RootState) => state?.activity?.postingActivityFailed;
export const postingActivityError = (state: RootState) => state?.activity?.postingActivityError;
export const postingActivityValidationErrors = (state: RootState) => state?.activity?.postingActivityValidationErrors;


export const fetchingActivitiesFailed = (state: RootState) => state?.activity?.fetchingActivitiesFailed;
