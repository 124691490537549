import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAssets } from '../../../selectors/Asset';
import { destroyAsset, fetchAssets } from '../../../actions/Asset';
import { Asset } from '../../../types/Asset';
import DataTable from '../../../components/DataTable';
/*import { formatUrl } from '../../../helpers/functions';*/
import Modal from '../../../components/Modal';


const AssetDataTable = () => {

  const assets = useSelector(getAssets);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [asset, setActiveAsset] = useState<Asset | null>();


  useEffect(() => {
    dispatch(fetchAssets());
  }, [dispatch]);

  const history = useHistory();

  const onCreate = () => {
    history.push(c.APP_ROUTES.ASSET_CREATE);
  };

  const onDelete = (row: Asset) => {
    setActiveAsset(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (asset?.id) {
      dispatch(destroyAsset(asset?.id));
    }
    setActiveAsset(null);
  };


  const config = {
    columns: [
      { key: 'title', label: 'File Title', mobileFriendly: false },
      { key: 'mime', label: 'Mime', mobileFriendly: true },
      { key: 'id', label: 'Identifier', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return <View>
    <DataTable title="ASSETS" ctaTitle="Upload Asset" onCreate={onCreate} data={assets?.data || []} config={config} />
    <Modal show={toggle} title="Delete Asset" cta="Delete" onCta={onConfirmDelete}>
      Are you sure you want to delete this asset?
    </Modal>

  </View>;
};

export default AssetDataTable;
