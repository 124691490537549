import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CURRENCY_REQUEST,
  FETCH_CURRENCY_RESPONSE,
  FETCH_CURRENCY_FAILURE,

  CurrencyActionTypes, CurrencyDataModel,
} from '../types/Currency';

import { RootState } from '../store/configureStore';

import { getCurrency } from '../api/Currency';

export function fetchCurrencyRequest(): CurrencyActionTypes {
  return {
    type: FETCH_CURRENCY_REQUEST,
    payload: null,
  };
}

export function fetchCurrencyResponse(
  currency: CurrencyDataModel,
): CurrencyActionTypes {
  return {
    type: FETCH_CURRENCY_RESPONSE,
    payload: currency,
  };
}

export function fetchCurrencyFailure(): CurrencyActionTypes {
  return {
    type: FETCH_CURRENCY_FAILURE,
    payload: null,
  };
}


export const fetchCurrency = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCurrencyRequest());
    const asyncResp: any = await getCurrency();
    if (asyncResp?.success) {
      await dispatch(fetchCurrencyResponse(asyncResp));
    } else {
      await dispatch(fetchCurrencyFailure());
    }
  };