import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import './CheckBox.scss';


type Props = {
  label: string;
  id?: string;
  name?: string;
  type?: 'checkbox' | 'radio' | 'switch';
  value?: string | number | readonly string[] | undefined;
  checked?: boolean;
  onChange?: (val: boolean) => void;
};

const CheckBox = (props: Props) => {
  const { label, type = 'switch', value, checked, id = '', name = '', onChange = () => {} } = props;

  const [toggle, setToggle] = useState(checked);

  useEffect(() => {
    setToggle(checked);
  }, [checked]);

  const onChangeInternal = () => {
    onChange(!toggle);
    setToggle(!toggle);
  };

  return (
    <div className='checkbox my-3'>
        <Form.Check onChange={onChangeInternal} id={id} name={name} checked={toggle} type={type} value={value} label={label} />
    </div>
  );
};

export default CheckBox;
