import { RootState } from '../store/configureStore';

export const getUsers = (state: RootState) => state?.user?.users;

export const getActiveUser = (state: RootState) => state?.user?.activeUser;

export const isFetchingUsers = (state: RootState) => state?.user?.isFetchingUsers;

export const isPostingUser = (state: RootState) => state?.user?.isPostingUser;
export const postingUserFailed = (state: RootState) => state?.user?.postingUserFailed;
export const postingUserError = (state: RootState) => state?.user?.postingUserError;
export const postingUserValidationErrors = (state: RootState) => state?.user?.postingUserValidationErrors;


export const fetchingUsersFailed = (state: RootState) => state?.user?.fetchingUsersFailed;
