import React from 'react';
import './Modal.scss';
import { Button, Modal } from 'react-bootstrap';
type Props = {
  title?: string;
  children?: React.ReactNode;
  onCta?: () => void;
  onHide?: () => void;
  cta?: string;
  show?: boolean;
};

const EMSModal = (props: Props) => {
  const {
    title = '', children, onCta = () => {}, cta, show = false, onHide = () => {},
  } = props;
  return (

        <Modal centered className="ems-modal" show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <div className="modal-header__left">
                    <Modal.Title>{title}</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body>
                <div className='modal-description'>{children}</div>
            </Modal.Body>
            <Modal.Footer>
                {cta && <Button onClick={onCta} variant="secondary">{cta}</Button>}
            </Modal.Footer>
        </Modal>
  );
};

export default EMSModal;

