import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CITY_REQUEST,
  FETCH_CITY_RESPONSE,
  FETCH_CITY_FAILURE,

  CityActionTypes, CityDataModel,
} from '../types/City';

import { RootState } from '../store/configureStore';

import { getCity } from '../api/City';

export function fetchCityRequest(): CityActionTypes {
  return {
    type: FETCH_CITY_REQUEST,
    payload: null,
  };
}

export function fetchCityResponse(
  city: CityDataModel,
): CityActionTypes {
  return {
    type: FETCH_CITY_RESPONSE,
    payload: city,
  };
}

export function fetchCityFailure(): CityActionTypes {
  return {
    type: FETCH_CITY_FAILURE,
    payload: null,
  };
}


export const fetchCity = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchCityRequest());
    const asyncResp: any = await getCity(id);
    if (asyncResp?.success) {
      await dispatch(fetchCityResponse(asyncResp));
    } else {
      await dispatch(fetchCityFailure());
    }
  };