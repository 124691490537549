import React, { useEffect } from 'react';
import View from '../../components/View';
import './Profile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from '../../selectors/User';
import { fetchActiveUser } from '../../actions/User';
import { getUserToken } from '../../helpers/functions';


const Profile = () => {

  const activeUser = useSelector(getActiveUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchActiveUser(getUserToken()));
  }, [dispatch]);

  return (
      <View isAdmin={false} isUser={true}>
          <div className="profile-container">
          <div>Username: <b>{activeUser?.username}</b></div>
          <div>Email: <b>{activeUser?.email}</b></div>
          <div>First Name: <b>{activeUser?.firstName}</b></div>
          <div>Last Name: <b>{activeUser?.lastName}</b></div>
          </div>
      </View>
  );
};

export default Profile;
