import React, { useEffect, useState } from 'react';

import { CartItem as CartItemType } from '../../types/Order';
import './CheckoutItem.scss';
import DropDownSearchableMultiselect from '../DropdownSearchableMultiselect/DropDownSearchableMultiselect';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCustomers, isFetchingCustomers,
  isPostingCustomer,
} from '../../selectors/Customer';

import { fetchUserCustomers, fetchUsers } from '../../actions/User';

import { getUserToken } from '../../helpers/functions';
import { Customer } from '../../types/Customer';
import format from 'date-fns/format';
import { getTimezoneOffset } from 'date-fns-tz';

type Props = {
  item: CartItemType;
  onCustomerSelect: (customer: Customer) => void;
};

const CheckoutItem = (props: Props) => {
  const { item, onCustomerSelect } = props;
  const customers = useSelector(getCustomers);
  const [customerId, setCustomerId] = useState('');
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingCustomer);
  const isFetching = useSelector(isFetchingCustomers);

  const itemsCustomers = customers?.data.map(r => ({ label: `${r.firstName} ${r.lastName}`, value: r.id })) || [];
  const activeCustomer = customers?.data.find(i => i.id === customerId);

  const timeZoneOffset = getTimezoneOffset(item.activitySlot.activityTimeZone);
  const timeZone = (Math.floor(timeZoneOffset / 3600000));
  const test = timeZone.toString();
  let GMT = '';
  if (timeZone >= 0) {
    GMT = `GMT +${test}`;
  } else {
    GMT = `GMT ${test}`;
  }

  const calculatedVat = (item.activitySlot.activityVat / 100 * item.activitySlot.activityPricePerPerson);

  useEffect(() => {
    dispatch(fetchUsers());
    dispatch(fetchUserCustomers(getUserToken()));
  }, [dispatch]);

  const onSelect = (obj: any) => {
    const customer = customers?.data?.find(c => c.id === obj.value);
    if (customer) {
      setCustomerId(customer.id);
      onCustomerSelect(customer);
    }
  };

  return (

      <div className="col">
        <div className='cart row justify-content-between'>
        <div className="middle-left">
          <h4 className='white-oswald pb-2 cart__left__title'>{item.activitySlot.activityTitle}</h4>
          <p className='offwhite-roboto cart__left__date'>{format(new Date(item.selectedDate), 'yyyy-MM-dd').toString()}</p>
          <p className='offwhite-roboto cart__left__time'>{item.activitySlot.startTime} - {item.activitySlot.endTime} | {GMT}</p>
          <p className='offwhite-roboto cart__left__teacher'>Instructor: {item.activitySlot.instructorTitle}</p>
        </div>
        <div className="middle-right">
          <div className="cart__middle__price">
            <p>{item.activitySlot.activityPricePerPerson} {item.activitySlot.activityCurrency.replace(/ *\([^)]*\) */g, '')} (+{calculatedVat} {item.activitySlot.activityCurrency.replace(/ *\([^)]*\) */g, '')} VAT)</p>
          </div>
        </div>
      </div>

        <div className="cart__dropdown">
          <DropDownSearchableMultiselect
            id="customer"
            placeholder="Please select a customer"
            required
            label="Customer"
            items={itemsCustomers}
            value={customerId}
            disabled={isPosting || isFetching}
            onSelect={onSelect}
            type="default"
          />
        </div>

        <div className="cart__customer mb-5">
          <h5 className='white-oswald pb-2 cart__left__title'>{activeCustomer ? (`${activeCustomer?.firstName} ${activeCustomer?.lastName}`) : ''}</h5>
          <p className='offwhite-roboto cart__left__date'>{activeCustomer ? (activeCustomer?.email) : ''}</p>
          <p className='offwhite-roboto cart__left__time'>{activeCustomer ? (activeCustomer?.country) : ''}</p>
          <p className='offwhite-roboto cart__left__time'>{activeCustomer ? (activeCustomer?.city) : ''}</p>
        </div>
    </div>
  );
};

export default CheckoutItem;
