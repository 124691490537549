import React from 'react';
import './Button.scss';

type Props = {
  title: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
};

const Button = (props: Props) => {
  const { title, onClick, isLoading = false, disabled } = props;
  const onClicked = () => {
    if (onClick && typeof onClick === 'function' && !isLoading && !disabled) {
      onClick();
    }
  };
  return (
    <div className={`e-button ${disabled && 'opaque'}`} onClick={onClicked}>
      {disabled && <span className="eb-button__text">{title}</span>}
      {!disabled && !isLoading && <span className="eb-button__text">{title}</span>}
      {!disabled && isLoading && <div className="spinner-border text-light" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>}
    </div>
  );
};

export default Button;
