import { ApiDataModel, FormError } from './Common';

export const FETCH_ACCOUNTS_REQUEST = 'FETCH_ACCOUNTS_REQUEST';
export const FETCH_ACCOUNTS_RESPONSE = 'FETCH_ACCOUNTS_RESPONSE';
export const FETCH_ACCOUNTS_FAILURE = 'FETCH_ACCOUNTS_FAILURE';

export const FETCH_ACCOUNT_REQUEST = 'FETCH_ACCOUNT_REQUEST';
export const FETCH_ACCOUNT_RESPONSE = 'FETCH_ACCOUNT_RESPONSE';
export const FETCH_ACCOUNT_FAILURE = 'FETCH_ACCOUNT_FAILURE';

export const POST_ACCOUNT_REQUEST = 'POST_ACCOUNT_REQUEST';
export const POST_ACCOUNT_RESPONSE = 'POST_ACCOUNT_RESPONSE';
export const POST_ACCOUNT_FAILURE = 'POST_ACCOUNT_FAILURE';

export interface Account {
  id: string;
  title: string;
  description: string;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface AccountDataModel extends Omit<ApiDataModel, 'data'> {
  data: Account[];
}

export interface AccountState {
  accounts: AccountDataModel | null,
  activeAccount: Account | null,
  isFetchingAccounts: boolean;
  fetchingAccountsFailed: boolean;
  postingAccountFailed: boolean;
  isPostingAccount: boolean;
  postingAccountValidationErrors: FormError[] | null;
  postingAccountError: string | null;
}

interface FetchAccountsRequestAction {
  type: typeof FETCH_ACCOUNTS_REQUEST;
  payload: null;
}

interface FetchAccountsResponseAction {
  type: typeof FETCH_ACCOUNTS_RESPONSE;
  payload: AccountDataModel;
}

interface FetchAccountsFailureAction {
  type: typeof FETCH_ACCOUNTS_FAILURE;
  payload: null;
}

interface FetchAccountRequestAction {
  type: typeof FETCH_ACCOUNT_REQUEST;
  payload: null;
}

interface FetchAccountResponseAction {
  type: typeof FETCH_ACCOUNT_RESPONSE;
  payload: Account;
}

interface FetchAccountFailureAction {
  type: typeof FETCH_ACCOUNT_FAILURE;
  payload: null;
}

interface PostAccountRequestAction {
  type: typeof POST_ACCOUNT_REQUEST;
  payload: null;
}

interface PostAccountResponseAction {
  type: typeof POST_ACCOUNT_RESPONSE;
  payload: null;
}

interface PostAccountFailureAction {
  type: typeof POST_ACCOUNT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type AccountActionTypes =
    | FetchAccountsRequestAction
    | FetchAccountsResponseAction
    | FetchAccountsFailureAction
    | PostAccountRequestAction
    | PostAccountResponseAction
    | PostAccountFailureAction
    | FetchAccountRequestAction
    | FetchAccountResponseAction
    | FetchAccountFailureAction;
