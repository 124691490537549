import {
  FETCH_INSTRUCTOR_FAILURE,
  FETCH_INSTRUCTOR_REQUEST,
  FETCH_INSTRUCTOR_RESPONSE,
  FETCH_INSTRUCTORS_FAILURE,
  FETCH_INSTRUCTORS_REQUEST,
  FETCH_INSTRUCTORS_RESPONSE,
  POST_INSTRUCTOR_FAILURE,
  POST_INSTRUCTOR_RESPONSE,
  InstructorActionTypes,
  InstructorState,
  POST_INSTRUCTOR_REQUEST,
} from '../types/Instructor';
import { parseValidationErrors } from '../helpers/functions';

const initialState: InstructorState = {
  instructors: null,
  activeInstructor: null,
  isFetchingInstructors: false,
  fetchingInstructorsFailed: false,
  isPostingInstructor: false,
  postingInstructorFailed: false,
  postingInstructorError: null,
  postingInstructorValidationErrors: null,
};

const instructorsReducer = (
  state = initialState,
  action: InstructorActionTypes,
): InstructorState => {
  switch (action.type) {
    case FETCH_INSTRUCTORS_REQUEST:
      return {
        ...state,
        instructors: null,
        isFetchingInstructors: true,
        fetchingInstructorsFailed: false,
      };
    case FETCH_INSTRUCTORS_RESPONSE:
      return {
        ...state,
        instructors: action.payload || [],
        isFetchingInstructors: false,
        fetchingInstructorsFailed: false,
      };
    case FETCH_INSTRUCTORS_FAILURE:
      return {
        ...state,
        instructors: null,
        isFetchingInstructors: false,
        fetchingInstructorsFailed: true,
      };
    case FETCH_INSTRUCTOR_REQUEST:
      return {
        ...state,
        instructors: null,
        isFetchingInstructors: true,
        fetchingInstructorsFailed: false,
      };
    case FETCH_INSTRUCTOR_RESPONSE:
      return {
        ...state,
        activeInstructor: action.payload,
        isFetchingInstructors: false,
        fetchingInstructorsFailed: false,
      };
    case FETCH_INSTRUCTOR_FAILURE:
      return {
        ...state,
        instructors: null,
        isFetchingInstructors: false,
        fetchingInstructorsFailed: true,
      };
    case POST_INSTRUCTOR_REQUEST:
      return {
        ...state,
        isPostingInstructor: true,
        postingInstructorError: null,
        postingInstructorValidationErrors: null,
        postingInstructorFailed: false,
      };
    case POST_INSTRUCTOR_RESPONSE:
      return {
        ...state,
        isPostingInstructor: false,
        postingInstructorError: null,
        postingInstructorValidationErrors: null,
        postingInstructorFailed: false,
      };
    case POST_INSTRUCTOR_FAILURE:
      return {
        ...state,
        isPostingInstructor: false,
        postingInstructorError: action.payload.error || 'An error occurred',
        postingInstructorValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingInstructorFailed: true,
      };

    default:
      return state;
  }
};

export default instructorsReducer;
