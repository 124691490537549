import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getRoles } from '../../../selectors/Role';
import { destroyRole, fetchRoles } from '../../../actions/Role';
import { Role } from '../../../types/Role';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const RoleDataTable = () => {

  const roles = useSelector(getRoles);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [role, setActiveRole] = useState<Role | null>();


  useEffect(() => {
    dispatch(fetchRoles());
  }, [dispatch]);

  const history = useHistory();
  
  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ROLE_UPDATE, row.id));
  };

  const onDelete = (row: Role) => {
    setActiveRole(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (role?.id) {
      dispatch(destroyRole(role?.id));
    }
    setActiveRole(null);
  };

  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'accessLevel', label: 'Level Of Access', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return <View>
        <>
            <DataTable title="ROLES" ctaTitle="Create Role" onCreate={onCreate} data={roles?.data || []} config={config} />
            <Modal show={toggle} title="Delete Role" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this role?
            </Modal>

        </>
    </View>;
};

export default RoleDataTable;
