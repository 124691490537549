import { ApiDataModel, FormError } from './Common';

export const FETCH_ACTIVITY_ASSETS_REQUEST = 'FETCH_ACTIVITY_ASSETS_REQUEST';
export const FETCH_ACTIVITY_ASSETS_RESPONSE = 'FETCH_ACTIVITY_ASSETS_RESPONSE';
export const FETCH_ACTIVITY_ASSETS_FAILURE = 'FETCH_ACTIVITY_ASSETS_FAILURE';

export const FETCH_ACTIVITY_ASSET_REQUEST = 'FETCH_ACTIVITY_ASSET_REQUEST';
export const FETCH_ACTIVITY_ASSET_RESPONSE = 'FETCH_ACTIVITY_ASSET_RESPONSE';
export const FETCH_ACTIVITY_ASSET_FAILURE = 'FETCH_ACTIVITY_ASSET_FAILURE';

export const POST_ACTIVITY_ASSET_REQUEST = 'POST_ACTIVITY_ASSET_REQUEST';
export const POST_ACTIVITY_ASSET_RESPONSE = 'POST_ACTIVITY_ASSETS_RESPONSE';
export const POST_ACTIVITY_ASSET_FAILURE = 'POST_ACTIVITY_ASSETS_FAILURE';

export interface ActivityAsset {
  id: string;
  activityId: string;
  instructorId: string;

}

export interface ActivityAssetDataModel extends Omit<ApiDataModel, 'data'> {
  data: ActivityAsset[];
}

export interface ActivityAssetState {
  activityAssets: ActivityAssetDataModel | null,
  activeActivityAsset: ActivityAsset | null,
  isFetchingActivityAssets: boolean;
  fetchingActivityAssetsFailed: boolean;
  postingActivityAssetFailed: boolean;
  isPostingActivityAsset: boolean;
  postingActivityAssetValidationErrors: FormError[] | null;
  postingActivityAssetError: string | null;
}

interface FetchActivityAssetsRequestAction {
  type: typeof FETCH_ACTIVITY_ASSETS_REQUEST;
  payload: null;
}

interface FetchActivityAssetsResponseAction {
  type: typeof FETCH_ACTIVITY_ASSETS_RESPONSE;
  payload: ActivityAssetDataModel;
}

interface FetchActivityAssetsFailureAction {
  type: typeof FETCH_ACTIVITY_ASSETS_FAILURE;
  payload: null;
}

interface FetchActivityAssetRequestAction {
  type: typeof FETCH_ACTIVITY_ASSET_REQUEST;
  payload: null;
}

interface FetchActivityAssetResponseAction {
  type: typeof FETCH_ACTIVITY_ASSET_RESPONSE;
  payload: ActivityAsset;
}

interface FetchActivityAssetFailureAction {
  type: typeof FETCH_ACTIVITY_ASSET_FAILURE;
  payload: null;
}

interface PostActivityAssetRequestAction {
  type: typeof POST_ACTIVITY_ASSET_REQUEST;
  payload: null;
}

interface PostActivityAssetResponseAction {
  type: typeof POST_ACTIVITY_ASSET_RESPONSE;
  payload: null;
}

interface PostActivityAssetFailureAction {
  type: typeof POST_ACTIVITY_ASSET_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ActivityAssetActionTypes =
    | FetchActivityAssetsRequestAction
    | FetchActivityAssetsResponseAction
    | FetchActivityAssetsFailureAction
    | PostActivityAssetRequestAction
    | PostActivityAssetResponseAction
    | PostActivityAssetFailureAction
    | FetchActivityAssetRequestAction
    | FetchActivityAssetResponseAction
    | FetchActivityAssetFailureAction;
