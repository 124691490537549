import { RootState } from '../store/configureStore';

export const getRoleGroups = (state: RootState) => state?.roleGroup?.roleGroups;

export const isFetchingRoleGroups = (state: RootState) =>
  state?.roleGroup?.isFetchingRoleGroups;

export const isPostingRoleGroup = (state: RootState) =>
  state?.roleGroup?.isPostingRoleGroup;
export const postingRoleGroupFailed = (state: RootState) =>
  state?.roleGroup?.postingRoleGroupFailed;
export const postingRoleGroupError = (state: RootState) =>
  state?.roleGroup?.postingRoleGroupError;
export const postingRoleGroupValidationErrors = (state: RootState) =>
  state?.roleGroup?.postingRoleGroupValidationErrors;

export const fetchingRoleGroupsFailed = (state: RootState) =>
  state?.roleGroup?.fetchingRoleGroupsFailed;
