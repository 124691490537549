import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoggedInUser, loginUser } from '../../../actions/Auth';

import {
  isPostingUser,
  postingUserError,
  postingUserFailed,
  postingUserValidationErrors,
  isFetchingUsers,
} from '../../../selectors/User';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';

const UserForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isPosting = useSelector(isPostingUser);
  const isFetching = useSelector(isFetchingUsers);
  const postingFailed = useSelector(postingUserFailed);
  const postingError = useSelector(postingUserError);
  const postingValidationErrors = useSelector(postingUserValidationErrors);


  const formConfig = [
    { field: 'email', validators: [isRequired] },
    { field: 'password', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
  }, [dispatch]);


  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      dispatch(fetchLoggedInUser());
      if (localStorage.getItem('returnUrl')){
        // history.push(localStorage.getItem('returnUrl'));
        // localStorage.setItem('returnUrl', '');
        history.push(c.APP_ROUTES.PROFILE);
      } else {
        history.push(c.APP_ROUTES.PROFILE);
      }
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      // TODO use returnUrl to redirect to where the user wanted to go in the first place after making sure they are indeed logged in.
      dispatch(loginUser(email, password));
    }
  };


  const onRegister = () => {
    history.push(c.APP_ROUTES.USER_REGISTER);
  };

  return <View isAdmin={false}>
          <div className="row p-3">
              <div className="col-12">
                  <h4>User Login</h4>
                  {postingError && (
                      <Alert className="mt-3" variant="danger">
                          {postingError}
                      </Alert>
                  )}
                  <form>
                      <Input v={v} err={err}
                             name="email"
                             id="email"
                             label="Email"
                             required
                             value={email}
                             onChange={setEmail}
                             disabled={isPosting || isFetching}
                             onBlur={setEmail}
                             placeholder="Insert a title"
                      />
                      <Input v={v} err={err}
                             required
                             name="password"
                             id="password"
                             type="password"
                             label="Password"
                             value={password}
                             disabled={isPosting || isFetching}
                             onChange={setPassword}
                             onBlur={setPassword}
                             placeholder="Insert a password"
                      />
                      <div className="pb-3">
                          <a href="resetPassword">Forgot password?</a>
                      </div>
                      <div style={{ color:'white' }}>
                          Don&apos;t have an account? <a href={c.APP_ROUTES.USER_REGISTER} onClick={onRegister}>Register here.</a>
                      </div>
                      <div className="row">
                          <div className="col-md-4">
                              <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                                      onClick={onSubmit}
                                      title={'LOGIN'}/>
                          </div>
                      </div>
                  </form>
              </div>
          </div>
    </View>;
};

export default UserForm;



