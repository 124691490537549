import { RootState } from '../store/configureStore';

export const getInstructors = (state: RootState) => state?.instructor?.instructors;

export const getActiveInstructor = (state: RootState) => state?.instructor?.activeInstructor;

export const isFetchingInstructors = (state: RootState) =>
  state?.instructor?.isFetchingInstructors;

export const isPostingInstructor = (state: RootState) => state?.instructor?.isPostingInstructor;
export const postingInstructorFailed = (state: RootState) => state?.instructor?.postingInstructorFailed;
export const postingInstructorError = (state: RootState) => state?.instructor?.postingInstructorError;
export const postingInstructorValidationErrors = (state: RootState) => state?.instructor?.postingInstructorValidationErrors;


export const fetchingInstructorsFailed = (state: RootState) =>
  state?.instructor?.fetchingInstructorsFailed;
