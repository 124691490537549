import axios from 'axios';

import c from '../helpers/constants';

export function getCity(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CITY}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}