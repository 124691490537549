import { RootState } from '../store/configureStore';

export const getLocations = (state: RootState) => state?.location?.locations;

export const getActiveLocation = (state: RootState) => state?.location?.activeLocation;

export const isFetchingLocations = (state: RootState) => state?.location?.isFetchingLocations;

export const isPostingLocation = (state: RootState) => state?.location?.isPostingLocation;
export const postingLocationFailed = (state: RootState) => state?.location?.postingLocationFailed;
export const postingLocationError = (state: RootState) => state?.location?.postingLocationError;
export const postingLocationValidationErrors = (state: RootState) => state?.location?.postingLocationValidationErrors;
export const fetchingLocationsFailed = (state: RootState) => state?.location?.fetchingLocationsFailed;
