import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createActivitySlotInstructor } from '../../../actions/ActivitySlotInstructor';


import {
  
  isPostingActivitySlotInstructor,
  postingActivitySlotInstructorError,
  postingActivitySlotInstructorFailed,
  postingActivitySlotInstructorValidationErrors,
  isFetchingActivitySlotInstructors,
} from '../../../selectors/ActivitySlotInstructor';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { getInstructors } from '../../../selectors/Instructor';
import { getActivitySlots } from '../../../selectors/ActivitySlot';
import { fetchInstructors } from '../../../actions/Instructor';
import { fetchActivitySlots } from '../../../actions/ActivitySlot';



const ActivitySlotInstructorForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const activitiesSlots = useSelector(getActivitySlots);
  const instructors = useSelector(getInstructors);
  const isPosting = useSelector(isPostingActivitySlotInstructor);
  const isFetching = useSelector(isFetchingActivitySlotInstructors);
  const postingFailed = useSelector(postingActivitySlotInstructorFailed);
  const postingError = useSelector(postingActivitySlotInstructorError);
  const postingValidationErrors = useSelector(postingActivitySlotInstructorValidationErrors);



  const formConfig = [
    { field: 'activitySlotId', validators: [isRequired] },
    { field: 'instructorId', validators: [isRequired] },
    
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [activitySlotId, setActivitySlotId] = useState('');
  const [instructorId, setInstructorId] = useState('');


  useEffect(() => {
    dispatch(fetchActivitySlots());
    dispatch(fetchInstructors());
    
  }, [dispatch]);
  
  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACTIVITY_SLOT_INSTRUCTOR);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createActivitySlotInstructor(activitySlotId, instructorId ));
    }
  };



  const itemsActivitiesSlots = activitiesSlots?.data.map(r => ({ label: r.activityTitle, value: r.id })) || [];
  const itemsInstructors = instructors?.data.map(r => ({ label: r.title, value: r.id })) || [];



  const onActivitySlotSelect = (obj: any) => {
    setActivitySlotId(obj.value);
  };
  const onInstructorSelect = (obj: any) => {
    setInstructorId(obj.value);
    console.log(itemsInstructors);
  };

  return <View>
        <h4>{'Create'} ActivitySlotInstructor</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="activitySlotId"
                      placeholder="Please select an Activity Slot"
                      required label="Activity Slot"
                      items={itemsActivitiesSlots}
                      value={activitySlotId}
                      disabled={isPosting || isFetching}
                      onSelect={onActivitySlotSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="instructorId"
                      placeholder="Please select an Instructor"
                      required label="Instructor"
                      items={itemsInstructors}
                      value={instructorId}
                      disabled={isPosting || isFetching}
                      onSelect={onInstructorSelect}
                      type="default"
            />
        

            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={ 'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default ActivitySlotInstructorForm;




