import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getGroups } from '../../../selectors/Group';
import { destroyGroup, fetchGroups } from '../../../actions/Group';
import { Group } from '../../../types/Group';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const GroupDataTable = () => {

  const groups = useSelector(getGroups);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [group, setActiveGroup] = useState<Group | null>();


  useEffect(() => {
    dispatch(fetchGroups());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.GROUP_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.GROUP_UPDATE, row.id));
  };

  const onDelete = (row: Group) => {
    setActiveGroup(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (group?.id) {
      dispatch(destroyGroup(group?.id));
    }
    setActiveGroup(null);
  };


  const config = {
    columns: [
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'shortcode', label: 'Shortcode', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
      <View>
        <DataTable title="GROUP" ctaTitle="Create Group" onCreate={onCreate} data={groups?.data || []} config={config} />
        <Modal show={toggle} title="Delete Group" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this group?
        </Modal>

      </View>
  );
};

export default GroupDataTable;
