import React from 'react';
import { Switch, Route } from 'react-router-dom';
import c from '../../helpers/constants';

import UserLogin from '../../views/admin/user/UserLogin';
import Cart from '../../views/cart/Cart';
import Search from '../../views/search/Search';
import UserRegister from '../../views/admin/user/UserRegister';


const Welcome = () => {
  return (
        <Switch>
            <Route path={c.APP_ROUTES.CART} exact component={Cart}/>
            <Route path={c.APP_ROUTES.CART_SHORTCODE} exact component={Cart}/>
            <Route path={c.APP_ROUTES.USER_LOGIN} exact component={UserLogin}/>
            <Route path={c.APP_ROUTES.USER_REGISTER} exact component={UserRegister}/>
            <Route path={c.APP_ROUTES.SEARCH_SHORTCODE} exact component={Search}/>
            <Route path={c.APP_ROUTES.SEARCH} exact component={Search}/>
        </Switch>
  );
};

export default Welcome;
