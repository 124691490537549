import { RootState } from '../store/configureStore';

export const getAccountGroups = (state: RootState) => state?.accountGroup?.accountGroups;

export const isFetchingAccountGroups = (state: RootState) =>
  state?.accountGroup?.isFetchingAccountGroups;

export const isPostingAccountGroup = (state: RootState) =>
  state?.accountGroup?.isPostingAccountGroup;
export const postingAccountGroupFailed = (state: RootState) =>
  state?.accountGroup?.postingAccountGroupFailed;
export const postingAccountGroupError = (state: RootState) =>
  state?.accountGroup?.postingAccountGroupError;
export const postingAccountGroupValidationErrors = (state: RootState) =>
  state?.accountGroup?.postingAccountGroupValidationErrors;

export const fetchingAccountGroupsFailed = (state: RootState) =>
  state?.accountGroup?.fetchingAccountGroupsFailed;
