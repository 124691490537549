import { AuthActionTypes, AuthState, SET_AUTH, SET_LOGGED_IN_USER, SET_LOGGED_IN_ROLE } from '../types/Auth';

const initialState: AuthState = {
  isAuthenticated: false,
  loggedInUser: null,
  loggedInRole: null,
};

const authReducer = (
  state = initialState,
  action: AuthActionTypes,
): AuthState => {
  switch (action.type) {
    case SET_LOGGED_IN_USER:
      return {
        ...state,
        loggedInUser: action.payload,
      };
    case SET_LOGGED_IN_ROLE:
      return {
        ...state,
        loggedInRole: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};

export default authReducer;
