import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActivities } from '../../../selectors/Activity';
import { destroyActivity, fetchActivities } from '../../../actions/Activity';
import { Activity } from '../../../types/Activity';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ActivityDataTable = () => {

  const activities = useSelector(getActivities);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [activity, setActiveActivity] = useState<Activity | null>();


  useEffect(() => {
    dispatch(fetchActivities());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACTIVITY_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ACTIVITY_UPDATE, row.id));
  };

  const onDelete = (row: Activity) => {
    setActiveActivity(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (activity?.id) {
      dispatch(destroyActivity(activity?.id));
    }
    setActiveActivity(null);
  };


  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },
      { key: 'locationTitle', label: 'Location', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'pricePerPerson', label: 'Price Per Person', mobileFriendly: false },
      { key: 'currency', label: 'Currency', mobileFriendly: false },
      { key: 'vat', label: 'VAT', mobileFriendly: false },
      { key: 'color', label: 'Color', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'timeZone', label: 'Time Zone', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return <View>
    <DataTable title="ACTIVITIES" ctaTitle="Create Activity" onCreate={onCreate} data={activities?.data || []} config={config} />
    <Modal show={toggle} title="Delete Activity" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
      Are you sure you want to delete this activity?
    </Modal>

  </View>;
};

export default ActivityDataTable;
