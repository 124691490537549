import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getClients } from '../../../selectors/Client';
import { destroyClient, fetchClients } from '../../../actions/Client';
import { Client } from '../../../types/Client';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';


const ClientDataTable = () => {

  const clients = useSelector(getClients);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [toggleShortcodes, setToggleShortcodes] = useState(false);
  const [client, setActiveClient] = useState<Client | null>();


  useEffect(() => {
    dispatch(fetchClients());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CLIENT_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CLIENT_UPDATE, row.id));
  };

  const onShortcodes = (row: Client) => {
    setActiveClient(row);
    setToggleShortcodes(true);
  };

  const onOk = () => {
    setToggleShortcodes(false);
  };

  const onDelete = (row: Client) => {
    setActiveClient(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (client?.id) {
      dispatch(destroyClient(client?.id));
    }
    setActiveClient(null);
  };


  const config = {
    columns: [
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },
      { key: 'shortcode', label: 'Shortcode', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'description', label: 'Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
      { icon: 'eye', label: '', onClick: onShortcodes }],
  };

  return (
      <View>
        <DataTable title="CLIENTS" ctaTitle="Create Client" onCreate={onCreate} data={clients?.data || []} config={config} />
        <Modal show={toggle} title="Delete Client" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this client?
        </Modal>
        <Modal show={toggleShortcodes} title={'Client Shortcode'} onCta={onOk} onHide={onOk}>
          <a href={`${formatUrl(c.APP_ROUTES.SEARCH_SHORTCODE, 'RNIzqurdcr2f', client?.shortcode)}`}>
            {`app.sked.world${formatUrl(c.APP_ROUTES.SEARCH_SHORTCODE, client?.groupShortcode, client?.shortcode)}`}
          </a>
        </Modal>
      </View>
  );
};

export default ClientDataTable;
