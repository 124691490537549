import axios from 'axios';

import c from '../helpers/constants';

export function getLocationAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION_ASSET}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getLocationAssets() {
  return (
    axios({
      url: c.API_ENDPOINTS.LOCATION_ASSET,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteLocationAsset(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.LOCATION_ASSET}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postLocationAsset(
  locationId: string,
  assetId: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.LOCATION_ASSET,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        locationId,
        assetId,

      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}




