import { ApiDataModel } from './Common';

export const FETCH_CURRENCY_REQUEST = 'FETCH_CURRENCY_REQUEST';
export const FETCH_CURRENCY_RESPONSE = 'FETCH_CURRENCY_RESPONSE';
export const FETCH_CURRENCY_FAILURE = 'FETCH_CURRENCY_FAILURE';



export interface Currency {
  id: string;
  currency: string;

}

export interface CurrencyDataModel extends Omit<ApiDataModel, 'data'> {
  data: Currency[];
}

export interface CurrencyState {
  currency: CurrencyDataModel | null,
  isFetchingCurrency: boolean;
  fetchingCurrencyFailed: boolean;
}

interface FetchCurrencyRequestAction {
  type: typeof FETCH_CURRENCY_REQUEST;
  payload: null;
}

interface FetchCurrencyResponseAction {
  type: typeof FETCH_CURRENCY_RESPONSE;
  payload: CurrencyDataModel;
}

interface FetchCurrencyFailureAction {
  type: typeof FETCH_CURRENCY_FAILURE;
  payload: null;
}



export type CurrencyActionTypes =
    | FetchCurrencyRequestAction
    | FetchCurrencyResponseAction
    | FetchCurrencyFailureAction;