import axios from 'axios';

import c from '../helpers/constants';


export function getClient(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function getClients() {
  return (
    axios({
      url: c.API_ENDPOINTS.CLIENT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteClient(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}



export function postClient(groupId: string, title: string, description: string, active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.CLIENT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function putClient(
  id: string,
  groupId: string,
  title: string,
  description: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CLIENT}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        groupId,
        title,
        description,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

