import {
  FETCH_ACTIVITY_ASSET_FAILURE,
  FETCH_ACTIVITY_ASSET_REQUEST,
  FETCH_ACTIVITY_ASSET_RESPONSE,
  FETCH_ACTIVITY_ASSETS_FAILURE,
  FETCH_ACTIVITY_ASSETS_REQUEST,
  FETCH_ACTIVITY_ASSETS_RESPONSE,
  POST_ACTIVITY_ASSET_FAILURE,
  POST_ACTIVITY_ASSET_RESPONSE,
  ActivityAssetActionTypes,
  ActivityAssetState,
  POST_ACTIVITY_ASSET_REQUEST,
} from '../types/ActivityAsset';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ActivityAssetState = {
  activityAssets: null,
  activeActivityAsset: null,
  isFetchingActivityAssets: false,
  fetchingActivityAssetsFailed: false,
  isPostingActivityAsset: false,
  postingActivityAssetFailed: false,
  postingActivityAssetError: null,
  postingActivityAssetValidationErrors: null,

};

const activityAssetsReducer = (
  state = initialState,
  action: ActivityAssetActionTypes,
): ActivityAssetState => {
  switch (action.type) {
    case FETCH_ACTIVITY_ASSETS_REQUEST:
      return {
        ...state,
        activityAssets: null,
        isFetchingActivityAssets: true,
        fetchingActivityAssetsFailed: false,
      };
    case FETCH_ACTIVITY_ASSETS_RESPONSE:
      return {
        ...state,
        activityAssets: action.payload || [],
        isFetchingActivityAssets: false,
        fetchingActivityAssetsFailed: false,
      };
    case FETCH_ACTIVITY_ASSETS_FAILURE:
      return {
        ...state,
        activityAssets: null,
        isFetchingActivityAssets: false,
        fetchingActivityAssetsFailed: true,
      };
    case FETCH_ACTIVITY_ASSET_REQUEST:
      return {
        ...state,
        activityAssets: null,
        isFetchingActivityAssets: true,
        fetchingActivityAssetsFailed: false,
      };
    case FETCH_ACTIVITY_ASSET_RESPONSE:
      return {
        ...state,
        activeActivityAsset: action.payload,
        isFetchingActivityAssets: false,
        fetchingActivityAssetsFailed: false,
      };
    case FETCH_ACTIVITY_ASSET_FAILURE:
      return {
        ...state,
        activityAssets: null,
        isFetchingActivityAssets: false,
        fetchingActivityAssetsFailed: true,
      };
    case POST_ACTIVITY_ASSET_REQUEST:
      return {
        ...state,
        isPostingActivityAsset: true,
        postingActivityAssetError: null,
        postingActivityAssetValidationErrors: null,
        postingActivityAssetFailed: false,
      };
    case POST_ACTIVITY_ASSET_RESPONSE:
      return {
        ...state,
        isPostingActivityAsset: false,
        postingActivityAssetError: null,
        postingActivityAssetValidationErrors: null,
        postingActivityAssetFailed: false,
      };
    case POST_ACTIVITY_ASSET_FAILURE:
      return {
        ...state,
        isPostingActivityAsset: false,
        postingActivityAssetError: action.payload.error || 'An error occurred',
        postingActivityAssetValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingActivityAssetFailed: true,
      };

    default:
      return state;
  }
};

export default activityAssetsReducer;
