import { ReactNode, useState } from 'react';
import React from 'react';
import './DataTable.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Eye, Trash, Pencil } from 'react-bootstrap-icons';
import DropDown from '../DropDown';
import SearchBar from '../SearchBar';
import Button from '../Button';

type Column = {
  label: string;
  format?: (value: any, row: any) => string | ReactNode;
  key: string;
  hidden?: boolean;
  mobileFriendly?: boolean;
  tabletFriendly?: boolean;
};

type Action = {
  label?: string;
  icon: 'edit' | 'bin' | 'eye' | string;
  onClick?: (row: any) => void;
};

type Config = {
  columns: Column[];
  actions: Action[];
  hideControls?: boolean;
  hideOverheadControls?: boolean;
  hideButton?: boolean;
  pageSizes?: number[];
};

type Props = {
  loading?: boolean;
  error?: boolean;
  data: any[];
  config: Config;
  title?: string;
  ctaTitle?: string;
  onCreate?: () => void;
};

const DataTable = (props: Props) => {
  const {
    onCreate = () => {
    }, config: { columns = [], actions = [], pageSizes = [10, 20, 30], hideControls = false, hideOverheadControls = false, hideButton = false }, data = [], title = 'N/A', ctaTitle = 'N/A',
  } = props;
  const [pages] = useState([1, 2, 3, 4]);
  const [pageSize, setPageSize] = useState('10');
  const renderIcon = (icon: any) => {
    switch (icon) {
      case 'edit': {/* @ts-ignore */
      }
        // @ts-ignore
        return <Pencil/>;
      case 'eye': {/* @ts-ignore */
      }
        // @ts-ignore
        return <Eye/>;
      case 'bin': {/* @ts-ignore */
      }
        // @ts-ignore
        return <Trash/>;
      default:
        return '';
    }
  };

  const colClassName = (col: Column) => {
    let finalClassName = 'd-lg-table-cell';
    if (col.mobileFriendly === false) {
      finalClassName = `${finalClassName} d-none d-sm-none d-md-table-cell`;
    }
    if (col.tabletFriendly === false) {
      finalClassName = `${finalClassName} d-md-none`;
    }
    return finalClassName;
  };

  const getValue = (row: any, col: Column) => {
    const checkSplit = col.key?.split('.');
    if (checkSplit.length > 1) {
      const innerValue = checkSplit.reduce((o, i) => o[i], row);
      return col?.format ? col.format(innerValue, row) : innerValue;
    }
    return col?.format ? col.format(row[col.key], row) : row[col.key];
  };

  return (
      <>
        {!hideOverheadControls && <div className="row mb-4 d-flex mt-5">
          {!hideOverheadControls && <div className="col-md-5">
            <h4 className='blue-text'>{title}</h4>
          </div>}
          {!hideOverheadControls && <div className="col-md-5">
            <SearchBar placeholder={'Search'} defaultField={''}/>
          </div>}
          {!hideOverheadControls && !hideButton && <div className="col-md-2">
            <Button title={ctaTitle} onClick={onCreate}/>
          </div>}
        </div>}
        <div className="data__table">
          <div className="data__table__top p-2">
            <table className="table table-striped table-dark">
              <thead className='thead-dark p-3'>
              <tr>
                {columns.map((h) => (
                    <th
                        key={h.key}
                        scope="col"
                        className={colClassName(h)}
                    >
                      {h.label}
                    </th>
                ))}
                {!hideControls && <th>Actions</th>}
              </tr>
              </thead>
              <tbody>
              {data?.map((row) => (
                  <tr key={`${row.id}-row`} className="">
                    {columns.map((col) => (
                        <td valign="middle" key={`${row.id}-${col.key}`}
                            className={`d-lg-table-cell ${colClassName(col)}`}>
                          {getValue(row, col)}
                        </td>
                    ))}
                    <td valign="middle">
                      {!hideControls && <div className="data__table__actions">
                        {actions.map((action) => (
                            <div className="data__table__actions__action" key={action.icon}
                                 onClick={() => action.onClick?.(row)}>
                              {renderIcon(action.icon)}
                            </div>
                        ))}
                      </div>}
                    </td>
                  </tr>
              ))}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-center justify-content-sm-between table__footer p-3">
            <div
                className="d-sm-flex justify-content-center align-items-center flex-column table__footer__left">
              <div>Showing {data.length} out of {data.length} Entries</div>
              <div className="d-flex d-none flex-row justify-content-start align-items-center">
                <span className="pe-2">Show</span>
                {/* @ts-ignore */}
                <DropDown value={pageSize} items={pageSizes.map(s => ({ value: `${s}`, label: `${s}` }))} onChange={(val) => setPageSize(val || '10')} type='orange'/>
                <span className="px-2"> Entries</span>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column table__footer__right">
              <nav aria-label="...">
                <ul className="pagination p-0 m-0 d-none">
                  <a className="page-link next" href="#">
                    Previous
                  </a>
                  {pages.map(page =>
                      <li key={page} className="page-item active">
                        <a className="page-link" href="#">
                          {page}
                        </a>
                      </li>,
                  )}
                  <li className="page-item">
                    <a className="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li className="page-item">
                    <a className="page-link next" href="#">
                      Next
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </>
  );
};

export default DataTable;
