import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  AccountGroup,
  AccountGroupActionTypes,
  AccountGroupDataModel, FETCH_ACCOUNT_GROUP_FAILURE,
  FETCH_ACCOUNT_GROUP_REQUEST,
  FETCH_ACCOUNT_GROUP_RESPONSE,
  FETCH_ACCOUNT_GROUPS_FAILURE,
  FETCH_ACCOUNT_GROUPS_REQUEST,
  FETCH_ACCOUNT_GROUPS_RESPONSE,
  POST_ACCOUNT_GROUP_FAILURE,
  POST_ACCOUNT_GROUP_REQUEST,
  POST_ACCOUNT_GROUP_RESPONSE,
} from '../types/AccountGroup';

import { RootState } from '../store/configureStore';

import { deleteAccountGroup, getAccountGroups, postAccountGroup } from '../api/AccountGroup';



export function fetchAccountGroupsRequest(): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUPS_REQUEST,
    payload: null,
  };
}

export function fetchAccountGroupsResponse(
  accountGroups: AccountGroupDataModel,
): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUPS_RESPONSE,
    payload: accountGroups,
  };
}

export function fetchAccountGroupsFailure(): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUPS_FAILURE,
    payload: null,
  };
}

export function fetchAccountGroupRequest(): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUP_REQUEST,
    payload: null,
  };
}

export function fetchAccountGroupResponse(
  user: AccountGroup,
): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUP_RESPONSE,
    payload: user,
  };
}

export function fetchAccountGroupFailure(): AccountGroupActionTypes {
  return {
    type: FETCH_ACCOUNT_GROUP_FAILURE,
    payload: null,
  };
}

export function postAccountGroupRequest(): AccountGroupActionTypes {
  return {
    type: POST_ACCOUNT_GROUP_REQUEST,
    payload: null,
  };
}

export function postAccountGroupResponse(): AccountGroupActionTypes {
  return {
    type: POST_ACCOUNT_GROUP_RESPONSE,
    payload: null,
  };
}

export function postAccountGroupFailure(error: string, validationErrors: any): AccountGroupActionTypes {
  return {
    type: POST_ACCOUNT_GROUP_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchAccountGroups = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAccountGroupsRequest());
    const asyncResp: any = await getAccountGroups();
    if (asyncResp?.success) {
      await dispatch(fetchAccountGroupsResponse(asyncResp));
    } else {
      await dispatch(fetchAccountGroupsFailure());
    }
  };

export const destroyAccountGroup = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteAccountGroup(id);
    if (asyncResp?.success) {
      await dispatch(fetchAccountGroups());
    }
  };


export const createAccountGroup = (
  accountId: string, groupId: string,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAccountGroupRequest());
    const asyncResp: any = await postAccountGroup(accountId, groupId);
    if (asyncResp?.success) {
      await dispatch(postAccountGroupResponse());
      await dispatch(fetchAccountGroups());
    } else {
      await dispatch(postAccountGroupFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
