import React from 'react';
import './DayDate.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

type Props = {
  day: string;
  date: string;
};

const Input = (props: Props) => {
  const { day, date } = props;
  
  return (
    <div className="day-date">
      <span className='day-date__top'>{day}</span>
      <span className='day-date__bottom'>{date}</span>
    </div>
  );
};

export default Input;

