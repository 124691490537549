import axios from 'axios';

import c from '../helpers/constants';

export function getRoleAccount(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ROLE_ACCOUNT}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getRoleAccounts() {
  return (
    axios({
      url: c.API_ENDPOINTS.ROLE_ACCOUNT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteRoleAccount(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ROLE_ACCOUNT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postRoleAccount(roleId: string, accountId: string ) {
  return (
    axios({
      url: c.API_ENDPOINTS.ROLE_ACCOUNT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        roleId,
        accountId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

