import {
  FETCH_ROLE_GROUP_FAILURE,
  FETCH_ROLE_GROUP_REQUEST,
  FETCH_ROLE_GROUP_RESPONSE,
  FETCH_ROLE_GROUPS_FAILURE,
  FETCH_ROLE_GROUPS_REQUEST,
  FETCH_ROLE_GROUPS_RESPONSE,
  POST_ROLE_GROUP_FAILURE,
  POST_ROLE_GROUP_RESPONSE,
  RoleGroupActionTypes,
  RoleGroupState,
  POST_ROLE_GROUP_REQUEST,
} from '../types/RoleGroup';
import { parseValidationErrors } from '../helpers/functions';

const initialState: RoleGroupState = {
  roleGroups: null,
  activeRoleGroup: null,
  isFetchingRoleGroups: false,
  fetchingRoleGroupsFailed: false,
  isPostingRoleGroup: false,
  postingRoleGroupFailed: false,
  postingRoleGroupError: null,
  postingRoleGroupValidationErrors: null,

};

const roleGroupsReducer = (
  state = initialState,
  action: RoleGroupActionTypes,
): RoleGroupState => {
  switch (action.type) {
    case FETCH_ROLE_GROUPS_REQUEST:
      return {
        ...state,
        roleGroups: null,
        isFetchingRoleGroups: true,
        fetchingRoleGroupsFailed: false,
      };
    case FETCH_ROLE_GROUPS_RESPONSE:
      return {
        ...state,
        roleGroups: action.payload || [],
        isFetchingRoleGroups: false,
        fetchingRoleGroupsFailed: false,
      };
    case FETCH_ROLE_GROUPS_FAILURE:
      return {
        ...state,
        roleGroups: null,
        isFetchingRoleGroups: false,
        fetchingRoleGroupsFailed: true,
      };
    case FETCH_ROLE_GROUP_REQUEST:
      return {
        ...state,
        roleGroups: null,
        isFetchingRoleGroups: true,
        fetchingRoleGroupsFailed: false,
      };
    case FETCH_ROLE_GROUP_RESPONSE:
      return {
        ...state,
        activeRoleGroup: action.payload,
        isFetchingRoleGroups: false,
        fetchingRoleGroupsFailed: false,
      };
    case FETCH_ROLE_GROUP_FAILURE:
      return {
        ...state,
        roleGroups: null,
        isFetchingRoleGroups: false,
        fetchingRoleGroupsFailed: true,
      };
    case POST_ROLE_GROUP_REQUEST:
      return {
        ...state,
        isPostingRoleGroup: true,
        postingRoleGroupError: null,
        postingRoleGroupValidationErrors: null,
        postingRoleGroupFailed: false,
      };
    case POST_ROLE_GROUP_RESPONSE:
      return {
        ...state,
        isPostingRoleGroup: false,
        postingRoleGroupError: null,
        postingRoleGroupValidationErrors: null,
        postingRoleGroupFailed: false,
      };
    case POST_ROLE_GROUP_FAILURE:
      return {
        ...state,
        isPostingRoleGroup: false,
        postingRoleGroupError: action.payload.error || 'An error occurred',
        postingRoleGroupValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingRoleGroupFailed: true,
      };

    default:
      return state;
  }
};

export default roleGroupsReducer;

