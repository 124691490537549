import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_RESPONSE,
  FETCH_ROLES_FAILURE,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_RESPONSE,
  FETCH_ROLE_FAILURE,
  POST_ROLE_FAILURE,
  POST_ROLE_REQUEST,
  POST_ROLE_RESPONSE,
  RoleActionTypes, RoleDataModel, Role,
} from '../types/Role';

import { RootState } from '../store/configureStore';

import { getRole, getRoles, postRole, putRole, deleteRole } from '../api/Role';


export function fetchRolesRequest(): RoleActionTypes {
  return {
    type: FETCH_ROLES_REQUEST,
    payload: null,
  };
}

export function fetchRolesResponse(
  roles: RoleDataModel,
): RoleActionTypes {
  return {
    type: FETCH_ROLES_RESPONSE,
    payload: roles,
  };
}

export function fetchRolesFailure(): RoleActionTypes {
  return {
    type: FETCH_ROLES_FAILURE,
    payload: null,
  };
}

export function fetchRoleRequest(): RoleActionTypes {
  return {
    type: FETCH_ROLE_REQUEST,
    payload: null,
  };
}

export function fetchRoleResponse(
  role: Role,
): RoleActionTypes {
  return {
    type: FETCH_ROLE_RESPONSE,
    payload: role,
  };
}

export function fetchRoleFailure(): RoleActionTypes {
  return {
    type: FETCH_ROLE_FAILURE,
    payload: null,
  };
}

export const fetchActiveRole = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRoleRequest());
    const asyncResp: any = await getRole(id);
    if (asyncResp?.success) {
      await dispatch(fetchRoleResponse(asyncResp.data));
    } else {
      await dispatch(fetchRoleFailure());
    }
  };

export function postRoleRequest(): RoleActionTypes {
  return {
    type: POST_ROLE_REQUEST,
    payload: null,
  };
}

export function postRoleResponse(): RoleActionTypes {
  return {
    type: POST_ROLE_RESPONSE,
    payload: null,
  };
}

export function postRoleFailure(error: string, validationErrors: any): RoleActionTypes {
  return {
    type: POST_ROLE_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchRoles = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchRolesRequest());
    const asyncResp: any = await getRoles();
    if (asyncResp?.success) {
      await dispatch(fetchRolesResponse(asyncResp));
    } else {
      await dispatch(fetchRolesFailure());
    }
  };

export const destroyRole = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteRole(id);
    if (asyncResp?.success) {
      await dispatch(fetchRoles());
    }
  };


export const createRole = (
  title: string, description: string, accessLevel: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postRoleRequest());
    const asyncResp: any = await postRole(title, description, accessLevel, active);
    if (asyncResp?.success) {
      await dispatch(postRoleResponse());
      await dispatch(fetchRoles());
    } else {
      await dispatch(postRoleFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateRole = (
  id: string,
  title: string,
  description: string,
  accessLevel: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postRoleRequest());
    const asyncResp: any = await putRole(id, title, description, accessLevel, active);
    if (asyncResp?.success) {
      await dispatch(postRoleResponse());
      await dispatch(fetchRoles());
    } else {
      await dispatch(postRoleFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };



