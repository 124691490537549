import {
  FETCH_ACCOUNT_USER_FAILURE,
  FETCH_ACCOUNT_USER_REQUEST,
  FETCH_ACCOUNT_USER_RESPONSE,
  FETCH_ACCOUNT_USERS_FAILURE,
  FETCH_ACCOUNT_USERS_REQUEST,
  FETCH_ACCOUNT_USERS_RESPONSE,
  POST_ACCOUNT_USER_FAILURE,
  POST_ACCOUNT_USER_RESPONSE,
  AccountUserActionTypes,
  AccountUserState,
  POST_ACCOUNT_USER_REQUEST,
} from '../types/AccountUser';
import { parseValidationErrors } from '../helpers/functions';

const initialState: AccountUserState = {
  accountUsers: null,
  activeAccountUser: null,
  isFetchingAccountUsers: false,
  fetchingAccountUsersFailed: false,
  isPostingAccountUser: false,
  postingAccountUserFailed: false,
  postingAccountUserError: null,
  postingAccountUserValidationErrors: null,

};

const accountUsersReducer = (
  state = initialState,
  action: AccountUserActionTypes,
): AccountUserState => {
  switch (action.type) {
    case FETCH_ACCOUNT_USERS_REQUEST:
      return {
        ...state,
        accountUsers: null,
        isFetchingAccountUsers: true,
        fetchingAccountUsersFailed: false,
      };
    case FETCH_ACCOUNT_USERS_RESPONSE:
      return {
        ...state,
        accountUsers: action.payload || [],
        isFetchingAccountUsers: false,
        fetchingAccountUsersFailed: false,
      };
    case FETCH_ACCOUNT_USERS_FAILURE:
      return {
        ...state,
        accountUsers: null,
        isFetchingAccountUsers: false,
        fetchingAccountUsersFailed: true,
      };
    case FETCH_ACCOUNT_USER_REQUEST:
      return {
        ...state,
        accountUsers: null,
        isFetchingAccountUsers: true,
        fetchingAccountUsersFailed: false,
      };
    case FETCH_ACCOUNT_USER_RESPONSE:
      return {
        ...state,
        activeAccountUser: action.payload,
        isFetchingAccountUsers: false,
        fetchingAccountUsersFailed: false,
      };
    case FETCH_ACCOUNT_USER_FAILURE:
      return {
        ...state,
        accountUsers: null,
        isFetchingAccountUsers: false,
        fetchingAccountUsersFailed: true,
      };
    case POST_ACCOUNT_USER_REQUEST:
      return {
        ...state,
        isPostingAccountUser: true,
        postingAccountUserError: null,
        postingAccountUserValidationErrors: null,
        postingAccountUserFailed: false,
      };
    case POST_ACCOUNT_USER_RESPONSE:
      return {
        ...state,
        isPostingAccountUser: false,
        postingAccountUserError: null,
        postingAccountUserValidationErrors: null,
        postingAccountUserFailed: false,
      };
    case POST_ACCOUNT_USER_FAILURE:
      return {
        ...state,
        isPostingAccountUser: false,
        postingAccountUserError: action.payload.error || 'An error occurred',
        postingAccountUserValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingAccountUserFailed: true,
      };

    default:
      return state;
  }
};

export default accountUsersReducer;

