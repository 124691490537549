import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_RESPONSE,
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_RESPONSE,
  FETCH_ACTIVITY_FAILURE,
  POST_ACTIVITY_FAILURE,
  POST_ACTIVITY_REQUEST,
  POST_ACTIVITY_RESPONSE,
  ActivityActionTypes, ActivityDataModel, Activity,
} from '../types/Activity';

import { RootState } from '../store/configureStore';

import {
  getActivity,
  getActivities,
  postActivity,
  putActivity,
  deleteActivity,
  getActivitiesByShortcodes,
} from '../api/Activity';


export function fetchActivitiesRequest(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES_REQUEST,
    payload: null,
  };
}

export function fetchActivitiesResponse(
  activities: ActivityDataModel,
): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES_RESPONSE,
    payload: activities,
  };
}

export function fetchActivitiesFailure(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITIES_FAILURE,
    payload: null,
  };
}

export function fetchActivityRequest(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITY_REQUEST,
    payload: null,
  };
}

export function fetchActivityResponse(
  activity: Activity,
): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITY_RESPONSE,
    payload: activity,
  };
}

export function fetchActivityFailure(): ActivityActionTypes {
  return {
    type: FETCH_ACTIVITY_FAILURE,
    payload: null,
  };
}


export function postActivityRequest(): ActivityActionTypes {
  return {
    type: POST_ACTIVITY_REQUEST,
    payload: null,
  };
}

export function postActivityResponse(): ActivityActionTypes {
  return {
    type: POST_ACTIVITY_RESPONSE,
    payload: null,
  };
}

export function postActivityFailure(error: string, validationErrors: any): ActivityActionTypes {
  return {
    type: POST_ACTIVITY_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveActivity = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivityRequest());
    const asyncResp: any = await getActivity(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivityResponse(asyncResp.data));
    } else {
      await dispatch(fetchActivityFailure());
    }
  };

export const fetchActivities = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitiesRequest());
    const asyncResp: any = await getActivities();
    if (asyncResp?.success) {
      await dispatch(fetchActivitiesResponse(asyncResp));
    } else {
      await dispatch(fetchActivitiesFailure());
    }
  };

export const fetchActivitiesByShortcodes = (groupShortcode: string, clientShortcode: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchActivitiesRequest());
    const asyncResp: any = await getActivitiesByShortcodes(groupShortcode, clientShortcode);
    if (asyncResp?.success) {
      await dispatch(fetchActivitiesResponse(asyncResp));
    } else {
      await dispatch(fetchActivitiesFailure());
    }
  };

export const destroyActivity = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteActivity(id);
    if (asyncResp?.success) {
      await dispatch(fetchActivities());
    }
  };



export const createActivity = (
  groupId: string,
  clientId: string,
  locationId: string,
  title: string,
  description: string,
  pricePerPerson: number,
  currency: string,
  vat: number,
  color: string,
  timeZone: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivityRequest());
    const asyncResp: any = await postActivity(groupId, clientId, locationId, title, description, pricePerPerson, currency, vat, color, timeZone, active);
    if (asyncResp?.success) {
      await dispatch(postActivityResponse());
      await dispatch(fetchActivities());
    } else {
      await dispatch(postActivityFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateActivity = (
  id: string,
  groupId: string,
  clientId: string,
  locationId: string,
  title: string,
  description: string,
  pricePerPerson: number,
  currency: string,
  vat: number,
  color: string,
  timeZone: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postActivityRequest());
    const asyncResp: any = await putActivity(id, groupId, clientId, locationId, title, description, pricePerPerson, currency, vat, color, timeZone, active);
    if (asyncResp?.success) {
      await dispatch(postActivityResponse());
      await dispatch(fetchActivities());
    } else {
      await dispatch(postActivityFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };