import axios from 'axios';

import c from '../helpers/constants';

export function getActivitySlotInstructor(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT_INSTRUCTOR}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getActivitySlotInstructors() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_SLOT_INSTRUCTOR,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteActivitySlotInstructor(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACTIVITY_SLOT_INSTRUCTOR}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}


export function postActivitySlotInstructor(
  activitySlotId: string,
  instructorId: string,
) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACTIVITY_SLOT_INSTRUCTOR,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        activitySlotId,
        instructorId,
       
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}




