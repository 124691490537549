import axios from 'axios';

import c from '../helpers/constants';

export function getAccountClient(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACCOUNT_CLIENT}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getAccountClients() {
  return (
    axios({
      url: c.API_ENDPOINTS.ACCOUNT_CLIENT,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteAccountClient(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.ACCOUNT_CLIENT}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function postAccountClient(accountId: string, clientId: string ) {
  return (
    axios({
      url: c.API_ENDPOINTS.ACCOUNT_CLIENT,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        accountId,
        clientId,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

