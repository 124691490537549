import React, { useState } from 'react';
import { endOfISOWeek, startOfISOWeek, eachDayOfInterval, subDays, addDays, format, isWithinInterval, parseISO } from 'date-fns';

// @ts-ignore
import ArrowRight from '../../assets/svgs/arrow-circle-right.svg';
// @ts-ignore
import ArrowLeft from '../../assets/svgs/arrow-circle-left.svg';
import './Calendar.scss';
import DayDate from '../DayDate';
import { ActivitySlot } from '../../types/ActivitySlot';
import ActivityTile from '../ActivityTile';
import { getTimezoneOffset } from 'date-fns-tz';

type Props = {
  activitySlots?: ActivitySlot[];
  onSlotClick?: (day: Date, slot: ActivitySlot) => void;
};

const Calendar = (props: Props) => {
  const { activitySlots, onSlotClick = () => {} } = props;
  const today = new Date();
  const [targetDate, setTargetDate] = useState(today);
  const start = startOfISOWeek(targetDate);
  const end = endOfISOWeek(targetDate);
  const daysOfWeek = eachDayOfInterval({ start, end }, { step: 1 });
  const onPreviousWeek = () => {
    setTargetDate(subDays(targetDate, 7));
  };
  const onNextWeek = () => {
    setTargetDate(addDays(targetDate, 7));
  };

  // @ts-ignore
  // const timeZoneOffset = getTimezoneOffset(activeSlot?.activityTimeZone);
  // const timeZone = (Math.floor(timeZoneOffset / 3600000));
  // const test = timeZone.toString();
  // let GMT = '';
  // if (timeZone >= 0) {
  //   GMT = `GMT +${test}`;
  // } else {
  //   GMT = `GMT ${test}`;
  // }

  const renderDay = (d: Date, i: number) => {
    const liveActivities = activitySlots?.filter(a => isWithinInterval(d, { start: parseISO(a.validFrom), end: parseISO(a.validUntil) } ) && a.active);
    const dailyActivities = liveActivities?.filter((a) => d.getDay() === a.dayOfTheWeek);
    return (<div key={i} className="col" >
        {dailyActivities?.map(slot => (<ActivityTile
            key={slot.id}
            onClick={() => onSlotClick(d, slot)}
            title={slot.activityTitle}
            time={`${slot.startTime} - ${slot.endTime} GMT ${(Math.floor(getTimezoneOffset(slot.activityTimeZone)) / 3600000) < 0 ? '' : '+'}${(Math.floor(getTimezoneOffset(slot.activityTimeZone)) / 3600000) }`}
            instructor={slot.instructorTitle}
            capacity={slot.capacity}
            booked={0} color={slot.activityColor} />))}
    </div>);
  };

  return (
        <div className="calendar">
            <div className="container-fluid">
                <div className="row calendar__header">
                    <div className='calendar__arrow-left' onClick={onPreviousWeek}>
                        <img src={ArrowLeft} alt=''/>
                    </div>
                    <div className='calendar__arrow-right' onClick={onNextWeek}>
                        <img src={ArrowRight} alt=''/>
                    </div>
                    {daysOfWeek.map(d => <div
                        key={format(d, 't')}
                        className={`col ${format(d, 'EEEE').toLowerCase()}`}
                    >
                        <DayDate day={format(d, 'EEEE')} date={format(d, 'd LLL yyy')}/>
                    </div>)}
                </div>
                <div className="row mt-2 g-4">
                    {daysOfWeek.map(renderDay)}
                </div>
            </div>
        </div>
  );
};

export default Calendar;

