import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_RESPONSE,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_RESPONSE,
  FETCH_CLIENT_FAILURE,
  POST_CLIENT_FAILURE,
  POST_CLIENT_REQUEST,
  POST_CLIENT_RESPONSE,
  ClientActionTypes, ClientDataModel, Client,
} from '../types/Client';

import { RootState } from '../store/configureStore';

import { getClient, getClients, postClient, putClient, deleteClient } from '../api/Client';

export function fetchClientsRequest(): ClientActionTypes {
  return {
    type: FETCH_CLIENTS_REQUEST,
    payload: null,
  };
}

export function fetchClientsResponse(
  clients: ClientDataModel,
): ClientActionTypes {
  return {
    type: FETCH_CLIENTS_RESPONSE,
    payload: clients,
  };
}

export function fetchClientsFailure(): ClientActionTypes {
  return {
    type: FETCH_CLIENTS_FAILURE,
    payload: null,
  };
}

export function fetchClientRequest(): ClientActionTypes {
  return {
    type: FETCH_CLIENT_REQUEST,
    payload: null,
  };
}

export function fetchClientResponse(
  client: Client,
): ClientActionTypes {
  return {
    type: FETCH_CLIENT_RESPONSE,
    payload: client,
  };
}

export function fetchClientFailure(): ClientActionTypes {
  return {
    type: FETCH_CLIENT_FAILURE,
    payload: null,
  };
}


export function postClientRequest(): ClientActionTypes {
  return {
    type: POST_CLIENT_REQUEST,
    payload: null,
  };
}

export function postClientResponse(): ClientActionTypes {
  return {
    type: POST_CLIENT_RESPONSE,
    payload: null,
  };
}

export function postClientFailure(error: string, validationErrors: any): ClientActionTypes {
  return {
    type: POST_CLIENT_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export const fetchActiveClient = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchClientRequest());
    const asyncResp: any = await getClient(id);
    if (asyncResp?.success) {
      await dispatch(fetchClientResponse(asyncResp.data));
    } else {
      await dispatch(fetchClientFailure());
    }
  };

export const fetchClients = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchClientsRequest());
    const asyncResp: any = await getClients();
    if (asyncResp?.success) {
      await dispatch(fetchClientsResponse(asyncResp));
    } else {
      await dispatch(fetchClientsFailure());
    }
  };

export const destroyClient = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteClient(id);
    if (asyncResp?.success) {
      await dispatch(fetchClients());
    }
  };


export const createClient = (
  groupId: string, title: string, description: string, active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postClientRequest());
    const asyncResp: any = await postClient(groupId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postClientResponse());
      await dispatch(fetchClients());
    } else {
      await dispatch(postClientFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const updateClient = (
  id: string,
  groupId: string,
  title: string,
  description: string,
  active: boolean,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postClientRequest());
    const asyncResp: any = await putClient(id, groupId, title, description, active);
    if (asyncResp?.success) {
      await dispatch(postClientResponse());
      await dispatch(fetchClients());
    } else {
      await dispatch(postClientFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };
