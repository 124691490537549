import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAccountGroup   } from '../../../actions/AccountGroup';
import { getAccounts } from '../../../selectors/Account';
import { getGroups } from '../../../selectors/Group';
import { fetchAccounts } from '../../../actions/Account';
import { fetchGroups } from '../../../actions/Group';


import {

  isPostingAccountGroup,
  postingAccountGroupError,
  postingAccountGroupFailed,
  postingAccountGroupValidationErrors,
  isFetchingAccountGroups,
} from '../../../selectors/AccountGroup';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const AccountGroupForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accounts = useSelector(getAccounts);
  const groups = useSelector(getGroups);
  const isPosting = useSelector(isPostingAccountGroup);
  const isFetching = useSelector(isFetchingAccountGroups);
  const postingFailed = useSelector(postingAccountGroupFailed);
  const postingError = useSelector(postingAccountGroupError);
  const postingValidationErrors = useSelector(postingAccountGroupValidationErrors);



  const formConfig = [
    { field: 'accountId', validators: [isRequired] },
    { field: 'groupId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [accountId, setAccountId] = useState('');
  const [groupId, setGroupId] = useState('');

  useEffect(() => {
    dispatch(fetchAccounts());
    dispatch(fetchGroups());

  }, [dispatch]);





  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACCOUNT_GROUP);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createAccountGroup(accountId, groupId ));
    }
  };



  const itemsAccounts = accounts?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
  };
  const onAccountSelect = (obj: any) => {
    setAccountId(obj.value);
  };

  return <View>
        <h4>Create AccountGroup</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
        {postingError}
        </Alert>
    )}
    <form>
        <DropDown v={v} err={err}
    id="accountId"
    placeholder="Please select an Account"
    required label="Account"
    items={itemsAccounts}
    value={accountId}
    disabled={isPosting || isFetching}
    onSelect={onAccountSelect}
    type="default"
    />
    <DropDown v={v} err={err}
    id="groupId"
    placeholder="Please select a Group"
    required label="Group"
    items={itemsGroups}
    value={groupId}
    disabled={isPosting || isFetching}
    onSelect={onGroupSelect}
    type="default"
    />


    <div className="row">
    <div className="col-md-4">
    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
    onClick={() => onSubmit()}
    title={'CREATE'}/>
    </div>
    </div>
    </form>
    </View>;
};

export default AccountGroupForm;



