import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAccounts } from '../../../selectors/Account';
import { destroyAccount, fetchAccounts } from '../../../actions/Account';
import { Account } from '../../../types/Account';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const AccountDataTable = () => {

  const accounts = useSelector(getAccounts);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [account, setActiveAccount] = useState<Account | null>();


  useEffect(() => {
    dispatch(fetchAccounts());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACCOUNT_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.ACCOUNT_UPDATE, row.id));
  };

  const onDelete = (row: Account) => {
    setActiveAccount(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (account?.id) {
      dispatch(destroyAccount(account?.id));
    }
    setActiveAccount(null);
  };


  const config = {
    columns: [
      { key: 'title', label: 'Account Title', mobileFriendly: true },
      { key: 'description', label: 'Account Description', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
          <DataTable title="ACCOUNTS" ctaTitle="Create Account" onCreate={onCreate} data={accounts?.data || []} config={config} />
          <Modal show={toggle} title="Delete Account" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to delete this account?
            </Modal>

        </View>
  );
};

export default AccountDataTable;
