import { User } from './User';
import { Role } from './Role';

export const SET_AUTH = 'SET_AUTH';
export const GET_AUTH = 'GET_AUTH';

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';

export const SET_LOGGED_IN_ROLE = 'SET_LOGGED_IN_ROLE';

export interface AuthState {
  isAuthenticated: boolean;
  loggedInUser: User | null;
  loggedInRole: Role | null;
}

interface SetAuthAction {
  type: typeof SET_AUTH;
  payload: {
    username: string;
    password: string;
  };
}

interface SetLoggedInUserAction {
  type: typeof SET_LOGGED_IN_USER;
  payload: User | null;
}

interface SetLoggedInRoleAction {
  type: typeof SET_LOGGED_IN_ROLE;
  payload: Role | null;
}

interface GetAuthAction {
  type: typeof GET_AUTH;
  payload: null;
}

export type AuthActionTypes =
    | SetLoggedInRoleAction
    | SetLoggedInUserAction
    | SetAuthAction
    | GetAuthAction;
