import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getCustomers } from '../../../selectors/Customer';
import { destroyCustomer, fetchCustomers } from '../../../actions/Customer';
import { Customer } from '../../../types/Customer';
import DataTable from '../../../components/DataTable';
import { formatUrl } from '../../../helpers/functions';
import Modal from '../../../components/Modal';

const CustomerDataTable = () => {

  const customers = useSelector(getCustomers);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [customer, setActiveCustomer] = useState<Customer | null>();


  useEffect(() => {
    dispatch(fetchCustomers());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.CUSTOMER_CREATE);
  };

  const onUpdate = (row: any) => {
    history.push(formatUrl(c.APP_ROUTES.CUSTOMER_UPDATE, row.id));
  };

  const onDelete = (row: Customer) => {
    setActiveCustomer(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (customer?.id) {
      dispatch(destroyCustomer(customer?.id));
    }
    setActiveCustomer(null);
  };


  const config = {
    columns: [
      { key: 'userEmail', label: 'User', mobileFriendly: true },
      { key: 'title', label: 'Title', mobileFriendly: true },
      { key: 'email', label: 'Customer Email', mobileFriendly: true },
      { key: 'firstName', label: 'First Name', mobileFriendly: true },
      { key: 'lastName', label: 'Last Name', mobileFriendly: true },
      { key: 'phone', label: 'Phone', mobileFriendly: false },
      { key: 'address', label: 'Address', mobileFriendly: false },
      { key: 'city', label: 'City', mobileFriendly: false },
      { key: 'postcode', label: 'Postcode', mobileFriendly: false },
      { key: 'country', label: 'Country', mobileFriendly: false },
      { key: 'active', label: 'Active', mobileFriendly: false },
      { key: 'created', label: 'Created At', mobileFriendly: false },
      { key: 'updated', label: 'Updated At', mobileFriendly: false },
    ],
    actions: [
      { icon: 'edit', label: '', onClick: onUpdate },
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
      <View>
        <DataTable title="CUSTOMERS" ctaTitle="Create Customer" onCreate={onCreate} data={customers?.data || []} config={config} />
        <Modal show={toggle} title="Delete Customer" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
          Are you sure you want to delete this customer?
        </Modal>

      </View>
  );
};

export default CustomerDataTable;
