import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getActivitySlotInstructors } from '../../../selectors/ActivitySlotInstructor';
import { destroyActivitySlotInstructor, fetchActivitySlotInstructors } from '../../../actions/ActivitySlotInstructor';
import { ActivitySlotInstructor } from '../../../types/ActivitySlotInstructor';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const ActivitySlotInstructorDataTable = () => {

  const activitySlotInstructors = useSelector(getActivitySlotInstructors);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [activitySlotInstructor, setActiveActivitySlotInstructor] = useState<ActivitySlotInstructor | null>();


  useEffect(() => {
    dispatch(fetchActivitySlotInstructors());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACTIVITY_SLOT_INSTRUCTOR_CREATE);
  };



  const onDelete = (row: ActivitySlotInstructor) => {
    setActiveActivitySlotInstructor(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (activitySlotInstructor?.id) {
      dispatch(destroyActivitySlotInstructor(activitySlotInstructor?.id));
    }
    setActiveActivitySlotInstructor(null);
  };


  const config = {
    columns: [
      { key: 'activitySlotValidFrom', label: 'Activity Slot', mobileFriendly: true },
      { key: 'instructorLastName', label: 'Instructor', mobileFriendly: true },

    ],
    actions: [

      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ACTIVITY SLOT/INSTRUCTOR" ctaTitle="Link Activity Slot/Instructor" onCreate={onCreate} data={activitySlotInstructors?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Activity Slot/Instructor" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Activity Slot/Instructor link?
            </Modal>

        </View>
  );
};

export default ActivitySlotInstructorDataTable;
