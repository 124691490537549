import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleGroups } from '../../../selectors/RoleGroup';
import { destroyRoleGroup, fetchRoleGroups } from '../../../actions/RoleGroup';
import { RoleGroup } from '../../../types/RoleGroup';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const RoleGroupDataTable = () => {

  const roleGroups = useSelector(getRoleGroups);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [roleGroup, setActiveRoleGroup] = useState<RoleGroup | null>();


  useEffect(() => {
    dispatch(fetchRoleGroups());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ROLE_GROUP_CREATE);
  };



  const onDelete = (row: RoleGroup) => {
    setActiveRoleGroup(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (roleGroup?.id) {
      dispatch(destroyRoleGroup(roleGroup?.id));
    }
    setActiveRoleGroup(null);
  };


  const config = {
    columns: [
      { key: 'roleTitle', label: 'Role', mobileFriendly: true },
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ROLE/GROUP" ctaTitle="Link Role/Group" onCreate={onCreate} data={roleGroups?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Role/Group" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Role/Group link?
            </Modal>
        </View>
  );
};

export default RoleGroupDataTable;
