import {
  FETCH_CLIENT_FAILURE,
  FETCH_CLIENT_REQUEST,
  FETCH_CLIENT_RESPONSE,
  FETCH_CLIENTS_FAILURE,
  FETCH_CLIENTS_REQUEST,
  FETCH_CLIENTS_RESPONSE,
  POST_CLIENT_FAILURE,
  POST_CLIENT_RESPONSE,
  ClientActionTypes,
  ClientState,
  POST_CLIENT_REQUEST,
} from '../types/Client';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ClientState = {
  clients: null,
  activeClient: null,
  isFetchingClients: false,
  fetchingClientsFailed: false,
  isPostingClient: false,
  postingClientFailed: false,
  postingClientError: null,
  postingClientValidationErrors: null,
};

const clientsReducer = (
  state = initialState,
  action: ClientActionTypes,
): ClientState => {
  switch (action.type) {
    case FETCH_CLIENTS_REQUEST:
      return {
        ...state,
        clients: null,
        isFetchingClients: true,
        fetchingClientsFailed: false,
      };
    case FETCH_CLIENTS_RESPONSE:
      return {
        ...state,
        clients: action.payload || [],
        isFetchingClients: false,
        fetchingClientsFailed: false,
      };
    case FETCH_CLIENTS_FAILURE:
      return {
        ...state,
        clients: null,
        isFetchingClients: false,
        fetchingClientsFailed: true,
      };
    case FETCH_CLIENT_REQUEST:
      return {
        ...state,
        clients: null,
        isFetchingClients: true,
        fetchingClientsFailed: false,
      };
    case FETCH_CLIENT_RESPONSE:
      return {
        ...state,
        activeClient: action.payload,
        isFetchingClients: false,
        fetchingClientsFailed: false,
      };
    case FETCH_CLIENT_FAILURE:
      return {
        ...state,
        clients: null,
        isFetchingClients: false,
        fetchingClientsFailed: true,
      };
    case POST_CLIENT_REQUEST:
      return {
        ...state,
        isPostingClient: true,
        postingClientError: null,
        postingClientValidationErrors: null,
        postingClientFailed: false,
      };
    case POST_CLIENT_RESPONSE:
      return {
        ...state,
        isPostingClient: false,
        postingClientError: null,
        postingClientValidationErrors: null,
        postingClientFailed: false,
      };
    case POST_CLIENT_FAILURE:
      return {
        ...state,
        isPostingClient: false,
        postingClientError: action.payload.error || 'An error occurred',
        postingClientValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingClientFailed: true,
      };
    default:
      return state;
  }
};

export default clientsReducer;
