import React, { useEffect } from 'react';
import Router from '../Router';
import './App.scss';
import { setCart } from '../../actions/Order';
import { useDispatch, useSelector } from 'react-redux';
import Themes from '../Themes/Themes';
import { getToken, getUserToken } from '../../helpers/functions';
import { fetchLoggedInUser } from '../../actions/Auth';
import { getActiveConfiguration } from '../../selectors/Configuration';
import { useRouteMatch } from 'react-router-dom';
import c from '../../helpers/constants';
import Welcome from '../Welcome/Welcome';


function App() {
  const dispatch = useDispatch();
  const activeConfiguration = useSelector(getActiveConfiguration);

  const matchUpdate = useRouteMatch(c.APP_ROUTES.SEARCH_SHORTCODE);
  // @ts-ignore
  const groupShortcode = matchUpdate?.params?.groupShortcode;
  // @ts-ignore
  const clientShortcode = matchUpdate?.params?.clientShortcode;

  useEffect(() => {
    if (getToken()) {
      dispatch(fetchLoggedInUser());
    }
  }, [dispatch]);
  
  useEffect(() => {
    const local = localStorage.getItem('cartItems') || '';
    if (localStorage.getItem('cartItems')){
      const items = JSON.parse(local);
      dispatch(setCart(items));
    }
  }, [dispatch]);

  return (
        <div className="app">
          <Themes/>
          {activeConfiguration && (getUserToken() || (groupShortcode && clientShortcode)) && <Router/>}
          {!activeConfiguration && (!getUserToken() || (!groupShortcode && !clientShortcode)) && <Welcome/>}
        </div>
  );
}

export default App;
