import { RootState } from '../store/configureStore';

export const getAssets = (state: RootState) => state?.asset?.assets;

export const getActiveAsset = (state: RootState) => state?.asset?.activeAsset;

export const isFetchingAssets = (state: RootState) => state?.asset?.isFetchingAssets;

export const isPostingAsset = (state: RootState) => state?.asset?.isPostingAsset;
export const postingAssetFailed = (state: RootState) => state?.asset?.postingAssetFailed;
export const postingAssetError = (state: RootState) => state?.asset?.postingAssetError;
export const postingAssetValidationErrors = (state: RootState) => state?.asset?.postingAssetValidationErrors;


export const fetchingAssetsFailed = (state: RootState) => state?.asset?.fetchingAssetsFailed;
