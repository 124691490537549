import { ApiDataModel, FormError } from './Common';

export const FETCH_INSTRUCTORS_REQUEST = 'FETCH_INSTRUCTORS_REQUEST';
export const FETCH_INSTRUCTORS_RESPONSE = 'FETCH_INSTRUCTORS_RESPONSE';
export const FETCH_INSTRUCTORS_FAILURE = 'FETCH_INSTRUCTORS_FAILURE';

export const FETCH_INSTRUCTOR_REQUEST = 'FETCH_INSTRUCTOR_REQUEST';
export const FETCH_INSTRUCTOR_RESPONSE = 'FETCH_INSTRUCTOR_RESPONSE';
export const FETCH_INSTRUCTOR_FAILURE = 'FETCH_INSTRUCTOR_FAILURE';

export const POST_INSTRUCTOR_REQUEST = 'POST_INSTRUCTOR_REQUEST';
export const POST_INSTRUCTOR_RESPONSE = 'POST_INSTRUCTOR_RESPONSE';
export const POST_INSTRUCTOR_FAILURE = 'POST_INSTRUCTOR_FAILURE';

export interface Instructor {
  id: string,
  userId: string,
  groupId: string,
  clientId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean
  created: Date;
  updated: Date;
}

export interface InstructorDataModel extends Omit<ApiDataModel, 'data'> {
  data: Instructor[];
}

export interface InstructorState {
  instructors: InstructorDataModel | null,
  activeInstructor: Instructor | null,
  isFetchingInstructors: boolean;
  fetchingInstructorsFailed: boolean;
  postingInstructorFailed: boolean;
  isPostingInstructor: boolean;
  postingInstructorValidationErrors: FormError[] | null;
  postingInstructorError: string | null;
}

interface FetchInstructorsRequestAction {
  type: typeof FETCH_INSTRUCTORS_REQUEST;
  payload: null;
}

interface FetchInstructorsResponseAction {
  type: typeof FETCH_INSTRUCTORS_RESPONSE;
  payload: InstructorDataModel;
}

interface FetchInstructorsFailureAction {
  type: typeof FETCH_INSTRUCTORS_FAILURE;
  payload: null;
}

interface FetchInstructorRequestAction {
  type: typeof FETCH_INSTRUCTOR_REQUEST;
  payload: null;
}

interface FetchInstructorResponseAction {
  type: typeof FETCH_INSTRUCTOR_RESPONSE;
  payload: Instructor;
}

interface FetchInstructorFailureAction {
  type: typeof FETCH_INSTRUCTOR_FAILURE;
  payload: null;
}

interface PostInstructorRequestAction {
  type: typeof POST_INSTRUCTOR_REQUEST;
  payload: null;
}

interface PostInstructorResponseAction {
  type: typeof POST_INSTRUCTOR_RESPONSE;
  payload: null;
}

interface PostInstructorFailureAction {
  type: typeof POST_INSTRUCTOR_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type InstructorActionTypes =
    | FetchInstructorsRequestAction
    | FetchInstructorsResponseAction
    | FetchInstructorsFailureAction
    | PostInstructorRequestAction
    | PostInstructorResponseAction
    | PostInstructorFailureAction
    | FetchInstructorRequestAction
    | FetchInstructorResponseAction
    | FetchInstructorFailureAction;
