import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import './DropDown.scss';


type Item = {
  value: string | number;
  label?: string;
};

type Props = {
  items: Item[],
  name?: string;
  id?: string,
  placeholder?: string,
  value: string | number | null,
  label?: string,
  required?: boolean,
  v?: any;
  err?: (id: string) => string[];
  onSelect: (item: Item) => void,
  errors?: string[];
  disabled?: boolean;
  validate?: (field: string, value: string | number | null | undefined | boolean) => void;
  type: 'default' | 'red',
};

const DropDown = (props: Props) => {

  const {
    items, v, onSelect = () => {
    }, value,
    disabled = false,
    type, placeholder = 'Please select', label, err = () => [], required, id = '',
  } = props;
  const selected = items.find(i => i.value === value);

  const [touched, setTouched] = useState(false);

  const onInnerSelect = (item: Item) => {
    setTouched(true);
    if (v && typeof v === 'function') {
      v(id, item?.value);
    }
    onSelect(item);
  };

  useEffect(() => {
    if (!touched && value) {
      if (v && typeof v === 'function') {
        v(id, value);
      }
    }
  }, [value]);

  const errors = err(id);

  return (
      <div className='dropdown my-3' id={type}>
        {label && <label className={`form-label ${errors.length > 0 && 'error-lbl'}`}>{label}{required && '*'}</label>}
        <Dropdown id={id}>
          <Dropdown.Toggle disabled={disabled} variant="success">
            {selected?.label || selected?.value || placeholder}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {items.map(item => <Dropdown.Item key={item.value}
                                              onClick={() => onInnerSelect(item)}>{item.label || item.value}</Dropdown.Item>)}
          </Dropdown.Menu>
        </Dropdown>
        {errors.map((e: string, i: number) => (<div className="error-lbl" key={i}>{e}</div>))}
      </div>
  );
};

export default DropDown;
