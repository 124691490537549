import { RootState } from '../store/configureStore';

export const getActivitySlotInstructors = (state: RootState) => state?.activitySlotInstructor?.activitySlotInstructors;

export const getActiveActivitySlotInstructor = (state: RootState) => state?.activitySlotInstructor?.activeActivitySlotInstructor;

export const isFetchingActivitySlotInstructors = (state: RootState) => state?.activitySlotInstructor?.isFetchingActivitySlotInstructors;

export const isPostingActivitySlotInstructor = (state: RootState) => state?.activitySlotInstructor?.isPostingActivitySlotInstructor;
export const postingActivitySlotInstructorFailed = (state: RootState) => state?.activitySlotInstructor?.postingActivitySlotInstructorFailed;
export const postingActivitySlotInstructorError = (state: RootState) => state?.activitySlotInstructor?.postingActivitySlotInstructorError;
export const postingActivitySlotInstructorValidationErrors = (state: RootState) => state?.activitySlotInstructor?.postingActivitySlotInstructorValidationErrors;


export const fetchingActivitySlotInstructorsFailed = (state: RootState) => state?.activitySlotInstructor?.fetchingActivitySlotInstructorsFailed;
