import React, { useState, FocusEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import SearchIcon from '../../assets/svgs/ems-search-icon.svg';
import './SearchBar.scss';

type Item = {
  value: string;
  label?: string;
};

type Props = {
  placeholder: string;
  onSearch?: (term: string, field?: string) => void;
  fields?: Item[];
  defaultField: string;
};

const SearchBar = (props: Props) => {
  const {
    placeholder, onSearch = () => {
    }, fields = [], defaultField,
  } = props;
  const [searchText, setSearchText] = useState('');
  const [field, setField] = useState(defaultField);

  const onInnerSearch = () => {
    onSearch(searchText, field);
  };

  const onChange = (e: FocusEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onBlur = (e: FocusEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onClear = () => {
    setSearchText('');
    onSearch('');
  };

  return (
        <>
            <div className="search-bar">
                <div className="input-group">
                    <input type='text' className='form-control search-input' value={searchText} onChange={onChange}
                           onBlur={onBlur} placeholder={placeholder}/>
                    {searchText && <span className="input-group-btn gray-clear" onClick={onClear}>Clear
                        {/* @ts-ignore */}
                        <X width={25} height={25}/>
                    </span>}

                        <Dropdown>
                            <Dropdown.Toggle variant="success">
                                Filters
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                                {fields.map((f, i) => (<Dropdown.Item key={i}
                                                                      onClick={() => setField(f.value)}>{f.label || f.value}</Dropdown.Item>))}
                            </Dropdown.Menu>
                        </Dropdown>

                    <span className="input-group-btn">
              <button className="btn btn-search" onClick={onInnerSearch}>
                <img className="search-icon" src={SearchIcon} alt='search-icon'/>
              </button>
            </span>
                </div>
            </div>
            <div className="pt-2"><p>{field && field !== 'all' && `Filter By: ${fields?.find(f => f.value === field)?.label || ''}`}</p></div>
        </>
  );
};

export default SearchBar;

