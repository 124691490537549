import {
  FETCH_ACTIVITY_FAILURE,
  FETCH_ACTIVITY_REQUEST,
  FETCH_ACTIVITY_RESPONSE,
  FETCH_ACTIVITIES_FAILURE,
  FETCH_ACTIVITIES_REQUEST,
  FETCH_ACTIVITIES_RESPONSE,
  POST_ACTIVITY_FAILURE,
  POST_ACTIVITY_RESPONSE,
  ActivityActionTypes,
  ActivityState,
  POST_ACTIVITY_REQUEST,
} from '../types/Activity';
import { parseValidationErrors } from '../helpers/functions';

const initialState: ActivityState = {
  activities: null,
  activeActivity: null,
  isFetchingActivities: false,
  fetchingActivitiesFailed: false,
  isPostingActivity: false,
  postingActivityFailed: false,
  postingActivityError: null,
  postingActivityValidationErrors: null,
};

const activityReducer = (
  state = initialState,
  action: ActivityActionTypes,
): ActivityState => {
  switch (action.type) {
    case FETCH_ACTIVITIES_REQUEST:
      return {
        ...state,
        activities: null,
        isFetchingActivities: true,
        fetchingActivitiesFailed: false,
      };
    case FETCH_ACTIVITIES_RESPONSE:
      return {
        ...state,
        activities: action.payload || null,
        isFetchingActivities: false,
        fetchingActivitiesFailed: false,
      };
    case FETCH_ACTIVITIES_FAILURE:
      return {
        ...state,
        activities: null,
        isFetchingActivities: false,
        fetchingActivitiesFailed: true,
      };
    case FETCH_ACTIVITY_REQUEST:
      return {
        ...state,
        activities: null,
        isFetchingActivities: true,
        fetchingActivitiesFailed: false,
      };
    case FETCH_ACTIVITY_RESPONSE:
      return {
        ...state,
        activeActivity: action.payload,
        isFetchingActivities: false,
        fetchingActivitiesFailed: false,
      };
    case FETCH_ACTIVITY_FAILURE:
      return {
        ...state,
        activities: null,
        isFetchingActivities: false,
        fetchingActivitiesFailed: true,
      };
    case POST_ACTIVITY_REQUEST:
      return {
        ...state,
        isPostingActivity: true,
        postingActivityError: null,
        postingActivityValidationErrors: null,
        postingActivityFailed: false,
      };
    case POST_ACTIVITY_RESPONSE:
      return {
        ...state,
        isPostingActivity: false,
        postingActivityError: null,
        postingActivityValidationErrors: null,
        postingActivityFailed: false,
      };
    case POST_ACTIVITY_FAILURE:
      return {
        ...state,
        isPostingActivity: false,
        postingActivityError: action.payload.error || 'An error occurred',
        postingActivityValidationErrors: parseValidationErrors(action.payload.validationErrors),
        postingActivityFailed: true,
      };

    default:
      return state;
  }
};

export default activityReducer;
