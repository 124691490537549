import { RootState } from '../store/configureStore';

export const getRoleAccounts = (state: RootState) => state?.roleAccount?.roleAccounts;

export const isFetchingRoleAccounts = (state: RootState) =>
  state?.roleAccount?.isFetchingRoleAccounts;

export const isPostingRoleAccount = (state: RootState) =>
  state?.roleAccount?.isPostingRoleAccount;
export const postingRoleAccountFailed = (state: RootState) =>
  state?.roleAccount?.postingRoleAccountFailed;
export const postingRoleAccountError = (state: RootState) =>
  state?.roleAccount?.postingRoleAccountError;
export const postingRoleAccountValidationErrors = (state: RootState) =>
  state?.roleAccount?.postingRoleAccountValidationErrors;

export const fetchingRoleAccountsFailed = (state: RootState) =>
  state?.roleAccount?.fetchingRoleAccountsFailed;
