import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
  CartItem,
  FETCH_ORDER_FAILURE,
  FETCH_ORDER_REQUEST,
  FETCH_ORDER_RESPONSE,
  FETCH_ORDERS_FAILURE,
  FETCH_ORDERS_REQUEST,
  FETCH_ORDERS_RESPONSE,
  Order,
  OrderActionTypes,
  OrderDataModel,
  POST_ORDER_FAILURE,
  POST_ORDER_REQUEST,
  POST_ORDER_RESPONSE,
  SET_CART,
} from '../types/Order';

import { RootState } from '../store/configureStore';

import { deleteOrder, getOrder, getOrderByReference, getOrders, postOrders, putOrder } from '../api/Order';



export function fetchOrdersRequest(): OrderActionTypes {
  return {
    type: FETCH_ORDERS_REQUEST,
    payload: null,
  };
}

export function fetchOrdersResponse(
  orders: OrderDataModel,
): OrderActionTypes {
  return {
    type: FETCH_ORDERS_RESPONSE,
    payload: orders,
  };
}

export function fetchOrdersFailure(): OrderActionTypes {
  return {
    type: FETCH_ORDERS_FAILURE,
    payload: null,
  };
}

export function fetchOrderRequest(): OrderActionTypes {
  return {
    type: FETCH_ORDER_REQUEST,
    payload: null,
  };
}

export function fetchOrderResponse(
  order: Order,
): OrderActionTypes {
  return {
    type: FETCH_ORDER_RESPONSE,
    payload: order,
  };
}

export function fetchOrderFailure(): OrderActionTypes {
  return {
    type: FETCH_ORDER_FAILURE,
    payload: null,
  };
}


export function postOrderRequest(): OrderActionTypes {
  return {
    type: POST_ORDER_REQUEST,
    payload: null,
  };
}

export function postOrderResponse(orders: Order[]): OrderActionTypes {
  return {
    type: POST_ORDER_RESPONSE,
    payload: orders,
  };
}

export function postOrderFailure(error: string, validationErrors: any): OrderActionTypes {
  return {
    type: POST_ORDER_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}

export function setCart(cartItems: CartItem[]): OrderActionTypes {
  return {
    type: SET_CART,
    payload: {
      cartItems,
    },
  };
}

export const fetchActiveOrder = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOrderRequest());
    const asyncResp: any = await getOrder(id);
    if (asyncResp?.success) {
      await dispatch(fetchOrderResponse(asyncResp.data));
    } else {
      await dispatch(fetchOrderFailure());
    }
  };

export const fetchOrders = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOrdersRequest());
    const asyncResp: any = await getOrders();
    if (asyncResp?.success) {
      await dispatch(fetchOrdersResponse(asyncResp));
    } else {
      await dispatch(fetchOrdersFailure());
    }
  };

export const destroyOrder = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteOrder(id);
    if (asyncResp?.success) {
      await dispatch(fetchOrders());
    }
  };



export const createOrders = (
  myList : []
  ,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOrderRequest());
    const asyncResp: any = await postOrders(myList);
    if (asyncResp?.success) {
      await dispatch(postOrderResponse(asyncResp.data));
    } else {
      await dispatch(postOrderFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const addToCart = (cartItem: CartItem):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    cartItem.localId = uuidv4();
    const cartItems: CartItem[] = [...state.order.cartItems, cartItem];
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    dispatch(setCart(cartItems));
  };

export const removeFromCart = (localId: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch, getState) => {
    const state = getState();
    const cartItems = state.order.cartItems.filter(c => c.localId !== localId);
    dispatch(setCart(cartItems));
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  };

export const updateOrder = (
  id: string,
  bookingReference: string,
  customerId: string,
  groupId: string,
  clientId: string,
  locationId: string,
  activityId: string,
  activitySlotId: string,
  userId: string,
  subtotal: number,
  vat: number,
  total: number,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postOrderRequest());
    const asyncResp: any = await putOrder(id, bookingReference, customerId,  groupId, clientId, locationId, activityId, activitySlotId, userId, subtotal, vat, total);
    if (asyncResp?.success) {
      await dispatch(postOrderResponse(asyncResp.data));
    } else {
      await dispatch(postOrderFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const fetchOrderByReference = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchOrdersRequest());
    const asyncResp: any = await getOrderByReference(id);
    if (asyncResp?.success) {
      await dispatch(fetchOrdersResponse(asyncResp));
    } else {
      await dispatch(fetchOrdersFailure());
    }
  };
