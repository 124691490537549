import axios from 'axios';

import c from '../helpers/constants';

export function getCustomer(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CUSTOMER}/${id}`,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function getCustomers() {
  return (
    axios({
      url: c.API_ENDPOINTS.CUSTOMER,
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}



export function postCustomer(
  userId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean) {
  return (
    axios({
      url: c.API_ENDPOINTS.CUSTOMER,
      method: 'POST',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        userId,
        title,
        email,
        firstName,
        lastName,
        phone,
        address,
        city,
        postcode,
        country,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

export function deleteCustomer(id: string) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CUSTOMER}/${id}`,
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}



export function putCustomer(
  id: string,
  userId: string,
  title: string,
  email: string,
  firstName: string,
  lastName: string,
  phone: string,
  address: string,
  city: string,
  postcode: string,
  country: string,
  active: boolean) {
  return (
    axios({
      url: `${c.API_ENDPOINTS.CUSTOMER}/${id}`,
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
      },
      data: {
        userId,
        title,
        email,
        firstName,
        lastName,
        phone,
        address,
        city,
        postcode,
        country,
        active,
      },
    })
      .then((response: any) => {
        return response?.data;
      })
      .catch((error: any) => {
        return error.response?.data;
      })
  );
}

