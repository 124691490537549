import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import DropDownSearchable from '../../../components/DropdownSearchable';
import Color from '../../../components/Color';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createActivity, fetchActiveActivity, updateActivity } from '../../../actions/Activity';
import { getGroups } from '../../../selectors/Group';
import { getClients } from '../../../selectors/Client';
import { getLocations } from '../../../selectors/Location';
import CheckBox from '../../../components/CheckBox';
import { fetchGroups } from '../../../actions/Group';
import { fetchClients } from '../../../actions/Client';
import { fetchLocations } from '../../../actions/Location';
import { fetchTimezones } from '../../../actions/Timezones';


import {
  getActiveActivity,
  isPostingActivity,
  postingActivityError,
  postingActivityFailed,
  postingActivityValidationErrors,
  isFetchingActivities,
} from '../../../selectors/Activity';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';
import { useEditMode } from '../../../hooks/EditModeHook';
import { getTimezones } from '../../../selectors/Timezones';
import { getCurrency } from '../../../selectors/Currency';
import { fetchCurrency } from '../../../actions/Currency';

const ActivityForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const locations = useSelector(getLocations);
  const timezones = useSelector(getTimezones);
  const currencies = useSelector(getCurrency);
  const activeActivity = useSelector(getActiveActivity);
  const isPosting = useSelector(isPostingActivity);
  const isFetching = useSelector(isFetchingActivities);
  const postingFailed = useSelector(postingActivityFailed);
  const postingError = useSelector(postingActivityError);
  const postingValidationErrors = useSelector(postingActivityValidationErrors);

  const [editMode, activeId] = useEditMode(c.APP_ROUTES.ACTIVITY_UPDATE);

  const formConfig = [
    { field: 'groupId', validators: [isRequired] },
    { field: 'clientId', validators: [isRequired] },
    { field: 'locationId', validators: [isRequired] },
    { field: 'title', validators: [isRequired] },
    { field: 'description', validators: [isRequired] },
    { field: 'pricePerPerson', validators: [isRequired] },
    { field: 'currency', validators: [isRequired] },
    { field: 'vat', validators: [isRequired] },
    { field: 'color', validators: [isRequired] },
    { field: 'timeZone', validators: [isRequired] },
  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [locationId, setLocationId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [pricePerPerson, setPricePerPerson] = useState(Number);
  const [currency, setCurrency] = useState('');
  const [vat, setVat] = useState(Number);
  const [color, setColor] = useState('#111111');
  const [timeZone, setTimeZone] = useState('');
  const [active, setActive] = useState(true);
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);
  const [itemsLocations, setItemsLocations] = useState(locations?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledLocation, setDisabledLocation] = useState(true);


  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
    dispatch(fetchLocations());
    dispatch(fetchTimezones());
    dispatch(fetchCurrency());
    if (editMode) {
      dispatch(fetchActiveActivity(activeId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (activeActivity && editMode) {
      setGroupId(activeActivity?.groupId);
      setClientId(activeActivity?.clientId);
      setLocationId(activeActivity?.locationId);
      setTitle(activeActivity?.title);
      setDescription(activeActivity?.description);
      setPricePerPerson(activeActivity?.pricePerPerson);
      setCurrency(activeActivity?.currency);
      setVat(activeActivity?.vat);
      setColor(activeActivity?.color);
      setTimeZone(activeActivity?.timeZone);
      // @ts-ignore
      setActive(activeActivity?.active === 1 || activeActivity?.active === true);
    }
  }, [activeActivity]);

  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ACTIVITY);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createActivity(groupId, clientId, locationId, title, description, pricePerPerson, currency, vat, color, timeZone, active));
    }
  };

  const onSave = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(updateActivity(activeId, groupId, clientId, locationId, title, description, pricePerPerson, currency, vat, color, timeZone, active));
    }
  };

  const itemsCurrencies = currencies?.data.map(r => ({ label: r.currency, value: r.currency })) || [];
  const itemsGroups = groups?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsTimezones = timezones?.data.map(r => ({ label: r.timezone, value: r.timezone })) || [];



  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);

  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);
    setItemsLocations(locations?.data.filter(data => data.clientId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledLocation(false);
  };

  const onLocationSelect = (obj: any) => {
    setLocationId(obj.value);
  };

  const onCurrencySelect = (obj: any) => {
    setCurrency(obj.value);

  };

  const onTimezoneSelect = (obj: any) => {
    setTimeZone(obj.value);

  };

  return <View>
    <h4>{editMode ? 'Edit' : 'Create'} Activity</h4>
    {postingError && (
        <Alert className="mt-3" variant="danger">
          {postingError}
        </Alert>
    )}
    <form>
      <DropDown v={v} err={err}
                id="groupId"
                placeholder="Please select a Group"
                required label="Group"
                items={itemsGroups}
                value={groupId}
                disabled={isPosting || isFetching}
                onSelect={onGroupSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="clientId"
                placeholder="Please select a Client"
                required label="Client"
                items={itemsClients}
                value={clientId}
                disabled={isPosting || isFetching || disabledClient}
                onSelect={onClientSelect}
                type="default"
      />
      <DropDown v={v} err={err}
                id="locationId"
                placeholder="Please select a Location"
                required label="Location"
                items={itemsLocations}
                value={locationId}
                disabled={isPosting || isFetching || disabledLocation}
                onSelect={onLocationSelect}
                type="default"
      />
      <Input v={v} err={err}
             name="title"
             id="title"
             label="Title"
             required
             value={title}
             onChange={setTitle}
             disabled={isPosting || isFetching}
             onBlur={setTitle}
             placeholder="Insert a title"
      />
      <Input v={v} err={err}
             required
             name="description"
             id="description"
             label="Description"
             value={description}
             disabled={isPosting || isFetching}
             onChange={setDescription}
             onBlur={setDescription}
             placeholder="Insert a description"
      />
      <Input v={v} err={err}
             name="pricePerPerson"
             id="pricePerPerson"
             label="Price Per Person"
             required
             value={pricePerPerson}
             onChange={setPricePerPerson}
             disabled={isPosting || isFetching}
             onBlur={setPricePerPerson}
             placeholder="Insert a price per person"
      />
      <DropDownSearchable v={v} err={err}
             id="currency"
             placeholder="Please select a currency"
             required label="Currency"
             items={itemsCurrencies}
             value={currency}
             disabled={isPosting || isFetching}
             onSelect={onCurrencySelect}
             type="default"
      />
      <Input v={v} err={err}
             name="vat"
             id="vat"
             label="VAT"
             required
             value={vat}
             onChange={setVat}
             disabled={isPosting || isFetching}
             onBlur={setVat}
             placeholder="Insert a VAT"
      />
      <Color v={v} err={err}
             name="color"
             id="color"
             label="Color"
             required
             value={color}
             onChange={setColor}
             disabled={isPosting || isFetching}
             onBlur={setColor}
             placeholder="Pick a color"
      />

      <DropDownSearchable v={v} err={err}
                          id="timeZone"
                          placeholder="Please select a timezone"
                          required label="Timezone"
                          items={itemsTimezones}
                          value={timeZone}
                          disabled={isPosting || isFetching}
                          onSelect={onTimezoneSelect}
                          type="default"
      />
      <CheckBox label="Is Active?" checked={active} onChange={setActive}/>
      <div className="row">
        <div className="col-md-4">
          <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                  onClick={() => editMode ? onSave() : onSubmit()}
                  title={editMode ? 'SAVE' : 'CREATE'}/>
        </div>
      </div>
    </form>
  </View>;
};

export default ActivityForm;



