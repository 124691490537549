//@ts-nocheck

import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import useFileUpload from 'react-use-file-upload';

import View from '../../../components/View';
import Button from '../../../components/Button';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createAsset } from '../../../actions/Asset';
import 'bootstrap/dist/css/bootstrap.min.css';
import DropDown from '../../../components/DropDown';
import { getGroups } from '../../../selectors/Group';
import { fetchGroups } from '../../../actions/Group';
import { getClients } from '../../../selectors/Client';
import { fetchClients } from '../../../actions/Client';
import Input from '../../../components/Input';

const AssetForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const groups = useSelector(getGroups);
  const clients = useSelector(getClients);
  const [groupId, setGroupId] = useState('');
  const [clientId, setClientId] = useState('');
  const [title, setTitle] = useState('');
  const [itemsClients, setItemsClients] = useState(clients?.data.map(r => ({ label: r.title, value: r.id })) || []);
  const [disabledClient, setDisabledClients] = useState(true);

  const {
    files,
    fileNames,
    fileTypes,
    totalSize,
    totalSizeInBytes,
    handleDragDropEvent,
    clearAllFiles,
    createFormData,
    setFiles,
    removeFile,
  } = useFileUpload();

  const inputRef = useRef();

  useEffect(() => {
    dispatch(fetchGroups());
    dispatch(fetchClients());
  }, [dispatch]);


  const onSubmit = async () => {
    //TODO add validation before submitting
    const fileFormData = createFormData();
    const finalFormData = new FormData();
    const fileArray = [];

    for (const pair of fileFormData.entries()) {
      fileArray.push(pair[1]);
    }
    fileArray.forEach((file => {
      finalFormData.append('assets[]', file);
    }),
    );


    dispatch(createAsset(groupId, clientId, title, finalFormData));
    history.push(c.APP_ROUTES.ASSET);
  };

  const itemsGroups = groups?.data.map((r: { title: any; id: any; }) => ({ label: r.title, value: r.id })) || [];


  const onGroupSelect = (obj: any) => {
    setGroupId(obj.value);
    setItemsClients(clients?.data.filter(data => data.groupId.includes(obj.value)).map(r => ({ label: r.title, value: r.id })) || []);
    setDisabledClients(false);
  };

  const onClientSelect = (obj: any) => {
    setClientId(obj.value);

  };

  return (
      <View>
        <h4>{'Create'} Asset</h4>
        <form>
          <div>

            <p style={{ color:'white' }}>Please use the form to your right to upload any file(s) of your choosing.</p>

            <div className="form-container">
              {/* Display the files to be uploaded */}
              <div>
                <ul>
                  {fileNames.map((name) => (
                      <li key={name}>
                        <span style={{ color:'white' }}>{name}</span>

                        <span onClick={() => removeFile(name)}>
                  <i className="fa fa-times" />
                </span>
                      </li>
                  ))}
                </ul>

                {files.length > 0 && (
                    <ul>
                      <li style={{ color:'white' }}>File types found: {fileTypes.join(', ')}</li>
                      <li style={{ color:'white' }}>Total Size: {totalSize}</li>
                      <li style={{ color:'white' }}>Total Bytes: {totalSizeInBytes}</li>

                      <li className="clear-all">
                        <button onClick={() => clearAllFiles()}>Clear All</button>
                      </li>
                    </ul>
                )}
              </div>

              {/* Provide a drop zone and an alternative button inside it to upload files. */}
              <div
                  onDragEnter={handleDragDropEvent}
                  onDragOver={handleDragDropEvent}
                  onDrop={(e) => {
                    handleDragDropEvent(e);
                    setFiles(e, 'a');
                  }}
              >
                <p style={{ color:'white' }}>Drag and drop files here</p>

                <button onClick={() => inputRef.current.click()}>Or select files to upload</button>

                {/* Hide the crappy looking default HTML input */}
                <input
                    ref={inputRef}
                    type="file"
                    multiple
                    style={{ display: 'none' }}
                    onChange={(e) => {
                      setFiles(e, 'a');
                      inputRef.current.value = null;
                    }}
                />
              </div>
            </div>
          </div>
          <Input name="title"
                 id="title"
                 label="Title"
                 required
                 value={title}
                 onChange={setTitle}
                 onBlur={setTitle}
                 placeholder="Insert a title"
          />
          <DropDown
              placeholder="Please select a Group"
              items={itemsGroups}
              value={groupId}
              onSelect={onGroupSelect}
              type="default"
          />
          <DropDown
              placeholder="Please select a Client"
              items={itemsClients}
              value={clientId}
              disabled={disabledClient}
              onSelect={onClientSelect}
              type="default"
          />
          <div className="row">
            <div className="col-md-4">
              <Button onClick={() => onSubmit()}
                      title={'CREATE'}/>
            </div>
          </div>
        </form>
      </View>
  );

};

export default AssetForm;
