import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import {
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_RESPONSE,
  FETCH_ASSETS_FAILURE,
  FETCH_ASSET_REQUEST,
  FETCH_ASSET_RESPONSE,
  FETCH_ASSET_FAILURE,
  POST_ASSET_FAILURE,
  POST_ASSET_REQUEST,
  POST_ASSET_RESPONSE,
  AssetActionTypes, AssetDataModel, Asset,
} from '../types/Asset';

import { RootState } from '../store/configureStore';

import { getAsset, getAssets, postAsset } from '../api/Asset';
import { deleteAsset } from '../api/Asset';


export function fetchAssetsRequest(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_REQUEST,
    payload: null,
  };
}

export function fetchAssetsResponse(
  assets: AssetDataModel,
): AssetActionTypes {
  return {
    type: FETCH_ASSETS_RESPONSE,
    payload: assets,
  };
}

export function fetchAssetsFailure(): AssetActionTypes {
  return {
    type: FETCH_ASSETS_FAILURE,
    payload: null,
  };
}

export function fetchAssetRequest(): AssetActionTypes {
  return {
    type: FETCH_ASSET_REQUEST,
    payload: null,
  };
}

export function fetchAssetResponse(
  asset: Asset,
): AssetActionTypes {
  return {
    type: FETCH_ASSET_RESPONSE,
    payload: asset,
  };
}

export function fetchAssetFailure(): AssetActionTypes {
  return {
    type: FETCH_ASSET_FAILURE,
    payload: null,
  };
}


export function postAssetRequest(): AssetActionTypes {
  return {
    type: POST_ASSET_REQUEST,
    payload: null,
  };
}

export function postAssetResponse(): AssetActionTypes {
  return {
    type: POST_ASSET_RESPONSE,
    payload: null,
  };
}

export function postAssetFailure(error: string, validationErrors: string): AssetActionTypes {
  return {
    type: POST_ASSET_FAILURE,
    payload: {
      error,
      validationErrors,
    },
  };
}


export const fetchActiveAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAssetRequest());
    const asyncResp: any = await getAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchAssetResponse(asyncResp.data));
    } else {
      await dispatch(fetchAssetFailure());
    }
  };

export const fetchAssets = ():
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(fetchAssetsRequest());
    const asyncResp: any = await getAssets();
    if (asyncResp?.success) {
      await dispatch(fetchAssetsResponse(asyncResp));
    } else {
      await dispatch(fetchAssetsFailure());
    }
  };


export const createAsset = (
  groupId: string, clientId: string, title: string, fileFormData: FormData,
):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    dispatch(postAssetRequest());
    const asyncResp: any = await postAsset(groupId, clientId, title, fileFormData);
    if (asyncResp?.success) {
      await dispatch(postAssetResponse());
      await dispatch(fetchAssets());
    } else {
      await dispatch(postAssetFailure(asyncResp?.error, asyncResp?.validationErrors));
    }
  };

export const destroyAsset = (id: string):
ThunkAction<void, RootState, unknown, Action<string>> =>
  async (dispatch) => {
    const asyncResp: any = await deleteAsset(id);
    if (asyncResp?.success) {
      await dispatch(fetchAssets());
    }
  };
