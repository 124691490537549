import { RootState } from '../store/configureStore';

export const getAccounts = (state: RootState) => state?.account?.accounts;
export const getActiveAccount = (state: RootState) => state?.account?.activeAccount;
export const isFetchingAccounts = (state: RootState) => state?.account?.isFetchingAccounts;
export const isPostingAccount = (state: RootState) => state?.account?.isPostingAccount;
export const postingAccountFailed = (state: RootState) => state?.account?.postingAccountFailed;
export const postingAccountError = (state: RootState) => state?.account?.postingAccountError;
export const postingAccountValidationErrors = (state: RootState) => state?.account?.postingAccountValidationErrors;
export const fetchingAccountsFailed = (state: RootState) => state?.account?.fetchingAccountsFailed;
