import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountClients } from '../../../selectors/AccountClient';
import { destroyAccountClient, fetchAccountClients } from '../../../actions/AccountClient';
import { AccountClient } from '../../../types/AccountClient';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const AccountClientDataTable = () => {

  const accountClients = useSelector(getAccountClients);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [accountClient, setActiveAccountClient] = useState<AccountClient | null>();


  useEffect(() => {
    dispatch(fetchAccountClients());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACCOUNT_CLIENT_CREATE);
  };



  const onDelete = (row: AccountClient) => {
    setActiveAccountClient(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (accountClient?.id) {
      dispatch(destroyAccountClient(accountClient?.id));
    }
    setActiveAccountClient(null);
  };


  const config = {
    columns: [
      { key: 'accountTitle', label: 'Account', mobileFriendly: true },
      { key: 'clientTitle', label: 'Client', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ACCOUNT/CLIENT" ctaTitle="Link Account/Client" onCreate={onCreate} data={accountClients?.data || []} config={config} />
            <Modal show={toggle} title="Delete Account/Client link" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Account/Client link?
            </Modal>

        </View>
  );
};

export default AccountClientDataTable;
