import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import View from '../../../components/View';
import Button from '../../../components/Button';
import DropDown from '../../../components/DropDown';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { createRoleAccount   } from '../../../actions/RoleAccount';
import { getRoles } from '../../../selectors/Role';
import { getAccounts } from '../../../selectors/Account';
import { fetchRoles } from '../../../actions/Role';
import { fetchAccounts } from '../../../actions/Account';


import {

  isPostingRoleAccount,
  postingRoleAccountError,
  postingRoleAccountFailed,
  postingRoleAccountValidationErrors,
  isFetchingRoleAccounts,
} from '../../../selectors/RoleAccount';
import { isRequired } from '../../../helpers/validators';
import { useValidation } from '../../../hooks/ValidationHook';



const RoleAccountForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const roles = useSelector(getRoles);
  const accounts = useSelector(getAccounts);
  const isPosting = useSelector(isPostingRoleAccount);
  const isFetching = useSelector(isFetchingRoleAccounts);
  const postingFailed = useSelector(postingRoleAccountFailed);
  const postingError = useSelector(postingRoleAccountError);
  const postingValidationErrors = useSelector(postingRoleAccountValidationErrors);



  const formConfig = [
    { field: 'roleId', validators: [isRequired] },
    { field: 'accountId', validators: [isRequired] },

  ];

  const [v, triggerValidation, isFormValid, err] = useValidation(formConfig, postingValidationErrors);

  const [hasSubmitted, setHasSubmitted] = useState(false);


  const [roleId, setRoleId] = useState('');
  const [accountId, setAccountId] = useState('');

  useEffect(() => {
    dispatch(fetchRoles());
    dispatch(fetchAccounts());

  }, [dispatch]);





  useEffect(() => {
    if (!isPosting && !postingFailed && hasSubmitted) {
      setHasSubmitted(false);
      history.push(c.APP_ROUTES.ROLE_ACCOUNT);
    }
  }, [isPosting]);

  const onSubmit = () => {
    if (triggerValidation()) {
      setHasSubmitted(true);
      dispatch(createRoleAccount(roleId, accountId ));
    }
  };



  const itemsRoles = roles?.data.map(r => ({ label: r.title, value: r.id })) || [];
  const itemsAccounts = accounts?.data.map(r => ({ label: r.title, value: r.id })) || [];

  const onAccountSelect = (obj: any) => {
    setAccountId(obj.value);
  };
  const onRoleSelect = (obj: any) => {
    setRoleId(obj.value);
  };

  return <View>
        <h4>Create RoleAccount</h4>
        {postingError && (
            <Alert className="mt-3" variant="danger">
                {postingError}
            </Alert>
        )}
        <form>
            <DropDown v={v} err={err}
                      id="roleId"
                      placeholder="Please select an Role"
                      required label="Role"
                      items={itemsRoles}
                      value={roleId}
                      disabled={isPosting || isFetching}
                      onSelect={onRoleSelect}
                      type="default"
            />
            <DropDown v={v} err={err}
                      id="accountId"
                      placeholder="Please select a Account"
                      required label="Account"
                      items={itemsAccounts}
                      value={accountId}
                      disabled={isPosting || isFetching}
                      onSelect={onAccountSelect}
                      type="default"
            />


            <div className="row">
                <div className="col-md-4">
                    <Button disabled={!isFormValid()} isLoading={isPosting || isFetching}
                            onClick={() => onSubmit()}
                            title={'CREATE'}/>
                </div>
            </div>
        </form>
    </View>;
};

export default RoleAccountForm;



