import { ApiDataModel, FormError } from './Common';

export const FETCH_ACTIVITY_SLOTS_REQUEST = 'FETCH_ACTIVITY_SLOTS_REQUEST';
export const FETCH_ACTIVITY_SLOTS_RESPONSE = 'FETCH_ACTIVITY_SLOTS_RESPONSE';
export const FETCH_ACTIVITY_SLOTS_FAILURE = 'FETCH_ACTIVITY_SLOTS_FAILURE';

export const FETCH_ACTIVITY_SLOT_REQUEST = 'FETCH_ACTIVITY_SLOT_REQUEST';
export const FETCH_ACTIVITY_SLOT_RESPONSE = 'FETCH_ACTIVITY_SLOT_RESPONSE';
export const FETCH_ACTIVITY_SLOT_FAILURE = 'FETCH_ACTIVITY_SLOT_FAILURE';

export const POST_ACTIVITY_SLOT_REQUEST = 'POST_ACTIVITY_SLOT_REQUEST';
export const POST_ACTIVITY_SLOT_RESPONSE = 'POST_ACTIVITY_SLOTS_RESPONSE';
export const POST_ACTIVITY_SLOT_FAILURE = 'POST_ACTIVITY_SLOTS_FAILURE';

export interface ActivitySlot {
  id: string;
  assetId: string;
  activityId: string;
  activityColor: string;
  activityTitle: string;
  activityTimeZone: string;
  activityDescription: string;
  activityPricePerPerson: number;
  locationId: string;
  locationTitle: string;
  instructorTitle: string;
  activityCurrency: string;
  activityVat: number;
  clientTitle: string;
  clientId: string;
  groupId: string;
  groupTitle: string;
  validFrom: string;
  validUntil: string;
  cutOffTime: number;
  dayOfTheWeek: number;
  startTime: string;
  endTime: string;
  capacity: number;
  active: boolean;
  created: Date;
  updated: Date;
}

export interface ActivitySlotDataModel extends Omit<ApiDataModel, 'data'> {
  data: ActivitySlot[];
}

export interface ActivitySlotState {
  activitySlots: ActivitySlotDataModel | null,
  activeActivitySlot: ActivitySlot | null,
  isFetchingActivitySlots: boolean;
  fetchingActivitySlotsFailed: boolean;
  postingActivitySlotFailed: boolean;
  isPostingActivitySlot: boolean;
  postingActivitySlotValidationErrors: FormError[] | null;
  postingActivitySlotError: string | null;
}

interface FetchActivitySlotsRequestAction {
  type: typeof FETCH_ACTIVITY_SLOTS_REQUEST;
  payload: null;
}

interface FetchActivitySlotsResponseAction {
  type: typeof FETCH_ACTIVITY_SLOTS_RESPONSE;
  payload: ActivitySlotDataModel;
}

interface FetchActivitySlotsFailureAction {
  type: typeof FETCH_ACTIVITY_SLOTS_FAILURE;
  payload: null;
}

interface FetchActivitySlotRequestAction {
  type: typeof FETCH_ACTIVITY_SLOT_REQUEST;
  payload: null;
}

interface FetchActivitySlotResponseAction {
  type: typeof FETCH_ACTIVITY_SLOT_RESPONSE;
  payload: ActivitySlot;
}

interface FetchActivitySlotFailureAction {
  type: typeof FETCH_ACTIVITY_SLOT_FAILURE;
  payload: null;
}

interface PostActivitySlotRequestAction {
  type: typeof POST_ACTIVITY_SLOT_REQUEST;
  payload: null;
}

interface PostActivitySlotResponseAction {
  type: typeof POST_ACTIVITY_SLOT_RESPONSE;
  payload: null;
}

interface PostActivitySlotFailureAction {
  type: typeof POST_ACTIVITY_SLOT_FAILURE;
  payload: {
    error?: string,
    validationErrors?: any;
  };
}

export type ActivitySlotActionTypes =
    | FetchActivitySlotsRequestAction
    | FetchActivitySlotsResponseAction
    | FetchActivitySlotsFailureAction
    | PostActivitySlotRequestAction
    | PostActivitySlotResponseAction
    | PostActivitySlotFailureAction
    | FetchActivitySlotRequestAction
    | FetchActivitySlotResponseAction
    | FetchActivitySlotFailureAction;
