import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';

import View from '../../components/View';
import { getCartItems } from '../../selectors/Order';
import CartItem from '../../components/CartItem';
import { CartItem as CartItemType } from '../../types/Order';
import { addToCart, removeFromCart } from '../../actions/Order';
import { useHistory } from 'react-router-dom';
import con from '../../helpers/constants';
import Button from '../../components/Button';
import { getUserToken } from '../../helpers/functions';


const Cart = () => {
  const history = useHistory();
  const toCheckout = ()=> {
    if (getUserToken()){
      history.push(con.APP_ROUTES.CHECKOUT);
    } else {
      history.push(con.APP_ROUTES.USER_LOGIN);
    }
  };

  const toActivities = ()=> {
    if (getUserToken()){
      history.push(con.APP_ROUTES.SEARCH);
    } else {
      history.push(con.APP_ROUTES.USER_LOGIN);
    }
  };
  const cartItems = useSelector(getCartItems);
  const dispatch = useDispatch();

  const distinctCartItems = {};
  cartItems?.forEach(c => {
    // @ts-ignore
    distinctCartItems[`${c.activitySlot?.id}-${format(new Date(c.selectedDate), 'yyyy-MM-dd').toString()}`] = c;
  });
  //@ts-ignore
  const finalCartItems = Object.keys(distinctCartItems).map(key => ({ ...distinctCartItems[key] }));

  const onDecreaseItem = (c: CartItemType) => {
    if (c.localId) {
      dispatch(removeFromCart(c.localId));
    }
  };

  const getCount = (c: CartItemType) => {
    const items = cartItems?.filter(cart => cart.selectedDate === c.selectedDate && cart.activitySlot.id == c.activitySlot.id);
    return items?.length || 0;
  };

  const onIncreaseItem = (c: CartItemType) => {
    dispatch(addToCart(c));
  };

  return <View isAdmin={false} style="dark">
        <div className="row">
            <div className="col align-self-end">
                <h2 className="title-white-primary m-5">CART SUMMARY</h2>
            </div>
        </div>
        <div className="row">
            <div className="col mx-5">
                {finalCartItems?.map((c, i) => <div key={i} className="row mb-3">
                    <div className="col">
                        <CartItem item={c} count={getCount(c)} onDecrease={() => onDecreaseItem(c)} onIncrease={() => onIncreaseItem(c)}/>
                    </div>
                </div>)}
                {finalCartItems?.length === 0 && <div className="white-oswald">Your cart is currently empty.</div>}
                {finalCartItems?.length > 0 && <div className="row justify-content-between mt-5">
                    <div className="col-3">
                        <Button title="GO BACK TO ACTIVITIES" onClick={toActivities}/>
                    </div>
                    <div className="col-3">
                        <Button title="PROCEED TO CHECKOUT" onClick={toCheckout}/>
                    </div>
                </div>}
            </div>

        </div>
    </View>;
};

export default Cart;
