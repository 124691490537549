import React, { useEffect, useState } from 'react';
import View from '../../../components/View';
import { useHistory } from 'react-router-dom';
import c from '../../../helpers/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountGroups } from '../../../selectors/AccountGroup';
import { destroyAccountGroup, fetchAccountGroups } from '../../../actions/AccountGroup';
import { AccountGroup } from '../../../types/AccountGroup';
import DataTable from '../../../components/DataTable';
import Modal from '../../../components/Modal';


const AccountGroupDataTable = () => {

  const accountGroups = useSelector(getAccountGroups);
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);
  const [accountGroup, setActiveAccountGroup] = useState<AccountGroup | null>();


  useEffect(() => {
    dispatch(fetchAccountGroups());
  }, [dispatch]);

  const history = useHistory();

  const onDismiss = () => {
    setToggle(false);
  };

  const onCreate = () => {
    history.push(c.APP_ROUTES.ACCOUNT_GROUP_CREATE);
  };



  const onDelete = (row: AccountGroup) => {
    setActiveAccountGroup(row);
    setToggle(true);
  };

  const onConfirmDelete = () => {
    setToggle(false);
    if (accountGroup?.id) {
      dispatch(destroyAccountGroup(accountGroup?.id));
    }
    setActiveAccountGroup(null);
  };


  const config = {
    columns: [
      { key: 'accountTitle', label: 'Account', mobileFriendly: true },
      { key: 'groupTitle', label: 'Group', mobileFriendly: true },

    ],
    actions: [
      { icon: 'bin', label: '', onClick: onDelete },
    ],
  };

  return (
        <View>
            <DataTable title="ACCOUNT/GROUP" ctaTitle="Link Account/Group" onCreate={onCreate} data={accountGroups?.data || []} config={config} />
            <Modal show={toggle} title="Unlink Account/Group" cta="Delete" onCta={onConfirmDelete} onHide={onDismiss}>
                Are you sure you want to undo this Account/Group link?
            </Modal>

        </View>
  );
};

export default AccountGroupDataTable;
